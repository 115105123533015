import { observer } from 'mobx-react-lite'
import React from 'react'

import Header from '@src/app/onboarding/Header'
import Button from '@src/component/ButtonV2'
import useInputState from '@src/lib/hooks/useInputState'
import TextField from '@ui/TextField'
import Typography from '@ui/Typography'

import { useLoginStore } from '.'
import * as styles from './ForgotPassword.css'

function ForgotPassword() {
  const loginStore = useLoginStore()
  const [email, setEmail] = useInputState(loginStore.email ?? '')
  const disabled = !email

  const handleResetPassword = () => {
    loginStore.resetPassword(email)
  }

  const handleBack = () => {
    loginStore.setStep('password')
  }

  return (
    <div>
      <Header />
      <div className={styles.card}>
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Reset your password
        </Typography>
        <Typography variant="body" color="textSecondary">
          We will send you an email with instructions on how to reset your password.
        </Typography>

        <div style={{ marginTop: 48, textAlign: 'left' }}>
          <TextField
            autoFocus={!email}
            type="email"
            fullWidth
            label="Email"
            size={45}
            value={email}
            onChange={setEmail}
          />
          <Button
            fullWidth
            height={40}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleResetPassword}
            disabled={disabled}
            loading={loginStore.loading === 'sending_reset_password'}
          >
            Send Reset Email
          </Button>
          <Button
            fullWidth
            height={36}
            variant="text"
            color="textSecondary"
            fontWeight="regular"
            style={{ marginTop: 8 }}
            onClick={handleBack}
          >
            Go back
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(ForgotPassword)
