import * as LDClient from 'launchdarkly-js-client-sdk'
import { makeObservable, observable, computed, action } from 'mobx'

import config from '@src/config'
import type Service from '@src/service'
import type { User, Organization } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'

import type { Flags, FlagKey, FlagValue } from './FlagsTypes'
import defaultFlags from './defaultFlags'
import launchDarklyAdapter from './launchDarklyAdapter'

const staticAnonymousUser: LDClient.LDUser = {
  key: '__anonymous__',
  anonymous: true,
}

export default class FlagsService {
  private ldClient: LDClient.LDClient
  private ldFlags: LDClient.LDFlagSet = {}

  constructor(private readonly root: Service) {
    makeObservable<this, 'ldFlags' | 'syncFlags'>(this, {
      ldFlags: observable,
      flags: computed,
      syncFlags: action.bound,
    })

    makePersistable(this, 'FlagsService', {
      ldFlags: this.root.storage.async(),
    })

    this.ldClient = LDClient.initialize(
      config.LAUNCH_DARKLY_CLIENT_SIDE_ID,
      staticAnonymousUser,
      {},
    )

    this.ldClient.on('change', this.syncFlags)
  }

  get flags(): Flags {
    return launchDarklyAdapter(this.ldFlags)
  }

  getFlag<Key extends FlagKey>(key: Key, defaultValue?: FlagValue<Key>): FlagValue<Key> {
    const isDefinedFlag = (flag: Flags[Key]): flag is FlagValue<Key> => flag !== undefined
    const flag = this.flags[key]

    if (isDefinedFlag(flag)) {
      return flag
    }

    return defaultValue ?? defaultFlags[key]
  }

  async tearDown() {
    await this.ldClient.close()
  }

  async waitUntilReady() {
    await this.ldClient.waitUntilReady()
    this.syncFlags()
  }

  async identify(user: User | null, organization: Organization | null) {
    if (!user) {
      await this.ldClient.identify(staticAnonymousUser)

      return
    }

    const custom: NonNullable<LDClient.LDUser['custom']> = {
      appVersion: config.VERSION,
    }

    if (organization) {
      custom.organizationId = organization.id
    }

    await this.ldClient.identify({
      key: user.id,
      avatar: user.pictureUrl ?? '',
      email: user.email ?? '',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      custom,
    })
  }

  private syncFlags() {
    this.ldFlags = this.ldClient.allFlags()
  }
}
