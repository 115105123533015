/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import cx from 'classnames'
import React, { useMemo } from 'react'

import type { FileType } from '@src/lib/file'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  width?: number
  height?: number
  type: FileType
}

const Icon = React.forwardRef(
  (
    { width, height, type, className, ...props }: IconProps,
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => {
    const {
      width: vbWidth,
      height: vbHeight,
      path,
    } = useMemo(() => {
      return icon[type]
    }, [type])

    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={width || vbWidth}
        height={height || vbHeight}
        viewBox={`0 0 ${vbWidth} ${vbHeight}`}
        fill="currentColor"
        {...props}
        className={cx(className, 'icon')}
      >
        <g>{path}</g>
      </svg>
    )
  },
)

export default Icon

const icon: {
  [key in FileType]: {
    width: number
    height: number
    path: React.ReactNode | null
  }
} = {
  audio: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="mwgukjtfza"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="pgxdvrur0b"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#mwgukjtfza)" xlinkHref="#pgxdvrur0b" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g>
            <path d="M7 13h16v16H7z" />
            <path
              d="M12.667 24.854v-6.545c0-.276.17-.524.429-.622l5.667-2.159a.666.666 0 0 1 .904.623v7.366"
              stroke="#FF2F48"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.179 22.338a1.667 1.667 0 1 1-2.357 2.357 1.667 1.667 0 0 1 2.357-2.357M12.179 23.671a1.667 1.667 0 1 1-2.358 2.357 1.667 1.667 0 0 1 2.358-2.357"
              stroke="#FF2F48"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
  blank: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="xl5au8drqa"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="imy7xd00ub"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#xl5au8drqa)" xlinkHref="#imy7xd00ub" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g>
            <path d="M7 13h16v16H7z" />
            <path
              d="M12.165 27h5.667a2 2 0 0 0 2-2v-6.419a2 2 0 0 0-.586-1.414l-1.581-1.581A2 2 0 0 0 16.25 15h-4.086a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2z"
              stroke="#7438FF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.832 18.667h-2.334a1.333 1.333 0 0 1-1.333-1.334V15"
              stroke="#7438FF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
  contact: {
    width: 24,
    height: 24,
    path: null,
  },
  doc: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="4kfoilveba"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="jrbraickmb"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#4kfoilveba)" xlinkHref="#jrbraickmb" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g transform="translate(7 13)">
            <path d="M0 0h16v16H0z" />
            <rect
              stroke="#218AFF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="2"
              y="2"
              width="12"
              height="12"
              rx="2"
            />
            <rect
              stroke="#218AFF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="4.667"
              y="4.667"
              width="2.333"
              height="2.333"
              rx=".333"
            />
            <path
              stroke="#218AFF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 4.667h2.333M9 7h2.333M4.667 9h6.666M4.667 11.333h6.666"
            />
          </g>
        </g>
      </>
    ),
  },
  csv: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="x7tgmrezfa"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="kye7cwhnjb"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#x7tgmrezfa)" xlinkHref="#kye7cwhnjb" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g>
            <path d="M7 13h16v16H7z" />
            <path
              d="M19.667 27h-9.334A1.333 1.333 0 0 1 9 25.667v-9.334C9 15.597 9.597 15 10.333 15h9.334c.736 0 1.333.597 1.333 1.333v9.334c0 .736-.597 1.333-1.333 1.333zM9 19h12M9 23h12M15 19v8"
              stroke="#00B44A"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
  pdf: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="77t031w7sa"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="pl9exmaj8b"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#77t031w7sa)" xlinkHref="#pl9exmaj8b" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g>
            <path d="M7 13h16v16H7z" />
            <path
              d="M13.152 16.323v1.85c0 .51.414.923.923.923h1.85c.51 0 .923-.414.923-.924v-1.849a.923.923 0 0 0-.923-.923h-1.85a.923.923 0 0 0-.923.923zM8.675 23.71v1.85c0 .51.413.923.923.923h1.849c.51 0 .923-.413.923-.923v-1.85a.923.923 0 0 0-.923-.922h-1.85a.924.924 0 0 0-.922.923h0zM17.63 23.71v1.85c0 .51.413.923.923.923h1.85c.51 0 .922-.413.922-.923v-1.85a.923.923 0 0 0-.923-.922h-1.849a.924.924 0 0 0-.923.923h0zM17.628 24.188h-5.256M11.501 22.794l2.486-3.721M16.156 19.042l2.375 3.752"
              stroke="#FF410B"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
  presentation: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="fkgy002zta"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="rv7p97kmwb"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#fkgy002zta)" xlinkHref="#rv7p97kmwb" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g transform="translate(7 13)">
            <path d="M0 0h16v16H0z" />
            <path
              stroke="#FA8618"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 10.667v2.666M5.333 14l1.24-3.333M10.667 14l-1.24-3.333M5 8V6.667M7 8V4.667M9 8V6M11 8V4.967"
            />
            <rect
              stroke="#FA8618"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="2"
              y="2"
              width="12"
              height="8.667"
              rx="1.333"
            />
          </g>
        </g>
      </>
    ),
  },
  spreadsheet: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="x7tgmrezfa"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="kye7cwhnjb"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#x7tgmrezfa)" xlinkHref="#kye7cwhnjb" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g>
            <path d="M7 13h16v16H7z" />
            <path
              d="M19.667 27h-9.334A1.333 1.333 0 0 1 9 25.667v-9.334C9 15.597 9.597 15 10.333 15h9.334c.736 0 1.333.597 1.333 1.333v9.334c0 .736-.597 1.333-1.333 1.333zM9 19h12M9 23h12M15 19v8"
              stroke="#00B44A"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
  zip: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="xg4t4hkvka"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="t27etwpfjb"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#xg4t4hkvka)" xlinkHref="#t27etwpfjb" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g transform="translate(7 13)">
            <path d="M0 0h16v16H0z" />
            <path
              stroke="#5E5E6F"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 4v.667M8 6.667v.666"
            />
            <rect
              stroke="#5E5E6F"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="7"
              y="9.333"
              width="2"
              height="2.667"
              rx="1"
            />
            <rect
              stroke="#5E5E6F"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="2"
              y="2"
              width="12"
              height="12"
              rx="2.2"
            />
          </g>
        </g>
      </>
    ),
  },
  image: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="f6xpvzr5xa"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="zf8wv2buob"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#f6xpvzr5xa)" xlinkHref="#zf8wv2buob" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g transform="translate(7 13)">
            <path d="M0 0h16v16H0z" />
            <rect
              stroke="#00C190"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="2"
              y="2"
              width="12"
              height="12"
              rx="2.5"
            />
            <path
              stroke="#00C190"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14 5.333 9.333 10M12.667 13.333 6.943 7.61a1.333 1.333 0 0 0-1.886 0L2 10.667M9.367 5.333a.033.033 0 1 1-.034-.033h0"
            />
            <path
              d="M9.333 5.3c.019 0 .034.015.034.033"
              stroke="#00C190"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
  text: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="4kfoilveba"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="jrbraickmb"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#4kfoilveba)" xlinkHref="#jrbraickmb" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g transform="translate(7 13)">
            <path d="M0 0h16v16H0z" />
            <rect
              stroke="#218AFF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="2"
              y="2"
              width="12"
              height="12"
              rx="2"
            />
            <rect
              stroke="#218AFF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="4.667"
              y="4.667"
              width="2.333"
              height="2.333"
              rx=".333"
            />
            <path
              stroke="#218AFF"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 4.667h2.333M9 7h2.333M4.667 9h6.666M4.667 11.333h6.666"
            />
          </g>
        </g>
      </>
    ),
  },
  video: {
    width: 30,
    height: 39,
    path: (
      <>
        <defs>
          <filter
            x="-18.8%"
            y="-10.9%"
            width="137.5%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="m8ifchffza"
          >
            <feMorphology
              radius="1"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              in="shadowBlurOuter1"
            />
          </filter>
          <path
            d="M3.5 0h12.672a2 2 0 0 1 1.414.586l5.828 5.828A2 2 0 0 1 24 7.828V28.5a3.5 3.5 0 0 1-3.5 3.5h-17A3.5 3.5 0 0 1 0 28.5v-25A3.5 3.5 0 0 1 3.5 0z"
            id="98myg50tab"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M1 0h28v38H1z" />
          <g transform="translate(3 3)">
            <use fill="#000" filter="url(#m8ifchffza)" xlinkHref="#98myg50tab" />
            <path
              strokeOpacity=".04"
              stroke="#000"
              d="M16.172-.5a2.5 2.5 0 0 1 1.767.732l5.829 5.829a2.5 2.5 0 0 1 .732 1.767V28.5a3.987 3.987 0 0 1-1.172 2.828A3.987 3.987 0 0 1 20.5 32.5h-17a3.987 3.987 0 0 1-2.828-1.172A3.987 3.987 0 0 1-.5 28.5v-25C-.5 2.395-.052 1.395.672.672A3.987 3.987 0 0 1 3.5-.5z"
              fill="#FFF"
            />
          </g>
          <path
            d="M19 3v6a2 2 0 0 0 2 2h6"
            stroke="#E1E1E3"
            fill="#FFF"
            strokeLinejoin="round"
          />
          <g>
            <path d="M7 13h16v16H7z" />
            <path
              d="M20.333 27H9.667a1.333 1.333 0 0 1-1.334-1.333v-9.334A1.333 1.333 0 0 1 9.667 15h10.666a1.333 1.333 0 0 1 1.334 1.333v9.334A1.333 1.333 0 0 1 20.333 27zM8.333 18.333h13.334M11 18.333 12.333 15M14.333 18.333 15.667 15M17.667 18.333 19 15"
              stroke="#3DB5E0"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.748 23.799v-2.466a.433.433 0 0 1 .65-.375l2.134 1.233a.433.433 0 0 1 0 .75l-2.133 1.233a.433.433 0 0 1-.65-.375z"
              stroke="#3DB5E0"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </>
    ),
  },
}
