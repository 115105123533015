/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable } from 'mobx'

import { ENV_OVERRIDE_KEY } from '@src/config'
import type Env from '@src/config/Env'
import { AnalyticsStore } from '@src/service/analytics'
import { CapabilitiesService } from '@src/service/capabilities'

import CnamStore from './CnamStore'
import PortRequestStore from './PortRequestStore'
import ScheduledMessageStore from './ScheduledMessageStore'
import type { SoundURLs } from './SoundStore'
import SoundStore from './SoundStore'
import TrustRegistrationStore from './TrustRegistrationStore'
import WorkspaceStore from './WorkspaceStore'
import ActivityStore from './activity-store'
import AlertStore from './alert-store'
import AuthStore from './auth-store'
import BillingStore from './billing-store'
import BlocklistStore from './blocklist-store'
import ContactStore from './contact-store'
import ConversationStore from './conversation-store'
import EmojiService from './emoji-service'
import { FlagsService } from './feature-flags'
import IntegrationStore from './integration-store'
import MemberStore from './member-store'
import OrganizationStore from './organization-store'
import ParticipantStore from './participant-store'
import PhoneNumberStore from './phone-number-store'
import ReferralStore from './referral-store'
import SearchStore from './search-store'
import SnippetsStore from './snippet-store'
import StorageService from './storage/StorageService'
import Transport from './transport'
import UserStore from './user-store'
import { VoiceStore } from './voice'
import MainWorker from './worker/main'

export interface ServiceConfig {
  workerName: string
  sounds: SoundURLs
}

export default class Service {
  readonly storage: StorageService
  readonly transport: Transport
  readonly activity: ActivityStore
  readonly alert: AlertStore
  readonly auth: AuthStore
  readonly billing: BillingStore
  readonly blocklist: BlocklistStore
  readonly contact: ContactStore
  readonly conversation: ConversationStore
  readonly integration: IntegrationStore
  readonly member: MemberStore
  readonly organization: OrganizationStore
  readonly participant: ParticipantStore
  readonly phoneNumber: PhoneNumberStore
  readonly search: SearchStore
  readonly snippet: SnippetsStore
  readonly sound: SoundStore
  readonly user: UserStore
  readonly voice: VoiceStore
  readonly referral: ReferralStore
  readonly workspace: WorkspaceStore
  readonly emoji: EmojiService
  readonly scheduledMessage: ScheduledMessageStore
  readonly cnam: CnamStore
  readonly flags: FlagsService
  readonly capabilities: CapabilitiesService
  readonly portRequest: PortRequestStore
  readonly trustRegistration: TrustRegistrationStore
  readonly analytics: AnalyticsStore

  constructor(
    protected config: ServiceConfig,
    protected desktopVersion: string | undefined,
  ) {
    makeAutoObservable(this, {})
    const worker = new MainWorker(this.config.workerName)
    this.storage = new StorageService(worker)
    this.transport = new Transport(this)
    this.activity = new ActivityStore(this)
    this.alert = new AlertStore(this)
    this.auth = new AuthStore(this)
    this.billing = new BillingStore(this)
    this.blocklist = new BlocklistStore(this)
    this.contact = new ContactStore(this, worker)
    this.conversation = new ConversationStore(this, worker)
    this.integration = new IntegrationStore(this)
    this.member = new MemberStore(this)
    this.organization = new OrganizationStore(this)
    this.participant = new ParticipantStore(this)
    this.phoneNumber = new PhoneNumberStore(this)
    this.search = new SearchStore(this, worker)
    this.snippet = new SnippetsStore(this)
    this.sound = new SoundStore(this, this.config.sounds)
    this.user = new UserStore(this)
    this.referral = new ReferralStore(this)
    this.workspace = new WorkspaceStore(this)
    this.emoji = new EmojiService(this)
    this.scheduledMessage = new ScheduledMessageStore(this)
    this.cnam = new CnamStore(this)
    this.flags = new FlagsService(this)
    this.capabilities = new CapabilitiesService(this)
    this.portRequest = new PortRequestStore(this)
    this.trustRegistration = new TrustRegistrationStore(this)
    this.analytics = new AnalyticsStore(this, desktopVersion)
    this.voice = new VoiceStore(this)
  }

  reset = () => {
    return this.storage.reset().then(() => window.location.reload())
  }

  clearAllAndRestart = (evnOverride?: Env) => {
    return this.storage.clearAll().then(() => {
      if (evnOverride) {
        localStorage.setItem(ENV_OVERRIDE_KEY, evnOverride)
      }
      window.location.reload()
    })
  }
}

export * from './dto'
