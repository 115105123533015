import { action, makeAutoObservable } from 'mobx'

import type { FileType } from '@src/lib/file'
import { fileSize, fileType, fileTypeFromExtension, isValidFileType } from '@src/lib/file'
import uuid from '@src/lib/uuid'

import type Service from '..'

import type { Model } from './base'
import { thumbnailUrl } from './utils'

export interface DecodableMessageMedia {
  id?: string
  name?: string | null
  type?: string | null
  file?: File | null
  url?: string | null
  thumbnailUrl?: string
}

export interface CodableMessageMedia {
  id: string
  type: string | null
  url: string | null
  name: string | null
  length: number | null
}

export class MessageMedia implements DecodableMessageMedia, Model {
  id: string = uuid()
  type: string | null = null
  url: string | null = null
  file: File | null = null
  name: string | null = null
  uploadProgress = 0
  length = 0

  constructor(
    protected root: Service,
    attrs?: Partial<CodableMessageMedia> | Partial<DecodableMessageMedia>,
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get thumbnailUrl(): string {
    return thumbnailUrl(this.url ?? '', { width: 500, quality: 100 })
  }

  get size() {
    if (this.length) {
      return fileSize(this.length)
    } else if (this.file) {
      return fileSize(this.file.size)
    } else {
      return null
    }
  }

  get nameWithoutExtension() {
    if (!this.name) return null
    return this.name.slice(0, this.name.lastIndexOf('.'))
  }

  get extension() {
    if (this.name) {
      return this.name.slice(this.name.lastIndexOf('.'))
    }

    if (this.url) {
      const url = new URL(this.url)
      return url.pathname.slice(url.pathname.lastIndexOf('.'))
    }

    return null
  }

  get typeFromExtension(): FileType {
    return fileTypeFromExtension(this.extension?.slice(1) ?? '')
  }

  get fileType(): FileType {
    let type: FileType = 'blank'

    if (this.type) {
      type = fileType(this.type, this.name)
    } else if (this.file) {
      type = fileType(this.file.type, this.file.path)
    } else {
      type = this.typeFromExtension
    }

    return type
  }

  async makeFileFromUrl() {
    try {
      if (!this.url) return
      const response = await fetch(this.url)
      const content = await response.blob()
      const file = new File([content], this.url)
      this.file = file
      this.length = content.size
    } catch {
      // ignore errors
    }
  }

  upload() {
    if (!this.file || this.isInvalidFileType()) return

    return this.root.conversation
      .upload(
        this.file,
        action((progress, total) => {
          this.uploadProgress = progress / total
        }),
      )
      .then(
        action((url) => {
          this.url = url
        }),
      )
  }

  deserialize(attrs?: Partial<CodableMessageMedia> | Partial<DecodableMessageMedia>) {
    if (attrs) {
      Object.assign(this, attrs)
    }
    return this
  }

  serialize(): CodableMessageMedia {
    return {
      id: this.id,
      type: this.type,
      url: this.url,
      name: this.name,
      length: this.file?.size ?? this.length,
    }
  }

  private isInvalidFileType() {
    return !isValidFileType(this.type ?? '')
  }
}

export function isCodableMessageMedia(item: unknown): item is CodableMessageMedia {
  if (typeof item !== 'object' || item == null) return false

  const requiredProperties: (keyof CodableMessageMedia)[] = ['id', 'name', 'type', 'url']

  return requiredProperties.every((property) => property in item)
}
