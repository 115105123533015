import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsIsTomorrow from 'dayjs/plugin/isTomorrow'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

import { isTruthy } from '@src/lib/string'

import { chrono } from './chrono'

/**
 * Extends `dayjs` with the needed plugins so that the are available throughout the whole app.
 * This gets only called once on app initialisation.
 */
export const extendDayJs = () => {
  dayjs.extend(quarterOfYear)
  dayjs.extend(relativeTime)
  dayjs.extend(utc)
  dayjs.extend(dayjsIsToday)
  dayjs.extend(dayjsIsTomorrow)
  dayjs.extend(advancedFormat)
}

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const

const nth = function (d) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

const getDayjs = (date: string | number | Date | Dayjs): Dayjs => {
  if (dayjs.isDayjs(date)) {
    return date
  }
  return dayjs(date)
}

export const isDate = (date: unknown): date is Date => {
  return !!date && date instanceof Date
}

export const fromNow = (
  date: string | number | Date | Dayjs,
  withoutSuffix = false,
): string => {
  return getDayjs(date).fromNow(withoutSuffix)
}

export const sameDay = (
  timestamp1: number | Date | Dayjs,
  timestamp2: number | Date | Dayjs,
): boolean => {
  return getDayjs(timestamp1).isSame(getDayjs(timestamp2), 'date')
}

export const sameWeek = (
  timestamp1: number | Date | Dayjs,
  timestamp2: number | Date | Dayjs,
): boolean => {
  return getDayjs(timestamp1).isSame(getDayjs(timestamp2), 'week')
}

export const sameYear = (
  timestamp1: number | Date | Dayjs,
  timestamp2: number | Date | Dayjs,
): boolean => {
  return getDayjs(timestamp1).isSame(getDayjs(timestamp2), 'year')
}

export const isYesterday = (timestamp: number | Date | Dayjs): boolean => {
  return sameDay(timestamp, Date.now() - 24 * 3600 * 1000)
}

export const isTomorrow = (timestamp: number | Date | Dayjs): boolean => {
  return sameDay(timestamp, Date.now() + 24 * 3600 * 1000)
}

export const isThisWeek = (timestamp: number | Date | Dayjs): boolean => {
  return sameWeek(timestamp, Date.now())
}

export const isToday = (timestamp: number | Date | Dayjs): boolean => {
  return sameDay(timestamp, Date.now())
}

export const isThisYear = (timestamp: number | Date | Dayjs): boolean => {
  return sameYear(timestamp, Date.now())
}

export const isFromPreviousYear = (
  timestamp: string | number | Date | Dayjs,
): boolean => {
  return dayjs(timestamp).year() < dayjs().year()
}

export interface FriendlyDateOptions {
  upperFirst?: boolean
}

export const friendlyDate = (
  timestamp: number | Date | Dayjs,
  { upperFirst = true }: FriendlyDateOptions = {},
): string => {
  if (isToday(timestamp)) {
    return (upperFirst ? 'T' : 't') + 'oday'
  } else if (isYesterday(timestamp)) {
    return (upperFirst ? 'Y' : 'y') + 'esterday'
  } else if (isThisYear(timestamp)) {
    return getDayjs(timestamp).format('MMM D')
  } else {
    return getDayjs(timestamp).format('MMM D, YYYY')
  }
}

export type FriendlyDateTimeOptions = FriendlyDateOptions

export const friendlyDateTime = (
  timestamp: number | Date | Dayjs,
  { upperFirst = true }: FriendlyDateTimeOptions = {},
): string => {
  if (isToday(timestamp)) {
    return (upperFirst ? 'T' : 't') + `oday, ${getDayjs(timestamp).format('h:mm a')}`
  } else if (isYesterday(timestamp)) {
    return (upperFirst ? 'Y' : 'y') + `esterday ${getDayjs(timestamp).format('h:mm a')}`
  } else if (isThisYear(timestamp)) {
    return getDayjs(timestamp).format('MMM D, h:mm a')
  } else {
    return getDayjs(timestamp).format('MMM D, YYYY, h:mm a')
  }
}

export const fromNowV2 = (timestamp: number | Date): string => {
  const minute = 60
  const hour = minute * 60
  const date = getDayjs(timestamp)
  const diff = date.unix() - dayjs().unix()

  /**
   * If the date is within 60 mins show e.g. "for 45 minutes."
   */
  if (diff < hour) {
    const min = Math.ceil(diff / minute)
    return `for ${min} ${min === 1 ? 'minute' : 'minutes'}`
  }

  /**
   * If it's within 3 hours, show e.g. "for 2 hours".
   */
  if (diff < 3 * hour) {
    const hours = Math.floor(diff / hour)
    const parts = [hours, Math.round((diff % hour) / minute)]
    return [
      'for',
      ...parts
        .map((p, i) =>
          i === 0
            ? `${p} ${p === 1 ? 'hour' : 'hours'}`
            : p > 0
            ? `and ${p} ${p === 1 ? 'minute' : 'minutes'}`
            : null,
        )
        .filter(isTruthy),
    ].join(' ')
  }

  /**
   * If it's today show e.g. "until 5pm"
   */
  if (isToday(date)) {
    return `until ${date.format('h:mma')}`
  }

  /**
   * If it's tomorrow show e.g. "until tomorrow at 5pm"
   */
  if (isTomorrow(date)) {
    return `until tomorrow at ${date.format('h:mma')}`
  }

  /**
   * If it's this week show e.g. "until Wednesday at 5pm"
   */
  if (isThisWeek(date)) {
    return `until ${date.format('dddd')} at ${date.format('h:mma')}`
  }

  /**
   * If it's this year show e.g. "until April 16th at 5pm"
   */
  if (isThisYear(date)) {
    return `until ${date.format('MMMM D')}${nth(date.date())} at ${date.format('h:mma')}`
  }

  /**
   * If it's in distant future, say "until further notice"
   */
  if (date.year() - dayjs().year() > 20) {
    return `until further notice`
  }

  /**
   * Return e.g. "on January 7th 2021 at 3pm"
   */
  return `on ${date.format('MMMM D')}${nth(date.date())} ${date.year()} at ${date.format(
    'h:mma',
  )}`
}

export const friendlyTime = (timestamp: number | Date): string => {
  return dayjs(timestamp).format('h:mm a')
}

export const formatDate = (
  date: Date | number | string,
  format = 'MMM D, YYYY',
  offset?: number,
): string => {
  if (offset !== undefined) {
    return dayjs(date).utcOffset(offset).format(format)
  }

  return dayjs(date).format(format)
}

export const toHHMMSS = (duration: number): string => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = duration - hours * 3600 - minutes * 60

  const hoursStr = hours > 0 ? `${hours}:` : ''
  const minutesStr = `${hours > 0 ? minutes.toString().padStart(2, '0') : minutes}:`
  const secondsStr = seconds.toString().padStart(2, '0')

  return `${hoursStr}${minutesStr}${secondsStr}`
}

export const parse = (input: string | number): Date | null => {
  if (!input) return null
  return dayjs(input).toDate()
}

export function minutes(number: number): number {
  return number * 60000
}

export function hours(number: number): number {
  return number * minutes(60)
}

export function days(number: number): number {
  return number * hours(24)
}

export function months(number: number): number {
  return number * days(30)
}

export function parseFromString(
  text: string,
  opts?: { forwardDate: boolean },
): Date | null {
  return (
    chrono.parseDate(text, undefined, opts) ||
    chrono.parseDate(`in ${text}`, undefined, opts)
  )
}

export function parseDate(date: string | number | Date): number | null {
  if (typeof date === 'string') {
    return Date.parse(date)
  } else if (typeof date === 'number') {
    return date
  } else if (date instanceof Date) {
    return date.getTime()
  }
  return null
}

export function daysBefore(date: string | number | Date | dayjs.Dayjs): number {
  return dayjs(date).diff(new Date(), 'day')
}

/**
 * Get the UTC offset formatted as `UTC[+-]hh:mm` given minutes
 * as the parameter to get the offset from.
 *
 * Example: given -60 it outputs UTC-01:00
 *
 * @param offsetInMinutes Can be negative or positive indicating
 * if it's behind UTC or ahead.
 */
export function formatUTCOffset(offsetInMinutes: number) {
  if (offsetInMinutes === 0) return 'UTC'

  const sign = offsetInMinutes < 0 ? '-' : '+'

  const hours = Math.abs(Math.trunc(offsetInMinutes / 60))
    .toString()
    .padStart(2, '0')

  const minutes = Math.abs(Math.trunc(offsetInMinutes % 60))
    .toString()
    .padStart(2, '0')

  return `UTC${sign}${hours}:${minutes}`
}

/**
 * Returns if the formatted date string should be preceeded by a
 * preposition such as "at", "in", or "on".
 *
 * E.g. it's correct to say "on May 1" but incorrect to say "on today".
 *
 * @param formattedDate The date formatted as a string from a function
 * such as friendlyDate.
 */
export function hasTimePreposition(formattedDate: string) {
  return !['today', 'yesterday', 'tomorrow'].includes(formattedDate.toLowerCase())
}
