import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useField } from '@react-aria/label'
import { useId } from '@react-aria/utils'
import cx from 'classnames'
import React from 'react'

import Tooltip from '@ui/Tooltip'
import VisuallyHidden from '@ui/VisuallyHidden'
import { MiniInfo } from '@ui/icons/custom'

import type { FormFieldContextType } from './FormFieldContext'
import FormFieldContext from './FormFieldContext'

export interface FormFieldProps {
  /**
   * The label for the form field.
   */
  label: React.ReactNode

  /**
   * Short description of the form field. This is used to provide a hint to the user of the field's purpose.
   */
  description?: string

  /**
   * Detailed description of the form field. This is used to provide additional information about the field's purpose.
   */
  details?: string

  /**
   * Error message to display. This is used to provide feedback to the user about the field's state.
   */
  error?: string

  /**
   * Hides the label and the details tooltip trigger from the UI.
   */
  hideTop?: boolean

  /**
   * Hides the description and error message from the UI.
   */
  hideBottom?: boolean

  /**
   * Class name to apply to the form field.
   */
  className?: string

  /**
   * Children should be Form inputs, selects or other form elements.
   *
   * FormField provides a context for the children to access the form field's state,
   * and a set of html props to pass to the native html element to make it accessible.
   *
   * This prop also allows passing a function, which will receive the context and should return a `ReactNode`.
   *
   * @see FormFieldContext
   */
  children: React.ReactNode | ((context: FormFieldContextType) => React.ReactNode)
}

export const FormField = ({
  label,
  description,
  details,
  error,
  children,
  hideTop,
  hideBottom,
  className,
}: FormFieldProps) => {
  const id = useId()
  const ariaDetailsId = `${id}-details`
  const styles = useStyles({ error })
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } = useField({
    id,
    label,
    description,
    errorMessage: error,
    validationState: error ? 'invalid' : 'valid',
  })

  const contextValue: FormFieldContextType = {
    htmlProps: {
      ...fieldProps,
      'aria-invalid': !!error,
      'aria-details': ariaDetailsId,
    },
    fieldProps: {
      hasError: !!error,
    },
  }

  const bottomContent = (
    <>
      {description && !error ? (
        <div {...descriptionProps} className={cx(styles.bottom, styles.description)}>
          {description}
        </div>
      ) : null}
      {error ? (
        <div {...errorMessageProps} className={cx(styles.bottom, styles.error)}>
          {error}
        </div>
      ) : null}
    </>
  )

  return (
    <FormFieldContext.Provider value={contextValue}>
      <div className={cx(styles.root, className)}>
        {hideTop ? (
          <VisuallyHidden>
            <label {...labelProps}>{label}</label>
          </VisuallyHidden>
        ) : (
          <div className={styles.top}>
            <label {...labelProps} className={styles.label}>
              {label}
            </label>

            {details ? (
              <Tooltip title={details} placement="right">
                <button
                  className={styles.detailsTrigger}
                  aria-label="Field Details"
                  type="button"
                >
                  <MiniInfo />
                </button>
              </Tooltip>
            ) : null}
          </div>
        )}

        {typeof children === 'function' ? children(contextValue) : children}

        {hideBottom ? <VisuallyHidden>{bottomContent}</VisuallyHidden> : bottomContent}

        <VisuallyHidden>
          <span id={ariaDetailsId}>{details}</span>
        </VisuallyHidden>
      </div>
    </FormFieldContext.Provider>
  )
}

export default FormField

const useStyles = makeStyles<Theme, Pick<FormFieldProps, 'error'>>((theme) => ({
  root: {},
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
  },
  // eslint-disable-next-line custom-rules/typography-variant -- FIXME: Fix this ESLint violation!
  label: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 450,
    color: ({ error }) =>
      error ? theme.palette.op.secondary.red2 : theme.palette.op.gray[1],
  },
  detailsTrigger: {
    marginLeft: 6,
    borderRadius: 3,
    width: 16,
    height: 16,
    backgroundColor: theme.palette.op.hover.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.op.gray[2],
    border: 0,

    '&:hover': {
      backgroundColor: theme.palette.op.hover.darker,
    },
  },
  bottom: {
    marginTop: 7,
  },
  // eslint-disable-next-line custom-rules/typography-variant -- FIXME: Fix this ESLint violation!
  description: {
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: 450,
    color: theme.palette.op.gray[3],
  },
  // eslint-disable-next-line custom-rules/typography-variant -- FIXME: Fix this ESLint violation!
  error: {
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: 450,
    color: theme.palette.op.secondary.red2,
  },
}))
