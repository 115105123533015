import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'

import AnimatingLogo from '@src/app/components/AnimatingLogo'
import AppSkeleton from '@src/app/components/AppSkeleton'
import RotatingTips from '@src/app/components/RotatingTips'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import { isMac } from '@src/config'
import useTransition from '@src/lib/hooks/useTransition'
import ProgressBar from '@ui/ProgressBar'
import Typography from '@ui/Typography'
import VisuallyHidden from '@ui/VisuallyHidden'

import * as styles from './ConnectedAppSkeleton.css'

const LoggedInSkeleton = function () {
  const {
    signOut,
    reset: resetState,
    loadingPercentage,
    initializationStatus,
    needsOnboarding,
    isLoggedIn,
  } = useAppStore()
  const state = useTransition(true, { enterDelay: 2000 })
  const hidden = state !== 'entered'
  const percentage = hidden ? 0 : Math.min(loadingPercentage, 100)

  const handleReload = () => {
    resetState()
  }

  const handleSignout = () => {
    signOut()
  }

  const showSkeleton = isLoggedIn && needsOnboarding === false

  return (
    <>
      {showSkeleton ? <AppSkeleton /> : null}
      <div
        className={cx(styles.root, hidden && styles.hidden)}
        style={{
          WebkitAppRegion: !hidden && isMac ? 'drag' : undefined,
        }}
      >
        <div className={styles.message} aria-atomic aria-live="assertive">
          <AnimatingLogo />
          <ProgressBar
            className={styles.progressBar}
            value={percentage}
            label="Loading application..."
            status={initializationStatus === 'failed' ? 'error' : 'active'}
            aria-hidden
          />
          {initializationStatus !== 'failed' ? (
            <>
              <RotatingTips aria-hidden />
              <VisuallyHidden>
                {percentage >= 100 ? 'Loaded' : `Loading application...`}
              </VisuallyHidden>
            </>
          ) : (
            <>
              <Typography
                variant="callout"
                color="textSecondary"
                className={styles.errorMessage}
              >
                Unfortunately this is taking too long.
                <br />
                Please choose one of the options below to reset your session.
              </Typography>
              <div style={{ display: 'flex', gap: 10 }}>
                <Button
                  height={36}
                  variant="outlined"
                  color="textPrimary"
                  onClick={handleReload}
                >
                  <Typography variant="footnote" fontWeight="medium">
                    Reload account
                  </Typography>
                </Button>
                <Button
                  height={36}
                  variant="outlined"
                  color="textPrimary"
                  onClick={handleSignout}
                >
                  <Typography variant="footnote" fontWeight="medium">
                    Log out
                  </Typography>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default observer(LoggedInSkeleton)
