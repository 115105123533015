/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'
import Collection from '@src/service/collections/Collection'
import type { Conversation } from '@src/service/conversation-store'

import type Service from '..'

import type { Model } from './base'

export class DirectNumber implements Model {
  id: string = ObjectID()
  createdAt: number | null = null
  number: string | null = null
  orgId: string | null = null
  updatedAt: number | null = null
  userId: string | null = null

  conversations = new Collection<Conversation>()

  constructor(private service: Service) {}

  deserialize = (json) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      number: this.number,
      orgId: this.orgId,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}

// TODO: maybe it's worth to move it to the class?
// still need to wait untill member obj will use DirectNumber objects
export const isDirectNumber = (value: string | null): boolean =>
  value?.startsWith('@') ?? false
