import { reaction } from 'mobx'
import posthog from 'posthog-js'

import config from '@src/config'
import { formatDate } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import type Service from '@src/service'
import type { IdentifyData } from '@src/service/analytics'
import type { OrganizationAnalytics } from '@src/service/model'

interface PostHogUserData {
  email: string
  full_name: string
  role: string
  signup_date: string
  first_name: string
  last_name: string
  theme_mode: string
  user_email_domain: string
  org_name: string
  is_paying: boolean
  plan: string
  org_creation_date: string
  size: string
  onboarding_industry?: OrganizationAnalytics['industry']
  onboarding_size?: OrganizationAnalytics['teamSize']
  onboarding_use_case?: OrganizationAnalytics['useCase']
  onboarding_teams?: OrganizationAnalytics['teams']
  onboarding_need?: OrganizationAnalytics['need']
  onboarding_users?: OrganizationAnalytics['users']
  subscription_start_date: string
  subscription_end_date: string
  has_auto_charge: boolean
  clearbit_industry: string
  clearbit_subindustry: string
  clearbit_employees: string
}

interface PostHogCompanyData {
  org_name: string
  is_paying: boolean
  plan: string
  org_creation_date: string
  size: string
  onboarding_industry?: OrganizationAnalytics['industry']
  onboarding_size?: OrganizationAnalytics['teamSize']
  onboarding_use_case?: OrganizationAnalytics['useCase']
  onboarding_teams?: OrganizationAnalytics['teams']
  onboarding_need?: OrganizationAnalytics['need']
  onboarding_users?: OrganizationAnalytics['users']
  subscription_start_date: string
  subscription_end_date: string
  has_auto_charge: boolean
  clearbit_industry: string
  clearbit_subindustry: string
  clearbit_employees: string
}

export default class PostHog {
  private disposeBag = new DisposeBag()

  constructor(private root: Service, private desktopVersion?: string) {
    this.initPostHog()

    this.disposeBag.add(
      reaction(
        () => root.flags.getFlag('posthog'),
        (isEnabled) => {
          if (!isEnabled) {
            this.reset()
          }
        },
      ),
    )
  }

  async initPostHog() {
    posthog.init(`${config.POSTHOG_API_KEY}`, {
      api_host: 'https://ph.openphone.com',
    })
  }

  async identify({
    user,
    member,
    organization,
    subscription,
    themeKey,
    workspaceSize,
  }: IdentifyData) {
    if (!user || !organization) {
      return
    }
    const posthogCompanyData: PostHogCompanyData = {
      org_name: organization.name,
      is_paying: subscription?.isActive ?? false,
      plan: subscription?.type ?? '',
      org_creation_date: organization.createdAt ? formatDate(organization.createdAt) : '',
      size: workspaceSize.toString(),
      onboarding_industry: organization.analytics?.industry,
      onboarding_size: organization.analytics?.teamSize,
      onboarding_use_case: organization.analytics?.useCase,
      onboarding_need: organization.analytics?.need,
      onboarding_teams: organization.analytics?.teams,
      onboarding_users: organization.analytics?.users,
      subscription_start_date: subscription?.originalStartedAt
        ? formatDate(subscription.originalStartedAt)
        : '',
      subscription_end_date: subscription?.currentPeriodExpiresAt
        ? formatDate(subscription.currentPeriodExpiresAt)
        : '',
      has_auto_charge: subscription ? subscription.autochargeAmount > 0 : false,
      clearbit_industry: organization.analytics?.enrichment?.category?.industry ?? '',
      clearbit_subindustry:
        organization.analytics?.enrichment?.category?.subIndustry ?? '',
      clearbit_employees:
        organization.analytics?.enrichment?.metrics?.employees?.toString() ?? '',
    }

    const posthogUserData: PostHogUserData = {
      email: user.email ?? '',
      first_name: user.firstName ?? '',
      last_name: user.lastName ?? '',
      full_name: `${user.firstName ?? ''}${user.lastName ? ` ${user.lastName}` : ''}`,
      role: member.role ?? '',
      signup_date: user.createdAt ? formatDate(user.createdAt) : '',
      theme_mode: themeKey,
      user_email_domain: user.email?.split('@')[1] ?? '',
      ...posthogCompanyData,
    }

    posthog.identify(user.id, posthogUserData)
    posthog.people.set({ appVersion: config.VERSION })
    posthog.group('company', organization.id, posthogCompanyData)
    if (this.desktopVersion) {
      posthog.people.set({ desktopVersion: this.desktopVersion })
    }
  }

  reset() {
    posthog.reset()
  }

  track(eventName: string, properties?: Record<string, unknown>) {
    if (this.root.flags.getFlag('posthog')) {
      posthog.capture(eventName, {
        appVersion: config.VERSION,
        desktopVersion: this.desktopVersion,
        ...properties,
      })
    }
  }

  tearDown() {
    this.reset()
    this.disposeBag.dispose()
  }
}
