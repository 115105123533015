/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { AxiosRequestConfig } from 'axios'

import config from '@src/config'
import uuid from '@src/lib/uuid'
import type {
  DeleteConversationRequest,
  PostConversationRequest,
} from '@src/service/dto/request/conversation'
import type {
  GetScheduledMessageRequest,
  GetScheduledMessagesRequest,
  PostScheduledMessageRequest,
  DeleteScheduledMessageRequest,
} from '@src/service/dto/request/scheduled-message'
import type PostUploadRequest from '@src/service/dto/request/upload'
import type {
  GetScheduledMessageResponse,
  GetScheduledMessagesResponse,
  PostScheduledMessageResponse,
  DeleteScheduledMessageResponse,
} from '@src/service/dto/response/scheduled-message'
import type {
  Conversation,
  IActivity,
  IConversation,
  EncodableComment,
  CodableMessageMedia,
} from '@src/service/model'
import type { ActivityEncodableReaction } from '@src/service/model/reactions'

import type Transport from '.'
import type Paginated from './lib/Paginated'
import { HttpTransaction } from './transaction'

export type ConversationParticipantStatus = 'active' | 'away' | 'typing' | 'exited'

export interface ActivitySearchResult {
  _index: string
  _type: string
  _id: string
  _score: number
  _source: {
    activityId: string
    userId: string
    conversationId: string
    sourceId: string
    createdAt: string
    updatedAt: string
    type: string
    status: string
    direction: string
    from: string
    to: string
    body: string
    createdBy: string
    phoneNumber: string
    phoneNumberId: string
    directNumberId: string
    orgId: string
    belongsTo: string
  }
  highlight: { body: string[] }
  sort: [number, number]
}

export interface IvrSettings {
  phoneNumberId?: string
  language?: string
  greeting?: string
  greetingUrl?: string
  defaultDestination?: string | null
  enabled?: boolean
  options?: IvrOption[]
}

export interface IvrOption {
  name?: string
  digit?: number
  phrase?: string
  destination?: string
  message?: string
  messageUrl?: string
}

export type ConversationListQueryParam = {
  before?: string
  last?: number
  since?: Date
  phoneNumber?: string
  phoneNumberId?: string
  directNumberId?: string
  snoozed?: boolean
  read?: boolean
  includeDeleted?: boolean
}

export type ActivitySearchParams = {
  query: string
  limit?: number
  fields?: string[]
  phoneNumbers?: string[]
  phoneNumberIds?: string[]
  conversationIds?: string[]
}

export type BucketName = 'static' | 'port_request_statement'
export type FolderName = 'i' | 'raw' | 'processed'
export type UploadPermissions = 'private'

export interface UploadOptions {
  bucket?: BucketName
  folder?: FolderName
  filePermissions?: UploadPermissions
}

export default class CommunicationClient {
  constructor(private transport: Transport) {}

  activities = {
    list: (params: {
      id?: string | null
      phoneNumberId?: string
      directNumberId?: string
      phoneNumber?: string
      before?: string | null
      last?: number
      next?: number
      inclusive?: boolean
      since?: Date
    }): Promise<Paginated<IActivity> & { conversation: IConversation }> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'get',
          url: `${config.COMMUNICATION_SERVICE_URL}activity`,
          query: params,
        }),
      )
    },

    send: (params: {
      id?: string
      body?: string | null
      media?: CodableMessageMedia[]
      phoneNumberId?: string | null
      directNumberId?: string | null
      conversationId?: string | null
      to?: string | null
    }): Promise<{ activity: IActivity; conversation: IConversation }> => {
      const { id, body, media, conversationId, phoneNumberId, directNumberId, to } =
        params
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation`,
          body: {
            phoneNumberId: phoneNumberId || undefined,
            directNumberId: directNumberId || undefined,
            to,
            body,
            mediaUrl: media?.map((m) => ({
              id: m.id || uuid(),
              url: m.url,
              type: m.type,
              name: m.name,
            })),
            activityId: id,
            conversationId,
          },
        }),
      )
    },

    resolve: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}activity/${id}/resolve`,
          body: {},
        }),
      )
    },

    unresolve: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}activity/${id}/unresolve`,
          body: {},
        }),
      )
    },
  }

  conversations = {
    list: (params: ConversationListQueryParam): Promise<Paginated<Conversation>> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}conversations`,
          query: params,
        }),
      )
    },

    create: (params: PostConversationRequest): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}conversations`,
          method: 'post',
          body: params,
        }),
      )
    },

    updateName: (params: {
      name: string
      conversationId: string
    }): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}conversations/${params.conversationId}`,
          method: 'put',
          body: { name: params.name },
        }),
      )
    },

    delete: ({ id }: DeleteConversationRequest): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}conversations/${id}`,
          method: 'DELETE',
        }),
      )
    },

    snooze: (id: string, duration = 525949200): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/snooze`,
          body: { duration },
        }),
      )
    },

    unsnooze: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/unsnooze`,
        }),
      )
    },

    markAsRead: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/markAsRead`,
        }),
      )
    },

    markAsUnread: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}/markAsUnread`,
        }),
      )
    },

    archive: (id: string): Promise<Conversation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${id}`,
        }),
      )
    },

    participantStatus: (
      conversationId: string,
      status: ConversationParticipantStatus,
    ): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}conversation/${conversationId}/participant/status`,
          body: { name: status, effectiveAt: new Date() },
        }),
      )
    },

    search: (params: ActivitySearchParams): Promise<ActivitySearchResult[]> => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return (
        this.transport
          .queue(
            new HttpTransaction<{ hits: { hits: ActivitySearchResult[] } }>({
              url: `${config.COMMUNICATION_SERVICE_URL}conversation/search`,
              query: {
                q: params.query,
                limit: params.limit ?? 50,
                fields: params.fields?.join(',') || undefined,
                phoneNumberIds: params.phoneNumberIds?.join(',') || undefined,
                phoneNumbers: params.phoneNumbers?.join(',') || undefined,
                conversationIds: params.conversationIds?.join(',') || undefined,
              },
            }),
          )
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
          .then((res) => res.hits.hits)
      )
    },
  }

  ivr = {
    get: (phoneNumberId: string): Promise<IvrSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.COMMUNICATION_SERVICE_URL}admin/ivr/${phoneNumberId}`,
        }),
      )
    },

    set: (settings: IvrSettings): Promise<IvrSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/ivr`,
          body: settings,
        }),
      )
    },

    delete: (phoneNumberId: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}admin/ivr/${phoneNumberId}`,
        }),
      )
    },
  }

  reactions = {
    post: (reaction: ActivityEncodableReaction): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}reaction`,
          body: reaction,
        }),
      )
    },

    delete: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}reaction/${id}`,
        }),
      )
    },
  }

  comments = {
    post: (comment: EncodableComment): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}comment`,
          body: comment,
        }),
      )
    },

    delete: (id: string): Promise<any> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}comment/${id}`,
        }),
      )
    },
  }

  scheduledMessages = {
    list: (
      params?: GetScheduledMessagesRequest,
    ): Promise<GetScheduledMessagesResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'get',
          url: `${config.COMMUNICATION_SERVICE_URL}scheduledMessage`,
          query: {
            phoneNumberId: params?.phoneNumberId,
          },
        }),
      )
    },

    get: ({ id }: GetScheduledMessageRequest): Promise<GetScheduledMessageResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'get',
          url: `${config.COMMUNICATION_SERVICE_URL}scheduledMessage/${id}`,
        }),
      )
    },

    send: (
      params: PostScheduledMessageRequest,
    ): Promise<PostScheduledMessageResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}scheduledMessage`,
          body: params,
        }),
      )
    },

    delete: ({
      id,
    }: DeleteScheduledMessageRequest): Promise<DeleteScheduledMessageResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}scheduledMessage/${id}`,
        }),
      )
    },
  }

  tts(text: string): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return (
      this.transport
        .queue(
          new HttpTransaction<{ url: string }>({
            method: 'post',
            url: `${config.COMMUNICATION_SERVICE_URL}tts`,
            body: { body: text },
          }),
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        .then((r) => r.url)
    )
  }

  upload(
    file: File,
    onProgress?: (loaded: number, total: number) => void,
    options?: UploadOptions,
  ): Promise<string> {
    return this.transport.client
      .post<string, PostUploadRequest>(`${config.COMMUNICATION_SERVICE_URL}upload/url`, {
        type: file.type,
        ...options,
      })
      .then((url) => {
        const config: AxiosRequestConfig = {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progress: ProgressEvent) => {
            // eslint-disable-next-line @typescript-eslint/prefer-optional-chain -- FIXME: Fix this ESLint violation!
            onProgress && onProgress(progress.loaded, progress.total)
          },
        }
        return this.transport.client.put(url, file, config).then(() => url.split('?')[0])
      })
  }
}
