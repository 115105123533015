import React from 'react'

import Typography from '@ui/Typography'

import type { TooltipProps } from './Tooltip'
import * as styles from './TooltipContent.css'

const mapPressedKeyToSpecialChar: { [key: string]: string } = {
  shift: '⇧',
  meta: '⌘',
  enter: '⏎',
  alt: '⌥',
}

interface TooltipContentProps {
  title: string
  body?: string
  icon?: React.ReactNode
  shortcut?: string
  placement?: TooltipProps['placement']
}

const TooltipContent = function ({
  title,
  body,
  icon,
  shortcut,
  placement,
}: TooltipContentProps) {
  const keys = (shortcut?.split('+') || []).map(
    (key) => mapPressedKeyToSpecialChar[key.toLowerCase()] ?? key,
  )

  const isCentered = !shortcut && (placement === 'top' || placement === 'bottom')

  return (
    <div
      className={styles.content}
      style={{
        fontSize: body ? 12 : 11,
        lineHeight: body ? '16px' : '14px',
        textAlign: isCentered ? 'center' : 'left',
      }}
    >
      <div
        className={styles.wrapper}
        style={{
          margin: body ? '12px 0' : '8px 0',
          fontWeight: body ? 450 : 'inherit',
        }}
      >
        <Typography
          variant="caption1"
          color="inherit"
          fontWeight="regular"
          className={styles.title({ isCentered })}
        >
          {icon} {title}
        </Typography>
        <Typography variant="caption2" color="textSecondary">
          {body}
        </Typography>
      </div>
      {shortcut && (
        <div className={styles.shortcut}>
          {keys.map((key) => (
            <Typography
              key={key}
              variant="caption3"
              fontWeight="semibold"
              className={styles.key}
            >
              {key}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}

export default TooltipContent
