/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable, observable } from 'mobx'

import type AppStore from '@src/app/AppStore'
import type { SelectPhoneNumberCommandProps } from '@src/app/command/SelectPhoneNumberCommand'
import type { IndividualContactSharingCommandProps } from '@src/app/command/contact-sharing/IndividualContactSharingCommand'
import type {
  SendTestRequestCommandProps,
  TestRequestDetailsCommandProps,
} from '@src/app/command/send-test-request'

import type { AddCallerIdCommandProps } from './AddCallerIdCommand'
import type { EditCallerIdCommandProps } from './EditCallerIdCommand'
import type { EditSnippetProps } from './EditSnippet'
import type { SetPasswordCommandProps } from './SetPasswordCommand'
import type { AccountCommandProps } from './account'
import type { PortExistingNumberFormCommandProps } from './add-phone-number'
import type { PortExistingNumberCommandProps } from './add-phone-number/PortExistingNumberCommand'
import type { BillingAutoChargeCommandProps } from './billing/AutoChargeCommand'
import type { ChangeEmailCommandProps } from './change-email'
import type { EmailCodeCommandProps } from './change-email/code'
import type { ContactFilterCommandProps } from './contact-filters'
import type { DialerCommandProps } from './dialer'
import type { DoNotDisturbProps } from './do-not-disturb'
import type { EditGroupCommandProps } from './edit-group/EditGroupCommand'
import type { ForwardCallsProps } from './forward-calls'
import type { ImportContactsProps } from './import-contacts'
import type { IvrDestinationProps } from './ivr-destination'
import type { MemberStatusCommandProps } from './member-status'
import type { MutePhoneNumberProps } from './mute-phone-number'
import type { PickOrgPhoneNumberProps } from './pick-org-phone-number'
import type { PickUserPhoneNumberProps } from './pick-user-phone-number'
import type { RejectWithMessageProps } from './reject-with-message'
import type { RequiresPremiumProps } from './requires-premium'
import type {
  ScheduleMessageCommandProps,
  RescheduleMessageCommandProps,
  EditScheduledMessageCommandProps,
} from './scheduled-message'
import type { SelectFileCommandProps } from './select-file'
import type { SetGreetingCommandProps } from './set-greeting'
import type { SharePhoneNumberCommandProps } from './share-number'
import type { ShareNumberRoleCommandProps } from './share-number/role'
import type { SharedWithCommandProps } from './shared-with'
import type { UploadCommandProps } from './upload'
import type { VerifyDomainCommandProps } from './verify-domain/VerifyDomainCommand'

export type Command =
  | ({ name: 'account' } & AccountCommandProps)
  | { name: 'app' }
  | ({ name: 'billing auto charge' } & BillingAutoChargeCommandProps)
  | { name: 'billing cancel subscription' }
  | { name: 'billing credits' }
  | ({ name: 'change email code' } & EmailCodeCommandProps)
  | ({ name: 'change email' } & ChangeEmailCommandProps)
  | { name: 'change env' }
  | ({ name: 'contact filters' } & ContactFilterCommandProps)
  | ({ name: 'dialer' } & DialerCommandProps)
  | ({ name: 'do not disturb' } & DoNotDisturbProps)
  | ({ name: 'edit shared with' } & SharedWithCommandProps)
  | ({ name: 'edit snippet' } & EditSnippetProps)
  | ({ name: 'forward calls' } & ForwardCallsProps)
  | ({ name: 'import contacts' } & ImportContactsProps)
  | { name: 'invite member' }
  | { name: 'add domain' }
  | ({ name: 'verify domain' } & VerifyDomainCommandProps)
  | ({ name: 'member status' } & MemberStatusCommandProps)
  | ({ name: 'mute phone number' } & MutePhoneNumberProps)
  | ({ name: 'pick org phone number' } & PickOrgPhoneNumberProps)
  | ({ name: 'pick user phone number' } & PickUserPhoneNumberProps)
  | ({ name: 'reject call with message' } & RejectWithMessageProps)
  | ({ name: 'requires premium' } & RequiresPremiumProps)
  | { name: 'search' }
  | ({ name: 'select file' } & SelectFileCommandProps)
  | ({ name: 'select ivr destination' } & IvrDestinationProps)
  | ({ name: 'set greeting' } & SetGreetingCommandProps)
  | ({ name: 'share phone number role' } & ShareNumberRoleCommandProps)
  | ({ name: 'share phone number' } & SharePhoneNumberCommandProps)
  | ({ name: 'upload' } & UploadCommandProps)
  | { name: 'referral' }
  | { name: 'report export' }
  | ({ name: 'edit group' } & EditGroupCommandProps)
  | { name: 'create webhook' }
  | ({ name: 'send test request' } & SendTestRequestCommandProps)
  | ({ name: 'test request details' } & TestRequestDetailsCommandProps)
  | ({ name: 'schedule message' } & ScheduleMessageCommandProps)
  | ({ name: 'reschedule message' } & RescheduleMessageCommandProps)
  | ({ name: 'edit scheduled message' } & EditScheduledMessageCommandProps)
  | ({ name: 'add caller id' } & AddCallerIdCommandProps)
  | ({ name: 'edit caller id' } & EditCallerIdCommandProps)
  | { name: 'default contact sharing' }
  | { name: 'change password' }
  | ({ name: 'set password' } & SetPasswordCommandProps)
  | ({ name: 'individual contact sharing' } & IndividualContactSharingCommandProps)
  | { name: 'bulk contact sharing' }
  | { name: 'add phone number' }
  | ({ name: 'port existing number' } & PortExistingNumberCommandProps)
  | ({ name: 'port existing number form' } & PortExistingNumberFormCommandProps)
  | { name: 'porting drafts' }
  | ({ name: 'select phone numbers' } & SelectPhoneNumberCommandProps)
  | { name: 'export csv' }

export default class CommandUiStore {
  command: Command | null = null

  constructor(private root: AppStore) {
    makeAutoObservable(this, {
      command: observable.ref,
    })
  }

  get open(): boolean {
    return Boolean(this.command)
  }

  present = (command: Command) => {
    this.command = command
  }

  hide = () => {
    this.command = null
  }
}
