/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable, toJS } from 'mobx'

import { getInitials, parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'
import type { Identity, IdentityPhone, Model } from '@src/service/model'
import type {
  Membership,
  Organization as AccountOrganization,
} from '@src/service/transport/account'

import type Service from '..'

export type UseCase = 'personal' | 'business'
export type TeamSize = '1' | '2-5' | '6-9' | '10-49' | '50+'
export type Users = 'solo' | 'team'

type PersonalOrganizationAnalytics = {
  useCase: Extract<UseCase, 'personal'>
  industry: null
  teamSize: Extract<TeamSize, '1'>
  users: Extract<Users, 'solo'>
  teams: null
  need: string
}

type BusinessOrganizationAnalytics = {
  useCase: Extract<UseCase, 'business'>
  industry: string
  teamSize: TeamSize
  users: Users
  teams: string[]
  need: null
}

export type OrganizationAnalytics = (
  | PersonalOrganizationAnalytics
  | BusinessOrganizationAnalytics
) & {
  /**
   * Enrichment data from Clearbit.
   * No attributes are guaranteed to be present.
   *
   * @see https://dashboard.clearbit.com/docs#enrichment-api-company-api
   */
  enrichment?: {
    name?: string
    category?: { industry?: string; subIndustry?: string }
    metrics?: { employees?: number }
  }
}

export class Organization implements Identity, Model {
  id: string = ObjectID()
  name = ''
  slug: string | null = null
  employees: string | null = null
  industry: string | null = null
  teams: string[] = []
  externalId: string | null = null
  membership: Membership | null = null
  pictureUrl: string | null = null
  analytics: OrganizationAnalytics | null = null
  createdAt: number | null = null
  updatedAt: number | null = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get phones(): IdentityPhone[] {
    return []
  }

  get emailAddresses(): string[] {
    return []
  }

  get shortName(): string {
    return this.name
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get isAnonymous() {
    return Boolean(this.pictureUrl)
  }

  update = (org: Partial<this>): Promise<this> => {
    Object.assign(this, org)
    return this.root.transport.account.organization
      .update(this.serialize())
      .then(this.deserialize)
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  serialize = (): AccountOrganization => {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      employees: this.employees,
      industry: this.industry,
      externalId: this.externalId,
      membership: toJS(this.membership),
      pictureUrl: this.pictureUrl,
      analytics: toJS(this.analytics),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}
