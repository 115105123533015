import type Config from './Config'
import type ConfigEnvironment from './ConfigEnvironment'
import type Env from './Env'
import type LogEnv from './LogEnv'

const SENTRY_DSN =
  'https://729cab65e25a4fd284161d58bc29777a@o4504085033582592.ingest.sentry.io/4504089825247232'
const COHERE_ID = 'iOlds-tyN7vHgv0QqBBLPIDC'

const PENDO_SUBSCRIPTION_KEY = '6a064aab-6629-4e24-5f22-8f738376e6d8'

export const ENV_OVERRIDE_KEY = 'env-override'

export default function createConfig(env: Partial<ConfigEnvironment>): Config {
  const OP_ENV = env.OP_ENV || 'development'
  const VERSION = env.OP_VERSION || 'N/A'
  const FULL_VERSION = env.OP_FULL_VERSION || 'N/A'

  const development: Config = {
    ENV: 'development',
    VERSION,
    FULL_VERSION,
    AUTH_SERVICE_URL: 'https://auth.openphone.dev/v2/',
    ACCOUNT_SERVICE_URL: 'https://account.openphone.dev/v2/',
    BILLING_SERVICE_URL: 'https://billing.openphone.dev/v2/',
    COMMUNICATION_SERVICE_URL: 'https://communication.openphone.dev/v2/',
    CONTACT_SERVICE_URL: 'https://contact.openphone.dev/v2/',
    INTEGRATION_SERVICE_URL: 'https://integration.openphone.dev/v2/',
    REPORT_SERVICE_URL: 'https://reports.openphone.dev/v2/',
    VOICE_SERVICE_URL: 'https://voice.openphone.dev/v3/',
    WEBSOCKET_SERVICE_URL: 'wss://ws.openphone.dev/v4/',
    API_SERVICE_URL: 'https://api.openphone.dev/v3/',
    GIPHY_API_KEY: 'FfBBeb6l464EfWZby04LxpgLNuypLNJ4',
    GOOGLE_ANALYTICS_ID: 'UA-103292266-1',
    GOOGLE_CLIENT_ID:
      '1068653975143-rntm48ibm9pdd1cnor38dh6j5ur6bh2a.apps.googleusercontent.com',
    GOOGLE_MAPS_API_KEY: 'AIzaSyD6M_8iPl3TV4Zps8tUYUwYVnYMs1Cl2CQ',
    HUBSPOT_CLIENT_ID: '828c95af-42aa-43f6-9f30-b100c802ac48',
    SALESFORCE_CLIENT_ID:
      '3MVG9riCAn8HHkYWlS2hSI1lATEwZZlh27oy6oFobqLxRhNfkSpMy._5OC_ecGWOB.wsUS.d2O0mqPG9N2Nek',
    GONG_CLIENT_ID: 'owditylvg',
    LOG_ENV: 'dev',
    LOG_KEY: 'dbf914014adf7ef669f3d648be831f4f',
    AUTH0_BASE_URL: 'https://openphone-dev.auth0.com/',
    AUTH0_CLIENT_ID: 'NUyavpZolu3slN1324y6qzkSrnHjwvVS',
    IS_DEV: '1',
    STATIC_URL: 'https://opstatics-dev.s3.amazonaws.com',
    FILES_URL: 'https://files.openphone.co/dev/a',
    STRIPE_API_KEY: 'pk_test_iZakIeQzdW4mZScP1v1Io6O0',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '6254703121be3715609b6428',
    SIFT_BEACON_KEY: 'ac5f659b0d',
    GOOGLE_RECAPTCHA_SITE_KEY: '6Leq-mIiAAAAAI6Hdvr3bGurO237nMwSNDyrdBJt',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_borRFhoYl5ACGluYiiS2H8b9',
    POSTHOG_API_KEY: 'phc_hOSirRzP0CQ2kQKdQUPDe5DwmCJXlB7eu4EkwAwb7pP',
    SENTRY_DSN,
    COHERE_ID,
    PENDO_SUBSCRIPTION_KEY,
  }

  const staging: Config = {
    ENV: 'staging',
    VERSION,
    FULL_VERSION,
    AUTH_SERVICE_URL: 'https://auth-alpha.openphoneapi.com/v2/',
    ACCOUNT_SERVICE_URL: 'https://account-alpha.openphoneapi.com/v2/',
    BILLING_SERVICE_URL: 'https://billing-alpha.openphoneapi.com/v2/',
    COMMUNICATION_SERVICE_URL: 'https://communication-alpha.openphoneapi.com/v2/',
    CONTACT_SERVICE_URL: 'https://contact-alpha.openphoneapi.com/v2/',
    INTEGRATION_SERVICE_URL: 'https://integration-alpha.openphoneapi.com/v2/',
    REPORT_SERVICE_URL: 'https://reports-alpha.openphoneapi.com/v2/',
    VOICE_SERVICE_URL: 'https://voice-alpha.openphoneapi.com/v3/',
    WEBSOCKET_SERVICE_URL: 'wss://ws-alpha.openphoneapi.com/v4/',
    API_SERVICE_URL: 'https://api-alpha.openphoneapi.com/v3/',
    GIPHY_API_KEY: 'FfBBeb6l464EfWZby04LxpgLNuypLNJ4',
    GOOGLE_ANALYTICS_ID: 'UA-103292266-1',
    GOOGLE_CLIENT_ID:
      '692563708763-tnm656np01inkn43nu5m20si8kd0j5ps.apps.googleusercontent.com',
    GOOGLE_MAPS_API_KEY: 'AIzaSyD6M_8iPl3TV4Zps8tUYUwYVnYMs1Cl2CQ',
    HUBSPOT_CLIENT_ID: 'a9866f4d-3a4e-47a5-81b2-4661a96ab92a',
    SALESFORCE_CLIENT_ID:
      '3MVG9riCAn8HHkYWlS2hSI1lATO2caJxc5qN1Ro3k9kJgNlBInd..ZUGpnvW4YvgRY9HB0mhgRWy8IySge5EH',
    GONG_CLIENT_ID: 'owditylvg',
    LOG_ENV: 'staging',
    LOG_KEY: 'dbf914014adf7ef669f3d648be831f4f',
    AUTH0_BASE_URL: 'https://openphone-staging.auth0.com/',
    AUTH0_CLIENT_ID: 'Qz4uApe5n6ZqfVEzsCUChn3rWh1kI4oL',
    IS_DEV: '1',
    STATIC_URL: 'https://opstatics-staging.s3.amazonaws.com',
    FILES_URL: 'https://files.openphone.co/staging/a',
    STRIPE_API_KEY: 'pk_test_iZakIeQzdW4mZScP1v1Io6O0',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '6318fd984c9844119bcd0c21',
    SIFT_BEACON_KEY: 'ac5f659b0d',
    GOOGLE_RECAPTCHA_SITE_KEY: '6LcTQHIiAAAAAN91eeyobFqYMgQ9UWF-KivTUERE',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_borRFhoYl5ACGluYiiS2H8b9',
    POSTHOG_API_KEY: 'phc_91sjZU3cIjvSsIMMIgATNKAccODF9w4VE2ZAIWaCWXY',
    SENTRY_DSN,
    COHERE_ID,
    PENDO_SUBSCRIPTION_KEY,
  }

  const production: Config = {
    ENV: 'production',
    VERSION,
    FULL_VERSION,
    ACCOUNT_SERVICE_URL: 'https://account.openphoneapi.com/v2/',
    AUTH_SERVICE_URL: 'https://auth.openphoneapi.com/v2/',
    BILLING_SERVICE_URL: 'https://billing.openphoneapi.com/v2/',
    COMMUNICATION_SERVICE_URL: 'https://communication.openphoneapi.com/v2/',
    CONTACT_SERVICE_URL: 'https://contact.openphoneapi.com/v2/',
    INTEGRATION_SERVICE_URL: 'https://integration.openphoneapi.com/v2/',
    REPORT_SERVICE_URL: 'https://reports.openphoneapi.com/v2/',
    VOICE_SERVICE_URL: 'https://voice.openphoneapi.com/v3/',
    WEBSOCKET_SERVICE_URL: 'wss://ws.openphoneapi.com/v4/',
    API_SERVICE_URL: 'https://api.openphoneapi.com/v3/',
    AUTH0_BASE_URL: 'https://auth0.openphoneapi.com/',
    AUTH0_CLIENT_ID: 'b2yEyee59rmdWzxDqD4Asm0LIM6MUS1c',
    GIPHY_API_KEY: 'FfBBeb6l464EfWZby04LxpgLNuypLNJ4',
    GOOGLE_ANALYTICS_ID: 'UA-103292266-1',
    GOOGLE_CLIENT_ID:
      '557674652047-4cr80imaminia4a8ipv8j2mm6dch31ll.apps.googleusercontent.com',
    GOOGLE_MAPS_API_KEY: 'AIzaSyD6M_8iPl3TV4Zps8tUYUwYVnYMs1Cl2CQ',
    HUBSPOT_CLIENT_ID: '41a86e1a-6f46-4884-9180-1271db80dc22',
    SALESFORCE_CLIENT_ID:
      '3MVG9riCAn8HHkYWlS2hSI1lATLRLo9q6PbqmTKjQpc.nTM9jrmoQZhccRhlcI_GzBPmRY4aKoVXPU15MzzxR',
    GONG_CLIENT_ID: '4voq6cjymd',
    LOG_ENV: 'prod',
    LOG_KEY: 'dbf914014adf7ef669f3d648be831f4f',
    IS_DEV: '',
    STATIC_URL: 'https://opstatics.s3.amazonaws.com',
    FILES_URL: 'https://files.openphone.co/a',
    STRIPE_API_KEY: 'pk_live_jOcn8a7ScOdBUWhux1KJoM4K',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '6254703121be3715609b6429',
    SIFT_BEACON_KEY: '45fa2bdc8b',
    GOOGLE_RECAPTCHA_SITE_KEY: '6Ld_OXEiAAAAAA63cLtrD41QvSaBRqx1w0byYWWM',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_yZAAXwlPeEPeXE679Xiw93Xy',
    POSTHOG_API_KEY: 'phc_5lJtY2QPVb9qQhNYGL7EqtIbNW1RLYqnRli7FxNmKwM',
    SENTRY_DSN,
    COHERE_ID,
    PENDO_SUBSCRIPTION_KEY,
  }

  const configs: { [key in Env]: Config } = {
    development,
    staging,
    production,
  }

  let envOverride: Env | undefined

  /**
   * The config could be included form the Worker which has no window
   * context.
   * TODO: Find a better way to store env overrides.
   */
  if (typeof window !== 'undefined') {
    envOverride = window.localStorage.getItem(ENV_OVERRIDE_KEY) as Env
  }

  const environmentVariableOverrides: { [K in keyof Config]: Config[K] | '' } = {
    ENV: (env.OP_ENV as Env | undefined) ?? '',
    VERSION: env.OP_VERSION ?? '',
    FULL_VERSION: env.OP_FULL_VERSION ?? '',
    ACCOUNT_SERVICE_URL: env.OP_ACCOUNT_SERVICE_URL ?? '',
    AUTH_SERVICE_URL: env.OP_AUTH_SERVICE_URL ?? '',
    BILLING_SERVICE_URL: env.OP_BILLING_SERVICE_URL ?? '',
    COMMUNICATION_SERVICE_URL: env.OP_COMMUNICATION_SERVICE_URL ?? '',
    CONTACT_SERVICE_URL: env.OP_CONTACT_SERVICE_URL ?? '',
    INTEGRATION_SERVICE_URL: env.OP_INTEGRATION_SERVICE_URL ?? '',
    REPORT_SERVICE_URL: env.OP_REPORT_SERVICE_URL ?? '',
    VOICE_SERVICE_URL: env.OP_VOICE_SERVICE_URL ?? '',
    WEBSOCKET_SERVICE_URL: env.OP_WEBSOCKET_SERVICE_URL ?? '',
    API_SERVICE_URL: env.OP_API_SERVICE_URL ?? '',
    AUTH0_BASE_URL: env.OP_AUTH0_BASE_URL ?? '',
    AUTH0_CLIENT_ID: env.OP_AUTH0_CLIENT_ID ?? '',
    GIPHY_API_KEY: env.OP_GIPHY_API_KEY ?? '',
    GOOGLE_ANALYTICS_ID: env.OP_GOOGLE_ANALYTICS_ID ?? '',
    GOOGLE_CLIENT_ID: env.OP_GOOGLE_CLIENT_ID ?? '',
    GOOGLE_MAPS_API_KEY: env.OP_GOOGLE_MAPS_API_KEY ?? '',
    HUBSPOT_CLIENT_ID: env.OP_HUBSPOT_CLIENT_ID ?? '',
    SALESFORCE_CLIENT_ID: env.OP_SALESFORCE_CLIENT_ID ?? '',
    GONG_CLIENT_ID: env.OP_GONG_CLIENT_ID ?? '',
    LOG_ENV: (env.OP_LOG_ENV as LogEnv | undefined) ?? '',
    LOG_KEY: env.OP_LOG_KEY ?? '',
    IS_DEV: env.OP_IS_DEV ?? '',
    STATIC_URL: env.OP_STATIC_URL ?? '',
    FILES_URL: env.OP_FILES_URL ?? '',
    STRIPE_API_KEY: env.OP_STRIPE_API_KEY ?? '',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: env.OP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
    SIFT_BEACON_KEY: env.OP_SIFT_BEACON_KEY ?? '',
    GOOGLE_RECAPTCHA_SITE_KEY: env.OP_GOOGLE_RECAPTCHA_SITE_KEY ?? '',
    SENTRY_DSN: env.OP_SENTRY_DSN ?? '',
    STRIPE_PUBLISHABLE_KEY: env.OP_STRIPE_PUBLISHABLE_KEY ?? '',
    COHERE_ID: env.OP_COHERE_ID ?? '',
    PENDO_SUBSCRIPTION_KEY: env.OP_PENDO_SUBSCRIPTION_KEY ?? '',
    POSTHOG_API_KEY: env.OP_POSTHOG_API_KEY ?? '',
  }

  // Webpack replaces the environment variables above with a falsy value if not
  // provided, so let's delete the keys so they don't override truthy values.
  for (const [key, value] of Object.entries(environmentVariableOverrides)) {
    if (!value) {
      delete environmentVariableOverrides[key]
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  const config: Config = {
    ...configs[envOverride ?? OP_ENV],
    ...environmentVariableOverrides,
  }

  return config
}
