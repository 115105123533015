import { makeAutoObservable, toJS } from 'mobx'

import type { EventTypes } from '@src/data'
import uuid from '@src/lib/uuid'

import type Service from '..'

import type { Model } from '.'

export interface CodableWebhookModel {
  id: string
  object: string | null
  label: string | null
  url: string
  triggers: TriggerType[]
  key: string | null
  setting: 'enabled' | 'disabled' | null
  apiVersion: string | null
  createdAt: string | null
  updatedAt: string | null
  etag: string
}

export interface TriggerType {
  category: 'activities' | 'contacts'
  events: (EventTypes | '*')[]
  resourceIds: string[] | ['*']
}

export class WebhookModel implements CodableWebhookModel, Model {
  id = `WH${uuid()}`.replace(/-/g, '')
  object: string | null = null
  label: string | null = null
  url = ''
  triggers: TriggerType[] = []
  key: string | null = null
  setting: 'enabled' | 'disabled' | null = null
  apiVersion: string | null = null
  createdAt: string | null = null
  updatedAt: string | null = null
  etag = ''

  constructor(private root: Service, attrs: Partial<CodableWebhookModel> = {}) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get enabled() {
    return this.setting === 'enabled'
  }

  set enabled(value: boolean) {
    this.setting = value ? 'enabled' : 'disabled'
  }

  get activitiesTrigger() {
    return (
      this.triggers.find((t) => t.category === 'activities') ?? {
        category: 'activities',
        events: [],
        resourceIds: [],
      }
    )
  }

  get contactsTrigger() {
    return (
      this.triggers.find((t) => t.category === 'contacts') ?? {
        category: 'contacts',
        events: [],
        resourceIds: [],
      }
    )
  }

  save() {
    this.root.workspace.webhooks.put(this)
    return this.root.workspace
      .updateWebhook(this.serialize())
      .then((json) => this.deserialize(json))
  }

  delete() {
    this.root.workspace.webhooks.delete(this)
    this.root.workspace.deleteWebhook(this.serialize())
  }

  deserialize(json: Partial<CodableWebhookModel>) {
    Object.assign(this, json)
    return this
  }

  serialize(): CodableWebhookModel {
    return {
      id: this.id,
      object: this.object,
      label: this.label,
      url: this.url,
      triggers: toJS(this.triggers),
      key: this.key,
      setting: this.setting,
      apiVersion: this.apiVersion,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      etag: this.etag,
    }
  }
}
