import { action, makeAutoObservable } from 'mobx'

import { parseDate } from '@src/lib'
import { logError } from '@src/lib/log'
import type { FetchWebhookEventsRequest } from '@src/service'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type WorkspaceModel from '@src/service/model/WorkspaceModel'

import type Service from '.'
import { GroupModel } from './model'
import type DomainModel from './model/DomainModel'
import type { CodableWebhookModel } from './model/WebhookModel'
import { WebhookModel } from './model/WebhookModel'
import type { GroupsRepository } from './worker/repository/GroupsRepository'
import type { WebhooksRepository } from './worker/repository/WebhooksRepository'

export default class WorkspaceStore {
  workspaces: WorkspaceModel[] = []
  groups: PersistedCollection<GroupModel, GroupsRepository>
  webhooks: PersistedCollection<WebhookModel, WebhooksRepository>

  constructor(private root: Service) {
    this.handleWebsocket()

    this.groups = new PersistedCollection({
      table: this.root.storage.table('groups'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (json) => new GroupModel(this.root, json),
    })

    this.webhooks = new PersistedCollection({
      table: this.root.storage.table('webhooks'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (json) => new WebhookModel(this.root, json),
      compare: (a, b) =>
        (parseDate(b.createdAt ?? 0) ?? 0) - (parseDate(a.createdAt ?? 0) ?? 0),
    })

    makeAutoObservable(this, {})

    this.webhooks.performQuery((repo) => repo.all())
    this.groups.performQuery((repo) => repo.all())
  }

  fetch() {
    return this.root.transport.workspace.fetch().then(
      action((response) => {
        this.workspaces = response.results
      }),
    )
  }

  addMember(workspaceId: string) {
    return this.root.transport.workspace.addMember(workspaceId)
  }

  addDomain(verificationEmail: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.add({ verificationEmail })
  }

  validateDomain(email: string) {
    return this.root.transport.account.domain.validate(email)
  }

  deleteDomain(domainId: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.delete(domainId)
  }

  fetchDomains() {
    if (!this.root.organization.current) {
      logError(new Error('Current organization is null'))
      return Promise.resolve({ results: [] as DomainModel[] })
    }

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.fetch()
  }

  resendCode(domainId: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.resendCode(domainId)
  }

  verifyDomain(email: string, code: string) {
    if (!this.root.organization.current) return this.handleCurrentOrganizationAbscence()

    return this.root.transport.account.organization
      .for(this.root.organization.current.id)
      .domain.verify({ email, code })
  }

  fetchUserGroups() {
    return this.root.transport.account.organization.groups
      .fetch({ includeDeleted: true })
      .then(
        action((response) => {
          this.groups.load(response)
        }),
      )
  }

  updateGroup(group: Pick<GroupModel, 'id'> & Partial<GroupModel>) {
    return this.root.transport.account.organization.groups.update(group)
  }

  createGroup(group: Partial<GroupModel>) {
    return this.root.transport.account.organization.groups.create(group)
  }

  deleteGroup(id: string) {
    return this.root.transport.account.organization.groups.delete(id)
  }

  addUser(groupId: string, userId: string) {
    return this.root.transport.account.organization.groups.addUser(groupId, userId)
  }

  deleteUser(groupId: string, userId: string) {
    return this.root.transport.account.organization.groups.deleteUser(groupId, userId)
  }

  createWebhook(webhook: CodableWebhookModel) {
    return this.root.transport.webhooks
      .create(webhook)
      .then((response) =>
        this.root.workspace.webhooks.put(new WebhookModel(this.root, response)),
      )
  }

  updateWebhook(webhook: CodableWebhookModel) {
    return this.root.transport.webhooks.update(webhook)
  }

  deleteWebhook(webhook: CodableWebhookModel) {
    return this.root.transport.webhooks.delete(webhook)
  }

  fetchWebhooks() {
    return this.root.transport.webhooks
      .fetch()
      .then((response) => this.root.workspace.webhooks.load(response))
  }

  sendTestRequest(id: string, data: Record<string, any>) {
    return this.root.transport.webhooks.sendTestRequest(id, data)
  }

  resendEvent(webhookId: string, eventId: string) {
    return this.root.transport.webhooks.resendEvent(webhookId, eventId)
  }

  fetchWebhookEvents(params: FetchWebhookEventsRequest) {
    return this.root.transport.webhooks.fetchWebhookEvents(params)
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'group-updated':
        case 'group-deleted':
          this.handleGroupUpdatedMessage(data.group)
          return
        case 'webhook-delete':
          this.handleWebhookDelete(data.webhook)
          return
        case 'webhook-update':
          this.handleWebhookUpdate(data.webhook)
          return
      }
    })
  }

  private handleWebhookDelete(webhook: CodableWebhookModel) {
    this.webhooks.delete(new WebhookModel(this.root, webhook))
  }

  private handleWebhookUpdate(value: CodableWebhookModel) {
    if (!value.id) return

    const webhook = this.webhooks.get(value.id)
    if (webhook) {
      webhook.deserialize(value)
    } else {
      this.webhooks.put(new WebhookModel(this.root, value))
    }
  }

  private handleGroupUpdatedMessage(group: GroupModel) {
    const userGroup = this.groups.get(group.id)
    if (userGroup) {
      userGroup.deserialize(group)
    } else {
      this.groups.put(new GroupModel(this.root, group))
    }
  }

  private handleCurrentOrganizationAbscence() {
    if (this.root.transport.online) {
      logError(new Error('Current organization is null'))
      return Promise.reject(`An error occurred`)
    } else {
      return Promise.reject(`Can't perform this action while offline`)
    }
  }
}
