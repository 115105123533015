/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import cx from 'classnames'
import React from 'react'

import Typography from '@ui/Typography'

import * as styles from './Disclaimer.css'

interface DisclaimerProps extends React.HTMLProps<HTMLDivElement> {
  emoji?: string
  text: React.ReactNode
  transparent?: boolean
}

export const Disclaimer = function ({
  transparent,
  emoji,
  text,
  className,
  ...props
}: DisclaimerProps) {
  return (
    <div
      {...props}
      className={cx(styles.root, className, { [styles.rootBackground]: !transparent })}
    >
      {emoji && (
        <Typography variant="emoji" style={{ flex: '0 0 auto', marginRight: 10 }}>
          {emoji}
        </Typography>
      )}
      <Typography
        variant="footnote"
        color={transparent ? 'textTertiary' : 'textSecondary'}
      >
        {text}
      </Typography>
    </div>
  )
}
