/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type {
  Reason,
  TechnicalIssueLocation,
  MissingFeature,
  NoLongerNeededReason,
  TechnicalIssue,
} from '@src/app/command/cancel-subscription/options'
import config from '@src/config'
import { type CodableCapability } from '@src/service/model/Capability'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export interface Coupon {
  id?: string
  local?: boolean
  object: 'coupon'
  amount_off: number
  created: number
  currency: string
  duration: 'repeating' | 'forever' | 'once'
  duration_in_months: number
  max_redemptions: number
  metadata: any
  name: string
  percent_off: number
  redeem_by: string
  times_redeemed: string
  valid: boolean
}

export interface Invoice {
  id?: string
  local?: boolean
  object: 'invoice'
  account_country: string | null
  account_name: string | null
  amount_due: number
  amount_paid: number
  amount_remaining: number
  application_fee_amount: number | null
  attempt_count: number
  attempted: boolean
  auto_advance?: boolean
  created: number
  currency: string
  customer_email: string | null
  customer_name: string | null
  customer_phone: string | null
  deleted?: void
  description: string | null
  discount?: {
    id: string
    object: 'discount'
    checkout_session: string
    coupon: Coupon
    customer: string
    end: number
    invoice: string
    invoice_item: string
    promotion_code: string
    start: number
    subscription: string
  }
  due_date: number | null
  ending_balance: number | null
  footer: string | null
  hosted_invoice_url?: string | null
  invoice_pdf?: string | null
  lines: any
  livemode: boolean
  metadata: any
  next_payment_attempt: number | null
  number: string | null
  paid: boolean
  period_end: number
  period_start: number
  post_payment_credit_notes_amount: number
  pre_payment_credit_notes_amount: number
  receipt_number: string | null
  starting_balance: number
  statement_descriptor: string | null
  status: 'deleted' | 'draft' | 'open' | 'paid' | 'uncollectible' | 'void'
  subscription: string
  subscription_proration_date?: number
  subtotal: number
  tax: number | null
  tax_percent: number | null
  total: number
  webhooks_delivered_at: number | null
}

export interface CreditCard {
  id?: string
  local?: boolean
  object: 'card'
  address_city: string
  address_country: string
  address_line1: string
  address_line1_check: string
  address_line2: string
  address_state: string
  address_zip: string
  address_zip_check: string
  brand: string
  country: string
  customer: string
  cvc_check: string
  dynamic_last4: string
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  last4: string
  metadata: any
  name: string
  tokenization_method: string
}

export interface Subscription {
  id?: string
  local?: boolean
  annual?: boolean
  canceledAt?: number
  cancellationReason?: string
  creditCardFingerprint?: string
  currentPeriodExpiresAt: number
  currentPeriodStartedAt: number
  orgId?: string
  originalStartedAt: number
  phoneNumbers?: number
  receipt?: string
  store?: 'stripe' | 'ios' | 'android'
  stripeCustomerId?: string
  totalCredits?: number
  transactionId?: string
  trial?: boolean
  type?: string
  usedCredits?: number
  version?: number
  meta: any
  prepaidCredits: number
  autochargeAmount: number
}

export type UpgradeParams = {
  plan?: 'standard' | 'premium'
  coupon?: string
  annual?: boolean
}

export type SubscriptionIntent = {
  clientSecret: string
}

export type CancelParams = { feedback: string } & (
  | {
      reason: Extract<Reason, 'cannot use with other service'>
      subreason: {
        incompatibleService: string
      }
    }
  | {
      reason: Extract<Reason, 'technical issues'>
      subreason: {
        technicalIssues: TechnicalIssue[]
        otherTechnicalIssue: string
        technicalIssueLocations: TechnicalIssueLocation[]
      }
    }
  | {
      reason: Extract<Reason, 'missing features'>
      subreason: {
        missingFeatures: MissingFeature[]
        otherMissingFeature: string
      }
    }
  | {
      reason: Extract<Reason, 'no longer needed'>
      subreason: {
        noLongerNeededReason: NoLongerNeededReason
        otherNoLongerNeededReason: string
      }
    }
  | {
      reason: Extract<Reason, 'other'>
      subreason: {
        otherReason: string
      }
    }
)

export default class BillingClient {
  constructor(private transport: Transport) {}

  capabilities(): Promise<CodableCapability[]> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}capability` }),
    )
  }

  subscription(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}subscription` }),
    )
  }

  identityVerificationSecret(): Promise<{ identityVerificationSecret: string }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/setupKYC`,
      }),
    )
  }

  upcomingInvoice(): Promise<Invoice> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}invoice/upcoming` }),
    )
  }

  upgrade(params: UpgradeParams): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription`,
        body: params,
      }),
    )
  }

  getSubscriptionIntent(): Promise<SubscriptionIntent> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/setupIntent`,
      }),
    )
  }

  convertCredits(amount: number): Promise<{ subscription: Subscription }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/convert`,
        body: { amount },
      }),
    )
  }

  addCredits(amount: number): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/credit`,
        body: { amount },
      }),
    )
  }

  autoCharge(amount: number): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.BILLING_SERVICE_URL}subscription/autoCharge`,
        body: { amount },
      }),
    )
  }

  restart(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription`,
      }),
    )
  }

  endTrial(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/endTrial`,
      }),
    )
  }

  // TODO(christopherbot) make params required when WRK-1023 is done
  cancel(params?: CancelParams): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/cancel`,
        body: params,
      }),
    )
  }

  creditCard(): Promise<CreditCard> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}card` }),
    )
  }

  upsertCreditCard(paymentMethod: string): Promise<CreditCard> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}card`,
        body: { paymentMethod },
      }),
    )
  }

  invoices(): Promise<Invoice[]> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}invoice` }),
    )
  }

  coupon(code: string): Promise<Coupon> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.BILLING_SERVICE_URL}coupon/${code}` }),
    )
  }

  refreshClientSecret(orgId: string): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription`,
        body: { orgId },
      }),
    )
  }

  declineKyc(): Promise<Subscription> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.BILLING_SERVICE_URL}subscription/declineKyc`,
      }),
    )
  }
}
