/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import type { UpdateEvent, UpdateInfo } from '@openphone/desktop-client'
import dayjs from 'dayjs'
import type { Debugger } from 'debug'
import debug from 'debug'
import { action, computed, makeObservable, observable, reaction } from 'mobx'

import type AppStore from '@src/app/AppStore'
import { logError } from '@src/lib/log'
import makePersistable from '@src/service/storage/makePersistable'

export class UpdateController {
  downloadProgress: number | null = null

  private _showPrompt = false
  private updatePromptDismissedAt: number | null = null
  private debug: Debugger
  private checkIntervalId: number | null = null
  private appUpdateAvailable = false
  private electronUpdateAvailable = false

  private checkPromise: {
    resolve: (val: boolean) => void
    reject: (error: Error) => void
  } | null = null

  constructor(private app: AppStore) {
    this.debug = debug('op:update')
    makeObservable<
      this,
      | 'handleElectronUpdate'
      | 'appUpdateAvailable'
      | 'electronUpdateAvailable'
      | 'handleServiceWorkerUpdate'
      | 'showUpdatePrompt'
      | 'checkPromise'
      | '_showPrompt'
      | 'updatePromptDismissedAt'
    >(this, {
      strategy: computed,
      _showPrompt: observable.ref,
      updatePromptDismissedAt: observable.ref,
      checkPromise: observable.ref,
      downloadProgress: observable.ref,
      appUpdateAvailable: observable.ref,
      electronUpdateAvailable: observable.ref,
      showPrompt: computed,
      hidePrompt: action,
      showUpdatePrompt: action,
      handleElectronUpdate: action,
      handleServiceWorkerUpdate: action,
    })

    makePersistable(this, 'AppStore', {
      updatePromptDismissedAt: this.app.service.storage.sync(),
    })

    this.app.electron?.on('app-update', this.handleElectronUpdate.bind(this))
    this.app.serviceWorker.onNewVersionAvailable.subscribe(
      this.handleServiceWorkerUpdate.bind(this),
    )

    reaction(
      () => this.updateAvailable,
      (update) => {
        if (!update) return
        this.stopChecking()
        this.showUpdatePrompt()
        setInterval(() => this.showUpdatePrompt(), 600_000)
      },
    )

    reaction(
      () => this.showPrompt,
      (currentShowPrompt, prevShowPrompt) => {
        if (!prevShowPrompt && currentShowPrompt) {
          this.app.service.analytics.updateModalAction('shown')
        }
      },
    )
  }

  get showPrompt() {
    const lastDismiss = this.updatePromptDismissedAt

    if (lastDismiss) {
      // if the user dismissed the prompt, show it first thing the next day...
      // or after at least six hours for the night owls
      const now = dayjs()
      const shouldDisplay =
        now.isAfter(lastDismiss, 'date') && now.subtract(6, 'hours').isAfter(lastDismiss)
      if (shouldDisplay) {
        this.updatePromptDismissedAt = null
      }

      return shouldDisplay && this._showPrompt
    }

    return this._showPrompt
  }

  set showPrompt(value: boolean) {
    if (!value) {
      // Dismissing the prompt or installing updates will save this
      this.updatePromptDismissedAt = Date.now()
    }

    this._showPrompt = value
  }

  get updateAvailable() {
    return this.electronUpdateAvailable || this.appUpdateAvailable
  }

  get strategy(): 'electron' | 'web' {
    return this.electronUpdateAvailable ? 'electron' : 'web'
  }

  startChecking() {
    this.debug(`Start checking for updates`)
    this.checkForUpdate()
    this.checkIntervalId = window.setInterval(() => {
      this.checkForUpdate()
    }, 1_800_000)
  }

  stopChecking() {
    if (this.checkIntervalId) {
      clearInterval(this.checkIntervalId)
    }
  }

  installAndRestart = () => {
    this.app.service.analytics.updateModalAction('accepted')
    this.hidePrompt()
    if (this.electronUpdateAvailable) {
      this.app.electron?.updater.installUpdatesAndRestart?.()
    } else if (this.appUpdateAvailable) {
      this.app.serviceWorker.upgrade()
    }
  }

  hidePrompt = () => {
    this.showPrompt = false
  }

  handleDismiss = () => {
    this.hidePrompt()
    this.app.service.analytics.updateModalAction('dismissed')
  }

  handleDefer = () => {
    this.hidePrompt()
    this.app.service.analytics.updateModalAction('deferred')
  }

  async getUpdateInfo(): Promise<UpdateInfo | null> {
    let updateInfo: UpdateInfo | null

    if (this.app.electron?.updater.checkForUpdate) {
      updateInfo = await this.app.electron.updater.checkForUpdate()
    } else {
      const result = await this.app.electron?.updater.checkForUpdates?.()
      updateInfo = result?.updateInfo ?? null
    }

    return updateInfo ?? null
  }

  /**
   * Checks for Electron updates first. If one exists, then it starts downloading it.
   * Otherwise, it goes to check for app updates throught he ServiceWorker.
   */
  async checkForUpdate() {
    this.debug(`Check for updates...`)

    if (this.checkPromise) {
      this.debug(`Already checking for updates. Cancelling this request.`)
      return
    }

    // eslint-disable-next-line no-async-promise-executor -- FIXME: Fix this ESLint violation!
    return new Promise(async (resolve, reject) => {
      this.checkPromise = { resolve, reject }
      let electronUpdateAvailable: boolean = this.electronUpdateAvailable

      if (this.app.isElectron && !this.electronUpdateAvailable) {
        this.debug(`Check for Electron update...`)
        const currentVersion = window._e?.version ?? '3.3.4'
        const availableVersion = await this.getUpdateInfo()
        electronUpdateAvailable =
          !!availableVersion?.version && availableVersion?.version !== currentVersion
      }

      if (electronUpdateAvailable) {
        this.debug(`Electron update available. Downloading...`)
        try {
          await this.app.electron?.updater.download?.()
          this.checkPromise = null
          resolve(true)
        } catch (_) {
          this.checkPromise = null
          resolve(false)
        }
      } else if (!this.appUpdateAvailable) {
        this.debug(`Check for ServiceWorker update...`)
        this.app.serviceWorker.checkForUpdate()
        setTimeout(() => {
          this.checkPromise?.resolve(false)
          this.checkPromise = null
        }, 3000)
      } else {
        resolve(true)
        this.checkPromise = null
      }
    })
  }

  private showUpdatePrompt() {
    this.debug(`Updates are available. Showing the update prompt.`)
    this.showPrompt = true
  }

  private handleServiceWorkerUpdate() {
    this.debug(`ServiceWorker update available.`)
    this.appUpdateAvailable = true
    this.checkPromise?.resolve(true)
    this.checkPromise = null
  }

  private handleElectronUpdate(event: UpdateEvent) {
    switch (event.type) {
      case 'check': {
        this.app.focus()
        this.app.toast.showLoading('Checking for updates...')
        this.checkForUpdate().then((available) => {
          if (!available) {
            this.app.toast.show({ message: 'Your version is up to date!' })
          } else {
            this.app.toast.hide()
          }
        })
        return
      }

      case 'downloading': {
        this.debug(`Downloading updates ${event.percent}%`)
        this.downloadProgress = event.percent
        return
      }

      case 'downloaded': {
        this.debug(`Electron update download completed`)
        this.electronUpdateAvailable = true
        return
      }

      case 'error': {
        logError(new Error(event.error))
        return
      }
    }
  }
}
