import { fallbackVar } from '@vanilla-extract/css'

import { baseZIndex } from './layer.css'

export type Layer = 'base' | 'modal' | 'popover' | 'menu' | 'callDialog'

export const zIndexes: Record<Layer, number> = {
  base: 0,
  modal: 1400,
  // React Aria uses this specific zIndex (100000). Technically we can lower these values to normal sequence (0, 10, 20, ...)
  // but there's a risk of causing bugs. A more thorough refactor should be done to have a nice scale of zIndex
  // FIXME: https://linear.app/openphone/issue/WRK-1160/complete-zindex-refactor
  popover: 100000,
  menu: 100010,
  callDialog: 100020,
}

export function setBaseZIndex(layer: Layer) {
  return {
    [baseZIndex]: String(zIndexes[layer]),
  }
}

export default function zIndex(layer: Layer): string {
  return `calc(${fallbackVar(baseZIndex, '0')} + ${zIndexes[layer]})`
}
