import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useAppStore } from '@src/app/context'
import Button, { DangerButton } from '@src/component/button'
import { CircularProgress } from '@src/component/progress'
import { Snackbar, SnackbarContent } from '@src/component/snackbar'
import useKeyboardShortcuts from '@src/lib/hooks/useKeyboardShortcuts'
import { darkTheme, lightTheme } from '@src/theme'
import Typography from '@ui/Typography'

import type { OpenToastState } from '.'
import type { ToastAction } from './ToastUiStore'

type StyleProps = {
  type: OpenToastState['type']
}

const Toast = () => {
  const { toast } = useAppStore()

  const {
    type = 'info',
    message = '',
    actions = [],
  } = toast.state.open ? toast.state : {}
  const duration =
    (toast.state.open && toast.state.duration) || (actions.length > 0 ? 8000 : 4000)

  const styles = useStyles({ type })

  useKeyboardShortcuts({
    name: 'toast',
    node: document,
    handler: (shortcut, event) => {
      if (shortcut === 'Meta+KeyZ') {
        const undoAction = actions.find((a) => a.title === 'Undo')
        if (undoAction) {
          handleToastAction(undoAction.onClick)()
          event.preventDefault()
        }
      }
    },
    dep: [actions],
  })

  const handleToastAction = (handler?: () => void) => () => {
    handler?.()
    toast.hide()
  }

  const ToastButton = type === 'error' ? DangerButton : Button

  const renderActionButton = (action: ToastAction) => {
    const ActionButton = action.type === 'destructive' ? DangerButton : ToastButton

    return (
      <ActionButton
        variant="text"
        size="small"
        onClick={handleToastAction(action.onClick)}
      >
        {action.title}
      </ActionButton>
    )
  }

  const renderActions = () => {
    if (type === 'special') return null
    if (actions.length > 0) {
      return actions.map(renderActionButton)
    }
    return null
  }

  return (
    <Snackbar
      open={toast.state.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={type === 'loading' ? null : duration}
      transitionDuration={0}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          toast.hide()
        }
      }}
    >
      <SnackbarContent
        className={styles.root}
        type={type}
        message={
          <div className={styles.toastContent}>
            {type === 'loading' ? (
              <>
                <CircularProgress className={styles.toastLoading} size={14} />
                <div>{message}</div>
              </>
            ) : type === 'special' ? (
              <div>{message}</div>
            ) : (
              <Typography
                variant="body"
                color={type === 'error' ? 'error' : 'inherit'}
                fontWeight={type === 'error' ? 'medium' : 'regular'}
                style={{ fontSize: 'inherit ' }}
              >
                {message}
              </Typography>
            )}
          </div>
        }
        action={renderActions()}
      />
    </Snackbar>
  )
}

export default observer(Toast)

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ type }) => {
    if (type === 'special') return {}

    const background = (() => {
      if (type === 'error') return theme.palette.op.background.error

      return theme.palette.op.match({
        dark: lightTheme.palette.op.gray[6],
        light: darkTheme.palette.op.gray[6],
      })
    })()

    return {
      minHeight: '45px',
      border: 0,
      paddingBlock: '7px',
      borderRadius: '7px',
      boxShadow:
        type === 'error' ? `inset 0 0 0 2px ${theme.palette.op.tag.red.bg}` : 'none',
      background,
    }
  },
  toastContent: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    color: ({ type }) => {
      if (type === 'special') return

      if (type === 'error') return theme.palette.op.tag.red.text

      return theme.palette.op.match({
        dark: lightTheme.palette.op.gray[1],
        light: darkTheme.palette.op.gray[1],
      })
    },
  },
  toastLoading: {
    marginRight: 10,
  },
}))
