import { computed, makeObservable, observable, action, toJS } from 'mobx'

import type { Model } from '.'

type PortRequestPhoneNumberType = 'Toll-Free' | 'US' | 'CA'
type PortRequestEntity = 'business' | 'residential'
export type PortRequestStatus =
  | 'pending'
  | 'submitted'
  | 'accepted'
  | 'scheduled'
  | 'completed'
  | 'canceled'
  | 'rejected'
  | 'failed'

export interface RawPortRequest {
  id: string
  userId: string
  orgId: string
  phoneNumber: string
  type: PortRequestPhoneNumberType
  status: PortRequestStatus
  carrier?: string
  entity?: PortRequestEntity
  firstName?: string
  lastName?: string
  businessName?: string
  address?: string
  city?: string
  state?: string
  postal?: string
  country?: string
  accountNumber?: string
  accountPin?: string
  rawBillingStatement?: string
  signatureImageUrl?: string
  timeZone?: string
  phoneNumberId?: string
  loa?: string
  rejectionReason?: string
  rejectionCount: number
  portDate?: string
  submittedAt?: string
  reSubmittedAt?: string
  acceptedAt?: string
  scheduledAt?: string
  completedAt?: string
  canceledAt?: string
  rejectedAt?: string
  failedAt?: string
  createdAt: string
  updatedAt: string
}

export default class PortRequest implements Model {
  rawBillingStatementName?: string

  private raw: RawPortRequest

  get id(): string {
    return this.raw.id
  }

  get userId(): string {
    return this.raw.userId
  }

  get status(): PortRequestStatus {
    return this.raw.status
  }

  get type() {
    return this.raw.type
  }

  get updatedAt() {
    return this.raw.updatedAt
  }

  get createdAt() {
    return this.raw.createdAt
  }

  get submittedAt() {
    return this.raw.submittedAt
  }

  get scheduledForDate() {
    return this.raw.portDate
  }

  get isDraft() {
    return this.raw.status === 'pending'
  }

  get isResubmitted() {
    return !!this.raw.reSubmittedAt && this.raw.status === 'submitted'
  }

  get phoneNumberId() {
    return this.raw.phoneNumberId
  }

  get savedAt() {
    return this.raw.updatedAt || this.raw.createdAt
  }

  get formValues() {
    return {
      // existing phone number step
      phoneNumber: this.raw.phoneNumber,
      type: this.raw.type,
      carrier: this.raw.carrier,

      // billing information step
      entity: this.raw.entity,
      firstName: this.raw.firstName,
      lastName: this.raw.lastName,
      businessName: this.raw.businessName,
      address: this.raw.address,
      city: this.raw.city,
      state: this.raw.state,
      postal: this.raw.postal,
      country: this.raw.country,
      rawBillingStatement: this.raw.rawBillingStatement,
      rawBillingStatementName: this.rawBillingStatementName,

      // carrier info step
      accountNumber: this.raw.accountNumber,
      accountPin: this.raw.accountPin,

      // last step
      signatureImageUrl: this.raw.signatureImageUrl,
    }
  }

  constructor(attrs: RawPortRequest & { rawBillingStatementName?: string }) {
    this.raw = attrs
    this.rawBillingStatementName = attrs.rawBillingStatementName

    makeObservable<this, 'raw' | 'rawBillingStatementName'>(this, {
      raw: observable.deep,
      rawBillingStatementName: observable.ref,
      id: computed,
      type: computed,
      updatedAt: computed,
      createdAt: computed,
      submittedAt: computed,
      isDraft: computed,
      formValues: computed,
      deserialize: action.bound,
    })
  }

  deserialize(json: RawPortRequest & { rawBillingStatementName?: string }): this {
    this.raw = json
    this.rawBillingStatementName = json.rawBillingStatementName
    return this
  }

  serialize(): RawPortRequest & { rawBillingStatementName?: string } {
    return { ...toJS(this.raw), rawBillingStatementName: this.rawBillingStatementName }
  }
}
