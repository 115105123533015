import { makeAutoObservable } from 'mobx'

import { parseDate } from '@src/lib'
import { formatted } from '@src/lib/phone-number'

import type Service from '..'

import type { Model } from '.'

export interface IBlocklist {
  createdAt: number
  id: string
  phoneNumber: string
  updatedAt: number | null
  userId: string
}

export class Blocklist implements IBlocklist, Model {
  createdAt = 0
  id = ''
  phoneNumber = ''
  updatedAt: number | null = null
  userId = ''

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  get formatted() {
    return formatted(this.phoneNumber)
  }

  delete = () => {
    return this.service.blocklist.delete(this.id)
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      phoneNumber: this.phoneNumber,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  deserialize = (json) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt) ?? 0
    return this
  }
}
