import dayjs from 'dayjs'
import { makeAutoObservable, toJS } from 'mobx'

import type {
  AvailabilityHours as IAvailabilityHours,
  AvailabilitySchedule,
} from '@src/service/transport/account'
import { defaultAvailabilitySchedule } from '@src/service/transport/account'

export default class AvailabilityHours implements IAvailabilityHours {
  enabled = false
  schedule: AvailabilitySchedule = defaultAvailabilitySchedule
  timezone: string | null = null

  constructor() {
    makeAutoObservable(this, {})
  }

  get isOffHours(): boolean {
    if (!this.enabled) {
      return false
    }

    // Find the time in user's preferred timezone
    const localDateTimeString = new Date().toLocaleString('en-US', {
      timeZone: this.timezone ?? undefined,
    })
    const localDateTime = dayjs(localDateTimeString)
    const localDay = (localDateTime.day() + 6) % 7
    const todaysSchedule = this.schedule[localDay]

    // If no hours are set, that means the business is closed today
    if (todaysSchedule == null) {
      return true
    }

    // If start is null, that means the business is closed today
    if (todaysSchedule.start === null) {
      return true
    }

    const openingTime = parseInt(todaysSchedule.start)
    const closingTime = parseInt(todaysSchedule.end)
    // If end and start time are the same, the business is open the whole day
    if (openingTime === closingTime) {
      return false
    }

    // Check if the current time is within the defined business hours
    // in the user's timezone
    const localTime = parseInt(
      `${localDateTime.format('HH')}${localDateTime.format('mm')}`,
    )
    return localTime < openingTime || localTime >= closingTime
  }

  deserialize = ({ ...json }: any) => {
    Object.assign(this, json)
    return this
  }

  serialize = (): IAvailabilityHours => {
    return {
      enabled: this.enabled,
      schedule: toJS(this.schedule),
      timezone: this.timezone,
    }
  }
}
