import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'

import type { MessageMedia } from '@src/service/model'

import * as styles from './PdfViewer.css'
import useViewerStyles from './useViewerStyles'

interface PdfViewerProps {
  media: MessageMedia
}

const PdfViewer = function ({ media }: PdfViewerProps) {
  const viewerStyles = useViewerStyles()
  const rootRef = useRef<HTMLDivElement>(null)

  if (media.file || !media.url) {
    return null
  }

  return (
    <div ref={rootRef} className={viewerStyles.root}>
      <iframe frameBorder={0} className={styles.iframe} src={media.url} />
    </div>
  )
}

export default observer(PdfViewer)
