import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { mergeRefs } from '@react-aria/utils'
import cx from 'classnames'
import type { ForwardedRef } from 'react'
import React, { forwardRef, useEffect, useRef } from 'react'

import { useFormField } from '@ui/FormField'
import type CommonTextInputProps from '@ui/common/CommonTextInputProps'
import { commonTextInputStyles } from '@ui/shared/styles'

/**
 * @deprecated
 */
export type DeprecatedInputSize = 20 | 24 | 48

export type BaseHtmlInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'ref' | 'size' | 'label'
>

export interface TextInputProps
  extends Omit<CommonTextInputProps, 'size'>,
    BaseHtmlInputProps {
  size?: CommonTextInputProps['size'] | DeprecatedInputSize

  /**
   * Automatically selects the whole content when mounts
   */
  autoSelect?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      fullWidth,
      variant = 'outlined',
      size = 45,
      hasError = false,
      autoSelect,
      ...props
    },
    outerRef,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const mergedRefs = mergeRefs(inputRef, outerRef)
    const { htmlProps, fieldProps } = useFormField({
      fieldProps: {
        hasError,
      },
    })
    const styles = useStyles({ size, fullWidth, variant, hasError: fieldProps.hasError })
    useAutoSelect(!!autoSelect, inputRef)

    return (
      <input
        {...props}
        {...htmlProps}
        ref={mergedRefs}
        className={cx(className, styles.root, styles.inputRoot)}
      />
    )
  },
)

export default TextInput

const useStyles = makeStyles<Theme, TextInputProps>((theme) => ({
  ...commonTextInputStyles(theme),
  inputRoot: ({ size }) => ({
    padding: '0 16px',
    height: size,
  }),
}))

function useAutoSelect(enabled: boolean, ref: ForwardedRef<HTMLInputElement>) {
  const didSelectRef = useRef(false)

  useEffect(() => {
    if (didSelectRef.current) return

    const scheduledAutoSelectionTimeout = setTimeout(() => {
      if (
        !enabled ||
        didSelectRef.current ||
        typeof ref === 'function' ||
        !ref?.current
      ) {
        return
      }

      ref.current.select()
      didSelectRef.current = true
    })

    return () => clearTimeout(scheduledAutoSelectionTimeout)
  }, [enabled, ref])
}
