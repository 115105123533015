/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import config from '@src/config'
import type {
  FetchWebhookEventsRequest,
  WebhookEventsResponse,
  WebhookEventsResultObject,
  WebhookTestEventResponse,
} from '@src/service'
import type { CodableWebhookModel } from '@src/service/model/WebhookModel'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export default class WebhooksClient {
  constructor(private transport: Transport) {}

  create(webhook: CodableWebhookModel): Promise<CodableWebhookModel> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.API_SERVICE_URL}webhook`,
        body: webhook,
      }),
    )
  }
  update({ id, ...webhook }: CodableWebhookModel): Promise<CodableWebhookModel> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.API_SERVICE_URL}webhook/${id}`,
        body: {
          label: webhook.label,
          url: webhook.url,
          triggers: webhook.triggers,
          key: webhook.key,
          setting: webhook.setting,
        },
        headers: { 'If-Match': webhook.etag },
      }),
    )
  }

  delete(webhook: CodableWebhookModel) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.API_SERVICE_URL}webhook/${webhook.id}`,
        headers: { 'If-Match': webhook.etag },
      }),
    )
  }

  fetch(): Promise<CodableWebhookModel[]> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.API_SERVICE_URL}webhook`,
      }),
    )
  }

  sendTestRequest(
    id: string,
    body: Record<string, any>,
  ): Promise<WebhookTestEventResponse> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.API_SERVICE_URL}webhook/${id}/events`,
        body: body,
      }),
    )
  }

  resendEvent(webhookId: string, eventId: string): Promise<WebhookEventsResultObject> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.API_SERVICE_URL}webhook/${webhookId}/events/${eventId}`,
      }),
    )
  }

  fetchWebhookEvents({
    id,
    params,
  }: FetchWebhookEventsRequest): Promise<WebhookEventsResponse> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.API_SERVICE_URL}webhook/${id}/events`,
        query: { limit: 10, dir: 'desc', ...params },
      }),
    )
  }
}
