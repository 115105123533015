import { computed, makeObservable, observable, reaction, action, toJS } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'
import shortId from '@src/lib/short-id'
import type { AvailabilityHours } from '@src/service/transport/account'

import type Service from '..'

import type { Model } from './base'

export type ReactionEmojis = [string, string, string, string, string]
export type NotificationUnreadCount = 'conversations' | 'activities'
export type FeatureTooltipName =
  | 'scheduledMessages'
  | 'callFlow'
  | 'inboxFilters'
  | 'helpAndSupport'

interface UserPhoneNumberSharedSettings {
  id: string
  ringtone?: string | null
  conversations?: {
    pinnedIds?: string[] | null
  } | null
}

export interface IUserSettings {
  android: Record<string, unknown>
  ios: Record<string, unknown>
  web: {
    onboardingCompleted: boolean
    newFeatureTooltips: Partial<Record<FeatureTooltipName, string>>
    playNotificationSounds?: boolean
  }
  shared: {
    phoneNumberOrder: string[]
    /**
     * Array of objects containing user specific settings for that number
     */
    phoneNumbers: UserPhoneNumberSharedSettings[]
  }
  notifications: {
    includeMessagePreview?: boolean
    snoozedDeliveryMethod?: 'silent' | 'none'
    schedule?: AvailabilityHours
    unreadCount?: NotificationUnreadCount
    playSounds?: 'all' | 'minimal' | 'none'
    defaultRingtone?: 'system' | 'primary' | 'secondary'
  }
  preferences: {
    reactionEmojis?: ReactionEmojis
    defaultSkinTone?: string
  }
}

export const defaultReactionEmojis: ReactionEmojis = ['😀', '😮', '❤️', '🎉', '👍']

export class UserSettings implements Model {
  readonly id = shortId()
  ios = {}
  android = {}
  private raw: IUserSettings

  private disposeBag = new DisposeBag()

  get web() {
    return this.raw.web
  }

  get shared() {
    return this.raw.shared
  }

  get preferences() {
    return this.raw.preferences
  }

  get phoneNumbers() {
    return this.shared.phoneNumbers
  }

  get notifications() {
    return this.raw.notifications
  }

  constructor(private root: Service, attrs: Partial<IUserSettings>) {
    this.raw = this.applyDefaults(attrs)

    makeObservable<this, 'raw'>(this, {
      raw: observable.deep,
      web: computed.struct,
      shared: computed.struct,
      preferences: computed.struct,
      notifications: computed.struct,
      deserialize: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.serialize(),
        (settings) => {
          this.root.transport.account.userSettings.update(settings)
        },
      ),
    )
  }

  getPhoneNumberSettings(phoneNumberId: string): UserPhoneNumberSharedSettings {
    const settings = this.phoneNumbers.find((n) => n.id === phoneNumberId)
    if (settings) {
      return settings
    } else {
      const newSettings = {
        id: phoneNumberId,
        ringtone: null,
        conversations: {
          pinnedIds: [],
        },
      }
      this.phoneNumbers.push(newSettings)
      return newSettings
    }
  }

  deserialize(json: Partial<IUserSettings>) {
    this.raw = this.applyDefaults(json)

    return this
  }

  serialize(): IUserSettings {
    return toJS(this.raw)
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private applyDefaults(attrs?: Partial<IUserSettings>): IUserSettings {
    return {
      ios: {
        ...attrs?.ios,
      },
      android: {
        ...attrs?.android,
      },
      web: {
        onboardingCompleted: false,
        playNotificationSounds: true,
        ...attrs?.web,
        newFeatureTooltips: {
          ...attrs?.web?.newFeatureTooltips,
        },
      },
      shared: {
        phoneNumberOrder: [],
        phoneNumbers: [],
        ...attrs?.shared,
      },
      notifications: {
        includeMessagePreview: true,
        ...attrs?.notifications,
      },
      preferences: {
        reactionEmojis: undefined,
        defaultSkinTone: undefined,
        ...attrs?.preferences,
      },
    }
  }
}
