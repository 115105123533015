import { action, computed, makeObservable, observable } from 'mobx'

import type { Model } from '.'

export const businessTypes = [
  'Sole Proprietorship',
  'Partnership',
  'Corporation',
  'Co-operative',
  'Limited Liability Corporation',
  'Non-profit Corporation',
] as const

export const companyTypes = ['Private', 'Public', 'Non-profit', 'Government'] as const

export const exchanges = [
  'NASDAQ',
  'NYSE',
  'AMEX',
  'AMX',
  'ASX',
  'B3',
  'BME',
  'BSE',
  'FRA',
  'ICEX',
  'JPX',
  'JSE',
  'KRX',
  'LON',
  'NSE',
  'OMX',
  'SEHK',
  'SGX',
  'SSE',
  'STO',
  'SWX',
  'SZSE',
  'TSX',
  'TWSE',
  'VSE',
  'Other',
] as const

export const businessIdTypes = [
  'USA: Employer Identification Number (EIN)',
  'USA: DUNS Number (Dun & Bradstreet)',
  'Canada: Canadian Corporation Number (CCN)',
  'Great Britain: Company Number',
  'Australia: Company Number from ASIC (ACN)',
  'India: Corporate Identity Number',
  'Estonia: VAT Number',
  'Romania: VAT Registration Number',
  'Israel: Registration Number',
  'Other',
] as const

export const industries = [
  'Agriculture',
  'Automotive',
  'Banking',
  'Consumer',
  'Education',
  'Electronics',
  'Energy',
  'Engineering',
  'Fast moving consumer goods',
  'Financial',
  'Fintech',
  'Food and beverage',
  'Government',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Jewelry',
  'Legal',
  'Manufacturing',
  'Media',
  'Not for profit',
  'Oil and gas',
  'Online',
  'Raw materials',
  'Real estate',
  'Religion',
  'Retail',
  'Technology',
  'Telecommunications',
  'Transportation',
  'Travel',
] as const

export const regionsOfOperation = [
  'Africa',
  'Asia',
  'Europe',
  'Latin America',
  'USA and Canada',
] as const

export const jobPositions = [
  'Other',
  'Director',
  'GM',
  'VP',
  'CEO',
  'CFO',
  'General Counsel',
] as const

export type BusinessType = typeof businessTypes[number]
export type CompanyType = typeof companyTypes[number]
export type Exchange = typeof exchanges[number]
export type BusinessIdType = typeof businessIdTypes[number]
export type Industry = typeof industries[number]
export type RegionOfOperation = typeof regionsOfOperation[number]
export type JobPosition = typeof jobPositions[number]
export type RegistrationStatus = 'none' | 'pending' | 'approved' | 'rejected' | 'failed'

export interface TrustRegistrationObject {
  businessName: string
  businessType: BusinessType
  companyType: CompanyType
  exchange?: Exchange
  ticker?: string
  idType: BusinessIdType
  idNumber: string
  industry: Industry
  websiteUrl: string
  regionsOfOperation: RegionOfOperation[]
  messageFlow: string
  messageSamples: string
  addressLine1: string
  addressLine2?: string
  city: string
  region: string
  postalCode: string
  country: string
  firstName: string
  lastName: string
  email: string
  jobTitle: string
  jobPosition: JobPosition
  phoneNumber: string
  remindAt?: string
  draft: boolean
}

export interface RegistrationStatusMessage {
  fieldName?: keyof TrustRegistrationObject
  errorCode: string
  errorReason: string
}

export interface CodableTrustRegistration extends Partial<TrustRegistrationObject> {
  id: string
  object: 'registration'
  status: RegistrationStatus
  statusMessages: RegistrationStatusMessage[]
  apiVersion: string
  createdAt: string
  updatedAt: string
  etag: string
  updating: boolean
}

export class TrustRegistration implements Model {
  private raw: CodableTrustRegistration

  get id(): string {
    return this.raw.id
  }

  get status(): RegistrationStatus {
    return this.raw.status
  }

  get statusMessages(): RegistrationStatusMessage[] {
    return this.raw.statusMessages
  }

  get businessName(): string | undefined {
    return this.raw.businessName
  }

  get businessType(): BusinessType | undefined {
    return this.raw.businessType
  }

  get companyType(): CompanyType | undefined {
    return this.raw.companyType
  }

  get exchange(): Exchange | undefined {
    return this.raw.exchange
  }

  get ticker(): string | undefined {
    return this.raw.ticker
  }

  get idType(): BusinessIdType | undefined {
    return this.raw.idType
  }

  get idNumber(): string | undefined {
    return this.raw.idNumber
  }

  get industry(): Industry | undefined {
    return this.raw.industry
  }

  get websiteUrl(): string | undefined {
    return this.raw.websiteUrl
  }

  get regionsOfOperation(): RegionOfOperation[] | undefined {
    return this.raw.regionsOfOperation
  }

  get messageFlow(): string | undefined {
    return this.raw.messageFlow
  }

  get messageSamples(): string | undefined {
    return this.raw.messageSamples
  }

  get addressLine1(): string | undefined {
    return this.raw.addressLine1
  }

  get addressLine2(): string | undefined {
    return this.raw.addressLine2
  }

  get city(): string | undefined {
    return this.raw.city
  }

  get region(): string | undefined {
    return this.raw.region
  }

  get postalCode(): string | undefined {
    return this.raw.postalCode
  }

  get country(): string | undefined {
    return this.raw.country
  }

  get firstName(): string | undefined {
    return this.raw.firstName
  }

  get lastName(): string | undefined {
    return this.raw.lastName
  }

  get email(): string | undefined {
    return this.raw.email
  }

  get jobTitle(): string | undefined {
    return this.raw.jobTitle
  }

  get jobPosition(): JobPosition | undefined {
    return this.raw.jobPosition
  }

  get phoneNumber(): string | undefined {
    return this.raw.phoneNumber
  }

  get remindAt(): string | undefined {
    return this.raw.remindAt
  }

  get draft(): boolean {
    return this.raw.draft ?? true
  }

  get updating(): boolean {
    return this.raw.updating
  }

  get etag(): string {
    return this.raw.etag
  }

  get isDraft(): boolean {
    return this.draft && ['none', 'pending'].includes(this.status)
  }

  get isPending(): boolean {
    return !this.draft && ['none', 'pending'].includes(this.status)
  }

  constructor(attrs: CodableTrustRegistration) {
    this.raw = attrs

    makeObservable<this, 'raw' | 'localUpdate'>(this, {
      raw: observable.ref,
      id: computed,
      status: computed,
      statusMessages: computed,
      businessName: computed,
      businessType: computed,
      companyType: computed,
      exchange: computed,
      ticker: computed,
      idType: computed,
      idNumber: computed,
      industry: computed,
      websiteUrl: computed,
      regionsOfOperation: computed,
      messageFlow: computed,
      messageSamples: computed,
      addressLine1: computed,
      addressLine2: computed,
      city: computed,
      region: computed,
      postalCode: computed,
      country: computed,
      firstName: computed,
      lastName: computed,
      email: computed,
      jobTitle: computed,
      jobPosition: computed,
      phoneNumber: computed,
      remindAt: computed,
      draft: computed,
      updating: computed,
      localUpdate: action.bound,
    })
  }

  formFields(): Partial<TrustRegistrationObject> {
    return {
      businessName: this.businessName,
      businessType: this.businessType,
      companyType: this.companyType,
      exchange: this.exchange,
      ticker: this.ticker,
      idType: this.idType,
      idNumber: this.idNumber,
      industry: this.industry,
      websiteUrl: this.websiteUrl,
      regionsOfOperation: this.regionsOfOperation,
      messageFlow: this.messageFlow,
      messageSamples: this.messageSamples,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      region: this.region,
      postalCode: this.postalCode,
      country: this.country,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      jobTitle: this.jobTitle,
      jobPosition: this.jobPosition,
      phoneNumber: this.phoneNumber,
    }
  }

  localUpdate(partial: Partial<CodableTrustRegistration>): this {
    this.raw = { ...this.raw, ...partial }
    return this
  }

  deserialize(json: CodableTrustRegistration): this {
    this.raw = json
    return this
  }

  serialize(): CodableTrustRegistration {
    return this.raw
  }
}
