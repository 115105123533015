import config from '@src/config'
import type {
  TrustCnamGetAllResponse,
  TrustCnamCreateRequest,
  TrustCnamCreateResponse,
  TrustCnamDeleteRequest,
  TrustCnamDeleteResponse,
  TrustCnamUpdateRequest,
  TrustCnamUpdateResponse,
} from '@src/service/dto'
import type {
  CodableTrustRegistration,
  TrustRegistrationObject,
} from '@src/service/model/TrustRegistration'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export default class TrustClient {
  constructor(private readonly transport: Transport) {}

  cnam = {
    getAll: (): Promise<TrustCnamGetAllResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam`,
          query: {
            // FIXME: https://linear.app/openphone/issue/ENG-4182
            limit: 1000,
          },
        }),
      )
    },
    create: (dto: TrustCnamCreateRequest): Promise<TrustCnamCreateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam`,
          method: 'POST',
          body: dto,
        }),
      )
    },
    delete: (dto: TrustCnamDeleteRequest): Promise<TrustCnamDeleteResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam/${dto.cnamId}`,
          method: 'DELETE',
          headers: {
            'If-Match': dto.etag,
          },
        }),
      )
    },
    update: (dto: TrustCnamUpdateRequest): Promise<TrustCnamUpdateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam/${dto.cnamId}`,
          method: 'PUT',
          body: {
            displayName: dto.displayName,
            useAsDefault: dto.useAsDefault,
            resourceIds: dto.resourceIds,
          },
          headers: {
            'If-Match': dto.etag,
          },
        }),
      )
    },
  }

  registration = {
    fetch: (): Promise<CodableTrustRegistration> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.API_SERVICE_URL}trust/registration` }),
      )
    },
    create: (
      registration: Partial<TrustRegistrationObject>,
    ): Promise<CodableTrustRegistration> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration`,
          method: 'POST',
          body: registration,
        }),
      )
    },
    update: (
      id: string,
      etag: string,
      registration: Partial<TrustRegistrationObject>,
    ): Promise<CodableTrustRegistration> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/${id}`,
          method: 'PUT',
          body: registration,
          headers: {
            'If-Match': etag,
          },
        }),
      )
    },
    delete: (id: string, etag: string): Promise<object> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/${id}`,
          method: 'DELETE',
          headers: {
            'If-Match': etag,
          },
        }),
      )
    },
  }
}
