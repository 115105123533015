import isEqualWith from 'lodash/fp/isEqualWith'
import isObjectLike from 'lodash/fp/isObjectLike'
import isPlainObject from 'lodash/fp/isPlainObject'

import type ById from '@src/lib/ById'

import isNonNull from './isNonNull'

export function isReadonlyArray(collection: unknown): collection is readonly unknown[] {
  return Array.isArray(collection)
}

export function isReadonlyArrayOf<T>(
  collection: unknown,
  guard: (t: unknown) => t is T,
): collection is readonly T[] {
  return isReadonlyArray(collection) && guard(collection[0])
}

export function isArray(collection: unknown): collection is unknown[] {
  return isReadonlyArray(collection)
}

export function isArrayOf<T>(
  collection: unknown,
  guard: (t: unknown) => t is T,
): collection is T[] {
  return isReadonlyArrayOf(collection, guard)
}

export function isArrayOfStrings(collection: unknown): collection is string[] {
  return isArrayOf(collection, (o): o is string => typeof o === 'string')
}

export function findLastIndex<T>(collection: T[], handler: (t: T) => boolean): number {
  if (collection?.length > 0) {
    for (let i = collection.length - 1; i >= 0; i--) {
      if (handler(collection[i])) {
        return i
      }
    }
  }
  return -1
}

export function random<T>(collection: T[]): T {
  return collection[Math.floor(Math.random() * collection.length)]
}

export function hasIndex(collection: unknown[], index: number): boolean {
  return index >= 0 && index < collection.length
}

export function removeAtIndex<T>(collection: T[], index: number): T[] {
  return hasIndex(collection, index)
    ? [...collection.slice(0, index), ...collection.slice(index + 1)]
    : collection
}

export function insertAtIndex<T>(collection: T[], item: T, index: number): T[] {
  return [...collection.slice(0, index), item, ...collection.slice(index)]
}

export function replaceAtIndex<T>(collection: T[], item: T, index: number): T[] {
  return [...collection.slice(0, index), item, ...collection.slice(index + 1)]
}

export function move<T>(collection: T[], from: number, to: number): T[] {
  const clone = [...collection]
  Array.prototype.splice.call(
    clone,
    to,
    0,
    Array.prototype.splice.call(clone, from, 1)[0],
  )
  return clone
}

export function insertSorted<T>(
  collection: T[],
  item: T,
  compare: (a: T, b: T) => number,
): T[] {
  const index = sortedIndex(collection, item, compare)
  return insertAtIndex(collection, item, index)
}

export function omit<
  Key extends string | number,
  Collection extends Record<Key, unknown>,
>(collection: Collection, ...keys: Key[]) {
  const lastKey = keys.pop()

  if (!lastKey) {
    return collection
  }

  const newCollection = { ...collection }
  delete newCollection[lastKey]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
  return omit(newCollection, ...keys)
}

export function removeFalsy<T>(collection: T[]): T[] {
  return collection.filter((c) => c)
}

export type PartitionedArray<T> = [pass: T[], fail: T[]]

export function partition<T>(
  collection: readonly T[],
  filter: (item: T) => boolean,
): PartitionedArray<T> {
  const pass: T[] = []
  const fail: T[] = []
  collection.forEach((item) => {
    filter(item) ? pass.push(item) : fail.push(item)
  })
  return [pass, fail]
}

export function last<T>(collection: readonly T[]): T | undefined {
  if (!collection || collection.length === 0) return undefined
  return collection[collection.length - 1]
}

export function map<T>(objects: T[], by: keyof T): ById<T> {
  return objects.reduce((final, obj) => {
    if (!obj) return final
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    final[obj[by] as any] = obj
    return final
  }, {})
}

export function whitelist<T extends object>(source: T, properties: (keyof T)[]): T {
  const obj = {} as T
  properties.forEach((property) => {
    if (property in source && typeof source[property] !== 'undefined') {
      obj[property] = source[property]
    }
  })
  return obj
}

export function unique<T>(source: T[]): T[] {
  return [...new Set<T>(source)]
}

export function uniqueBy<T>(source: T[], mapper: (t: T) => unknown): T[] {
  const set = new Set<unknown>()

  return source.filter((t) => {
    const mapped = mapper(t)
    const exists = set.has(mapped)
    set.add(mapped)
    return !exists
  })
}

export function uniqueAndNonNull<T>(source: T[]): NonNullable<T>[] {
  return [...new Set<T>(source)].filter(isNonNull)
}

export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const R: T[][] = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize))
  return R
}

export function isEmpty(object: any) {
  if (typeof object === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    if (object.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      object.length === 0
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return Object.keys(object).length === 0
    }
  }
  if (!object) return true
}

export function toCamelCase(
  object: { [key: string]: any } | string | any[] | boolean | number,
): any {
  if (!object || typeof object === 'number' || typeof object === 'boolean') {
    return object
  }
  if (typeof object === 'string') {
    return object.replace(/_([a-z])/g, (char) => char[1].toUpperCase())
  } else if (object instanceof Array) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    return object.map((obj) => toCamelCase(obj))
  }
  return Object.keys(object).reduce((final, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    let value = object[key]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const newKey = toCamelCase(key)
    if (value instanceof Object && value instanceof Date === false) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      value = toCamelCase(value)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    final[newKey] = value
    return final
  }, {})
}

/**
 * Binary search a collection.
 */
export function searchIndex<T>(
  collection: T[],
  matcher: (item: T, index: number) => number,
): number {
  let low = 0,
    high = collection.length
  while (low < high) {
    const mid = (low + high) >>> 1
    if (matcher(collection[mid], mid) < 0) low = mid + 1
    else high = mid
  }
  return low
}

export function sortedIndex<T>(
  collection: T[],
  item: T,
  compare: (a: T, b: T) => number,
) {
  let low = 0,
    high = collection.length
  while (low < high) {
    const mid = (low + high) >>> 1
    if (compare(collection[mid], item) < 0) low = mid + 1
    else high = mid
  }
  return low
}

export function minMax<T>(collection: T[], compare: (t1: T, t2: T) => T): T | null {
  let min: T | null = null
  for (const item of collection) {
    if (!min) {
      min = item
    } else {
      min = compare(min, item)
    }
  }
  return min
}

/**
 * Structurally verify that two objects are equal.
 *
 * When two instances of a class are compared, they are compared with
 * referential equality instead of recursing further.
 *
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 *
 * @returns `true` if the objects are structurally equal, otherwise `false`.
 */
export const isReferentiallyDeepEqual = isEqualWith((a, b) => {
  if (
    isObjectLike(a) &&
    isObjectLike(b) &&
    !Array.isArray(a) &&
    !Array.isArray(b) &&
    !isPlainObject(a) &&
    !isPlainObject(b)
  ) {
    return a === b
  }
})
