import type { User as SentryUser } from '@sentry/react'
import {
  init,
  reactRouterV6Instrumentation,
  setUser,
  makeFetchTransport,
} from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import config from '@src/config'
import type { Member, Organization } from '@src/service/model'

/**
 * The user context sent to Sentry.
 *
 * Some fields are used by Sentry (e.g. `id` and `email`), the rest are custom
 * to provide context to the user that experienced this error.
 *
 * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
 */
export type EnrichedSentryUser = Pick<Required<SentryUser>, 'id' | 'email'> & {
  name: string
  role: string
  organizationId: string
  organizationPlan?: string
}

type QueuedEvent = [RequestInfo | URL, RequestInit | undefined]

export class SentryManagerSingleton {
  private queuedEvents: QueuedEvent[] = []
  private enabled = false
  private tracesSampleRate = 0

  enable() {
    this.enabled = true

    for (const queuedEvent of this.queuedEvents) {
      const [input, init] = queuedEvent
      const index = this.queuedEvents.indexOf(queuedEvent)

      setTimeout(() => {
        this.fetch(input, init).catch(() => null)
      }, 200 * index)
    }

    this.queuedEvents = []
  }

  disable() {
    this.enabled = false
  }

  initialize() {
    // Don't initialize Sentry during local development
    if (import.meta.env.PROD) {
      init({
        dsn: config.SENTRY_DSN,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
        ],
        environment: config.ENV,
        release: config.FULL_VERSION,
        tracesSampler: this.tracesSampler.bind(this),
        transport: (options) => {
          return makeFetchTransport(options, this.fetch.bind(this))
        },
        // Local environment does not need to tunnel events
        tunnel: import.meta.env.PROD ? '/api/sentry' : undefined,
      })
    }
  }

  setUser(
    user: Pick<Required<Member>, 'id' | 'email' | 'name' | 'role'> | undefined,
    organization: (Pick<Required<Organization>, 'id'> & { plan?: string }) | undefined,
  ) {
    if (!user || !organization) {
      return
    }

    const sentryUser: EnrichedSentryUser = {
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role,
      organizationId: organization.id,
      organizationPlan: organization.plan,
    }

    setUser(sentryUser)
  }

  setTracesSampleRate(tracesSampleRate: number) {
    this.tracesSampleRate = tracesSampleRate
  }

  private tracesSampler(): boolean | number {
    if (!this.enabled) {
      return false
    }

    return this.tracesSampleRate
  }

  private fetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    if (!this.enabled) {
      this.queuedEvents.push([input, init])
      return Promise.resolve(new Response('', { status: 200 }))
    }

    return fetch(input instanceof URL ? input.toString() : input, init)
  }
}

const SentryManager = new SentryManagerSingleton()

export default SentryManager
