import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'
import { formatted } from '@src/lib/phone-number'
import type { ContactItemType } from '@src/service/transport/contacts'

import type Service from '..'

import type { Contact, Model } from '.'

export interface IContactItem {
  createdAt: number | null
  deletedAt: number | null
  id: string
  name: string | null
  templateKey: string | null
  type: ContactItemType | null
  updatedAt: number | null
  value: any
}

export class ContactItem implements IContactItem, Model {
  createdAt: number | null = Date.now()
  deletedAt: number | null = null
  id: string = ObjectID()
  name: string | null = null
  templateKey: string | null = null
  type: ContactItemType | null = null
  updatedAt: number | null = Date.now()
  value: any = null
  isNew?: boolean = false

  constructor(
    private service: Service,
    private contact: Contact,
    attrs: Partial<ContactItem> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get formatted() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const { type, value } = this
    if (type === 'phone-number') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return formatted(value)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return value
  }

  get template() {
    return this.service.contact.template.list.find((t) => t.key === this.templateKey)
  }

  get isEmpty() {
    if (!this.value) return true
    if (Array.isArray(this.value) && this.value.length === 0) return true
    return false
  }

  get isBlocked(): boolean {
    if (this.type === 'phone-number') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      return !!this.service.blocklist.byPhoneNumber[this.value]
    } else {
      return false
    }
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    return this.contact.update()
  }

  delete() {
    this.contact.items = this.contact.items.filter((i) => i !== this)
    return this.contact.update()
  }

  deserialize(json: any) {
    if (json) {
      Object.assign(this, json)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.deletedAt = parseDate(json.deletedAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.createdAt = parseDate(json.createdAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.updatedAt = parseDate(json.updatedAt)
    }
    return this
  }

  serialize() {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      name: this.name,
      templateKey: this.templateKey,
      type: this.type,
      updatedAt: this.updatedAt,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      value: toJS(this.value),
    }
  }
}
