import { makeStyles } from '@material-ui/core/styles'

const useViewerStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      position: 'absolute',
    },
  },
}))

export default useViewerStyles
