import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import React from 'react'

import { useAppStore } from '@src/app/context'
import useStore from '@src/lib/hooks/useStore'

import ErrorStore from './ErrorStore'
import ErrorView from './ErrorView'

export interface ErrorBoundaryProps {
  children: React.ReactNode
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const app = useAppStore()
  const store = useStore(() => new ErrorStore(app), [app])

  return (
    <SentryErrorBoundary
      onError={store.onError}
      fallback={({ resetError }) => {
        store.resetErrorBoundary = resetError
        return <ErrorView store={store} />
      }}
    >
      {children}
    </SentryErrorBoundary>
  )
}

export default ErrorBoundary
