/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { Debugger } from 'debug'
import Debug from 'debug'
import { Subject } from 'rxjs'
import type { WorkboxLifecycleWaitingEvent } from 'workbox-window'
import { Workbox } from 'workbox-window'

export default class MainServiceWorker {
  private newVersionAvailable$ = new Subject<void>()
  private debug: Debugger
  private workbox: Workbox | null = null
  private registration: ServiceWorkerRegistration | undefined = undefined
  enabled: boolean

  constructor() {
    this.debug = Debug('MainServiceWorker')
    this.enabled = 'serviceWorker' in navigator && location.hostname !== 'localhost'
  }

  async init() {
    await this.registerServiceWorker()
  }

  get onNewVersionAvailable() {
    return this.newVersionAvailable$.asObservable()
  }

  async checkForUpdate() {
    if (!this.enabled) {
      this.debug('checkForUpdate is a noop. Service worker is disabled.')
      return false
    }

    this.debug('Checking for update...')

    await this.workbox?.update()

    if (this.registration?.installing || this.registration?.waiting) {
      return true
    }

    return false
  }

  upgrade() {
    if (!this.enabled) {
      return this.debug('Cannot upgrade. ServiceWorker is not enabled.')
    }

    this.workbox?.messageSkipWaiting()
  }

  unregister() {
    this.debug('Unregister ServiceWorker...')
    if (!this.enabled) {
      return Promise.resolve()
    }
    return new Promise<void>((resolve) => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            this.debug('ServiceWorker unregistered.')
            registration.unregister()
          }
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  private async registerServiceWorker() {
    if (!this.enabled) {
      return this.debug('Cannot register ServiceWorker. Capability is disabled.')
    }
    this.workbox = new Workbox('/sw.js')
    this.workbox.addEventListener('waiting', this.handleWaitingEvent.bind(this))
    this.workbox.addEventListener('controlling', () => {
      window.location.reload()
    })
    this.registration = await this.workbox.register()
  }

  private handleWaitingEvent(event: WorkboxLifecycleWaitingEvent) {
    this.debug('Received waiting event. Setting new version available to true.')
    this.newVersionAvailable$.next()
  }
}
