import { createClient as createElectronClient } from '@openphone/desktop-client'

import log from './lib/log'
import { mountLoadingScreen } from './updater/index'
import MainServiceWorker from './worker'

main()

async function main() {
  try {
    const client = createElectronClient()
    const isElectron = !!client
    const serviceWorker = new MainServiceWorker()

    if (isElectron) {
      const updateAvailable = await doElectronUpdateFlow(serviceWorker)

      if (!updateAvailable) {
        loadApp()
      }

      return
    }

    loadApp()
  } catch (error) {
    let message = 'Unknown error'

    if (error instanceof Error) {
      message = error.message
    }

    log.error(message)
  }
}

async function doElectronUpdateFlow(serviceWorker: MainServiceWorker) {
  const subscription = serviceWorker.onNewVersionAvailable.subscribe(() => {
    serviceWorker.upgrade()
  })
  await serviceWorker.init()
  const updateAvailable = await serviceWorker.checkForUpdate()

  if (!updateAvailable) {
    subscription.unsubscribe()
    return false
  }

  mountLoadingScreen()

  return true
}

async function loadApp() {
  window.__openPhoneBootstrap()
}
