/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable } from 'mobx'

import makePersistable from '@src/service/storage/makePersistable'
import type { Role } from '@src/service/transport/account'

import type Service from '.'
import EntityPhoneNumberStore from './entity-phone-number-store'
import { Organization } from './model'

export default class OrganizationStore {
  /**
   * Prefer using `OrganizationStore.getCurrentOrganization()` instead if you expect the current
   * organization to be ready.
   */
  current: Organization | null = null
  role: Role[] = []
  phoneNumber: EntityPhoneNumberStore

  constructor(private root: Service) {
    this.phoneNumber = new EntityPhoneNumberStore(root)

    makeAutoObservable(this, {})

    makePersistable(this, 'OrganizationStore', {
      role: root.storage.async(),
      current: root.storage.async((json) =>
        new Organization(this.root).deserialize(json),
      ),
    })
  }

  /**
   * Returns the current organization if it's ready, otherwise throws an error.
   *
   * @returns The current organization
   * @throws If the current organization is not ready yet
   */
  getCurrentOrganization() {
    const currentOrganization = this.current

    if (!currentOrganization) {
      throw new Error(
        'OrganizationStore.getCurrentOrganization() called before the current organization was ready',
      )
    }

    return currentOrganization
  }

  fetch() {
    return this.root.transport.account.organization.list().then(
      action((orgs) => {
        if (this.current) {
          this.current.deserialize(orgs[0])
        } else {
          this.current = new Organization(this.root).deserialize(orgs[0])
        }
      }),
    )
  }

  fetchRoles() {
    return this.root.transport.account.organization.roles().then(
      action((roles) => {
        this.role = roles
          .filter((role) => role.name !== 'billing')
          .sort((a, b) => roleSortWeight(a) - roleSortWeight(b))
      }),
    )
  }
}

export const roleSortWeight = (role: Role): number => {
  switch (role.name) {
    case 'owner':
      return 0
    case 'admin':
      return 1
    case 'member':
      return 2
    case 'billing':
      return 3
  }
}
