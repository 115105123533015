/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import config from '@src/config'
import type WorkspaceModel from '@src/service/model/WorkspaceModel'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export default class WorkspaceClient {
  constructor(private transport: Transport) {}

  fetch(): Promise<{ results: WorkspaceModel[] }> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.ACCOUNT_SERVICE_URL}workspace/all` }),
    )
  }

  addMember(workspaceId: string): Promise<unknown> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.ACCOUNT_SERVICE_URL}workspace/${workspaceId}/members`,
      }),
    )
  }
}
