import { action, makeObservable, observable } from 'mobx'

import { logError } from '@src/lib/log'

export type AugmentedPermissionName = PermissionName | 'microphone'

const isPermissionName = (name: string): name is PermissionName =>
  ['geolocation', 'notifications', 'push'].includes(name)

type PermissionStatesMap = { [P in AugmentedPermissionName]?: PermissionState }

export default class Permissions {
  readonly states: PermissionStatesMap = {}

  constructor(permissions: readonly AugmentedPermissionName[] = []) {
    makeObservable(this, {
      states: observable.shallow,
    })

    for (const permission of permissions) {
      this.subscribeToPermissionChange(permission as PermissionName).catch(
        (err: unknown) => {
          // Only log errors for permissions that are supported by all
          // browsers. (For example, 'microphone' is not supported by Firefox.)
          if (!isPermissionName(permission)) {
            logError(err)
          }
        },
      )
    }
  }

  protected async subscribeToPermissionChange(name: PermissionName) {
    // Not supported in SSR or jsdom
    if (typeof navigator !== 'undefined' && navigator.permissions) {
      return navigator.permissions.query({ name }).then((result) => {
        result.onchange = action(() => {
          this.states[name] = result.state
        })
      })
    }
  }
}
