/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'

import type Service from '..'

import type { Model } from './'

export type IdentityVerificationStatus =
  | 'unverified'
  | 'required'
  | 'processing'
  | 'verified'
  | 'failed'

const defaultPrepaidCreditThresholdCents = 100

export class Subscription implements Model {
  annual?: boolean | null = null
  autochargeAmount = 0
  private canceledAt?: number | null = null
  cancellationReason?: string | null = null
  creditCardFingerprint?: string | null = null
  currentPeriodExpiresAt: number | null = null
  currentPeriodStartedAt: number | null = null
  id: string = ObjectID()
  identityVerificationSecret?: string | null = null
  identityVerificationStatus?: IdentityVerificationStatus | null = null
  identityFailedCount = 0
  meta: any = null
  orgId?: string | null = null
  originalStartedAt: number | null = null
  phoneNumbers?: number | null = null
  prepaidCredits = 0
  receipt?: string | null = null
  reviewStatus?:
    | 'auto-approved'
    | 'needs-review'
    | 'rejected'
    | 'approved'
    | 'needs-identity'
    | 'limited'
    | 'frozen'
    | null = null
  settings?: {
    prepaidCreditThreshold?: number
  }
  store?: 'stripe' | 'ios' | 'android' | null = null
  stripeCustomerId?: string | null = null
  totalCredits?: number | null = null
  transactionId?: string | null = null
  trial?: boolean | null = null
  type?: string | null = null
  usedCredits?: number | null = null
  version?: number | null = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  /**
   * Whether or not the subscription is active.
   *
   * A subscription is active if it is either standard or premium and the
   * current period has not yet expired. Even trial subscriptions are active.
   */
  get isActive(): boolean {
    return this.type !== 'free' && this?.currentPeriodExpiresAt
      ? this.currentPeriodExpiresAt > Date.now()
      : false
  }

  /**
   * Whether or not the subscription was canceled by the user.
   */
  get isCanceled(): boolean {
    return Boolean(this.canceledAt)
  }

  /**
   * Whether or not the subscription is premium.
   *
   * A subscription is premium if the user is on the premium plan and active.
   */
  get isPremium(): boolean {
    return this.type === 'premium' && this.isActive
  }

  get localizedCredits() {
    return (this.prepaidCredits / 100).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  get localizedAutoCharge() {
    return (this.autochargeAmount / 100).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  get needsReview() {
    return this.reviewStatus === 'needs-review'
  }

  /**
   * Whether or not a user has limited functionality
   *
   * @see https://www.notion.so/openphone/Limited-KYC-and-Frozen-states-06455c6900c444dc83c50b04bcd5376e?pvs=4#89a91f1e94f84142939f44bb5b8998e8
   */
  get limitedState() {
    return this.reviewStatus === 'limited'
  }

  /**
   * Whether or not a user is being routed to KYC to prove their identity
   *
   * KYC is Know Your Customer and is a back end initiative to prevent fraud
   * and ensure users are who they say they are. If a user is identified as
   * potentially a bad actor we flag them and require identity verification
   * using a third party service before they can proceed with our application.
   *
   * Flowchart documenting KYC
   * @see https://www.figma.com/file/VTg7SuAIjsVQgn8TVOwxDf/Signup-flow-(fraud%2C-onboarding%2C-offboarding)?node-id=393%3A870&t=uSjPSrzj4dos2mvP-4
   *
   * Findings on the third party service KYC provider chosen (Stripe Identity)
   * @see https://www.notion.so/openphone/KYC-Comparisons-968c2808154f475884c3bcfd7aa941a9
   */
  get needsIdentity() {
    return this.reviewStatus === 'needs-identity'
  }

  get isReviewRejected() {
    return this.reviewStatus === 'rejected'
  }

  get isReviewApproved() {
    return this.reviewStatus === 'approved' || this.reviewStatus === 'auto-approved'
  }

  get isFlagged() {
    return this.needsReview || this.isReviewRejected
  }

  get isLegacy() {
    const version = this.version ?? 2

    return this.root.flags.getFlag('pricingV5') ? version < 5 : version < 3
  }

  get monthlyStandardPrice(): number {
    const version = this.version ?? 2

    if (version <= 2) {
      return 10
    } else if (version <= 3) {
      return 13
    } else {
      return 17
    }
  }

  get monthlyPremiumPrice(): number {
    return 25
  }

  get isKycProcessing() {
    return this.identityVerificationStatus === 'processing'
  }

  get hasKycExceeded() {
    return this.identityFailedCount >= 3
  }

  /**
   * The threshold under which auto-charge will be triggered, in cents.
   */
  get prepaidCreditThreshold() {
    return this.settings?.prepaidCreditThreshold ?? defaultPrepaidCreditThresholdCents
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.canceledAt = parseDate(json.canceledAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.currentPeriodExpiresAt = parseDate(json.currentPeriodExpiresAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.currentPeriodStartedAt = parseDate(json.currentPeriodStartedAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.originalStartedAt = parseDate(json.originalStartedAt)
    return this
  }

  serialize = () => {
    return {
      annual: this.annual,
      autochargeAmount: this.autochargeAmount,
      canceledAt: this.canceledAt,
      cancellationReason: this.cancellationReason,
      creditCardFingerprint: this.creditCardFingerprint,
      currentPeriodExpiresAt: this.currentPeriodExpiresAt,
      currentPeriodStartedAt: this.currentPeriodStartedAt,
      id: this.id,
      identityVerificationSecret: this.identityVerificationSecret,
      identityVerificationStatus: this.identityVerificationStatus,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      meta: toJS(this.meta),
      orgId: this.orgId,
      originalStartedAt: this.originalStartedAt,
      phoneNumbers: this.phoneNumbers,
      prepaidCredits: this.prepaidCredits,
      receipt: this.receipt,
      reviewStatus: this.reviewStatus,
      settings: toJS(this.settings),
      store: this.store,
      stripeCustomerId: this.stripeCustomerId,
      totalCredits: this.totalCredits,
      transactionId: this.transactionId,
      trial: this.trial,
      type: this.type,
      usedCredits: this.usedCredits,
      version: this.version,
    }
  }
}
