import { ThemeProvider as LegacyThemeProvider } from '@material-ui/core'
import React from 'react'

import { darkTheme as legacyDarkTheme, lightTheme as legacyLightTheme } from '@src/theme'

import ThemeContext, { createThemeContextValue } from './ThemeContext'
import createThemeMatch from './createThemeMatch'
import type { ThemeKey } from './themes/common'

export interface ThemeProviderProps {
  themeKey: ThemeKey
  children: React.ReactNode
}

export default function ThemeProvider({ themeKey, children }: ThemeProviderProps) {
  const match = createThemeMatch(themeKey)

  return (
    <LegacyThemeProvider
      theme={match({
        light: legacyLightTheme,
        dark: legacyDarkTheme,
      })}
    >
      <ThemeContext.Provider value={createThemeContextValue(themeKey)}>
        {children}
      </ThemeContext.Provider>
    </LegacyThemeProvider>
  )
}
