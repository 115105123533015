/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'

import useKeyStepper from '@src/lib/hooks/useKeyStepper'
import Typography from '@ui/Typography'
import { ArrowRightIcon } from '@ui/icons/tint/20/general'

import * as styles from './List.css'

interface Item {
  title: string
  emoji: string
}
interface ListProps<T> {
  items: T[]
  onSelect: (item: T | null) => void
}

export const List = observer(<T extends Item>({ items, onSelect }: ListProps<T>) => {
  const { selectedIndex, getItemProps } = useKeyStepper({
    items,
    name: 'onboarding/common/list',
    handleSelect: (item) => {
      onSelect(item)
    },
  })

  return (
    <div>
      {items.map((item, index) => (
        <div
          {...getItemProps(index)}
          key={item.title}
          className={cx(styles.item, selectedIndex === index && styles.selected)}
        >
          <Typography variant="emoji">{item.emoji}</Typography>
          <Typography variant="body" className={styles.body}>
            {item.title}
          </Typography>
          {selectedIndex === index && (
            <Typography variant="inherit" color="textSecondary" style={{ lineHeight: 0 }}>
              <ArrowRightIcon />
            </Typography>
          )}
        </div>
      ))}
    </div>
  )
})
