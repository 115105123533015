import React, { useEffect } from 'react'

import { CircularProgress } from '@src/component/progress'

import * as styles from './StripeCallback.css'

const StripeCallback = () => {
  useEffect(() => {
    window.top?.postMessage('3DS-authentication-complete')
  }, [])

  return (
    <div className={styles.root}>
      <CircularProgress />
    </div>
  )
}

export default StripeCallback
