import type { ElectronClient } from '@openphone/desktop-client'
import Debug from 'debug'
import { action, flowResult, makeAutoObservable, reaction, runInAction, when } from 'mobx'
import type React from 'react'
import type { Location, createBrowserRouter } from 'react-router-dom'
import { asyncScheduler, of } from 'rxjs'
import type { Observable } from 'rxjs'
import { filter, mergeMap, throttleTime } from 'rxjs/operators'

import type { MediaViewerState } from '@src/component/MediaViewer'
import config from '@src/config'
import type Env from '@src/config/Env'
import { fromQueryString } from '@src/lib'
import type LoadableStatus from '@src/lib/LoadableStatus'
import { errorTitle } from '@src/lib/api/errorHandler'
import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import type Service from '@src/service'
import type { IdentifyData } from '@src/service/analytics'
import type { MessageMedia } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'
import type { StorageThemeKey } from '@src/theme'
import MainServiceWorker from '@src/worker'

import type { EmojiPickerProps } from './AppEmojiPicker'
import NotificationController from './NotificationController'
import CommandUiStore from './command/store'
import { ErrorHelper, SentryManager } from './error'
import type { LoginUiStore } from './login/store'
import type { LocationSearch, LocationSearchParam } from './router'
import { stripSearchParam, stripUnknownParams } from './router'
import Sift from './sift'
import { ToastUiStore } from './toast'
import { UpdateController } from './update/controller'
import { URLController } from './url'

type Router = ReturnType<typeof createBrowserRouter>

export interface AlertAction {
  title: string
  type?: 'primary' | 'secondary' | 'secondary-outlined' | 'destructive'
  onClick?: () => void
}

export type AlertState = {
  open: boolean
  title?: string
  body?: React.ReactNode
  actions?: AlertAction[]
}

const HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL = 'HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL'

async function loadUiStores() {
  const [
    AlertsUiStore,
    AnalyticsUiStore,
    CnamUiStore,
    ContactsUiStore,
    ConversationUiStore,
    HelpAndSupportUiStore,
    InboxesUiStore,
    PortRequestUiStore,
    ScheduledMessagesUiStore,
    SearchUiStore,
    SideMenuUiStore,
    TrustRegistrationUiStore,
    WorkspaceUiStore,
  ] = await Promise.all([
    import('./alerts/AlertsUiStore').then((m) => m.AlertsUiStore),
    import('./analytics/AnalyticsUiStore').then((m) => m.default),
    import('./cnam').then((m) => m.CnamUiStore),
    import('./contacts/ContactsUiStore').then((m) => m.default),
    import('./inbox/conversation/store').then((m) => m.default),
    import('@src/app/help-and-support/HelpAndSupportUiStore').then((m) => m.default),
    import('./inbox/InboxesUiStore').then((m) => m.default),
    import('./porting').then((m) => m.PortRequestUiStore),
    import('./scheduled-message').then((m) => m.ScheduledMessagesUiStore),
    import('./search/store').then((m) => m.default),
    import('./side-menu/SideMenuUiStore').then((m) => m.default),
    import('./trust-registration').then((m) => m.TrustRegistrationUiStore),
    import('./workspace').then((m) => m.WorkspaceUiStore),
  ])

  return {
    AlertsUiStore,
    AnalyticsUiStore,
    CnamUiStore,
    ContactsUiStore,
    ConversationUiStore,
    HelpAndSupportUiStore,
    InboxesUiStore,
    PortRequestUiStore,
    ScheduledMessagesUiStore,
    SearchUiStore,
    SideMenuUiStore,
    TrustRegistrationUiStore,
    WorkspaceUiStore,
  } as const
}

type LoadedTypes = Awaited<ReturnType<typeof loadUiStores>>

type UiStoreTypes = {
  [K in keyof LoadedTypes]: InstanceType<LoadedTypes[K]>
}

export default class AppStore {
  readonly command: CommandUiStore
  readonly history: HistoryManager

  readonly debug = {
    enable(value?: string) {
      Debug.enable(typeof value === 'string' ? value : '*')
    },
    disable() {
      Debug.disable()
    },
  }

  private _alerts: UiStoreTypes['AlertsUiStore'] | null = null
  private _contacts: UiStoreTypes['ContactsUiStore'] | null = null
  private _conversation: UiStoreTypes['ConversationUiStore'] | null = null
  private _analytics: UiStoreTypes['AnalyticsUiStore'] | null = null
  private _inboxes: UiStoreTypes['InboxesUiStore'] | null = null
  private _search: UiStoreTypes['SearchUiStore'] | null = null
  private _scheduledMessages: UiStoreTypes['ScheduledMessagesUiStore'] | null = null
  private _sideMenu: UiStoreTypes['SideMenuUiStore'] | null = null
  private _trustRegistration: UiStoreTypes['TrustRegistrationUiStore'] | null = null
  private _workspace: UiStoreTypes['WorkspaceUiStore'] | null = null
  private _cnam: UiStoreTypes['CnamUiStore'] | null = null
  private _portRequest: UiStoreTypes['PortRequestUiStore'] | null = null
  private _helpAndSupport: UiStoreTypes['HelpAndSupportUiStore'] | null = null

  private scrollTimeout: number | null = null

  // FIXME: make LoginUiStore a permanent instance in AppStore
  login?: LoginUiStore
  toast: ToastUiStore
  error: ErrorHelper
  sift?: Sift

  alert: AlertState = { open: false }
  config = config
  confetti = false
  darkMode = true
  emojiPicker: EmojiPickerProps = { open: false }
  mediaViewer: MediaViewerState | null = null
  storesLoaded = false
  serviceWorker = new MainServiceWorker()
  url: URLController
  update: UpdateController
  notification: NotificationController
  themeKey: StorageThemeKey = 'system'
  hasNetworkConnection = navigator.onLine
  isScrolling = false
  loadingPercentage = 0
  initializationStatus: LoadableStatus = 'idle'
  initializePromise: Promise<void>

  constructor(
    readonly electron: ElectronClient | undefined,
    readonly router: Router,
    readonly service: Service,
  ) {
    this.history = new HistoryManager(router)
    this.url = new URLController(this)
    this.update = new UpdateController(this)
    this.notification = new NotificationController(this)
    this.command = new CommandUiStore(this)
    this.toast = new ToastUiStore(this)
    this.error = new ErrorHelper(this)
    this.sift = new Sift(this)

    this.initializePromise = when(() => this.initialized && this.storesLoaded, {
      name: 'AppStore.initializePromise',
    })

    makeAutoObservable(this, {
      config: false,
      debug: false,
      electron: false,
      history: false,
      service: false,
      setThemeKey: action.bound,
    })

    makePersistable(this, 'AppStore', {
      themeKey: this.service.storage.sync(),
    })

    when(
      () => service.auth.hasSession,
      () => {
        flowResult(this.initialize()).then(() =>
          this.service.transport.runPendingTransactions(),
        )
      },
    )

    reaction(
      () => service.flags.flags,
      () => {
        if (this.initialized) {
          this.loadFlagDependentEssentials()
        }

        const isSentryEnabled = service.flags.getFlag('sentry')
        const sentryTracesSampleRate = service.flags.getFlag('sentryTracesSampleRate')

        SentryManager.setTracesSampleRate(sentryTracesSampleRate)

        if (isSentryEnabled) {
          SentryManager.enable()
        } else {
          SentryManager.disable()
        }
      },
      { name: 'AppStore.FlagsChanged' },
    )

    reaction(
      () =>
        this.initialized && service.organization.current?.id && service.transport.online,
      (shouldRun) => {
        if (shouldRun) {
          service.member.fetchPresence().catch(logError)
        }
      },
      { fireImmediately: true },
    )

    reaction(
      () => this.initialized,
      (loaded) => {
        if (loaded) {
          this.loadUiStores()
        } else {
          this.tearDownUiStores()
        }
      },
      { fireImmediately: true },
    )

    this.service.voice.onRefreshRejected = () => {
      this.toast.showError('Could not refresh voice token.')
      this.service.clearAllAndRestart()
    }

    this.electron?.on('tray', (event) => {
      if (event.type === 'click') {
        this.electron?.window.focus?.()
        this.electron?.window.show?.()
      }
    })

    if (this.is('windows')) {
      this.electron?.app.createTray?.()
    }

    this.setAsDefaultTelProtocol()
    this.serviceWorker.init()

    document.addEventListener('scroll', this.onDocumentScroll, true)

    window.addEventListener(
      'online',
      action(() => {
        this.hasNetworkConnection = true
      }),
    )

    window.addEventListener(
      'offline',
      action(() => {
        this.hasNetworkConnection = false
      }),
    )

    // We need to handle Dexie error using addEventListener since `on('error')` is deprecated https://dexie.org/docs/Dexie/Dexie.on.error
    window.addEventListener('unhandledrejection', (error) => {
      const reason = error.reason as { name?: string }
      if (reason.name === 'VersionError') {
        this.signOut()
      }
    })
  }

  get alerts(): UiStoreTypes['AlertsUiStore'] {
    if (this._alerts) {
      return this._alerts
    }
    throw new Error('alerts store is not initialized')
  }

  get contacts(): UiStoreTypes['ContactsUiStore'] {
    if (this._contacts) {
      return this._contacts
    }
    throw new Error('contacts store is not initialized')
  }

  get conversation(): UiStoreTypes['ConversationUiStore'] {
    if (this._conversation) {
      return this._conversation
    }
    throw new Error('conversation store is not initialized')
  }

  get analytics(): UiStoreTypes['AnalyticsUiStore'] {
    if (this._analytics) {
      return this._analytics
    }
    throw new Error('analytics store is not initialized')
  }

  get inboxes(): UiStoreTypes['InboxesUiStore'] {
    if (this._inboxes) {
      return this._inboxes
    }
    throw new Error('inboxes store is not initialized')
  }

  get search(): UiStoreTypes['SearchUiStore'] {
    if (this._search) {
      return this._search
    }
    throw new Error('search store is not initialized')
  }

  get scheduledMessages(): UiStoreTypes['ScheduledMessagesUiStore'] {
    if (this._scheduledMessages) {
      return this._scheduledMessages
    }
    throw new Error('scheduled messages store is not initialized')
  }

  get sideMenu(): UiStoreTypes['SideMenuUiStore'] {
    if (this._sideMenu) {
      return this._sideMenu
    }
    throw new Error('side menu store is not initialized')
  }

  get trustRegistration(): UiStoreTypes['TrustRegistrationUiStore'] {
    if (this._trustRegistration) {
      return this._trustRegistration
    }
    throw new Error('trust registration store is not initialized')
  }

  get workspace(): UiStoreTypes['WorkspaceUiStore'] {
    if (this._workspace) {
      return this._workspace
    }
    throw new Error('workspace store is not initialized')
  }

  get cnam(): UiStoreTypes['CnamUiStore'] {
    if (this._cnam) {
      return this._cnam
    }
    throw new Error('cnam store is not initialized')
  }

  get portRequest(): UiStoreTypes['PortRequestUiStore'] {
    if (this._portRequest) {
      return this._portRequest
    }
    throw new Error('port request store is not initialized')
  }

  get helpAndSupport(): UiStoreTypes['HelpAndSupportUiStore'] | null {
    return this._helpAndSupport
  }

  get isElectron() {
    return Boolean(this.electron)
  }

  get isLoggedIn(): boolean {
    return Boolean(this.service.auth.session?.idToken)
  }

  get isFocused() {
    return document.hasFocus()
  }

  get needsOnboarding(): boolean | undefined {
    const user = this.service.user.current
    const phoneNumbers = this.service.user.phoneNumbers
    if (this.service.phoneNumber.loaded === false) {
      return undefined
    }
    return (
      (this.service.phoneNumber.loaded && phoneNumbers.length === 0) ||
      (user && !user?.firstName && !user?.lastName) ||
      this.hasPendingInvites
    )
  }

  get isAccountFlagged(): boolean {
    return this.service.billing.subscription?.isFlagged ?? false
  }

  get needsIdentity() {
    return this.service.billing.subscription?.needsIdentity ?? false
  }

  get hasPendingInvites(): boolean {
    return this.service.user.invites.length > 0
  }

  get onDataNeedsRefresh(): Observable<void> {
    return this.service.transport.connectivity.downtime.pipe(
      // Fitler shorter downtimes
      filter((downtime) => downtime > 5_000),
      // Map to void
      mergeMap(() => of(undefined as void)),
      // Throttle so we don't spam the backend
      throttleTime(60_000, asyncScheduler, { leading: true, trailing: true }),
    )
  }

  get unreadActivitiesCount() {
    let count = Object.entries(this.service.conversation.unreadCounts).reduce(
      (acc, [id, count]) => {
        if (this.inboxes.all.get(id)?.muted) return acc
        return acc + count
      },
      0,
    )
    count += this.alerts.unread
    count +=
      this.inboxes.dm?.conversations.list.reduce(
        (total, conversation) => total + conversation.unreadCount,
        0,
      ) ?? 0
    return count
  }

  get initialized(): boolean {
    return this.initializationStatus === 'success'
  }

  private get essentials(): (() => Promise<unknown>)[] {
    return [
      () => this.service.user.fetchInvites(),
      () => this.service.user.fetchConnections(),
      () => this.service.phoneNumber.fetch(),
      () => this.service.member.fetch(),
      () => this.service.organization.fetch(),
      () => this.service.organization.fetchRoles(),
      () => this.service.organization.phoneNumber.fetch(),
      () => this.service.workspace.fetchUserGroups(),
      () => this.service.capabilities.fetch(),
      () => this.service.billing.fetchSubscription(),
      () => this.service.blocklist.fetch(),
      () => this.service.integration.fetchAll(),
      () => this.service.portRequest.fetch(),
      () => this.service.contact.fetchSettings(),
    ]
  }

  private get flagDepdendentEssentials(): (() => Promise<unknown>)[] {
    // Only admins and owners can fetch CNAM data.
    const shouldFetchCnam =
      this.service.capabilities.features.callerIdEnabled &&
      this.service.user.current?.asMember?.isAdmin &&
      this.service.flags.getFlag('cnam')

    const snippetsEnabled = this.service.capabilities.features.snippetsEnabled

    return [
      shouldFetchCnam ? () => this.service.cnam.fetch() : null,
      snippetsEnabled ? () => this.service.snippet.fetch() : null,
    ].filter(isNonNull)
  }

  private get loadingPercentageStep(): number {
    // +2 for the independent call to user + identify step
    const steps = this.essentials.length + this.flagDepdendentEssentials.length + 2
    return Math.round(100 / steps)
  }

  private onDocumentScroll = action(() => {
    if (this.scrollTimeout) {
      window.clearTimeout(this.scrollTimeout)
    }
    this.isScrolling = true
    this.scrollTimeout = window.setTimeout(() => {
      this.isScrolling = false
    }, 100)
  })

  is(platform: 'web' | 'mac' | 'windows') {
    const current = ((): 'web' | 'mac' | 'windows' => {
      switch (this.electron?.platform) {
        case 'darwin':
          return 'mac'
        case 'win32':
          return 'windows'
        default:
          return 'web'
      }
    })()
    return current === platform
  }

  setThemeKey(themeKey: StorageThemeKey) {
    this.themeKey = themeKey
  }

  showAlert = (state: Omit<AlertState, 'open'>): void => {
    this.alert = { open: true, ...state }
  }

  showErrorAlert = (error: Error): void => {
    this.service.sound.play('error')
    this.alert = {
      open: true,
      title: errorTitle(error) ?? undefined,
      body: error.message,
    }
  }

  hideAlert = () => {
    this.alert = { ...this.alert, open: false }
  }

  showEmojiPicker = (props: Omit<EmojiPickerProps, 'open'>) => {
    this.emojiPicker = { open: true, ...props }
  }

  hideEmojiPicker = () => {
    this.emojiPicker = { open: false }
  }

  openMediaViewer = (media: MessageMedia[], index: number) => {
    this.mediaViewer = { media, index }
  }

  closeMediaViewer = () => {
    this.mediaViewer = null
  }

  reset = () => {
    this.alert = { open: false }
    this.emojiPicker = { open: false }
    this.serviceWorker.unregister().then(() => this.service.reset())
  }

  signOut = (evnOverride?: Env) => {
    this.tearDown()
    this.serviceWorker
      .unregister()
      .then(() => this.service.clearAllAndRestart(evnOverride))
  }

  showConfetti = () => {
    this.confetti = true
  }

  focus() {
    if (this.electron) {
      this.electron.window.show?.()
      this.electron.window.focus?.()
    } else {
      window.focus()
    }
  }

  incrementLoadingPercentage() {
    this.loadingPercentage += this.loadingPercentageStep
  }

  *initialize() {
    try {
      this.initializationStatus = 'loading'

      yield this.service.user.fetch().then(() => this.incrementLoadingPercentage())

      yield this.loadEssentials()

      if (!this.service.user.current) {
        throw new Error('current user is not initialized')
      }
      if (!this.service.organization.current) {
        throw new Error('current organization is not initialized')
      }

      const member = this.service.user.current.asMember
      if (!member) {
        throw new Error('member for current user could not be loaded')
      }

      yield this.identify({
        user: this.service.user.current,
        member,
        organization: this.service.organization.current,
        subscription: this.service.billing.subscription,
        themeKey: this.themeKey,
        workspaceSize: this.service.member.collection.length,
      }).then(() => this.incrementLoadingPercentage())

      yield this.loadFlagDependentEssentials()

      this.initializationStatus = 'success'
    } catch (error) {
      logError(error)
      this.initializationStatus = 'failed'
    }
  }

  private *loadEssentials() {
    const promises: Promise<unknown>[] = []

    for (const essential of this.essentials) {
      promises.push(essential().then(() => this.incrementLoadingPercentage()))
    }

    yield Promise.all(promises)
  }

  /**
   * Like loadEssentials, but for services that depend on a flag being enabled.
   *
   * Should get called after the user has been properly identified.
   */
  private *loadFlagDependentEssentials() {
    const promises: Promise<unknown>[] = []

    for (const flagDependentEssential of this.flagDepdendentEssentials) {
      promises.push(
        flagDependentEssential().then(() => this.incrementLoadingPercentage()),
      )
    }

    yield Promise.all(promises)
  }

  private async identify({
    user,
    member,
    organization,
    subscription,
    themeKey,
    workspaceSize,
  }: IdentifyData) {
    // Identify the user in the Feature Flags service
    await this.service.flags.identify(user, organization)

    // Set user for Sentry
    SentryManager.setUser(member, {
      id: organization.id,
      plan: subscription?.type ?? undefined,
    })

    // Identify the user in the analytics
    try {
      await this.service.analytics.identify({
        user,
        member,
        organization,
        subscription,
        themeKey,
        workspaceSize,
      })
    } catch (error) {
      logError(error)
    }
  }

  private setAsDefaultTelProtocol() {
    const hasBeenSetAlready = localStorage.getItem(HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL)
    if (this.electron && !hasBeenSetAlready) {
      this.electron.app.registerTelProtocol?.()
      localStorage.setItem(HAS_BEEN_SET_AS_DEFAULT_TEL_PROTOCOL, '1')
    }
  }

  private loadDeployPreviewer() {
    if (this.electron && this.service.user.current?.isOpenPhoneOrganizationMember) {
      this.electron.app.initDeployPreviewer?.()
    }
  }

  private async loadUiStores() {
    const {
      AlertsUiStore,
      ContactsUiStore,
      ConversationUiStore,
      HelpAndSupportUiStore,
      InboxesUiStore,
      SearchUiStore,
      SideMenuUiStore,
      ScheduledMessagesUiStore,
      AnalyticsUiStore,
      WorkspaceUiStore,
      CnamUiStore,
      PortRequestUiStore,
      TrustRegistrationUiStore,
    } = await loadUiStores()

    runInAction(() => {
      this._alerts = new AlertsUiStore(this)
      this._contacts = new ContactsUiStore(this)
      this._conversation = new ConversationUiStore(this)
      this._inboxes = new InboxesUiStore(this)
      this._search = new SearchUiStore(this)
      this._sideMenu = new SideMenuUiStore(this)
      this._scheduledMessages = new ScheduledMessagesUiStore(this)
      this._analytics = new AnalyticsUiStore(this)
      this._workspace = new WorkspaceUiStore(this)
      this._cnam = new CnamUiStore(this)
      this._portRequest = new PortRequestUiStore(this)
      this._trustRegistration = new TrustRegistrationUiStore(this)
      this._helpAndSupport = this.service.flags.flags.cohere
        ? new HelpAndSupportUiStore(this)
        : null
      this.storesLoaded = true
      this.loadDeployPreviewer()
      this.url.checkHandlerUrl()
    })
  }

  private tearDownUiStores() {
    this._alerts?.tearDown()
    this._alerts = null
    this._contacts?.tearDown()
    this._contacts = null
    this._conversation?.tearDown()
    this._conversation = null
    this._inboxes?.tearDown()
    this._inboxes = null
    this._search?.tearDown()
    this._search = null
    this._sideMenu?.tearDown()
    this._sideMenu = null
    this._scheduledMessages?.tearDown()
    this._scheduledMessages = null
    this._analytics?.tearDown()
    this._analytics = null
    this._cnam?.tearDown()
    this._cnam = null
    this._portRequest?.tearDown()
    this._portRequest = null
    this._trustRegistration = null
    this._workspace?.tearDown()
    this._workspace = null
    this._helpAndSupport?.tearDown()
    this._helpAndSupport = null
    this.storesLoaded = false
  }

  tearDown = () => {
    this.alert = { open: false }
    this.emojiPicker = { open: false }
    document.removeEventListener('scroll', this.onDocumentScroll, true)
    this.service.analytics.tearDown()
  }
}

class HistoryManager {
  location: Location

  constructor(readonly router: Router) {
    this.location = router.state.location

    makeAutoObservable(this, {})

    router.subscribe(
      action((state) => {
        this.location = state.location
      }),
    )
  }

  get pathComponents() {
    return this.location.pathname.split('/').slice(1)
  }

  /**
   * Returns everything after the ? in the url as a hash, so ?a=1&b2
   * turns into { a: 1, b: 2 }
   */
  get query(): LocationSearch {
    return fromQueryString(this.location.search) as LocationSearch
  }

  push = (path: string) => {
    this.router.navigate({
      pathname: path,
      search: stripUnknownParams(this.location.search).toString(),
    })
  }

  replace = (path: string) => {
    this.router.navigate(
      {
        pathname: path,
        search: stripUnknownParams(this.location.search).toString(),
      },
      { replace: true },
    )
  }

  consumeQueryParam = (param: LocationSearchParam): string | undefined => {
    const value = this.query[param]

    if (value) {
      const search = stripSearchParam(this.location.search, param)

      this.router.navigate(
        {
          ...this.location,
          search: search.toString(),
        },
        { replace: true },
      )
    }

    return value
  }

  goBack = () => {
    this.router.navigate(-1)
  }
}
