import type { Theme } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { useId } from '@react-aria/utils'
import cx from 'classnames'
import React, { forwardRef, useMemo } from 'react'

import { computeRelativeFontSize } from '@src/component/utils'
import type { ThemeKey } from '@src/theme'
import type { TooltipPlacement } from '@ui/Tooltip'
import Tooltip from '@ui/Tooltip'
import VisuallyHidden from '@ui/VisuallyHidden'

import * as styles from './IconButton.css'

export type IconButtonColor =
  | 'default'
  | 'primary'
  | 'gray'
  | 'grayTertiary'
  | 'note'
  | 'white'
export type IconButtonVariant = 'transparent' | 'outlined' | 'dashed'

export interface IconButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'ref' | 'title'
  > {
  icon: React.ReactNode
  color?: IconButtonColor
  hoverColor?: 'white' | 'red'
  backgroundColor?: 'transparent' | `rgb(${string})`
  opaque?: boolean
  size?: 16 | 22 | 24 | 30 | 32 | 34 | 36 | 40 | 45 | 48
  title?: string
  titleIcon?: React.ReactNode
  shortcut?: string
  variant?: IconButtonVariant
  rounded?: boolean
  tooltipPlacement?: TooltipPlacement
  tooltipMode?: ThemeKey
  wrapperClassName?: string
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      icon,
      color = 'default',
      hoverColor,
      backgroundColor = 'transparent',
      rounded = false,
      opaque = false,
      disabled = false,
      shortcut,
      size = 30,
      variant = 'transparent',
      title,
      titleIcon,
      tooltipPlacement,
      tooltipMode,
      style,
      wrapperClassName,
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    const theme = useTheme()

    const inlineStyles = useMemo(
      () => ({
        backgroundColor,
        ...style,
        ...getSizeStyles(size),
        ...getBorderStyles(theme, variant, color),
      }),
      [backgroundColor, style, size, theme, variant, color],
    )

    const id = useId(props.id)

    const button = (
      <button
        {...props}
        ref={ref}
        disabled={disabled}
        className={cx(
          styles.root({
            color,
            hoverColor,
            variant,
            rounded: String(rounded),
            opaque: String(opaque),
            disabled: String(disabled),
          }),
          className,
        )}
        style={inlineStyles}
        aria-labelledby={title ? id : undefined}
      >
        {icon}
      </button>
    )

    if (title) {
      /**
       * Wrap button in span because if the button is disabled, the tooltip
       * cannot work with a disabled child as it won't be firing events the tooltip
       * needs to listen to
       */
      return (
        <>
          <Tooltip
            title={title}
            icon={titleIcon}
            shortcut={shortcut}
            placement={tooltipPlacement}
            themeKey={tooltipMode}
          >
            <span
              className={cx(styles.wrapper, wrapperClassName)}
              style={{ minWidth: inlineStyles.width, height: inlineStyles.height }}
            >
              {button}
            </span>
          </Tooltip>
          <VisuallyHidden>
            <span id={id}>{title}</span>
          </VisuallyHidden>
        </>
      )
    }

    return button
  },
)

export default IconButton

const getSizeStyles = (size: NonNullable<IconButtonProps['size']>) => {
  const x = size
  const y = computeRelativeFontSize(x ?? 0)

  return {
    width: x,
    height: x,
    fontSize: y,
  }
}

const getBorderStyles = (
  theme: Theme,
  variant: IconButtonVariant,
  clr: IconButtonColor,
) => {
  if (variant === 'transparent') {
    return { border: 'none' }
  }

  if (variant === 'dashed') {
    return {
      border: `2px dashed ${theme.palette.op.hover.darker}`,
    }
  }

  if (clr === 'primary') {
    return {
      border: `1.5px solid ${theme.palette.op.primary[1]}`,
    }
  }

  return {
    border: 'none',
    boxShadow: theme.palette.op.shadow.dropdown.hover,
  }
}
