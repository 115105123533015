import { observer } from 'mobx-react-lite'
import React from 'react'

import { useAppStore } from '@src/app/context'
import Login from '@src/app/login'

import Redirect from './Redirect'

const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const app = useAppStore()

  if (!app.isLoggedIn) {
    return <Login />
  }

  if (!app.initialized || !app.storesLoaded) {
    throw app.initializePromise
  }

  if (app.isAccountFlagged) {
    return <Redirect to="/verification/flagged" />
  }

  if (app.needsIdentity) {
    return <Redirect to="/verification/identity" />
  }

  return <>{children}</>
}

export default observer(AuthGuard)
