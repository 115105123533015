import { useId } from '@react-aria/utils'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import Modal from '@ui/Modal'
import Typography from '@ui/Typography'

import * as styles from './AlertDialog.css'
import type { AlertAction } from './AppStore'

const AlertDialog = () => {
  const titleId = useId()
  const descriptionId = useId()
  const { alert, hideAlert } = useAppStore()

  const handleAlertAction = (action: AlertAction) => {
    action.onClick?.()
    hideAlert()
  }

  if (!alert.open) return null

  return (
    <Modal
      onClose={hideAlert}
      className={styles.root}
      underlayColor="rgb(26 25 38 / 40%)"
      isDismissable
      role="alertdialog"
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
    >
      <Typography
        component="h2"
        id={titleId}
        fontWeight="medium"
        variant="headline"
        color="textPrimary"
      >
        {alert?.title}
      </Typography>

      <Typography
        id={descriptionId}
        variant="callout"
        color="textSecondary"
        className={styles.body}
      >
        {alert?.body}
      </Typography>

      <div className={styles.actions}>
        {alert?.actions && alert.actions.length > 0 ? (
          alert?.actions
            .slice()
            .reverse()
            .map((action) => {
              const color =
                action.type === 'primary'
                  ? 'primary'
                  : action.type === 'secondary-outlined'
                  ? 'neutral'
                  : 'textSecondary'
              const variant =
                action.type === 'primary' || action.type === 'secondary-outlined'
                  ? 'contained'
                  : 'text'

              return action.type === 'destructive' ? (
                <Button
                  autoFocus
                  key={action.title}
                  height={36}
                  color="danger"
                  variant="contained"
                  onClick={() => handleAlertAction(action)}
                >
                  {action.title}
                </Button>
              ) : (
                <Button
                  key={action.title}
                  height={36}
                  autoFocus={action.type === 'primary'}
                  variant={variant}
                  color={color}
                  onClick={() => handleAlertAction(action)}
                >
                  {action.title}
                </Button>
              )
            })
        ) : (
          <Button variant="contained" height={36} color="primary" onClick={hideAlert}>
            Okay
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default observer(AlertDialog)
