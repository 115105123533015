import type { Conversation } from '@src/service/model'

export default function isUnreadConversation(
  conversation?: Pick<
    Conversation,
    'phoneNumberId' | 'directNumberId' | 'deletedAt' | 'snoozedUntil' | 'unreadCount'
  >,
) {
  if (!conversation) {
    return false
  }

  const doesNotHavePhoneNumberId =
    !conversation.phoneNumberId && !conversation.directNumberId
  const isDeleted = Boolean(conversation.deletedAt)
  const isSnoozed = conversation.snoozedUntil
    ? conversation.snoozedUntil > Date.now()
    : false

  if (doesNotHavePhoneNumberId || isDeleted || isSnoozed) {
    return false
  }

  return conversation.unreadCount > 0
}
