/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'

import type Service from '..'

import type { Model } from './'

export interface SerializedSnippet {
  id: string
  userId: string | null
  name: string
  text: string
  sharedWith: string[]
  createdAt: number
  updatedAt: number
}

export class Snippet implements Model, SerializedSnippet {
  id: string = ObjectID()
  userId: string | null = null
  name = ''
  text = ''
  sharedWith: string[] = []
  createdAt = Date.now()
  updatedAt = Date.now()

  // Local
  isNew = false

  constructor(private root: Service, attrs: Partial<Snippet> = {}) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  update = (attributes: Partial<Snippet>) => {
    Object.assign(this, attributes)
    this.save()
  }

  save = () => {
    this.isNew = false
    this.root.snippet.save(this)
  }

  delete = () => {
    this.root.snippet.delete(this)
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt) || this.updatedAt
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt) || this.createdAt
    return this
  }

  serialize = (): SerializedSnippet => {
    return {
      id: this.id,
      userId: this.userId,
      name: this.name,
      text: this.text,
      sharedWith: toJS(this.sharedWith),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}
