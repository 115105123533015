import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'
import type { TemplateTagItemOption } from '@src/lib/search'

import type Service from '..'

import type { Model } from './base'

const supportedTypes = [
  'address',
  'boolean',
  'date',
  'multi-select',
  'number',
  'string',
  'url',
  'created-by',
]

type TemplateType =
  | 'address'
  | 'boolean'
  | 'date'
  | 'multi-select'
  | 'number'
  | 'string'
  | 'url'
  | 'created-by'

export interface IContactTemplateItem {
  createdAt?: number | null
  id: string
  key?: string | null
  name?: string | null
  options?: any
  order?: number | null
  orgId?: string | null
  type: TemplateType | null
  updatedAt?: number | null
}

export class ContactTemplateItem implements IContactTemplateItem, Model {
  id: string = ObjectID()
  key: string | null = null
  name: string | null = null
  options: any = {}
  order: number | null = null
  orgId: string | null = null
  type: TemplateType | null = null
  createdAt: number | null = Date.now()
  updatedAt: number | null = Date.now()
  local = false

  constructor(private service: Service, attrs: Partial<ContactTemplateItem> = {}) {
    Object.assign(this, { id: ObjectID(), key: ObjectID() }, attrs)
    this.setDefaultOption(this.type)
    makeAutoObservable(this, {})
  }

  get isSupportedType() {
    return this.type ? supportedTypes.includes(this.type) : false
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    return this.service.contact.updateTemplate(this)
  }

  delete() {
    return this.service.contact.deleteTemplate(this)
  }

  deleteOption(option: TemplateTagItemOption) {
    if (!Array.isArray(this.options)) return
    this.options = this.options.filter((item) => item !== option)
    this.update(this)
  }

  renameOption(option: TemplateTagItemOption, name: string) {
    option.name = name
    this.update(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.setDefaultOption(json.type, json.options)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      key: this.key,
      name: this.name,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      options: toJS(this.options),
      order: this.order,
      orgId: this.orgId,
      type: this.type,
      updatedAt: this.updatedAt,
    }
  }

  private setDefaultOption(type: TemplateType | null, options?: any) {
    if (options || this.options) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      this.options = options || this.options
    } else if (type === 'multi-select') {
      this.options = []
    } else {
      this.options = {}
    }
  }
}
