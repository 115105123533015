import React from 'react'
import type { Path } from 'react-router-dom'
import { useLocation, Navigate } from 'react-router-dom'

export interface RedirectProps {
  to: string
}

/**
 * Redirects to a new location with the current query parameters.
 *
 * @deprecated Please use `Navigate` instead.
 */
export default function Redirect({ to }: RedirectProps) {
  const location = useLocation()
  const descriptor: Path = {
    ...location,
    pathname: to,
    search: window.location.search,
  }

  return <Navigate to={descriptor} replace={true} />
}
