import { makeAutoObservable } from 'mobx'

import type AppStore from '@src/app/AppStore'
import type IUiStore from '@src/app/IUiStore'
import shortId from '@src/lib/short-id'

export default class ErrorStore implements IUiStore {
  error: Error | null = null
  errorId: string | null = null
  resetErrorBoundary: (() => void) | null = null

  constructor(private app: AppStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.app.update.checkForUpdate()
  }

  get errorMessage(): string | null {
    return this.error ? this.error.message ?? String(this.error) : null
  }

  onError(event: Error) {
    this.error = event
    this.errorId = shortId()
  }

  copyError() {
    if (!this.error) {
      return
    }

    const text = `[${this.errorId}] ${this.error.stack}`
    navigator.clipboard.writeText(text)
    this.app.toast.show({ message: 'Copied to clipboard!' })
  }

  reload() {
    this.reset()
    if (this.app.update.updateAvailable) {
      this.app.update.installAndRestart()
    } else {
      this.resetErrorBoundary?.()
    }
  }

  resetAllDataAndReload() {
    this.reset()
    this.app.reset()
  }

  tearDown() {
    this.reset()
  }

  private reset() {
    this.error = null
    this.errorId = null
  }
}
