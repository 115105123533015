import type { EffectCallback, DependencyList } from 'react'
import { useEffect, useRef, useMemo } from 'react'

export default function useEffectBeforeMount(
  effect: EffectCallback,
  deps?: DependencyList,
) {
  const cleanup = useRef<(() => void) | void>()

  useMemo(() => {
    cleanup.current = effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, deps)

  useEffect(() => {
    return cleanup.current
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, deps)
}
