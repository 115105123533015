import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import ObjectID from '@src/lib/object-id'
import type {
  AvailabilityHours,
  PhoneNumberSettings,
  SharedUserRole,
  UserPhoneNumber,
} from '@src/service/transport/account'
import type { IvrSettings } from '@src/service/transport/communication'

import type Service from '..'

import { fullName } from '.'
import type { Model, PortRequestStatus } from '.'
import type { Integration } from './integration'
import type { Member } from './member'

export interface IEntityPhoneNumber {
  availabilityHours: AvailabilityHours | null
  awayVoicemailId: string | null
  awayVoicemailUrl: string | null
  color: string | null
  createdAt: number | null
  entityId: string | null
  entityName: string | null
  entityType: string | null
  formattedNumber: string | null
  forward: string | null
  groupId: string | null
  id: string
  ivr: IvrSettings | null
  mutedUntil: number | null
  name: string | null
  number: string | null
  orgId: string | null
  ownerId: string | null
  primary: boolean | null
  releasedAt: number | null
  role: SharedUserRole | null
  settings: PhoneNumberSettings
  sid: string | null
  status: string | null
  symbol: string | null
  timeout: number | null
  updatedAt: number | null
  userId: string | null
  users: UserPhoneNumber[]
  voicemailId: string | null
  voicemailUrl: string | null
  portRequestId: string | null
  portingStatus: PortRequestStatus | null
}

export class EntityPhoneNumber implements IEntityPhoneNumber, Model {
  availabilityHours: AvailabilityHours | null = null
  awayVoicemailId: string | null = null
  awayVoicemailUrl: string | null = null
  color: string | null = null
  createdAt: number | null = null
  entityId: string | null = null
  entityName: string | null = null
  entityType: string | null = null
  formattedNumber: string | null = null
  forward: string | null = null
  groupId: string | null = null
  id: string = ObjectID()
  ivr: IvrSettings | null = null
  mutedUntil: number | null = null
  name: string | null = null
  number: string | null = null
  orgId: string | null = null
  ownerId: string | null = null
  primary: boolean | null = null
  releasedAt: number | null = null
  role: SharedUserRole | null = null
  settings: PhoneNumberSettings = {}
  sid: string | null = null
  status: string | null = null
  symbol: string | null = null
  timeout: number | null = null
  updatedAt: number | null = null
  userId: string | null = null
  users: UserPhoneNumber[] = []
  voicemailId: string | null = null
  voicemailUrl: string | null = null
  portRequestId: string | null = null
  portingStatus: PortRequestStatus | null = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  get members() {
    return this.users
      .map((u) => (u.id ? this.service.member.collection.get(u.id) : null))
      .filter(isNonNull)
  }

  get userNames(): string {
    if (!this.users) {
      return ''
    } else if (this.users.length === 1) {
      return fullName(this.users[0])
    }
    return this.users
      .map((u) => u.firstName)
      .filter((u) => u)
      .join(', ')
  }

  get isForwarding() {
    return Boolean(this.forward)
  }

  get integrations(): Integration[] {
    return this.service.integration.collection.list.filter(
      (i) => (i.type === 'slack' || i.type === 'email') && i.phoneNumberId === this.id,
    )
  }

  get isPorting() {
    return !!this.portingStatus && this.portingStatus !== 'completed'
  }

  get portRequest() {
    return this.service.portRequest.collection.get(this.portRequestId)
  }

  update = (attributes: Partial<EntityPhoneNumber>) => {
    Object.assign(this, attributes)
    return this.service.organization.phoneNumber.update(this)
  }

  updateSettings = (attributes: Partial<PhoneNumberSettings>) => {
    Object.assign(this.settings, attributes)
    return this.service.organization.phoneNumber.updateSettings(this)
  }

  makeOwner = (member: Member) => {
    this.entityId = member.id
    this.ownerId = member.id
    this.users = this.users.map((u) => {
      if (u.id !== member.id && u.role == 'owner') {
        return { ...u, role: 'admin' }
      } else if (u.id === member.id) {
        return { ...u, role: 'owner' }
      }
      return u
    })
    return this.service.organization.phoneNumber.reassign(this, member.id)
  }

  addMember = (member: Member, role: SharedUserRole) => {
    this.users = this.users.filter((u) => u.id !== member.id)
    this.users.push({
      id: member.id,
      role,
      email: member.email,
      firstName: member.firstName,
      lastName: member.lastName,
    })
    return this.service.organization.phoneNumber.addUser(this, member.id)
  }

  removeMember = (member: Member) => {
    this.users = this.users.filter((u) => u.id !== member.id)
    return this.service.organization.phoneNumber.removeUser(this, member.id)
  }

  toggleInternational = (enabled: boolean) => {
    this.settings.international = { ...this.settings.international, enabled }
    return this.service.organization.phoneNumber.toggleInternational(this)
  }

  fetchIvr = () => {
    return this.service.organization.phoneNumber.fetchIvr(this)
  }

  updateIvr = (ivr: IvrSettings) => {
    this.ivr = ivr
    return this.service.organization.phoneNumber.setIvr(this)
  }

  deleteIvr = () => {
    this.ivr = null
    return this.service.organization.phoneNumber.deleteIvr(this)
  }

  fetchIntegrations = () => {
    return this.service.integration.fetchForPhoneNumber(this.id)
  }

  delete = () => {
    return this.service.organization.phoneNumber.delete(this)
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    this.settings ??= {}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.mutedUntil = parseDate(json.mutedUntil)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    return this
  }

  setVoicemailUrl = (url: string) => {
    this.voicemailUrl = url
    return this.service.organization.phoneNumber.setVoicemail(this)
  }

  setAwayVoicemailUrl = (url: string) => {
    this.awayVoicemailUrl = url
    return this.service.organization.phoneNumber.setAwayVoicemail(this)
  }

  serialize = (): IEntityPhoneNumber => {
    return {
      availabilityHours: toJS(this.availabilityHours),
      awayVoicemailId: this.awayVoicemailId,
      awayVoicemailUrl: this.awayVoicemailUrl,
      color: this.color,
      createdAt: this.createdAt,
      entityId: this.entityId,
      entityName: this.entityName,
      entityType: this.entityType,
      formattedNumber: this.formattedNumber,
      forward: this.forward,
      groupId: this.groupId,
      id: this.id,
      ivr: toJS(this.ivr),
      mutedUntil: this.mutedUntil,
      name: this.name,
      number: this.number,
      orgId: this.orgId,
      ownerId: this.ownerId,
      primary: this.primary,
      releasedAt: this.releasedAt,
      role: this.role,
      settings: toJS(this.settings),
      sid: this.sid,
      status: this.status,
      symbol: this.symbol,
      timeout: this.timeout,
      updatedAt: this.updatedAt,
      userId: this.userId,
      users: toJS(this.users),
      voicemailId: this.voicemailId,
      voicemailUrl: this.voicemailUrl,
      portRequestId: this.portRequestId,
      portingStatus: this.portingStatus,
    }
  }
}
