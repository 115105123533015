import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import ReCaptchaLegalInfo from '@src/app/login/ReCaptchaLegalInfo'
import { Disclaimer } from '@src/app/onboarding/common'
import Button from '@src/component/ButtonV2'
import useGoogleClientApi from '@src/lib/hooks/useGoogleClientApi'
import useInputState from '@src/lib/hooks/useInputState'
import useMetaDescription from '@src/lib/hooks/useMetaDescription'
import useTitle from '@src/lib/hooks/useTitle'
import keyboard from '@src/lib/keyboard'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'
import { GoogleIcon } from '@ui/icons/no-tint/misc'
import { LogomarkIcon } from '@ui/icons/tint/misc'

import { useLoginStore } from '.'
import * as styles from './LoginOptions.css'

function LoginOptions() {
  const loginStore = useLoginStore()
  const [email, setEmail] = useInputState(loginStore.email ?? '')

  useEffect(() => {
    loginStore.setInitialStep()
  }, [loginStore])

  const handleCode = () => {
    loginStore.hideGoogleOneTap()
    loginStore.continueToCode(email.trim())
  }

  const handlePassword = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    event.preventDefault()
    loginStore.hideGoogleOneTap()
    loginStore.continueToPassword(email.trim())
  }

  const loginTitle = 'Log in'
  const signUpTitle = 'Sign up and start your free trial'

  const loginDescription =
    'Log back into your OpenPhone account or create an account to get started with a Free Trial.'
  const signUpDescription =
    'Create an account to start your Free OpenPhone Trial. Sign up with your email or Google account. Try now for Free!'

  useTitle(loginStore.mode === 'signup' ? signUpTitle : loginTitle)
  useMetaDescription(loginStore.mode === 'signup' ? signUpDescription : loginDescription)

  const { loaded } = useGoogleClientApi()

  useEffect(() => {
    if (!loaded) return

    if (loginStore.loading !== 'none' || loginStore.isAlertShown) {
      loginStore.hideGoogleOneTap()
    } else {
      loginStore.initializeGoogleOneTap()
      loginStore.showGoogleOneTap()
    }
  }, [loaded, loginStore, loginStore.mode, loginStore.loading, loginStore.isAlertShown])

  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <LogomarkIcon />
            {loginStore.mode === 'signup' && loginStore.coupon ? (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 24 }}>
                  Redeem your <br />
                  {loginStore.coupon.name} offer
                </Typography>
                <Typography
                  variant="subheadline"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  Save {loginStore.coupon.percent_off}% for{' '}
                  {loginStore.coupon.duration_in_months} months
                </Typography>
              </>
            ) : loginStore.invite ? (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 24 }}>
                  Join {loginStore.invite.name ?? 'your team'} on OpenPhone
                </Typography>
                <Typography
                  variant="body"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  Create an account to start.
                </Typography>
              </>
            ) : loginStore.mode === 'signup' ? (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 16 }}>
                  Your business phone reimagined
                </Typography>
                <Typography
                  variant="body"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  Create an account and start your free trial
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="largeTitle" style={{ marginTop: 16 }}>
                  Welcome back
                </Typography>
                <Typography
                  variant="body"
                  color="textSecondary"
                  style={{ marginTop: 16 }}
                >
                  It’s great to see you 👋
                  <br />
                  Log in to your account below.
                </Typography>
              </>
            )}

            <Button
              height={40}
              fullWidth
              className={styles.googleLogin}
              loading={loginStore.loading === 'authenticating_with_google'}
              disabled={loginStore.loading === 'authenticating_with_google'}
              onClick={loginStore.loginWithGoogle}
              startIcon={
                <div className={styles.googleIconBg}>
                  <GoogleIcon />
                </div>
              }
            >
              Continue with Google
            </Button>

            <Typography
              variant="footnote"
              color="textSecondary"
              style={{ marginTop: 24, marginBottom: 10 }}
            >
              Or continue with email
            </Typography>
            <TextInput
              ref={loginStore.inputRef}
              autoFocus
              fullWidth
              value={email}
              placeholder="Email address..."
              onChange={setEmail}
              onKeyDown={keyboard.onEnter(handleCode)}
            />
            <Button
              fullWidth
              disabled={!email}
              loading={loginStore.loading === 'sending_code'}
              variant="contained"
              color="primary"
              height={40}
              style={{ marginTop: 12 }}
              onClick={handleCode}
            >
              Continue
            </Button>

            {loginStore.mode === 'login' && (
              <Disclaimer
                emoji="🧙‍♀️"
                text={
                  <>
                    We'll email you a magic code to log in password-free. Or if you
                    prefer, you can log in with your{' '}
                    <a href="/login/password" onClick={handlePassword}>
                      password instead
                    </a>
                    .
                  </>
                }
              />
            )}

            {loginStore.mode === 'login' ? (
              <div style={{ marginTop: 32, marginBottom: 4 }}>
                <Typography variant="footnote" color="textSecondary">
                  Don’t have an account yet?
                </Typography>
                <Button
                  height={30}
                  variant="text"
                  color="primary"
                  fontWeight="regular"
                  onClick={loginStore.toggleMode}
                >
                  Sign up for free
                </Button>
              </div>
            ) : (
              <div style={{ marginTop: 32, marginBottom: 4 }}>
                <Typography variant="footnote" color="textSecondary">
                  Already have an account?
                </Typography>
                <Button
                  height={30}
                  variant="text"
                  color="primary"
                  fontWeight="regular"
                  onClick={loginStore.toggleMode}
                >
                  Log back in
                </Button>
              </div>
            )}
          </div>

          <Typography
            variant="caption1"
            color="textTertiary"
            fontWeight="regular"
            className={styles.terms}
            style={{ marginTop: 64 }}
          >
            By continuing, you acknowledge that you have read, understood, and agree to
            OpenPhone's{' '}
            <a href="https://www.openphone.com/terms" target="_blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.openphone.com/privacy" target="_blank">
              Privacy Policy
            </a>
            .
          </Typography>
          <ReCaptchaLegalInfo className={styles.recaptchaLegal} />
        </div>
      </div>
    </div>
  )
}

export default observer(LoginOptions)
