/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable } from 'mobx'

import { NotFoundError } from '@src/lib/api/errorHandler'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type { Session } from './model'
import type TransportClient from './transport'
import type { LoginResponse } from './transport/auth'

export default class AuthStore {
  session: Session | null = null

  constructor(private root: Service) {
    makeAutoObservable(this, {
      transport: false,
    })

    makePersistable(this, 'AuthStore', {
      session: root.storage.sync(),
    })

    if (this.session) {
      this.transport.setSession(this.session)
    }

    /**
     * When the websocket indicates token needs to be refreshed, go ahead and do it
     */
    this.transport.onMessage.subscribe((message: any) => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        message.className === 'AuthUser' &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        message.tokenStatus === 'expired' &&
        this.session?.refreshToken
      ) {
        this.refreshToken(this.session.refreshToken)
      }
    })
  }

  get transport(): TransportClient {
    return this.root.transport
  }

  get hasSession() {
    return Boolean(this.session?.idToken)
  }

  get isImpersonating() {
    return this.session?.idToken && !this.session.refreshToken
  }

  setSession = (session: Session) => {
    this.session = session
    this.transport.setSession(this.session)
  }

  setSessionFromResponse = (res: LoginResponse): Session => {
    const session = {
      idToken: res.id_token,
      refreshToken: res.refresh_token,
    }

    this.setSession(session)

    return session
  }

  acceptReferral = (referralToken?: string) => (session: Session) => {
    if (referralToken) {
      this.root.referral.accept(referralToken)
    }
    return session
  }

  googleSignin = (accessToken: string, inviteToken?: string): Promise<Session> => {
    return this.transport.auth.signin
      .google(accessToken)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
  }

  googleRegister = (
    accessToken: string,
    inviteToken?: string,
    referralToken?: string,
  ): Promise<Session> => {
    return this.transport.auth.register
      .google(accessToken)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
      .then(this.acceptReferral(referralToken))
  }

  passwordSignin = (
    email: string,
    password: string,
    inviteToken?: string,
    recaptcha_token?: string,
  ): Promise<Session> => {
    return this.transport.auth.signin
      .password(email, password, recaptcha_token)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
  }

  forgotPassword = (email: string): Promise<any> => {
    return this.transport.auth.signin.forgotPassword(email)
  }

  startPasswordlessSignin = (email: string): Promise<Session> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.transport.auth.signin.exists(email).then((res) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      if (res.exists) {
        return this.transport.auth.signin.sendCode(email)
      } else {
        throw new NotFoundError(
          'The email address you entered does not belong to an OpenPhone customer.',
        )
      }
    })
  }

  startPasswordlessRegister = (email: string): Promise<Session> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.transport.auth.register.sendCode(email)
  }

  verifyPasswordlessSignin = (
    email: string,
    code: string,
    inviteToken?: string,
    recaptcha_token?: string,
  ): Promise<Session> => {
    return this.transport.auth.signin
      .verifyCode(email, code, recaptcha_token)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
  }

  verifyPasswordlessRegister = (
    email: string,
    code: string,
    inviteToken?: string,
    referralToken?: string,
    recaptcha_token?: string,
  ): Promise<Session> => {
    return this.transport.auth.register
      .verifyCode(email, code, recaptcha_token)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
      .then(this.acceptReferral(referralToken))
  }

  refreshToken = (refreshToken?: string): Promise<Session> => {
    refreshToken ??= this.session?.refreshToken
    return this.transport.auth.signin
      .refreshToken(refreshToken)
      .then(this.setSessionFromResponse)
  }

  changeEmail = (email: string) => {
    return this.transport.auth.changeEmail(email)
  }

  verifyChangeEmail(email: string, code: string) {
    return this.transport.auth.verifyChangeEmail(email, code)
  }

  private acceptInvite = (
    token?: string,
  ): ((session: LoginResponse) => Promise<LoginResponse>) => {
    if (token) {
      return (session: LoginResponse) => {
        this.root.transport.client.idToken = session.id_token
        return this.root.transport.account.invites.accept(token).then(() => {
          localStorage.setItem('OnboardingUiState.isJoinWorkspace', 'true')
          return this.transport.auth.signin.refreshToken(session.refresh_token)
        })
      }
    }
    return (session: LoginResponse) => Promise.resolve(session)
  }
}

export { Session }
