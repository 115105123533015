import { action, makeAutoObservable } from 'mobx'

import type Service from '@src/service'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import Capability from '@src/service/model/Capability'
import type { CapabilityRepository } from '@src/service/worker/repository/CapabilityRepository'

export default class CapabilitiesService {
  private readonly raw: PersistedCollection<Capability, CapabilityRepository>

  constructor(private readonly root: Service) {
    this.subscribeToWebSocket()
    makeAutoObservable(this, {})

    this.raw = new PersistedCollection({
      table: root.storage.table('capability'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (json) => new Capability(json),
    })

    this.raw.performQuery((repo) => repo.all())
  }

  fetch() {
    return this.root.transport.billing
      .capabilities()
      .then(action((json) => this.raw.load(json)))
  }

  get features() {
    return {
      snippetsEnabled: this.raw.get('snippets')?.enabled ?? false,
      autoRepliesEnabled: this.raw.get('autoReplies')?.enabled ?? false,
      callerIdEnabled: this.raw.get('callerId')?.enabled ?? false,
      callTransferEnabled: this.raw.get('callTransfer')?.enabled ?? false,
      analyticsReportsEnabled: this.raw.get('reports')?.enabled ?? false,
      ivrEnabled: this.raw.get('ivr')?.enabled ?? false,
      conferenceCallingEnabled: this.raw.get('conferenceCalling')?.enabled ?? false,
      roundRobinEnabled: this.raw.get('roundRobinCalling')?.enabled ?? false,
      apiMessagingEnabled: this.raw.get('apiMessaging')?.enabled ?? false,
      auditLogEnabled: this.raw.get('auditLog')?.enabled ?? false,
      showOnCallStatusEnabled: this.raw.get('showOnCallStatus')?.enabled ?? false,
      voicemailTranscriptionEnabled:
        this.raw.get('voicemailTranscription')?.enabled ?? false,
      autoCallRecordingEnabled: this.raw.get('autoCallRecording')?.enabled ?? false,
      messagingEnabled: this.raw.get('messaging')?.enabled ?? false,
      groupMessagingEnabled: this.raw.get('groupMessaging')?.enabled ?? false,
      internationalMessagingEnabled:
        this.raw.get('internationalMessaging')?.enabled ?? false,
      internationalCallingEnabled: this.raw.get('internationalCalling')?.enabled ?? false,
      callingEnabled: this.raw.get('calling')?.enabled ?? false,
      callRecordingEnabled: this.raw.get('callRecording')?.enabled ?? false,
      slackEnabled: this.raw.get('slackIntegration')?.enabled ?? false,
      googleContactsEnabled: this.raw.get('googleContacts')?.enabled ?? false,
      hubspotEnabled: this.raw.get('hubspot')?.enabled ?? false,
      emailEnabled: this.raw.get('emailIntegration')?.enabled ?? false,
      gongEnabled: this.raw.get('gong')?.enabled ?? false,
      salesforceEnabled: this.raw.get('salesforce')?.enabled ?? false,
      webhooksEnabled: this.raw.get('webhooks')?.enabled ?? false,
      scheduledMessagesEnabled: this.raw.get('scheduledMessages')?.enabled ?? false,
      businessHoursEnabled: this.raw.get('businessHours')?.enabled ?? false,
      zapierEnabled: this.raw.get('zapier')?.enabled ?? false,
      zapierSms: this.raw.get('zapierSms')?.enabled ?? false,
      userManagementEnabled: this.raw.get('userManagement')?.enabled ?? false,
      holdEnabled: this.raw.get('callHolding')?.enabled ?? false,
    }
  }

  get maximumPhoneNumberUsers(): number {
    const capability = this.raw.get('maxPhoneNumberUsers')

    if (capability?.included) {
      return capability.included === -1 ? Infinity : capability.included ?? 10
    }

    return 0
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'subscription-update':
          this.root.capabilities.fetch()
      }
    })
  }
}
