/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable } from 'mobx'

import type Service from '@src/service'
import type { Model } from '@src/service/model/base'

export class WebhookIntegration implements Model {
  id = ''
  // eslint-disable-next-line @typescript-eslint/prefer-as-const -- FIXME: Fix this ESLint violation!
  type: 'webhook' = 'webhook'
  orgId: string | null = null
  userId: string | null = null
  url: string | null = null
  method: 'post' | 'get' | 'put' | null = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      orgId: this.orgId,
      userId: this.userId,
      url: this.url,
      method: this.method,
    }
  }
}
