/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, flow, makeAutoObservable } from 'mobx'

import { last } from '@src/lib'
import type ById from '@src/lib/ById'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type { ActivityDecodableReaction } from '@src/service/model/reactions/ActivityReaction'
import ActivityReaction, {
  isActivityReaction,
} from '@src/service/model/reactions/ActivityReaction'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type { Conversation } from './conversation-store'
import { ActivityModel } from './conversation-store'
import type { CodableMessageMedia } from './model'
import { Comment } from './model'
import type { PageInfo } from './transport/lib/Paginated'
import type { CommentUpdateMessage, CommentDeleteMessage } from './transport/websocket'
import type { ActivityRepository } from './worker/repository'

export default class ActivityStore {
  readonly collection: PersistedCollection<ActivityModel, ActivityRepository>

  private lastFetchedAt: { [key: string]: number } = {}

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table('activity'),
      classConstructor: () => new ActivityModel(this.root),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'lastFetchedAt' | 'pageInfos'>(this, 'ActivityStore', {
      lastFetchedAt: root.storage.async(),
      pageInfos: root.storage.async(),
    })

    this.handleWebsocket()
    this.buildRelationships()
  }

  get(id: string) {
    return this.collection.get(id)
  }

  /**
   * Tries to get an activity from memory first, then the underlying storage
   */
  getById = async (id: string): Promise<ActivityModel | null> => {
    let activity = this.collection.get(id, { skipStorage: true })

    if (activity) {
      return activity
    }

    activity = (await this.collection.performQuery((repo) => repo.get(id))) ?? null

    if (activity) {
      return activity
    }

    return null
  }

  sendRaw(params: {
    to: string
    directNumberId?: string
    phoneNumberId?: string
    body: string
    media?: CodableMessageMedia[]
  }) {
    return this.root.transport.communication.activities.send(params)
  }

  loadByIds(ids: string[]) {
    ids = ids.filter((id) => !this.collection.isInMemory(id))
    return this.collection.performQuery((repo) => repo.getBulk(ids))
  }

  send(activity: ActivityModel) {
    return this.root.transport.communication.activities
      .send({
        body: activity.body,
        media: activity.media,
        id: activity.id,
        conversationId: activity.conversation?.id,
        phoneNumberId: activity.conversation?.phoneNumberId,
        directNumberId: activity.conversation?.directNumberId,
        to: activity.conversation?.phoneNumber,
      })
      .then(
        action(({ conversation: conversationJson, activity: activityJson }) => {
          this.root.conversation.collection.load(conversationJson)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
          return this.collection.load(activityJson)[0]
        }),
      )
  }

  /**
   * Loads cached activities for a given conversation. The results are paginated.
   * @param conversation
   * @param beforeId
   */
  load = (conversation: Conversation, beforeId?: string, searchActivityId?: string) => {
    this.collection.performQuery((repo) =>
      repo.forConversation(conversation.id, beforeId, searchActivityId),
    )
  }

  addReaction(reaction: ActivityReaction) {
    this.collection.put(reaction.activity)
    return this.root.transport.communication.reactions.post(reaction.toJSON())
  }

  deleteReaction(reaction: ActivityReaction) {
    this.collection.put(reaction.activity)
    return this.root.transport.communication.reactions.delete(reaction.id)
  }

  addComment(comment: Comment) {
    this.collection.put(comment.activity)
    return this.root.transport.communication.comments.post(comment.toJSON())
  }

  deleteComment(comment: Comment) {
    this.collection.put(comment.activity)
    return this.root.transport.communication.comments.delete(comment.id)
  }

  resolve(activity: ActivityModel) {
    return this.root.transport.communication.activities.resolve(activity.id)
  }

  unresolve(activity: ActivityModel) {
    return this.root.transport.communication.activities.unresolve(activity.id)
  }

  /**
   * Fetches from the API, changes to activities since a certain time
   * @param conversation
   * @returns
   */
  fetchRecent = async (conversation: Conversation) => {
    const self = this
    const lastFetchedAt = this.lastFetchedAt[conversation.id]
    const since = lastFetchedAt ? new Date(lastFetchedAt) : undefined
    if (conversation.isNew) return
    return this.root.transport.communication.activities
      .list({ id: conversation.id, since })
      .then(
        flow(function* (res) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const activities = yield self.collection.load(res.result)
          self.lastFetchedAt[conversation.id] = Math.max(
            lastFetchedAt || 0,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            ...activities.map((c) => c.updatedAt),
          )
          if (!since) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
            self.savePreviousPage(res.pageInfo, activities)
          }
        }),
      )
  }

  fetchAround = (conversationId: string, activityId: string) => {
    const self = this
    return this.root.transport.communication.activities
      .list({
        id: conversationId,
        before: activityId,
        last: 100,
        next: 100,
        inclusive: true,
      })
      .then(
        flow(function* (res) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const activities = yield self.collection.load(res.result)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          self.saveNextPage(res.pageInfo, activities)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          self.savePreviousPage(res.pageInfo, activities)
        }),
      )
  }

  fetchBefore = (activity: ActivityModel) => {
    const self = this
    return this.root.transport.communication.activities
      .list({ id: activity.conversationId, before: activity.beforeId, last: 200 })
      .then(
        flow(function* (res) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const activities = yield self.collection.load(res.result)
          if (activity.type == 'loading') {
            self.collection.delete(activity)
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          self.savePreviousPage(res.pageInfo, activities)
        }),
      )
  }

  fetchAfter = (activity: ActivityModel) => {
    const self = this
    return this.root.transport.communication.activities
      .list({ id: activity.conversationId, before: activity.afterId, next: 200 })
      .then(
        flow(function* (res) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const activities = yield self.collection.load(res.result)
          if (activity.type == 'loading') {
            self.collection.delete(activity)
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          self.saveNextPage(res.pageInfo, activities)
        }),
      )
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'reaction-update':
          if (isActivityReaction(msg.reaction)) {
            return this.handleReactionUpdate(msg.reaction)
          }
          break
        case 'reaction-delete':
          if (isActivityReaction(msg.reaction)) {
            return this.handleReactionDelete(msg.reaction)
          }
          break
        case 'comment-update':
          return this.handleCommentUpdate(msg)
        case 'comment-delete':
          return this.handleCommentDelete(msg)
      }
    })
  }

  private handleReactionUpdate(value: ActivityDecodableReaction) {
    const activity = this.collection.get(value.activityId)
    if (!activity) return
    const object = value.commentId
      ? activity.comments.find((comment) => comment.id === value.commentId)
      : activity
    if (!object) return
    const reaction = object.reactions.find((reaction) => reaction.id == value.id)
    if (reaction) {
      reaction.deserialize(value)
    } else {
      object.reactions.push(new ActivityReaction(this.root, object, value))
    }
  }

  private handleReactionDelete(value: ActivityDecodableReaction) {
    const activity = this.collection.get(value.activityId)
    if (!activity) return
    const reaction = activity.reactions.find((reaction) => reaction.id == value.id)
    if (reaction) activity.deleteReaction(reaction)
  }

  private handleCommentUpdate(msg: CommentUpdateMessage) {
    const activity = this.collection.get(msg.comment.activityId ?? null)
    if (!activity) return
    const comment = activity.comments.find((comment) => comment.id == msg.comment.id)
    if (comment) {
      comment.deserialize(msg.comment)
    } else {
      activity.comments.push(new Comment(this.root, activity, msg.comment))
    }
  }

  private handleCommentDelete(msg: CommentDeleteMessage) {
    const activity = this.collection.get(msg.comment.activityId ?? null)
    if (!activity) return
    const comment = activity.comments.find((comment) => comment.id == msg.comment.id)
    if (comment) activity.deleteComment(comment)
  }

  private buildRelationships() {
    this.collection.observe(
      action(({ type, objects }) => {
        const byConversation: ById<ActivityModel[]> = objects.reduce(
          (final, activity) => {
            if (!activity.conversationId) {
              return final
            }

            final[activity.conversationId] ??= []
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
            final[activity.conversationId].push(activity)
            return final
          },
          {},
        )

        if (type === 'put') {
          Object.keys(byConversation).forEach((conversationId) => {
            const conversation = this.root.conversation.collection.get(conversationId)
            const activities = byConversation[conversationId]
            activities.forEach((activity) => {
              if (activity.conversation?.id !== activity.conversationId) {
                activity.conversation?.activities.delete(activity.id)
                activity.conversation = conversation
              }
            })
            conversation?.activities.putBulk(activities)
          })
        } else {
          Object.keys(byConversation).forEach((conversationId) => {
            const conversation = this.root.conversation.collection.get(conversationId)
            const activities = byConversation[conversationId]
            conversation?.activities.deleteBulk(activities)
          })
        }
      }),
    )
  }

  private saveNextPage(pageInfo: PageInfo, activities: ActivityModel[]) {
    if (pageInfo.hasNextPage) {
      const lastItem = last(
        activities.sort((a, b) => Number(a.createdAt) - Number(b.createdAt)),
      )

      if (!lastItem) {
        return
      }

      this.collection.load(
        new ActivityModel(this.root, {
          id: `${lastItem.id}-after`,
          type: 'loading',
          conversationId: lastItem.conversationId,
          createdAt: Number(lastItem.createdAt) + 1,
          afterId: lastItem.id,
        }),
      )
    }
  }

  private savePreviousPage(pageInfo: PageInfo, activities: ActivityModel[]) {
    if (pageInfo.hasPreviousPage) {
      const firstItem = activities.sort(
        (a, b) => Number(a.createdAt) - Number(b.createdAt),
      )[0]
      this.collection.load(
        new ActivityModel(this.root, {
          id: `${firstItem.id}-before`,
          type: 'loading',
          conversationId: firstItem.conversationId,
          createdAt: Number(firstItem.createdAt) - 1,
          beforeId: firstItem.id,
        }),
      )
    }
  }
}
