/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { isValidEmail } from '@openphone/lib/email'
import { action, makeAutoObservable, reaction } from 'mobx'
import React from 'react'

import type AppStore from '@src/app/AppStore'
import type IUiStore from '@src/app/IUiStore'
import config from '@src/config'
import { NotFoundError } from '@src/lib/api/errorHandler'
import { DisposeBag } from '@src/lib/dispose'

import { googleLogin } from './google-login'

type LoginStep = 'login' | 'signup' | 'code' | 'password' | 'forgot'
type LoadingStatus =
  | 'none'
  | 'sending_code'
  | 'sending_reset_password'
  | 'authenticating_with_google'
  | 'logging_in'

export class LoginUiStore implements IUiStore {
  email: string | null = null
  step: LoginStep | null = null
  loading: LoadingStatus = 'none'
  invite?: { email: string; name: string; role: string }
  referralCode?: string
  inviteCode?: string

  readonly inputRef: React.MutableRefObject<HTMLInputElement | null> = React.createRef()

  private disposeBag = new DisposeBag()

  constructor(private app: AppStore) {
    // FIXME: make LoginUiStore a permanent instance in AppStore
    this.app.login = this

    makeAutoObservable(this, {})

    this.fetchInvite()
    this.fetchCoupon()
    this.handleReferralCode()
    this.handlePrepopulatedEmail()

    this.disposeBag.add(
      reaction(
        () => this.step,
        (step) => {
          switch (step) {
            case 'login':
              return this.app.history.push(`/login`)
            case 'signup': {
              const path = this.app.history.location.pathname
              const newPath =
                path.startsWith('/signup') || path.startsWith('/coupon')
                  ? path
                  : '/signup'
              return this.app.history.push(newPath)
            }
            case 'code':
              return this.mode === 'login'
                ? this.app.history.push('/login/code')
                : this.app.history.push('/signup/code')
            case 'password':
              return this.app.history.push('/login/password')
            case 'forgot':
              return this.app.history.push('/login/forgot')
          }
        },
      ),
    )

    /**
     * Set the initial step
     */
    this.step = this.mode
  }

  get mode() {
    const path = this.app.history.location.pathname
    return path.startsWith('/login') ? 'login' : 'signup'
  }

  get coupon() {
    return this.app.service.billing.coupon
  }

  get isAlertShown(): boolean {
    return this.app.alert.open
  }

  setInitialStep = () => {
    this.step = this.mode
  }

  toggleMode = () => {
    if (this.mode === 'login') {
      this.step = 'signup'
    } else {
      this.step = 'login'
    }
  }

  setStep = (step: LoginStep) => {
    this.step = step
  }

  continueToCode = (email: string) => {
    if (!isValidEmail(email)) {
      return this.app.toast.showError(new Error('Email address is not valid.'))
    }

    this.loading = 'sending_code'
    this.email = email
    const operation =
      this.step === 'login'
        ? this.app.service.auth.startPasswordlessSignin(email.toLowerCase())
        : this.app.service.auth.startPasswordlessRegister(email.toLowerCase())

    operation
      .then(action(() => (this.step = 'code')))
      .catch((error) => {
        if (error instanceof NotFoundError) {
          this.app.showAlert({
            title: 'Email not found',
            body: `The email you entered (${email}) does not belong to an existing user. Would you like to create a new account instead?`,
            actions: [
              {
                title: 'Create a new account',
                type: 'primary',
                onClick: action(() => {
                  this.toggleMode()
                  this.continueToCode(email)
                }),
              },
              {
                title: 'Try another email',
              },
            ],
          })
        } else {
          this.app.toast.showError(error)
        }
      })
      .finally(this.stopLoading)
  }

  continueToPassword = (email: string) => {
    this.email = email
    this.step = 'password'
  }

  loginWithCode = (code: string, recaptcha_token?: string) => {
    if (!this.email) {
      this.step = this.mode
      return
    }
    this.loading = 'logging_in'
    const operation =
      this.mode === 'login'
        ? this.app.service.auth
            .verifyPasswordlessSignin(
              this.email.toLowerCase(),
              code,
              this.inviteCode,
              recaptcha_token,
            )
            .then(() => this.app.service.analytics.login('passwordless'))
        : this.app.service.auth
            .verifyPasswordlessRegister(
              this.email.toLowerCase(),
              code,
              this.inviteCode,
              this.referralCode,
              recaptcha_token,
            )
            .then((res) => {
              this.app.service.analytics.signup('passwordless')
              return res
            })
    operation.catch(this.app.toast.showError).finally(this.stopLoading)
  }

  loginWithPassword = (email: string, password: string, recaptcha_token?: string) => {
    this.loading = 'logging_in'
    this.app.service.auth
      .passwordSignin(email, password, this.inviteCode, recaptcha_token)
      .then(() => this.app.service.analytics.login('password'))
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  showGoogleOneTap = () => {
    google.accounts.id.prompt((notification) => {
      if (notification.isDisplayed()) {
        // The One Tap modal uses an iframe which takes focus from the main window.
        // This is a hack that adds a listener for when the window blurs, just to
        // prevent the window blur and put the focus back on the Email input.
        const onBlur = (event: FocusEvent) => {
          event.preventDefault()
          window.removeEventListener('blur', onBlur)
          setTimeout(() => this.inputRef.current?.focus())
        }
        window.addEventListener('blur', onBlur)
      }
    })
  }

  hideGoogleOneTap = () => {
    google.accounts.id.cancel()
  }

  initializeGoogleOneTap = () => {
    const client_id = config.GOOGLE_CLIENT_ID
    const callback = (response: google.accounts.id.CredentialResponse) => {
      this.loading = 'logging_in'
      this.accessWithGoogle(response.credential).finally(() => this.stopLoading())
    }
    google.accounts.id.initialize({
      client_id,
      callback,
      itp_support: true,
      context: this.mode === 'login' ? 'signin' : 'signup',
    })
  }

  handleGoogleAccountNotFound = () => {
    this.app.showAlert({
      title: 'Account not found',
      body: `This Google account does not belong to an existing OpenPhone user. Would you like to create a new account instead?`,
      actions: [
        {
          title: 'Create a new account',
          type: 'primary',
          onClick: action(() => {
            this.toggleMode()
            this.loginWithGoogle()
          }),
        },
        {
          title: 'Try another account',
        },
      ],
    })
  }

  accessWithGoogle = (token: string) => {
    return this.mode === 'login'
      ? this.app.service.auth
          .googleSignin(token, this.inviteCode)
          .then(() => this.app.service.analytics.login('google'))
          .catch((error) => {
            if (error instanceof NotFoundError) {
              this.handleGoogleAccountNotFound()
            } else {
              this.app.toast.showError(error)
            }
          })
      : this.app.service.auth
          .googleRegister(token, this.inviteCode)
          .then((res) => {
            this.app.service.analytics.signup('google')
            return res
          })
          .catch((error) => this.app.toast.showError(error))
  }

  loginWithGoogle = () => {
    if (this.app.isElectron) {
      this.app.electron?.app.openExternal?.(`${window.location.origin}/native/login`)
    } else {
      this.loading = 'authenticating_with_google'
      googleLogin()
        .then(async (auth): Promise<any> => {
          if (auth) {
            this.loading = 'logging_in'
            return this.accessWithGoogle(auth.accessToken)
            // return operation.then(() => this.app.service.contact.googleSync(auth.code))
          } else {
            return Promise.resolve()
          }
        })
        .catch((error) => {
          if (error instanceof NotFoundError) {
            this.handleGoogleAccountNotFound()
          } else {
            this.app.toast.showError(error)
          }
        })
        .finally(this.stopLoading)
    }
  }

  resetPassword = (email: string) => {
    this.loading = 'sending_reset_password'
    this.app.service.auth
      .forgotPassword(email)
      .then(() => {
        this.app.showAlert({
          title: 'Email sent',
          body: 'Check your email for instructions on how to reset your password.',
        })
        this.setStep('password')
      })
      .catch(this.app.toast.showError)
      .finally(this.stopLoading)
  }

  tearDown() {
    this.disposeBag.dispose()
    // FIXME: make LoginUiStore a permanent instance in AppStore
    this.app.login = undefined
  }

  stopLoading = () => {
    this.loading = 'none'
  }

  private fetchInvite = () => {
    this.inviteCode = this.app.history.query.invite
    if (!this.inviteCode) return
    this.app.service.user
      .fetchInviteByToken(this.inviteCode)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      .then(action((invite) => (this.invite = invite)))
  }

  private fetchCoupon = () => {
    const parts = this.app.history.pathComponents
    if (parts[0] !== 'coupon') return
    this.app.service.billing.fetchCoupon(parts[1])
  }

  private handleReferralCode = () => {
    if (this.app.history.query.referral_code) {
      this.referralCode = this.app.history.query.referral_code
    }
  }

  private handlePrepopulatedEmail = () => {
    if (this.app.history.query.email) {
      this.email = this.app.history.query.email
    }
  }
}
