import { makeStyles } from '@material-ui/core/styles'

import { fonts, typography } from '@src/theme'
import './global.css'

export const useGlobalStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      fontFamily: fonts.default,
      minWidth: 0,
      wordBreak: 'break-word',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    body: {
      ...typography.body,
      color: theme.palette.op.text.primary,
      background: theme.palette.op.background.primary,
      lineHeight: 'normal',
      margin: 0,
      padding: 0,
      overflow: 'auto',
      userSelect: 'none',
      minWidth: 'max-content',

      '@media (max-width: 1500px)': {
        fontSize: 15,
      },
    },

    'h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },

    label: {
      cursor: 'pointer',
    },

    button: {
      userSelect: 'none',
    },

    a: {
      color: theme.palette.op.text.primary,
    },

    input: {
      color: theme.palette.op.text.primary,
    },

    '::placeholder': {
      color: theme.palette.op.text.placeholder,
    },

    '::-webkit-input-placeholder': {
      color: theme.palette.op.text.placeholder,
    },

    ':-moz-placeholder': {
      color: theme.palette.op.text.placeholder,
      opacity: 1,
    },

    '::-moz-placeholder': {
      color: theme.palette.op.text.placeholder,
      opacity: 1,
    },

    ':-ms-input-placeholder': {
      color: theme.palette.op.text.placeholder,
    },

    '::-ms-input-placeholder': {
      color: theme.palette.op.text.placeholder,
    },

    // These two are here to detect select all
    '.extent': {
      position: 'fixed',
      opacity: 0,

      /* to catch select-all */
      userSelect: 'auto',
    },

    '.extent::after': {
      content: '"\\200b"',
    },

    "input[type='time']::-webkit-calendar-picker-indicator": {
      display: 'none',
    },

    'input, textarea, [contenteditable]': {
      userSelect: 'auto',
    },

    // styles for Google Places Autocomplete suggestion box
    '.pac-container': {
      zIndex: 1300,
      marginTop: 8,
      backgroundColor: theme.palette.op.background.deepPopover,
      boxShadow: theme.palette.op.shadow.popup,
      borderTop: 'none',
      borderRadius: 5,
    },
    '.pac-item': {
      color: theme.palette.text.secondary,
      borderTop: 'none',
      lineHeight: '40px',
      padding: '0 1rem',

      '&:hover, &.pac-item-selected': {
        backgroundColor: theme.palette.op.background.highlight(0.07),
      },
    },
    '.pac-item-query': {
      color: theme.palette.text.primary,
      paddingRight: 10,
    },
    '.pac-icon': {
      display: 'none',
    },
    '.pac-logo:after': {
      display: 'none',
    },
  },
}))

export const GlobalStyles = () => {
  useGlobalStyles()
  return null
}
