import axios from 'axios'

export class BaseError<T = unknown> extends Error {
  data: T | null = null

  constructor(message: string, data?: T) {
    super(message)

    this.data = data ?? null
  }

  get code(): string {
    if (isDataWithCode(this.data)) {
      return this.data.code
    }

    return 'UNKNOWN'
  }
}
export class ValidationError extends BaseError {}
export class BadRequestError extends BaseError {}
export class AuthenticationError extends BaseError {}
export class ForbiddenError extends BaseError {}
export class UpgradeRequiredError extends BaseError {}
export class NotFoundError extends BaseError {}
export class ConnectionError extends BaseError {}
export class UnknownError extends BaseError {}
export class TooManyRequestsError extends BaseError {}
export class MaintenanceError extends BaseError {}

export function convertError(error: any): Error {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  const status = error.response ? error.response.status : error.statusCode
  if (status) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    const data = error.response ? error.response.data : error
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    switch (status) {
      case 400:
        return new BadRequestError(errorMessageFromJSON(data), data)
      case 401:
        return new AuthenticationError(errorMessageFromJSON(data), data)
      case 403:
        return new ForbiddenError(errorMessageFromJSON(data), data)
      case 404:
        return new NotFoundError(errorMessageFromJSON(data), data)
      case 426:
        return new UpgradeRequiredError(errorMessageFromJSON(data), data)
      case 429:
        return new TooManyRequestsError(errorMessageFromJSON(data), data)
      case 503:
        return new MaintenanceError(errorMessageFromJSON(data), data)
      default:
        return new UnknownError(errorMessageFromJSON(data), data)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return new ConnectionError('Could not establish a connection. Try again momentarily.')
  } else {
    // Something happened in setting up the request that triggered an Error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    return new ConnectionError(error.message)
  }
}

export default function errorHandler(error: any) {
  if (error instanceof axios.Cancel) {
    console.warn(`Request canceled: "${error.message}"`)
  } else {
    throw convertError(error)
  }
}

export function convertWorkerError(className: string, error: Error) {
  let err: Error
  switch (className) {
    case 'AuthenticationError':
      err = new AuthenticationError(error.message)
      break
    case 'BadRequestError':
      err = new BadRequestError(error.message)
      break
    case 'ForbiddenError':
      err = new ForbiddenError(error.message)
      break
    case 'NotFoundError':
      err = new NotFoundError(error.message)
      break
    case 'ConnectionError':
      err = new ConnectionError(error.message)
      break
    case 'UnknownError':
      err = new UnknownError(error.message)
      break
    default:
      err = new Error(error.message)
      break
  }
  err.stack = error.stack
  err.name = error.name
  err.message = error.message
  return err
}

export function errorTitle(error: Error): string | null {
  if (error instanceof BadRequestError) {
    return 'Invalid request'
  } else if (error instanceof AuthenticationError) {
    return 'Login failed'
  } else if (error instanceof ConnectionError) {
    return 'Network issue'
  } else if (error instanceof ForbiddenError) {
    return 'Access denied'
  } else if (error instanceof NotFoundError) {
    return 'Not found'
  } else if (error instanceof TooManyRequestsError) {
    return 'Too many requests'
  } else if (error instanceof UnknownError) {
    return 'Hmmm...'
  }
  return null
}

function errorMessageFromJSON(json: any): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    json.message ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    json.description ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    json.error_description ||
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    (json.error && json.error.message) ||
    'Unknown error. Please try again.'
  )
}

function isDataWithCode(data: any): data is { code: string } {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return !!data && typeof data === 'object' && typeof data.code === 'string'
}
