/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import isTruthy from '@src/lib/isTruthy'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { Contact, Identity, Member } from './model'
import SearchQuery from './model/SearchQuery'
import type MainWorker from './worker/main'
import type SearchRepository from './worker/repository/SearchRepository'

export interface SearchResult<I extends Identity = Identity> {
  identity: I
  type: 'contact' | 'member'
  matchedPhoneNumber?: string
}

export default class SearchStore {
  collection: PersistedCollection<SearchQuery, SearchRepository> | null = null

  constructor(private root: Service, private worker: MainWorker) {
    this.worker.service.search.hydrate()
    this.collection = new PersistedCollection({
      table: this.root.storage.table('search'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (searchQuery) => new SearchQuery(this.root, searchQuery),
    })
  }

  async identities(
    query: string,
    limit: number,
    type: 'members',
  ): Promise<SearchResult<Member>[]>
  async identities(
    query: string,
    limit: number,
    type: 'contacts',
  ): Promise<SearchResult<Contact>[]>
  async identities(
    query: string,
    limit?: number,
    type?: 'all',
  ): Promise<SearchResult<Contact | Member>[]>
  async identities(
    query: string,
    limit = 20,
    type: 'all' | 'members' | 'contacts' = 'all',
  ): Promise<SearchResult<Contact | Member>[]> {
    const result = await this.worker.service.search.identities(query, limit, type)
    const contacts = result.filter((r) => r.t === 'contact').map((r) => r.i)
    const members = result.filter((r) => r.t === 'member').map((r) => r.i)
    await Promise.all([
      this.root.contact.collection.load(contacts),
      this.root.member.collection.load(members),
    ])
    return result
      .map((r) => {
        const identity =
          r.t === 'contact'
            ? this.root.contact.collection.get(r.i.id)
            : this.root.member.collection.get(r.i.id)

        if (!identity) {
          return null
        }

        return {
          matchedPhoneNumber: r.m,
          type: r.t,
          identity,
        }
      })
      .filter(isTruthy)
  }

  companies(query: string, limit = 10): Promise<string[]> {
    return this.worker.service.search.companies(query, limit)
  }

  fetchRecents = () => {
    return this.collection?.performQuery((repo) => repo.all())
  }

  saveRecent = (recent: SearchQuery) => {
    /*
     * Delete elements so that the collection is always of 10 or less elements
     */
    this.root.search.collection?.deleteBulk(this.root.search.collection.list.slice(9))
    this.root.search.collection?.put(recent)
  }

  deleteRecent = (recent: SearchQuery) => {
    this.collection?.delete(recent)
  }
}
