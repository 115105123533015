import type { Emoji as EmojiData } from 'emoji-datasource'
import emojidata from 'emoji-datasource'
import emojiRegex from 'emoji-regex'
import fuzzysort from 'fuzzysort'

import { logError } from '@src/lib/log'
import ImmutableCollection from '@src/service/collections/ImmutableCollection'

import { memoize } from './fn'

export interface Char {
  char: string
  unified: string
}

export interface Emoji extends Char {
  shortName: string
  keywords: string
  category: EmojiCategory
  skinVariations: { [key: string]: Char } | null
  weight: number
}

export interface SkinVariation extends Char {
  name: string
}

export const emojiSkinVariations: SkinVariation[] = [
  { char: '🏻', unified: '1F3FB', name: 'Light Skin Tone' },
  { char: '🏼', unified: '1F3FC', name: 'Medium-Light Skin Tone' },
  { char: '🏽', unified: '1F3FD', name: 'Medium Skin Tone' },
  { char: '🏾', unified: '1F3FE', name: 'Medium-Dark Skin Tone' },
  { char: '🏿', unified: '1F3FF', name: 'Dark Skin Tone' },
]

const skinVariationRegex = new RegExp(
  emojiSkinVariations.map(({ char }) => char).join('|'),
  'g',
)

export const getEmojis = memoize(
  () =>
    new ImmutableCollection<Emoji>(emojidata.map(convertEmoji), {
      idKey: 'char',
      filter: (emoji) => Boolean(emoji.category),
      compare: (a, b) => a.weight - b.weight,
    }),
)

export const getRandomEmoji = (): Emoji => {
  return convertEmoji(emojidata[Math.floor(Math.random() * emojidata.length)])
}

export function getEmojiVariation(emoji: string, skinVariation: string): string {
  const char = getEmojis().get(stripSkinVariation(emoji))

  if (!char) {
    // Return the emoji itself if not found. We may need to update the emoji-datasource package.
    logError(new Error(`Emoji not found: ${emoji}`))
    return emoji
  }

  return char.skinVariations ? char.skinVariations[skinVariation].char : char.char
}

export function stripSkinVariation(body: string): string {
  return body.replace(skinVariationRegex, '')
}

export function searchEmoji(input: string, limit = 5): Promise<Emoji[]> {
  return fuzzysort
    .goAsync(input, getEmojis().list, {
      limit,
      threshold: -Infinity,
      allowTypo: false,
      keys: ['shortName', 'keywords'],
    })
    .then((result) => result.map((r) => r.obj))
}

export function isEmojiOnlyString(input: string) {
  const regex = emojiRegex()
  const str = input.replace(/ /gi, '')
  let match: RegExpExecArray | null
  let currentIndex = 0
  while ((match = regex.exec(str))) {
    const emoji = match[0]
    if (currentIndex !== match.index) {
      return false
    }
    currentIndex += emoji.length
  }

  return currentIndex === input.length
}

function convertEmoji(data: EmojiData): Emoji {
  const char = decodeUnified(data.unified)

  const dataSkinVariations = data.skin_variations

  const skinVariations =
    dataSkinVariations &&
    emojiSkinVariations.every((variation) => variation.unified in dataSkinVariations)
      ? Object.fromEntries(
          emojiSkinVariations.map((variation): [string, Char] => {
            const unified = dataSkinVariations[variation.unified].unified

            return [
              variation.char,
              {
                char: decodeUnified(unified),
                unified,
              },
            ]
          }),
        )
      : null

  const keywords = [
    char,
    ...data.short_names,
    ...data.name.toLowerCase().split(' '),
  ].join(' ')

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  const [category, weight] = getCategoryAndWeight(data)

  return {
    char,
    unified: data.unified,
    shortName: data.short_name,
    keywords,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    category,
    skinVariations,
    weight,
  }
}

export const emojiCategories = [
  'Smileys & People',
  'Animals & Nature',
  'Food & Drink',
  'Travel & Places',
  'Activities',
  'Objects',
  'Symbols',
  'Flags',
  null, // These are emojis that are not in the emoji-datasource package. Won't be displayed in the emoji picker.
] as const

export type EmojiCategory = typeof emojiCategories[number]

/**
 * Expected categories from emoji-datasource:
 * - 'Smileys & Emotion'
 * - 'Objects'
 * - 'People & Body'
 * - 'Animals & Nature'
 * - 'Skin Tones'
 * - 'Symbols'
 * - 'Flags'
 * - 'Activities'
 * - 'Food & Drink'
 * - 'Travel & Places'
 */
function getCategoryAndWeight(data: EmojiData): [EmojiCategory, number] {
  const { category } = data

  switch (category) {
    case 'Smileys & Emotion':
      return ['Smileys & People', data.sort_order]
    case 'People & Body':
      return ['Smileys & People', 5000 + data.sort_order]
    case 'Animals & Nature':
      return [category, 10000 + data.sort_order]
    case 'Food & Drink':
      return [category, 15000 + data.sort_order]
    case 'Travel & Places':
      return [category, 20000 + data.sort_order]
    case 'Activities':
      return [category, 25000 + data.sort_order]
    case 'Objects':
      return [category, 30000 + data.sort_order]
    case 'Symbols':
      return [category, 35000 + data.sort_order]
    case 'Flags':
      return [category, 40000 + data.sort_order]
    default:
      return [null, -1]
  }
}

function decodeUnified(unified: string): string {
  return String.fromCodePoint(...unified.split('-').map((cp) => parseInt(cp, 16)))
}

export const getEmojiChar = (emoji: Emoji, modifier?: string): Char => {
  if (!modifier) {
    return emoji
  }

  return emoji.skinVariations?.[modifier] ?? emoji
}
