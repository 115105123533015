/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import type { Theme } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles'

import { typography } from '@src/theme'
import type CommonTextInputProps from '@ui/common/CommonTextInputProps'

export const commonTextInputStyles = (theme: Theme) => ({
  root: ({
    size,
    fullWidth,
    variant,
    hasError,
  }: {
    size?: number
    fullWidth?: boolean
    variant?: CommonTextInputProps['variant']
    hasError?: boolean
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
  }) => ({
    ...(size && size > 40 ? typography.body : typography.footnote),
    background: 'transparent',
    border:
      variant === 'outlined'
        ? hasError
          ? `1.5px solid ${theme.palette.op.secondary.red2}`
          : `1.5px solid ${alpha(theme.palette.op.gray[1], 0.2)}`
        : 'inherit',
    borderRadius: 5,
    color: theme.palette.op.text.primary,
    outline: 'none',
    transition: theme.transitions.create(['border', 'box-shadow'], {
      easing: 'ease',
      duration: 240,
    }),
    width: fullWidth ? '100%' : 'auto',

    '&::placeholder': {
      color: alpha(theme.palette.op.text.primary, 0.4),
    },

    '&:disabled': {
      color: theme.palette.op.text.tertiary,
    },

    '&:hover:not(:disabled):not(:focus)': {
      border:
        variant === 'outlined'
          ? hasError
            ? `1.5px solid ${theme.palette.op.secondary.red2}`
            : `1.5px solid ${alpha(theme.palette.op.gray[1], 0.4)}`
          : 'inherit',
    },

    '&:focus': {
      border:
        variant === 'outlined'
          ? hasError
            ? `1.5px solid ${theme.palette.op.secondary.red2}`
            : `1.5px solid ${theme.palette.op.primary[2]}`
          : 'inherit',
      boxShadow:
        variant === 'outlined'
          ? hasError
            ? `0 0 0 4px ${alpha(theme.palette.op.secondary.red2, 0.18)}`
            : `0 0 0 4px ${alpha(theme.palette.op.primary[2], 0.18)}`
          : 'inherit',
    },
  }),
})
