import { action, makeObservable } from 'mobx'

import { parseDate } from '@src/lib'
import type Service from '@src/service'
import type { ContactNote } from '@src/service/transport/contacts'

import type { BaseCodableReaction } from './BaseReaction'
import BaseReaction from './BaseReaction'

export interface ContactNoteCodableReaction extends BaseCodableReaction {
  contactId: string | null
  noteId: string | null
}

export function isContactNoteReaction(reaction: any): reaction is ContactNoteReaction {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return reaction.id && reaction.contactId && reaction.noteId
}

class ContactNoteReaction extends BaseReaction implements ContactNoteCodableReaction {
  contactId: string | null = null
  noteId: string | null = null

  constructor(
    root: Service,
    readonly object: ContactNote,
    attrs: Partial<ContactNoteCodableReaction>,
  ) {
    super(root)

    this.deserialize(attrs)
    makeObservable(this, {
      deserialize: action.bound,
      serialize: action.bound,
      toJSON: action.bound,
    })
  }

  deserialize(json?: Partial<ContactNoteCodableReaction>) {
    if (json) {
      const { createdAt, updatedAt, ...attrs } = json
      Object.assign(this, attrs)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion -- FIXME: Fix this ESLint violation!
      this.createdAt = parseDate(createdAt || this.createdAt) as number
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion -- FIXME: Fix this ESLint violation!
      this.updatedAt = parseDate(updatedAt || this.updatedAt) as number
    }
    return this
  }

  serialize(): ContactNoteCodableReaction {
    return {
      id: this.id,
      body: this.body,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
      noteId: this.noteId,
      contactId: this.contactId,
    }
  }

  toJSON(): ContactNoteCodableReaction {
    return this.serialize()
  }
}

export default ContactNoteReaction
