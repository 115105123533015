import config from '@src/config'
import { formatDate } from '@src/lib'
import Deferred from '@src/lib/Deferred'
import type Service from '@src/service'
import type { IdentifyData } from '@src/service/analytics'
import type { OrganizationAnalytics } from '@src/service/model'

export const PENDO_INSTALLATION_SCRIPT_ID = 'pendo'
export const PENDO_SCRIPT_ID = 'pendo-script'

interface PendoData {
  visitor: {
    id: string
    email: string
    full_name: string
    role: string
    signup_date: string
    first_name: string
    last_name: string
    theme_mode: string
    user_email_domain: string
  }
  account: {
    id: string
    name: string
    is_paying: boolean
    plan: string
    creation_date: string
    size: string
    onboarding_industry?: OrganizationAnalytics['industry']
    onboarding_size?: OrganizationAnalytics['teamSize']
    onboarding_use_case?: OrganizationAnalytics['useCase']
    onboarding_teams?: OrganizationAnalytics['teams']
    onboarding_need?: OrganizationAnalytics['need']
    onboarding_users?: OrganizationAnalytics['users']
    subscription_start_date: string
    subscription_end_date: string
    has_auto_charge: boolean
    clearbit_industry: string
    clearbit_subindustry: string
    clearbit_employees: string
  }
}

export default class Pendo {
  private scriptLoader: Deferred<boolean> = new Deferred()

  constructor(private root: Service) {
    this.installPendo()
  }

  installPendo() {
    if (this.scriptLoader.state === 'fulfilled') {
      return
    }
    const installationScript = document.createElement('script')
    installationScript.innerText = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.id='${PENDO_SCRIPT_ID}';y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('${config.PENDO_SUBSCRIPTION_KEY}');`
    installationScript.id = PENDO_INSTALLATION_SCRIPT_ID
    document.head.appendChild(installationScript)
    const script = document.getElementById(PENDO_SCRIPT_ID)
    if (script) {
      script.onload = () => {
        this.scriptLoader.resolve(true)
      }
      script.onerror = () => {
        this.scriptLoader.resolve(false)
      }
    }
  }

  async initialize({
    user,
    member,
    organization,
    subscription,
    themeKey,
    workspaceSize,
  }: IdentifyData) {
    if (!window.pendo) {
      return
    }

    if (!user || !organization) {
      return
    }

    const isPendoLoaded = await this.scriptLoader.promise

    if (!isPendoLoaded) {
      return false
    }

    const pendoData: PendoData = {
      visitor: {
        id: user.id,
        email: user.email ?? '',
        first_name: user.firstName ?? '',
        last_name: user.lastName ?? '',
        full_name: `${user.firstName ?? ''}${user.lastName ? ` ${user.lastName}` : ''}`,
        role: member.role ?? '',
        signup_date: user.createdAt ? formatDate(user.createdAt) : '',
        theme_mode: themeKey,
        user_email_domain: user.email?.split('@')[1] ?? '',
      },
      account: {
        id: organization.id,
        name: organization.name,
        is_paying: subscription?.isActive ?? false,
        plan: subscription?.type ?? '',
        creation_date: organization.createdAt?.toString() ?? '',
        size: workspaceSize.toString(),
        onboarding_industry: organization.analytics?.industry,
        onboarding_size: organization.analytics?.teamSize,
        onboarding_use_case: organization.analytics?.useCase,
        onboarding_teams: organization.analytics?.teams,
        onboarding_need: organization.analytics?.need,
        onboarding_users: organization.analytics?.users,
        subscription_start_date: subscription?.originalStartedAt
          ? formatDate(subscription.originalStartedAt)
          : '',
        subscription_end_date: subscription?.currentPeriodExpiresAt
          ? formatDate(subscription.currentPeriodExpiresAt)
          : '',
        has_auto_charge: subscription ? subscription.autochargeAmount > 0 : false,
        clearbit_industry: organization.analytics?.enrichment?.category?.industry ?? '',
        clearbit_subindustry:
          organization.analytics?.enrichment?.category?.subIndustry ?? '',
        clearbit_employees:
          organization.analytics?.enrichment?.metrics?.employees?.toString() ?? '',
      },
    }

    window.pendo.initialize({
      ...pendoData,
      apiKey: config.PENDO_SUBSCRIPTION_KEY,
    })
  }

  track: NonNullable<typeof window.pendo>['track'] = (...args) => {
    if (!window.pendo || !this.root.flags.getFlag('pendo')) {
      return
    }
    if (window.pendo.isReady?.()) {
      window.pendo.track(...args)
    }
  }
}
