import Cookies from 'js-cookie'

import type { InboxState } from '@src/app/inbox/conversations/store'
import config from '@src/config'
import { toCamelCase } from '@src/lib/collections'
import type Service from '@src/service'
import Pendo from '@src/service/analytics/Pendo'
import PostHog from '@src/service/analytics/PostHog'
import type {
  Member,
  Organization,
  User,
  Subscription,
  UserAnalytics,
  OrganizationAnalytics,
} from '@src/service/model'
import type { StorageThemeKey } from '@src/theme'

interface AnalyticsData {
  orgId: string | undefined
  orgAutoCharge: boolean
  orgPlan: string | undefined
  orgIndustry: string | undefined
  orgSubIndustry: string | undefined
  orgSize: string | undefined
  email: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  useCase?: OrganizationAnalytics['useCase']
  selfIndustry?: OrganizationAnalytics['industry']
  selfSize?: OrganizationAnalytics['teamSize']
  users?: OrganizationAnalytics['users']
  teams?: OrganizationAnalytics['teams']
  need?: OrganizationAnalytics['need']
  currentSize: string | undefined
  role: string | undefined
  subRole: string | undefined
}

export interface IdentifyData {
  user: User
  member: Member
  organization: Organization
  subscription: Subscription | null
  themeKey: StorageThemeKey
  workspaceSize: number
}

interface CallEventContext {
  direction: 'incoming' | 'outgoing' | null
  participants: string[] | undefined | null
}

class AnalyticsStore {
  private userId?: string
  private segmentIo: any
  private analytics?: AnalyticsData
  private pendo: Pendo
  private posthog: PostHog

  constructor(private root: Service, private desktopVersion?: string) {
    window.dataLayer ??= []
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.segmentIo = (window as any).analytics
    this.pendo = new Pendo(root)
    this.posthog = new PostHog(root, desktopVersion)
  }

  private updateAnalyticsCookie({
    analyticsData,
    savedAnalytics,
    userId,
  }: {
    analyticsData: AnalyticsData
    savedAnalytics: { [key: string]: any }
    userId: string
  }) {
    // Update the cookie if it is missing user data
    const missingAnalytics: { [key: string]: any } = {}
    for (const key in analyticsData) {
      if (!savedAnalytics[key]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        missingAnalytics[key] = analyticsData[key]
      }
    }

    if (!savedAnalytics.userId) {
      missingAnalytics.userId = userId
    }

    if (!savedAnalytics.referrer) {
      missingAnalytics.referrer = document.referrer
    }

    const isCookieMissingAnalytics = Object.keys(missingAnalytics).length > 0
    if (isCookieMissingAnalytics) {
      Cookies.set(
        'op_analytics',
        JSON.stringify({ ...savedAnalytics, ...missingAnalytics }),
      )
    }
  }

  private updateUserAnalytics({
    savedAnalytics,
    user,
    gaId,
  }: {
    savedAnalytics: { [key: string]: any }
    user: User
    gaId?: string
  }) {
    // Look for differences between our cookie data and our backend data
    const updatedAnalytics: { [key: string]: any } = {}
    for (const key in savedAnalytics) {
      if (savedAnalytics[key] && user.analytics?.[key] !== savedAnalytics[key]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        updatedAnalytics[key] = savedAnalytics[key]
      }
    }

    if (user.analytics && !user.analytics.ga) {
      updatedAnalytics.ga = gaId
    }

    // Update the backend
    const isUserMissingAnalytics = Object.keys(updatedAnalytics).length > 0
    if (isUserMissingAnalytics) {
      user.update({
        analytics: { ...user.analytics, ...updatedAnalytics } as UserAnalytics,
      })
    }
  }

  /**
   * Tracks when a user has been identified
   */
  async identify({
    user,
    member,
    organization,
    subscription,
    workspaceSize,
    themeKey,
  }: IdentifyData) {
    const analyticsData: AnalyticsData = {
      orgId: organization.id,
      orgPlan: subscription?.type ?? undefined,
      orgAutoCharge: subscription ? subscription.autochargeAmount > 0 : false,
      orgIndustry: organization.analytics?.enrichment?.category?.industry,
      orgSubIndustry: organization.analytics?.enrichment?.category?.subIndustry,
      orgSize: organization.analytics?.enrichment?.metrics?.employees?.toString(),
      email: user.email ?? undefined,
      firstName: member.firstName,
      lastName: member.lastName,
      useCase: organization.analytics?.useCase,
      selfIndustry: organization.analytics?.industry,
      selfSize: organization.analytics?.teamSize,
      users: organization.analytics?.users,
      teams: organization.analytics?.teams,
      need: organization.analytics?.need,
      currentSize: workspaceSize.toString(),
      role: user.analytics?.enrichment?.employment?.role,
      subRole: user.analytics?.enrichment?.employment?.subRole,
    }

    this.userId = user.id
    this.analytics = analyticsData

    // Obtain data that needs to be tracked

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.identify(user.id, {
      ...analyticsData,
    })

    window.dataLayer.push({
      userId: user.id,
      orgId: analyticsData.orgId,
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.identify(user.id, {
      name: `${analyticsData.firstName} ${analyticsData.lastName}`,
      email: analyticsData.email,
      orgId: analyticsData.orgId,
    })

    // Identify the user with Pendo
    await this.pendo.initialize({
      user,
      member,
      organization,
      subscription,
      themeKey,
      workspaceSize,
    })

    // Identify the user with PostHog
    await this.posthog.identify({
      user,
      member,
      organization,
      subscription,
      themeKey,
      workspaceSize,
    })

    // Get analytics cookies
    const gaId = Cookies.get('_ga')
    const analyticsCookie = Cookies.get('op_analytics')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    const savedAnalytics = analyticsCookie ? toCamelCase(JSON.parse(analyticsCookie)) : {}

    // Sync analytics cookie and user analytics
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    this.updateAnalyticsCookie({ analyticsData, savedAnalytics, userId: user.id })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    this.updateUserAnalytics({ savedAnalytics, user, gaId })
  }

  page(path: string) {
    this.googlePageView(path)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.page()
  }

  /**
   * Tracks when a user has added payment info
   */
  addedPaymentInfo() {
    window.dataLayer.push({
      event: 'payment_info_added',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('payment_info_added')

    this.pendo.track('payment_info_added')

    this.posthog.track('payment_info_added')
  }

  /**
   * Tracks when a user has updated payment info
   */
  updatedPaymentInfo() {
    window.dataLayer.push({
      event: 'payment_info_updated',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('payment_info_updated')

    this.pendo.track('payment_info_updated')
    this.posthog.track('payment_info_updated')
  }

  /**
   * Tracks when a user has invited someone
   */
  invited(emails: string[]) {
    window.dataLayer.push({
      event: 'invite',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('invite')

    this.pendo.track('invite', { emails })
    this.posthog.track('invite', { emails })
  }

  /**
   * Tracks when a user has cancelled the trial or subscription
   */
  cancellation() {
    window.dataLayer.push({
      event: 'cancellation',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('cancellation')

    this.pendo.track('cancellation')
    this.posthog.track('cancellation')
  }

  opened() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.track('App Opened')
  }

  referred() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.track('Contact Referred')
  }

  reset() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.reset()
  }

  private googlePageView(path: string) {
    window.dataLayer.push({
      event: 'pageview',
      page: path,
      title: window.document.title,
      environment: config.ENV,
      ...this.analytics,
    })
  }

  /**
   * Tracks when a user has logged in
   * @param method - Method used to log in
   */
  login(method: 'password' | 'passwordless' | 'google') {
    window.dataLayer.push({
      event: 'login',
      method,
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('login', { method })

    this.pendo.track('login', { method })
    this.posthog.track('login', { method })
  }

  /**
   * Tracks when a user has signed up
   * @param method - Method used to sign up
   */
  signup(method: 'passwordless' | 'google') {
    window.dataLayer.push({
      event: 'signup',
      method,
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('signup', { method })

    this.pendo.track('signup', { method })
    this.posthog.track('signup', { method })
  }

  /**
   * Tracks when a user has sent a message
   */
  sentMessage() {
    window.dataLayer.push({
      event: 'message_sent',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('message_sent')

    this.pendo.track('message_sent')
    this.posthog.track('message_sent')
  }

  /**
   * Tracks when an user has started a call
   */
  callStarted({ direction, participants }: CallEventContext) {
    if (direction === 'outgoing') {
      window.dataLayer.push({
        event: 'call_started',
      })

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
      window.Attribution.track('call_started')
    }

    this.pendo.track('call_started', { direction, participants })
    this.posthog.track('call_started', { direction, participants })
  }

  callEnded({ direction, participants }: CallEventContext) {
    this.pendo.track('call_ended', { direction, participants })
    this.posthog.track('call_ended', { direction, participants })
  }

  /**
   * Tracks click events
   * @param analyticsId - Describes the goal of the clickable element
   * @param redirect - Full URL (if external) or path (if internal) to which the clickable element redirects the user
   */
  click(analyticsId: string, redirect?: URL | string) {
    const url: URL | undefined =
      typeof redirect === 'string' ? new URL(redirect, window.location.origin) : redirect
    window.dataLayer.push({
      event: 'click',
      analyticsId,
      url: url?.toString(),
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('click', {
      analyticsId,
      url: url?.toString(),
    })
  }

  /**
   * Tracks when a user has created a Workspace
   */
  createdWorkspace() {
    window.dataLayer.push({
      event: 'create_workspace',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('create_workspace')

    this.pendo.track('create_workspace')
    this.posthog.track('create_workspace')
  }

  /**
   * Tracks when a user has started a trial
   */
  startedTrial() {
    window.dataLayer.push({
      event: 'start_trial',
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    window.Attribution.track('start_trial')

    this.pendo.track('start_trial')
    this.posthog.track('start_trial')
  }

  switchedToPaid() {
    this.pendo.track('switched_to_paid')
    this.posthog.track('switched_to_paid')
  }

  userAcceptedInvite(companyName?: string) {
    this.pendo.track('user_accepted_invite', { companyName })
    this.posthog.track('user_accepted_invite', { companyName })
  }

  scheduleMessageButtonHovered() {
    this.pendo.track('schedule_message_button_hovered')
    this.posthog.track('schedule_message_button_hovered')
  }

  messageScheduled(action: 'click' | 'submit') {
    this.pendo.track('message_scheduled', { action })
    this.posthog.track('message_scheduled', { action })
  }

  billingPlansViewed() {
    this.pendo.track('billing_plans_viewed')
    this.posthog.track('billing_plans_viewed')
  }

  numberCreated(type: 'member' | 'workspace') {
    this.pendo.track('number_created', { type })
    this.posthog.track('number_created', { type })
  }

  messageReceived() {
    this.pendo.track('message_received')
    this.posthog.track('message_received')
  }

  inboxFiltered(state: InboxState) {
    this.pendo.track('inbox_filtered', { state })
    this.posthog.track('inbox_filtered', { state })
  }

  contactCreated() {
    this.pendo.track('contact_created')
    this.posthog.track('contact_created')
  }

  contactUpdated() {
    this.pendo.track('contact_updated')
    this.posthog.track('contact_updated')
  }

  updateModalAction(action: 'shown' | 'deferred' | 'accepted' | 'dismissed') {
    this.pendo.track('update_modal_action', { action })
    this.posthog.track('update_modal_action', { action })
  }

  referralClicked(
    action:
      | 'settings_tab_clicked'
      | 'account_menu_tab_clicked'
      | 'side_menu_cta_clicked'
      | 'profile_cta_clicked'
      | 'open_new_window_button_clicked'
      | 'copy_link_button_clicked'
      | 'link_copied',
  ): void

  referralClicked(
    action: 'social_button_clicked',
    network: 'facebook' | 'twitter' | 'linkedin' | 'email',
  ): void

  referralClicked(action: string, network?: string): void {
    this.pendo.track('referral_clicked', { action, network })
    this.posthog.track('referral_clicked', { action, network })
  }

  conversationPinned(pinCount: number, conversationId: string, inboxId: string | null) {
    this.pendo.track('conversation_pinned', { pinCount, conversationId, inboxId })
    this.posthog.track('conversation_pinned', { pinCount, conversationId, inboxId })
  }

  conversationUnpinned(pinCount: number, conversationId: string, inboxId: string | null) {
    this.pendo.track('conversation_unpinned', { pinCount, conversationId, inboxId })
    this.posthog.track('conversation_unpinned', { pinCount, conversationId, inboxId })
  }

  conversationPinFailedLimitReached(inboxId: string | null) {
    this.pendo.track('conversation_pin_failed_limit_reached', { inboxId })
    this.posthog.track('conversation_pin_failed_limit_reached', { inboxId })
  }

  conversationRenamed() {
    this.pendo.track('conversation_renamed')
    this.posthog.track('conversation_renamed')
  }

  conversationRenameInitiated(trigger: 'header_button' | 'action' | 'double_click_name') {
    this.pendo.track('conversation_rename_initiated', { trigger })
    this.posthog.track('conversation_rename_initiated', { trigger })
  }

  helpAndSupportClicked(action: string) {
    this.pendo.track('help_and_support_clicked', { action })
    this.posthog.track('help_and_support_clicked', { action })
  }

  tearDown() {
    this.posthog.tearDown()
  }
}

export default AnalyticsStore
