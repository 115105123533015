import type { Device, TwilioError } from '@twilio/voice-sdk'
import { Call } from '@twilio/voice-sdk'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { action, makeAutoObservable, reaction, toJS } from 'mobx'

import type { PhoneNumberSelection } from '@src/component/phone-number-selector/Controller'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import ObjectID from '@src/lib/object-id'
import { toE164 } from '@src/lib/phone-number'
import uuid from '@src/lib/uuid'
import permissions from '@src/permissions'
import type Service from '@src/service'
import { createAnonymousIdentityFromNumber } from '@src/service/model'
import type { DirectNumber } from '@src/service/model/direct-number'
import { isDirectNumber } from '@src/service/model/direct-number'
import { Member } from '@src/service/model/member'
import { PhoneNumber } from '@src/service/model/phone-number'
import type {
  ParticipantTarget,
  RecordingStatus,
  RoomInvitation,
  RoomInvitationType,
} from '@src/service/transport/voice'

import ActiveCallParticipant from './ActiveCallParticipant'
import ActiveCallParticipants from './ActiveCallParticipants'
import type { RoomParticipant } from './Room'
import type Room from './Room'
import convertSelectionToVoiceTarget, {
  getLegacyTransferParams,
} from './convertSelectionToVoiceTarget'

export type ActiveCallFromType = PhoneNumber | DirectNumber

export type ActiveCallToType = {
  number: string
  userId: string | null
}[]

export type ActiveCallStatus =
  | 'incoming'
  | 'ringing'
  | 'connecting'
  | 'reconnecting'
  | 'connected'
  | 'dismissed'
  | 'none'

export interface ActiveCallData {
  id: string

  /**
   * Twilio CallSid
   *
   * May be `null` for outgoing calls until they are accepted.
   */
  sid: string | null

  room: Room

  fromParticipantId?: string | null
  fromUserId: string | null
  toParticipantId?: string | null
  toUserId: string | null
  transferrerParticipantId?: string | null

  status: ActiveCallStatus | null
  startedAt?: number | null
  invitationType?: RoomInvitationType | null
  inviteMessage?: string | null
  isListener?: boolean
  recordingStatus?: RecordingStatus | null

  /**
   * This is a legacy param that is used only by the old operator
   */
  // FIXME: this should be removed once we complete the migration to the new operator
  isRecording?: boolean
}

export default class ActiveCall {
  readonly participants: ActiveCallParticipants

  error: TwilioError.TwilioError | null = null
  isMuted = false
  roomReady: boolean
  private roomReadyTimeout: number | null = null

  protected debug: Debugger
  protected disposeBag = new DisposeBag()

  /**
   * Construct an ActiveCall from an incoming connection.
   *
   * @see https://www.notion.so/openphone/Incoming-Call-Modal-a0dd8d5838144e11b20685f8fd3d7ed6#8fc156c4d637490abae9f3d7519c7d58
   */
  static async fromIncomingCall(root: Service, call: Call) {
    const { parameters, customParameters } = call
    const roomId = root.voice.isOldOperator ? uuid() : customParameters.get('room_id')
    if (!roomId) {
      call.reject()
      logError(new Error('No roomId found'))
      return
    }
    const sid = root.voice.isOldOperator
      ? customParameters.get('session_id') ?? parameters.CallSid
      : parameters.CallSid
    const invitationType = root.voice.isOldOperator
      ? 'called'
      : (customParameters.get('invitation_type') as RoomInvitationType)
    const isListener = customParameters.get('is_listener') === 'true'
    const recordingStatus =
      customParameters.get('is_recording') === 'true' ? 'in-progress' : 'stopped'

    const fromRoomParticipant = root.voice.isOldOperator
      ? {
          id: uuid(),
          roomId,
          userId: null,
          identifier: parameters.From,
        }
      : getRoomParticipant(customParameters, roomId, 'from')
    const toRoomParticipant = root.voice.isOldOperator
      ? {
          id: uuid(),
          roomId,
          userId: parameters.To.replace('client:', ''),
          identifier: customParameters.get('to_phone_number') ?? '',
        }
      : getRoomParticipant(customParameters, roomId, 'to')
    const transferrerRoomParticipant = getRoomParticipant(
      customParameters,
      roomId,
      'transferrer',
    )

    if (!fromRoomParticipant || !toRoomParticipant) {
      call.reject()
      logError(new Error('No fromRoomParticipant or toRoomParticipant found'))
      return
    }

    return new ActiveCall(
      root,
      call,
      {
        id: sid,
        sid,
        status: 'incoming',
        room: {
          id: roomId,
          participants: [
            fromRoomParticipant,
            toRoomParticipant,
            transferrerRoomParticipant,
          ].filter(isNonNull),
        },
        inviteMessage: customParameters.get('invitation_message'),
        invitationType,
        recordingStatus,
        isListener,
        fromParticipantId: fromRoomParticipant.id,
        fromUserId: fromRoomParticipant.userId ?? null,
        toParticipantId: toRoomParticipant.id,
        toUserId: toRoomParticipant.userId ?? null,
        transferrerParticipantId: transferrerRoomParticipant?.id,
      },
      true,
    )
  }

  /**
   * Construct an ActiveCall by making an outgoing call.
   *
   * @see https://www.notion.so/openphone/Starting-a-call-794f80fa47014b6fbd0787d0d3dec20e#07d623d8658749aa9e313455e4ba55cb
   */
  static async connect(
    root: Service,
    device: Device,
    from: ActiveCallFromType,
    to: ActiveCallToType,
  ) {
    const userId = root.user.current?.id

    if (!userId) {
      return
    }

    const call = await device.connect({
      params: root.voice.isOldOperator
        ? {
            To: toE164(to[0].number),
            PhoneNumberId: from.id,
            UserId: userId,
          }
        : {
            ...{
              UserId: userId,
              RoomId: ObjectID(),
              ParticipantId: ObjectID(),
              To: convertActiveCallDestinationToString(to),
            },
            ...(from instanceof PhoneNumber
              ? {
                  PhoneNumberId: from.id,
                  PhoneNumber: from.number,
                }
              : {
                  DirectNumberId: from.id,
                  DirectNumber: from.number ?? '',
                }),
          },
    })

    return ActiveCall.fromOutgoingCall(root, call, false)
  }

  /**
   * Construct an ActiveCall by joining an ongoing call.
   *
   * @see https://www.notion.so/openphone/Starting-a-call-794f80fa47014b6fbd0787d0d3dec20e#07d623d8658749aa9e313455e4ba55cb
   */
  static async join(
    root: Service,
    device: Device,
    fromPhoneNumberId: PhoneNumber,
    roomId: string,
    isListener: boolean,
  ) {
    const userId = root.user.current?.id

    if (!userId) {
      return
    }

    const call = await device.connect({
      params: {
        ...{
          UserId: userId,
          RoomId: roomId,
          ParticipantId: ObjectID(),
          Listener: isListener ? 'true' : 'false',
          PhoneNumberId: fromPhoneNumberId.id,
        },
      },
    })

    return ActiveCall.fromOutgoingCall(root, call, true)
  }

  protected static async fromOutgoingCall(
    root: Service,
    call: Call,
    getRoomData: boolean,
  ) {
    const roomId = root.voice.isOldOperator ? uuid() : call.customParameters.get('RoomId')
    if (!roomId) {
      call.reject()
      logError(new Error('No roomId found'))
      return
    }

    const isListener = call.customParameters.get('Listener') === 'true'

    // FIXME: this should be removed once we complete the migration to the new operator
    const isRecording = call.customParameters.get('is_recording') === 'true'

    const fromUserId = call.customParameters.get('UserId') ?? null

    const to = call.customParameters.get('To')?.split('&') ?? []
    const toTriplets = to.map(
      (triplet) =>
        triplet.split(':') as [
          phoneNumber: string,
          participantId: string,
          userId: string | undefined,
        ],
    )

    const participantId = root.voice.isOldOperator
      ? uuid()
      : call.customParameters.get('ParticipantId')
    if (!participantId) {
      call.reject()
      logError(new Error('No participantId found'))
      return
    }

    const identifier = root.voice.isOldOperator
      ? call.customParameters.get('PhoneNumberId')
      : call.customParameters.get('DirectNumber') ??
        call.customParameters.get('PhoneNumber')
    if (!identifier) {
      call.reject()
      logError(new Error('No identifier found'))
      return
    }
    const userId = call.customParameters.get('UserId')

    const participants: RoomParticipant[] = [
      {
        id: participantId,
        identifier,
        userId,
        roomId,
        status: 'active',
      },
      ...toTriplets.map(
        ([phoneNumber, participantId, userId = null]): RoomParticipant => ({
          id: root.voice.isOldOperator ? uuid() : participantId,
          identifier: phoneNumber,
          roomId,
          status: 'ringing',
          userId,
        }),
      ),
    ]

    const outboundConnectionId = call.outboundConnectionId
    if (!outboundConnectionId) {
      call.reject()
      logError(new Error('No outboundConnectionId found'))
      return
    }

    return new ActiveCall(
      root,
      call,
      {
        id: outboundConnectionId,
        // We don't have the CallSid until Twilio establishes the call. For
        // outgoing calls, sid is set when the call is accepted.
        sid: null,
        status: 'connecting',
        room: {
          id: roomId,
          participants,
        },
        isListener,
        fromParticipantId: participants[0].id,
        fromUserId,
        toParticipantId: participants[1]?.id,
        // FIXME we technically know the userId
        toUserId: null,

        // FIXME: this should be removed once we complete the migration to the new operator
        isRecording,
      },
      getRoomData,
    )
  }

  constructor(
    protected readonly root: Service,

    /**
     * The Twilio Call object.
     */
    protected readonly call: Call,

    /**
     * The raw data of this active call.
     *
     * Do not access in components.
     *
     * @access module
     */
    readonly data: ActiveCallData,

    /**
     * Decides wether the construct should fetch the room data from the backend or not.
     */
    private readonly getRoomData: boolean,
  ) {
    this.debug = Debug(`op:service:voice:ActiveCall:@${this.id}`)
    this.debug('new active call (data: %O)', data)

    this.participants = new ActiveCallParticipants(this.root, this)
    this.roomReady = this.root.voice.isOldOperator || this.direction === 'incoming'

    makeAutoObservable(
      this,
      {
        direction: false,
        isVerified: false,
      },
      { autoBind: true },
    )

    this.call.on('accept', this.handleAccept)
    this.call.on('cancel', this.handleDisconnect)
    this.call.on('disconnect', this.handleDisconnect)
    this.call.on('error', this.handleError)
    this.call.on('reconnecting', this.handleReconnecting)
    this.call.on('reconnected', this.handleReconnected)
    this.call.on('reject', this.handleDisconnect)
    this.call.on('ringing', this.handleRinging)
    this.call.on('mute', this.handleMute)

    this.roomReadyTimeout = window.setTimeout(() => {
      this.setRoomAsReady()
    }, 5000)

    this.disposeBag.add(
      () => {
        if (this.roomReadyTimeout) {
          clearTimeout(this.roomReadyTimeout)
        }
      },
      reaction(
        () => this.data,
        () => {
          this.debug('data changed (%O)', toJS(this.data))
        },
        { name: 'ActiveCall.DataChanged' },
      ),
    )

    if (this.getRoomData && !this.root.voice.isOldOperator) {
      this.fetchRoomData()
    }

    if (this.root.voice.isOldOperator) {
      this.data.isRecording =
        this.data.isRecording || this.phoneNumber?.settings?.autoRecord
    }
  }

  get hasMicrophonePermission(): boolean {
    const state = permissions.states['microphone']
    // Firefox and Safari <16 don't support the `microphone` permission through
    // this API, so we don't want to disable accepting of calls in those cases.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
    return !state || state === 'granted'
  }

  get id() {
    return this.data.id
  }

  get sid() {
    return this.data.sid
  }

  get roomId() {
    return this.data.room.id
  }

  get direction(): 'incoming' | 'outgoing' {
    return this.call.direction === Call.CallDirection.Incoming ? 'incoming' : 'outgoing'
  }

  get isRecording(): boolean {
    return this.recordingStatus === 'in-progress'
  }

  /**
   * The status of the recording.
   * When `null`, it means we are waiting for the data from the backend
   */
  get recordingStatus(): RecordingStatus | null {
    const status = this.data.recordingStatus

    // Handle the old operator `in-progress` status, which can come from both the data recordingStatus
    // and the data isRecording legacy variable
    if (
      this.root.voice.isOldOperator &&
      (this.data.isRecording || status === 'in-progress')
    ) {
      return 'in-progress'
    }

    // If we have a status from the backend let's use it
    if (status) {
      return status
    }

    // If the phone number has autoRecord active we initialise to `null` since we are
    // waiting for a backend update
    if (this.phoneNumber?.settings?.autoRecord) {
      return null
    }

    // If the phone number has autoRecord disabled the recording will only be
    // started by the user, but we still want to be able to send `null` over for state changes
    return status === undefined ? 'stopped' : null
  }

  get isVerified(): boolean {
    return Boolean(this.call.callerInfo?.isVerified)
  }

  get isDirectCall(): boolean {
    return this.direction === 'incoming'
      ? isDirectNumber(this.participants.toNumber)
      : isDirectNumber(this.participants.fromNumber)
  }

  get isGroupCall(): boolean {
    return this.participants.list.length > 2
  }

  get isOnHold(): boolean {
    return (
      this.participants.others.length > 0 &&
      this.participants.others.some((p) => p.onHold)
    )
  }

  get isListener(): boolean {
    return !!this.data.isListener
  }

  get status() {
    return this.root.transport.online ? this.data.status : 'reconnecting'
  }

  get startedAt(): Date | null {
    return this.data.startedAt ? new Date(this.data.startedAt) : null
  }

  get invitation() {
    return {
      type: this.data.invitationType,
      message: this.data.inviteMessage,
    }
  }

  get phoneNumber(): PhoneNumber | null {
    return this.root.voice.isOldOperator
      ? this.root.phoneNumber.collection.find((phoneNumber) =>
          this.direction === 'incoming'
            ? phoneNumber.number === this.participants.toNumber
            : phoneNumber.id === this.participants.fromNumber,
        ) ?? null
      : this.root.phoneNumber.collection.find(
          (phoneNumber) =>
            phoneNumber.number ===
            (this.direction === 'incoming'
              ? this.participants.toNumber
              : this.participants.fromNumber),
        ) ?? null
  }

  accept() {
    this.call.accept()
  }

  reject() {
    this.call.reject()
    this.handleDisconnect()
  }

  dismiss() {
    this.call.ignore()
    this.data.status = 'dismissed'
  }

  disconnect() {
    this.root.analytics.callEnded({
      direction: this.direction,
      participants: this.participants.identifiers,
    })
    this.call.disconnect()
  }

  endForEveryone() {
    this.disconnect()
    return this.root.voice.room.update(this.data.room.id, {
      status: 'ended',
    })
  }

  async toggleRecord(): Promise<void> {
    if (
      this.recordingStatus === null ||
      (this.root.voice.isOldOperator && !this.data.sid)
    ) {
      return
    }
    return this.isRecording ? this.pauseRecording() : this.startRecording()
  }

  async startRecording(): Promise<void> {
    if (this.participants.current?.id) {
      const previousRecordingStatus = this.data.recordingStatus
      const previousIsRecording = this.data.isRecording
      this.data.recordingStatus = null
      this.data.isRecording = true
      return this.root.voice.isOldOperator
        ? this.root.voice.recordings
            .legacyRecord(this.data.sid ?? '', this.phoneNumber?.id ?? '', 'start')
            .then(
              action((response: { recordingStatus: RecordingStatus } | null) => {
                this.data.recordingStatus = response?.recordingStatus
              }),
            )
            .catch((error) => {
              this.data.recordingStatus = previousRecordingStatus
              this.data.isRecording = previousIsRecording
              throw error
            })
        : (previousRecordingStatus === 'paused'
            ? this.root.voice.recordings.resume(
                this.data.room.id,
                this.participants.current?.id,
              )
            : this.root.voice.recordings.start(
                this.data.room.id,
                this.participants.current?.id,
              )
          )
            .then(
              action((response) => {
                this.data.recordingStatus = response.recordingStatus
              }),
            )
            .catch((error) => {
              this.data.recordingStatus = previousRecordingStatus
              this.data.isRecording = previousIsRecording
              throw error
            })
    }
  }

  async pauseRecording(): Promise<void> {
    if (this.participants.current?.id) {
      const previousRecordingStatus = this.data.recordingStatus
      const previousIsRecording = this.data.isRecording
      this.data.recordingStatus = null
      this.data.isRecording = false
      return this.root.voice.isOldOperator
        ? this.root.voice.recordings
            .legacyRecord(this.data.sid ?? '', this.phoneNumber?.id ?? '', 'stop')
            .then(
              action(() => {
                this.data.recordingStatus = 'paused'
              }),
            )
            .catch((error) => {
              this.data.recordingStatus = previousRecordingStatus
              this.data.isRecording = previousIsRecording
              throw error
            })
        : this.root.voice.recordings
            .pause(this.data.room.id, this.participants.current?.id)
            .then(
              action(() => {
                this.data.recordingStatus = 'paused'
              }),
            )
            .catch((error) => {
              this.data.recordingStatus = previousRecordingStatus
              this.data.isRecording = previousIsRecording
              throw error
            })
    }
  }

  toggleHold() {
    const onHold = !this.isOnHold
    this.data.room.participants = this.data.room.participants.map((p) => ({
      ...p,
      // FIXME: replace with `onHold: isDirectNumber(p.identifier) ? false : onHold` when we restore direct dialing
      onHold:
        this.participants.others.find((external) => external.roomParticipant === p) &&
        p.status === 'active'
          ? onHold
          : false,
    }))
    return this.root.voice.room.update(this.data.room.id, { onHold })
  }

  sendDigits(digits: string) {
    return this.call.sendDigits(digits)
  }

  toggleMute() {
    this.call.mute(!this.isMuted)
  }

  async transfer(
    participant: ActiveCallParticipant,
    message: string,
    fromPhoneNumberId?: string,
  ): Promise<void> {
    this.debug('transferring to new participant (participant: %O)', participant)
    if (participant.selection && this.participants.current?.id) {
      this.data.room.participants = this.data.room.participants.filter(
        (p) =>
          !(
            p.invitation?.type === 'transferred' &&
            p.identifier === participant.identifier
          ),
      )
      if (this.root.flags.getFlag('groupCallingWarmTransfer')) {
        this.data.room.participants.push(participant.roomParticipant)
      }

      const target: ParticipantTarget = {
        ...convertSelectionToVoiceTarget(participant.selection),
        message,
        participantId: participant.id,
      }

      return this.root.voice.isOldOperator
        ? this.root.voice.room
            .legacyTransfer(
              this.data.id ?? '',
              getLegacyTransferParams(participant.selection),
            )
            .catch((error) => {
              this.data.room.participants = this.data.room.participants.filter(
                (p) => participant.id !== p.id,
              )
              throw error
            })
        : this.root.voice.room
            .transfer(this.data.room.id, {
              to: target,
              from: {
                participantId: this.participants.current?.id,
                phoneNumberId: fromPhoneNumberId,
              },
            })
            .catch((error) => {
              this.data.room.participants = this.data.room.participants.filter(
                (p) => participant.id !== p.id,
              )
              throw error
            })
    }
  }

  /**
   * Set the recording status from raw data.
   *
   * @access module
   */
  handleRecordingStatusUpdate(recordingStatus: RecordingStatus) {
    this.setRoomAsReady()
    this.data.recordingStatus = recordingStatus
  }

  /**
   * Fully upsert a room participant with raw data.
   *
   * @access module
   */
  upsertRoomParticipant(participantId: string, participant: RoomParticipant) {
    this.setRoomAsReady()
    const participantIndex = this.data.room.participants.findIndex(
      (p) => p.id === participantId,
    )

    if (participantIndex >= 0) {
      const oldParticipant = this.data.room.participants[participantIndex]
      this.debug('update participant (old: %O, new: %O)', oldParticipant, participant)
      this.data.room.participants[participantIndex] = participant
    } else {
      this.debug('new participant (participant: %O)', participant)
      this.data.room.participants.push(participant)
    }
  }

  /**
   * Partially update a room participant with raw data.
   *
   * @access module
   */
  updateRoomParticipant(participantId: string, participant: Partial<RoomParticipant>) {
    this.setRoomAsReady()
    const participantIndex = this.data.room.participants.findIndex(
      (p) => p.id === participantId,
    )

    if (participantIndex >= 0) {
      const oldParticipant = this.data.room.participants[participantIndex]
      const newParticipant = { ...oldParticipant, ...participant }
      this.debug('update participant (old: %O, new: %O)', oldParticipant, newParticipant)
      this.data.room.participants[participantIndex] = newParticipant
    }
  }

  tearDown() {
    this.call.off('accept', this.handleAccept)
    this.call.off('cancel', this.handleDisconnect)
    this.call.off('disconnect', this.handleDisconnect)
    this.call.off('error', this.handleError)
    this.call.off('reconnecting', this.handleReconnecting)
    this.call.off('reconnected', this.handleReconnected)
    this.call.off('reject', this.handleDisconnect)
    this.call.off('ringing', this.handleRinging)
    this.disposeBag.dispose()
    this.participants.tearDown()
  }

  createParticipantFromSelection(
    selection: PhoneNumberSelection,
    invitation?: RoomInvitation,
    isListener?: boolean,
  ): ActiveCallParticipant {
    const identity =
      typeof selection.to === 'string'
        ? createAnonymousIdentityFromNumber(selection.to)
        : selection.type === 'inbox'
        ? selection.to.group
        : selection.to

    const participant: RoomParticipant = {
      id: ObjectID(),
      identifier: this.getIdentifier(selection),
      userId: selection.to instanceof Member ? identity?.id : undefined,
      roomId: this.data.room.id,
      invitation,
      isListener,
    }

    return new ActiveCallParticipant(this.root, this, participant, selection)
  }

  private handleAccept() {
    this.data.sid = this.call.parameters.CallSid
    this.data.status = 'connected'
    this.data.startedAt = Date.now()
    this.root.analytics.callStarted({
      direction: this.direction,
      participants: this.participants.identifiers,
    })
  }

  private handleDisconnect() {
    // TODO: The 'error' event may be emitted directly after a disconnect, so
    // this is a hack to show the error message and _then_ disconnect.
    setTimeout(
      action(() => {
        this.data.status = 'none'
        this.tearDown()
        this.root.voice.calls.delete(this.id)
        this.root.analytics.callEnded({
          direction: this.direction,
          participants: this.participants.identifiers,
        })
      }),
      0,
    )
  }

  private fetchRoomData(): void {
    this.root.voice.room.get(this.data.room.id).then((room: Room) => {
      this.data.room = {
        ...this.data.room,
        ...room,
        participants: [
          ...new Map(
            [...this.data.room.participants, ...room.participants].map((p) => [p.id, p]),
          ).values(),
        ],
      }
    })
  }

  private handleError(error: TwilioError.TwilioError) {
    this.error = error
  }

  private handleReconnecting() {
    this.data.status = 'reconnecting'
  }

  private handleReconnected() {
    this.data.status = 'connected'
  }

  private handleRinging() {
    this.data.status = 'connecting'
  }

  private handleMute(isMuted: boolean) {
    this.isMuted = isMuted
  }

  private getIdentifier = (selection: PhoneNumberSelection): string => {
    switch (selection.type) {
      case 'number':
      case 'add-number':
        return selection.to
      case 'contact':
      case 'member':
      case 'inbox-member':
        return selection.via
      case 'inbox':
        return selection.to.number
    }
  }

  private setRoomAsReady() {
    this.roomReady = true
    if (this.roomReadyTimeout) {
      clearTimeout(this.roomReadyTimeout)
    }
  }
}

const getRoomParticipant = (
  customParameters: Map<string, string>,
  roomId: string,
  type: 'from' | 'to' | 'transferrer',
): RoomParticipant | null => {
  const id = customParameters.get(`${type}_participant_id`) ?? ObjectID()
  const identifier = customParameters.get(`${type}_identifier`) ?? null
  const userId = customParameters.get(`${type}_user_id`) ?? null

  if (!identifier) {
    return null
  }

  return {
    id,
    roomId,
    userId,
    identifier,
  }
}

export const convertActiveCallDestinationToString = (to: ActiveCallToType): string => {
  return to
    .map((o) => `${toE164(o.number)}:${ObjectID()}${o.userId ? `:${o.userId}` : ''}`)
    .join('&')
}
