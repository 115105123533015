import { observer } from 'mobx-react-lite'
import React from 'react'

import { useAppStore } from '@src/app/context'
import Header from '@src/app/onboarding/Header'
import { useRecaptcha } from '@src/app/recaptcha'
import Button from '@src/component/ButtonV2'
import Illustration from '@src/component/illustrations'
import CheckEmail from '@src/component/illustrations/CheckEmail'
import useInputState from '@src/lib/hooks/useInputState'
import keyboard from '@src/lib/keyboard'
import TextInput from '@ui/TextInput'
import Typography from '@ui/Typography'

import { useLoginStore } from '.'
import * as styles from './EmailCode.css'

function EmailCode() {
  const { history } = useAppStore()
  const loginStore = useLoginStore()
  const [code, setCode] = useInputState('')

  const { isReady: isGrecaptchaReady, generateToken } = useRecaptcha()

  const disabled = !code && !isGrecaptchaReady

  const handleLogin = async () => {
    let recaptcha_token
    try {
      recaptcha_token = await generateToken(
        loginStore.mode === 'login' ? 'login_code' : 'signup_code',
      )
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      loginStore.loginWithCode(code, recaptcha_token)
    }
  }

  return (
    <div>
      <Header />
      <div className={styles.card}>
        <Illustration illustration={CheckEmail} className={styles.image} />
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Check your email
        </Typography>
        <Typography variant="body" color="textSecondary">
          Enter the 6-digit code we just sent to{' '}
          <Typography component="span" variant="body" color="textPrimary">
            {loginStore.email}
          </Typography>{' '}
          to continue.
        </Typography>
        <TextInput
          autoComplete="off"
          autoFocus
          fullWidth
          inputMode="numeric"
          placeholder="6-digit code"
          size={45}
          value={code}
          onChange={setCode}
          onKeyDown={keyboard.onEnter(handleLogin)}
          style={{ marginTop: 48, textAlign: 'center' }}
        />
        <Button
          fullWidth
          disabled={disabled}
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={handleLogin}
          loading={loginStore.loading === 'logging_in'}
        >
          Continue
        </Button>
        <Button
          fullWidth
          variant="text"
          color="textSecondary"
          fontWeight="regular"
          height={36}
          style={{ marginTop: 8 }}
          onClick={history.goBack}
        >
          Go back
        </Button>
      </div>
    </div>
  )
}

export default observer(EmailCode)
