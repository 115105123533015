/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, flow, makeAutoObservable, runInAction } from 'mobx'

import PersistedCollection from '@src/service/collections/PersistedCollection'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type { Alert, AlertAssociations, DecodableAlert } from './model'
import {
  ThreadMentionAlert,
  ActivityMentionAlert,
  ContactNoteMentionAlert,
  ThreadReplyAlert,
  ThreadCommentReactionAlert,
  ActivityReactionAlert,
  ThreadResolutionAlert,
} from './model'
import type { PageInfo } from './transport/lib/Paginated'
import type { AlertDeleteMessage, AlertUpdateMessage } from './transport/websocket'
import type { AlertRepository } from './worker/repository'

export default class AlertStore {
  readonly collection: PersistedCollection<Alert, AlertRepository>

  private pageInfo: PageInfo | null = null
  private lastFetchedAt: number | null = null

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('alert'),
      classConstructor: (json) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        switch (json.type) {
          case 'comment-on-activity':
          case 'reply-in-mentioned-thread':
            return new ThreadReplyAlert(this.root)
          case 'reaction-on-activity':
            return new ActivityReactionAlert(this.root)
          case 'mention-in-activity-comment':
            return new ThreadMentionAlert(this.root)
          case 'mention-in-activity':
            return new ActivityMentionAlert(this.root)
          case 'mention-in-contact-note':
            return new ContactNoteMentionAlert(this.root)
          case 'reaction-on-activity-comment':
            return new ThreadCommentReactionAlert(this.root)
          case 'activity-resolved':
          case 'activity-unresolved':
            return new ThreadResolutionAlert(this.root)
          default:
            return null
        }
      },
    })

    makeAutoObservable(this, {}, { autoBind: true })

    makePersistable<this, 'pageInfo' | 'lastFetchedAt'>(this, 'AlertStore', {
      pageInfo: root.storage.sync(),
      lastFetchedAt: root.storage.sync(),
    })

    this.subscribeToWebhookEvents()
  }

  getAll() {
    this.collection.performQuery((repo) => repo.all())
  }

  async fetchMissing() {
    const self = this
    const lastFetchedAt = this.lastFetchedAt

    /**
     * If lastFetchedAt was older than a week ago, nuke the cache and try
     * fetching alerts again from scratch
     */
    if (
      typeof lastFetchedAt === 'number' &&
      lastFetchedAt < Date.now() - 3600 * 24 * 7 * 1000
    ) {
      await this.collection.deleteQuery((repo) => repo.all())
      runInAction(() => {
        this.lastFetchedAt = null
      })
    }

    if (lastFetchedAt) {
      return this.root.transport.account.alert.since(new Date(lastFetchedAt)).then(
        flow(function* (res) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const alerts = yield self.collection.load(res) ?? []
          self.lastFetchedAt = Math.max(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            ...alerts.map((c) => c.updatedAt),
            lastFetchedAt + 1,
          )
        }),
      )
    } else {
      return this.root.transport.account.alert.paginated().then(
        flow(function* (res) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const alerts = yield self.load(res.result, res.associations) ?? []
          self.pageInfo = res.pageInfo
          self.lastFetchedAt = Math.max(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            ...alerts.map((c) => c.updatedAt + 1),
            (self.lastFetchedAt || Date.now()) + 1,
          )
        }),
      )
    }
  }

  async markRead(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.forEach(
      action((alert) => {
        alert.readAt = Date.now()
      }),
    )
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.read(ids)
  }

  async markUnread(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.forEach((alert) => {
      alert.readAt = null
    })
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.unread(ids)
  }

  async markOpened(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.map(
      action((alert) => {
        alert.openedAt = Date.now()
      }),
    )
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.open(ids)
  }

  async markUnopened(alerts: Alert[]) {
    if (alerts.length === 0) return
    alerts.forEach((alert) => {
      alert.openedAt = null
    })
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.unopen(ids)
  }

  delete(alerts: Alert[]) {
    const ids = alerts.map((a) => a.id)
    this.collection.deleteBulk(alerts)
    return this.root.transport.account.alert.delete(ids)
  }

  private subscribeToWebhookEvents() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'alert-update':
          return this.handleAlertUpdate(data)
        case 'alert-delete':
          return this.handleAlertDelete(data)
      }
    })
  }

  private handleAlertUpdate(message: AlertUpdateMessage) {
    this.load(message.alert, message.associations)
  }

  private handleAlertDelete(message: AlertDeleteMessage) {
    this.collection.delete(message.alert.id)
  }

  private load(
    alerts: DecodableAlert | DecodableAlert[],
    { contacts = [], conversations = [], activities = [] }: AlertAssociations = {},
  ) {
    this.root.contact.load(contacts)
    this.root.conversation.load(conversations, activities)
    return this.collection.load(alerts)
  }
}
