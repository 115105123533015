import Dexie from 'dexie'
import zip from 'lodash/fp/zip'

import type MainWorker from '@src/service/worker/main'
import type Repository from '@src/service/worker/repository/base'

import AsyncStorage from './AsyncStorage'
import SyncStorage from './SyncStorage'
import createDatabase from './createDatabase'

export type StorageEngine = AsyncStorage | SyncStorage

export default class StorageService {
  database: Dexie

  constructor(private worker: MainWorker) {
    this.database = createDatabase()
  }

  async(classConstructor?: (data: any) => any): AsyncStorage {
    return new AsyncStorage(this.database.table('kv'), classConstructor)
  }

  sync<T = unknown>(): SyncStorage<T> {
    return new SyncStorage<T>()
  }

  async reset() {
    const restorableKeys = [
      'AuthStore.session',
      'ApiClient.visitorId',
      'ApiClient.visitorIdUpdatedAt',
      'env-override',
      /**
       * When a user joins a workspace during onboarding, we clear the storage
       * and do a page reload so this ensures the user ends up in the correct
       * flow after the page reloads.
       */
      'OnboardingUiState.isJoinWorkspace',
    ]
    const restorableValues = restorableKeys.map((key) => localStorage.getItem(key))
    localStorage.clear()
    for (const [key, value] of zip(restorableKeys, restorableValues)) {
      if (key && value) {
        localStorage.setItem(key, value)
      }
    }
    if ('caches' in window) {
      const keys = await window.caches.keys()
      keys.forEach((key) => window.caches.delete(key))
    }
    return Promise.all(this.database.tables.map((t) => t.clear()))
  }

  async clearAll() {
    const restorableKeys = [
      'ApiClient.visitorId',
      'ApiClient.visitorIdUpdatedAt',
      'env-override',
    ]
    const restorableValues = restorableKeys.map((key) => localStorage.getItem(key))
    localStorage.clear()
    for (const [key, value] of zip(restorableKeys, restorableValues)) {
      if (key && value) {
        localStorage.setItem(key, value)
      }
    }
    if ('caches' in window) {
      const keys = await window.caches.keys()
      keys.forEach((key) => window.caches.delete(key))
    }
    return this.database.delete().then(() => (this.database = createDatabase()))
  }

  exists(dbName: string): Promise<boolean> {
    return Dexie.exists(dbName)
  }

  delete(dbName: string): Promise<void> {
    return Dexie.delete(dbName)
  }

  table<T extends Repository>(collection: string): T {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.worker.repo[collection]
  }
}
