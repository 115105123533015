import React from 'react'

import { visuallyHidden } from './VisuallyHidden.css'

export interface VisuallyHiddenProps {
  children: React.ReactNode
}

export const VisuallyHidden = ({ children }: VisuallyHiddenProps) => {
  return <div className={visuallyHidden}>{children}</div>
}

export default VisuallyHidden
