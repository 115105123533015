import React, { useLayoutEffect } from 'react'
import { OverlayProvider } from 'react-aria'

import TooltipProvider from '@ui/TooltipProvider/TooltipProvider'
import type { ThemeKey } from '@ui/theme'
import { ThemeProvider, themeClassNames } from '@ui/theme'

export interface DesignSystemProviderProps {
  children?: React.ReactNode
  themeKey?: ThemeKey
}

export const DesignSystemProvider = ({
  children,
  themeKey = 'light',
}: DesignSystemProviderProps) => {
  useLayoutEffect(() => {
    const className = themeClassNames[themeKey]

    document.body.classList.add(className)

    return () => {
      document.body.classList.remove(className)
    }
  }, [themeKey])

  return (
    <ThemeProvider themeKey={themeKey}>
      <OverlayProvider style={{ flex: 1, height: '100%' }}>
        <TooltipProvider>{children}</TooltipProvider>
      </OverlayProvider>
    </ThemeProvider>
  )
}

export default DesignSystemProvider
