/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import config from '@src/config'
import type { Room } from '@src/service/voice'
import type { LegacyTransferParams } from '@src/service/voice/convertSelectionToVoiceTarget'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export type RoomRole = 'host' | 'member'
export type RoomInvitationType = 'added' | 'transferred' | 'called'
export type RoomInvitation = {
  type: RoomInvitationType
  message?: string
  fromParticipantId: string
}
export type RoomParticipantStatus =
  | 'active'
  | 'ringing'
  | 'no-answer'
  | 'completed'
  | 'busy'
  | 'failed'

export type RecordingStatus = 'in-progress' | 'paused' | 'stopped'

export type RecordingBody = { action: 'start' } | { action: 'pause' | 'resume' | 'stop' }

export interface RecordingResponse {
  recordingKey: string
  recordingStatus: RecordingStatus
  recordingUrl: string
}

export interface VoiceTarget {
  phoneNumber?: string
  phoneNumberId?: string
  directNumber?: string
  userId?: string
}

export interface ParticipantTarget extends VoiceTarget {
  role?: RoomRole
  message?: string
  participantId: string
  isListener?: boolean
}

export interface TransferParams {
  to: ParticipantTarget
  from: { participantId: string; phoneNumberId?: string }
}

export interface AddParticipantsParams {
  to: readonly ParticipantTarget[]
  from: { participantId: string; phoneNumberId?: string }
}

export interface UpdateParticipantsParams {
  isMuted?: boolean
  onHold?: boolean
  role?: RoomRole
  coaching?: string
}

export interface UpdateRoomParams {
  onHold?: boolean
  status?: 'ended'
}

export interface TokenResponse {
  expiry: string
  token: string
  version: number
}

export default class VoiceClient {
  constructor(private transport: Transport) {}

  recordings = {
    put: (
      roomId: string,
      participantId: string,
      body: RecordingBody,
    ): Promise<RecordingResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/record/${participantId}`,
          body,
        }),
      )
    },
    bulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.VOICE_SERVICE_URL}call/recording`,
          body: {
            activityId,
            recordingIds,
          },
        }),
      )
    },
    // FIXME: this should be removed once we complete the migration to the new operator
    legacyBulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.COMMUNICATION_SERVICE_URL}call/recording`,
          body: {
            activityId,
            recordingIds,
          },
        }),
      )
    },
    // FIXME: this should be removed once we complete the migration to the new operator
    legacyRecord: (
      callSid: string,
      phoneNumberId: string,
      action: 'start' | 'pause' | 'resume' | 'stop',
    ) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.COMMUNICATION_SERVICE_URL}call/${callSid}/record`,
          body: { action, phoneNumberId },
        }),
      )
    },
  }

  token(): Promise<TokenResponse> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.VOICE_SERVICE_URL}token`,
      }),
    )
  }

  room = {
    get: (roomId: string) => {
      return this.transport.queue(
        new HttpTransaction<Room>({
          method: 'get',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}`,
        }),
      )
    },

    transfer: (roomId: string, params: TransferParams) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'post',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/transfer`,
          body: params,
        }),
      )
    },

    // FIXME: this should be removed once we complete the migration to the new operator
    legacyTransfer: (callSid: string, params: LegacyTransferParams) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}call/${callSid}/transfer`,
          body: params,
        }),
      )
    },

    update: (roomId: string, params: UpdateRoomParams) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}`,
          body: params,
        }),
      )
    },
  }

  participants = {
    add: (roomId: string, params: AddParticipantsParams) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant`,
          body: params,
        }),
      )
    },

    addAgain: (roomId: string, participantId: string) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'post',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant/${participantId}`,
        }),
      )
    },

    update: (roomId: string, participantId: string, params: UpdateParticipantsParams) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant/${participantId}`,
          body: params,
        }),
      )
    },

    remove: (roomId: string, participantId: string) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'delete',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant/${participantId}`,
        }),
      )
    },
  }
}
