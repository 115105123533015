import { observer } from 'mobx-react-lite'
import React from 'react'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/button'
import Logo from '@src/component/logo'

import * as styles from './Header.css'

const Header = function () {
  const { service, signOut } = useAppStore()

  return (
    <div className={styles.root}>
      <Logo />
      {service.auth.hasSession && (
        <Button variant="text" size="small" onClick={() => signOut()}>
          Sign out
        </Button>
      )}
    </div>
  )
}

export default observer(Header)
