import type AppStore from '@src/app/AppStore'
import { BaseError } from '@src/lib/api/errorHandler'
import { logError } from '@src/lib/log'

interface ErrorMatchHandlerProps {
  error: Error
  showToast: (message: string) => void
}

type ErrorMatchHandler = (props: ErrorMatchHandlerProps) => void

export default class ErrorHelper {
  private unknownMatchHandler: ErrorMatchHandler = ({ error, showToast }) => {
    logError(error)
    showToast(error.message)
  }

  constructor(private readonly root: AppStore) {}

  match(error: unknown, handlers: Record<string, ErrorMatchHandler>) {
    const errorInstance = error instanceof Error ? error : new Error(String(error))
    let code = 'UNKNOWN'

    if (errorInstance instanceof BaseError) {
      code = errorInstance.code ?? 'UNKNOWN'
    }

    const handler = handlers[code] ?? this.unknownMatchHandler

    handler({
      error: errorInstance,
      showToast: (message) => {
        this.root.toast.showError(new Error(message))
      },
    })
  }
}
