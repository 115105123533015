import { useContext } from 'react'

import ThemeContext, { createThemeContextValue } from './ThemeContext'
import type { ThemeKey } from './themes/common'

/**
 * Returns the current theme key and theme class name.
 *
 * @param themeKey Overrides the current theme with a specific theme.
 */
export default function useTheme(themeKey?: ThemeKey) {
  const context = useContext(ThemeContext)

  if (themeKey) {
    return createThemeContextValue(themeKey)
  }

  return context
}
