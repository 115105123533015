import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Routes, useNavigate, Route } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import { DeployPreview } from '@src/app/dialogs/deploy-preview'
import { useEmitFirstRender } from '@src/app/electron'

export default function DialogRouter() {
  const { electron } = useAppStore()
  const navigate = useNavigate()
  const styles = useStyles()

  useEmitFirstRender()

  useEffect(() => {
    const handler = ({ dialogName }: { dialogName: string }) => {
      navigate(dialogName, { replace: true })
    }

    electron?.window.onFirstRender?.()?.catch(() => null)
    electron?.on('navigate-to-dialog', handler)

    return () => {
      electron?.off('navigate-to-dialog', handler)
    }
  }, [navigate, electron, history])

  return (
    <div>
      <div className={styles.dragArea}></div>
      <Routes>
        <Route path="deploy-preview/*" element={<DeployPreview />} />
      </Routes>
    </div>
  )
}

const useStyles = makeStyles({
  dragArea: {
    height: 30,
    '-webkit-app-region': 'drag',
  },
})
