import { yupResolver } from '@hookform/resolvers/yup'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import Header from '@src/app/onboarding/Header'
import { useRecaptcha } from '@src/app/recaptcha'
import Button from '@src/component/ButtonV2'
import Tooltip from '@src/component/tooltip'
import TextField from '@ui/TextField'
import Typography from '@ui/Typography'

import { useLoginStore } from '.'
import * as styles from './Password.css'

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required()

interface FormValues {
  email: string
  password: string
}

function Password() {
  const loginStore = useLoginStore()
  const { isReady: isGrecaptchaReady, generateToken } = useRecaptcha()

  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: loginStore.email ?? '',
      password: '',
    },
  })

  const emailValue = watch('email')

  const handleForgot = () => {
    loginStore.email = emailValue
    loginStore.setStep('forgot')
  }

  const handleBack = () => {
    loginStore.setStep('login')
  }

  const onSubmit = async (data: FormValues) => {
    let recaptcha_token
    try {
      recaptcha_token = await generateToken(
        loginStore.mode === 'login' ? 'login_code' : 'signup_code',
      )
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      loginStore.loginWithPassword(data.email, data.password, recaptcha_token)
    }
  }

  useEffect(() => {
    setFocus(loginStore?.email ? 'password' : 'email')
  }, [setFocus, loginStore])

  return (
    <div>
      <Header />
      <div className={styles.card}>
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Welcome back!
        </Typography>
        <Typography variant="body" color="textSecondary">
          Enter your email address and password to log back in to your account.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginTop: 48, textAlign: 'left' }}>
            <TextField
              type="email"
              fullWidth
              label="Email"
              size={45}
              autoComplete="email"
              {...register('email')}
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              size={45}
              style={{ marginTop: 20 }}
              autoComplete="current-password"
              {...register('password')}
            />
            <Button
              fullWidth
              height={40}
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              disabled={!isValid && isGrecaptchaReady}
              loading={loginStore.loading === 'logging_in'}
              type="submit"
            >
              Continue
            </Button>
            <Tooltip disabled={Boolean(emailValue)} title="Enter an email first">
              <span>
                <Button
                  fullWidth
                  height={36}
                  variant="text"
                  color="textSecondary"
                  style={{ marginTop: 8 }}
                  onClick={handleForgot}
                  disabled={!emailValue}
                >
                  Forgot your password?
                </Button>
              </span>
            </Tooltip>
            <Button
              fullWidth
              height={36}
              variant="text"
              color="textSecondary"
              fontWeight="regular"
              style={{ marginTop: 8 }}
              onClick={handleBack}
            >
              Go back
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default observer(Password)
