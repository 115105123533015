import { makeAutoObservable, toJS } from 'mobx'

import type Service from '@src/service'
import type { Model } from '@src/service/model/base'
import type {
  CreatedBy,
  CallDeposition,
  ContactImport,
  LoggableActivity,
  SerializedIntegration,
} from '@src/service/transport/integration'

import type { BaseIntegration } from '.'
import { createdByUserName } from './utils'

interface SerializedHubspot extends SerializedIntegration {
  type: 'hubspot'
  /**
   * @deprecated
   */
  hubspotUser: string
}

export class HubspotIntegration implements Model, BaseIntegration {
  id = ''
  readonly type = 'hubspot'
  /**
   * @deprecated
   *
   * Kept due to backwards compatibility
   * `createdBy` should be the property to access the information of the
   * user who created the integration
   */
  hubspotUser = ''
  resourceIds: string[] = []
  refreshToken: string | null = null
  accessToken: string | null = null
  logCalls: LoggableActivity = { enabled: false, activityType: null }
  logMessages: LoggableActivity = { enabled: false, activityType: null }
  accessTokenExpiresAt: Date | null = null
  contactProperties: string[] = []
  callDepositions: CallDeposition[] = []
  contactImport: ContactImport | null = null
  createdBy: CreatedBy | null = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  get userName() {
    return createdByUserName(this.createdBy) ?? this.hubspotUser
  }

  update = (attrs: Partial<this>) => {
    Object.assign(this, attrs)
    return this.service.integration.update(this)
  }

  delete = () => {
    return this.service.integration.delete(this)
  }

  getStatus() {
    return this.service.integration.getStatus(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = (): SerializedHubspot => {
    return {
      id: this.id,
      type: this.type,
      hubspotUser: this.hubspotUser,
      resourceIds: toJS(this.resourceIds),
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      logCalls: toJS(this.logCalls),
      logMessages: toJS(this.logMessages),
      accessTokenExpiresAt: this.accessTokenExpiresAt,
      contactProperties: toJS(this.contactProperties),
      callDepositions: toJS(this.callDepositions),
      contactImport: toJS(this.contactImport),
      createdBy: toJS(this.createdBy),
    }
  }
}

export const isHubspotIntegration = (a: unknown): a is HubspotIntegration => {
  return a instanceof HubspotIntegration
}
