import { makeAutoObservable, action } from 'mobx'
import { nanoid } from 'nanoid'

import { parseDate } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import type { AvailabilityHours as IAvailabilityHours } from '@src/service/transport/account'

import type Service from '..'

import type { Model } from '.'
import AvailabilityHours from './AvailabilityHours'

type PresenceStatus = 'online' | 'offline'

export interface IPresence {
  userId?: string | null
  status?: PresenceStatus | null
  snoozed?: boolean | null
  onCall?: boolean
  snoozedUntil?: number | null
  offlineUntil?: number | null
  symbol?: string | null
  text?: string | null
  schedule?: AvailabilityHours
}

export class Presence implements IPresence, Model {
  userId?: string | null = null
  status?: PresenceStatus | null = null
  snoozed?: boolean | null = null
  _onCall?: boolean | null = null
  snoozedUntil: number | null = 0
  offlineUntil: number | null = 0
  symbol?: string | null = null
  text?: string | null = null
  _schedule?: IAvailabilityHours

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get id() {
    return this.userId ?? nanoid()
  }

  get isOnline() {
    return this.status === 'online'
  }

  get isSnoozed() {
    return this.snoozed && this.snoozedUntil && this.snoozedUntil > Date.now()
  }

  get onCall(): boolean {
    return (
      (this.root.capabilities.features.showOnCallStatusEnabled && this._onCall) ?? false
    )
  }

  get schedule(): AvailabilityHours | undefined {
    return this._schedule
      ? new AvailabilityHours().deserialize(this._schedule)
      : undefined
  }

  get hasStatus(): boolean {
    return Boolean(this.text || this.symbol)
  }

  get fullStatus(): string {
    return [this.symbol, this.text].filter(isNonNull).join(' ')
  }

  /**
   * The weight to be used when sorting members by presence.
   *
   * Members should be ordered like this:
   *  1. Online
   *  2. Offline
   *  3. On a call
   *  4. Snoozed
   */
  get sortWeight(): number {
    if (this.isSnoozed) return 3
    if (this.onCall) return 2
    if (!this.isOnline) return 1
    return 0
  }

  setStatus = (symbol: string | null, text: string | null) => {
    this.text = text
    this.symbol = symbol
    return this.root.member.updatePresence(this)
  }

  setOnline = (online: boolean) => {
    if (online) {
      this.offlineUntil = null
    } else {
      this.offlineUntil = Date.now() + 3600000 * 24 * 365 * 100
    }
    this.status = online ? 'online' : 'offline'
    return this.root.member.updatePresence(this)
  }

  snoozeNotifications(until: number) {
    this.snoozedUntil = until
    return this.root.member
      .setDoNotDisturb(this)
      .then(action(() => (this.snoozed = true)))
  }

  unsnooze() {
    this.snoozedUntil = null
    return this.root.member
      .setDoNotDisturb(this)
      .then(action(() => (this.snoozed = false)))
  }

  save = () => {
    return this.root.member.updatePresence(this)
  }

  deserialize = ({ id, onCall, schedule, ...json }: any) => {
    Object.assign(this, json)
    if (typeof onCall === 'boolean') {
      this._onCall = onCall
    }
    if (typeof schedule !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      this._schedule = schedule
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.snoozedUntil = parseDate(json.snoozedUntil)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.offlineUntil = parseDate(json.offlineUntil)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      userId: this.userId,
      status: this.status,
      snoozed: this.snoozed,
      snoozedUntil: this.snoozedUntil,
      offlineUntil: this.offlineUntil,
      _onCall: this._onCall,
      symbol: this.symbol,
      text: this.text,
    }
  }
}
