import { makeAutoObservable } from 'mobx'

import type { PhoneNumberSelection } from '@src/component/phone-number-selector/Controller'
import { formatted, isValidNumber } from '@src/lib/phone-number'
import type Service from '@src/service'
import type { Member, Identity, PhoneNumber } from '@src/service/model'

import type ActiveCall from './ActiveCall'
import type { RoomParticipant } from './Room'

export default class ActiveCallParticipant implements Identity {
  constructor(
    protected readonly root: Service,

    /**
     * The call to which this participant belongs.
     */
    protected readonly call: ActiveCall,

    /**
     * The raw participant data.
     *
     * This shouldn't be used in the view layer.
     */
    readonly roomParticipant: RoomParticipant,

    /**
     * The selection that was used to generate this temporary participant.
     *
     * If this is defined, then the participant was added through either
     * transferring or adding as a participant.
     */
    readonly selection?: PhoneNumberSelection,
  ) {
    makeAutoObservable(this)
  }

  /**
   * The associated identity of the participant identifier.
   *
   * The identity is looked up in the following order:
   *   1. By member ID
   *   2. By direct number
   *   3. By inbox phone number
   *   4. By contact phone number
   */
  get identity(): Identity | null {
    return (this.getMember() ??
      this.phoneNumber?.group ??
      this.root.contact.getByNumber(this.roomParticipant.identifier)?.[0] ??
      null) as Identity | null
  }

  /**
   * If the participant was added to the call by phone number, this
   * return the associated phone number of the participant, based on
   * the identifier. Otherwise, returns null.
   */
  get phoneNumber(): PhoneNumber | null {
    return this.root.phoneNumber.byNumber[this.roomParticipant.identifier] ?? null
  }

  get isCurrentUser(): boolean {
    return this.identity?.id === this.root.user.current?.asMember?.id
  }

  get isTeamMember(): boolean {
    return !!this.getMember()
  }

  get id() {
    return this.roomParticipant.id
  }

  get identifier() {
    return this.roomParticipant.identifier
  }

  get userId() {
    return this.roomParticipant.userId
  }

  get name() {
    const name = this.identity?.name ?? this.roomParticipant.identifier
    return (isValidNumber(name) && formatted(name)) || name
  }

  get isMuted() {
    if (this.isCurrentUser && this.call.isMuted) {
      return true
    }

    return this.roomParticipant.isMuted
  }

  get onHold() {
    return this.roomParticipant.onHold
  }

  get isSpeaking() {
    if (this.isMuted || this.onHold) {
      return false
    }

    return this.roomParticipant.isSpeaking ?? false
  }

  get isExternal() {
    // FIXME: This is currently commented out since we temporarily don't support direct dialing
    //return !isDirectNumber(this.roomParticipant.identifier)
    return !this.isTeamMember && !this.isInbox
  }

  get isInbox(): boolean {
    return !this.isTeamMember && !!this.phoneNumber
  }

  get isListener() {
    return this.roomParticipant.isListener
  }

  get coaching() {
    return this.call.participants.list.find((p) => p.id === this.roomParticipant.coaching)
  }

  get shortName() {
    return this.identity?.shortName || ''
  }

  get initials() {
    return this.identity?.initials || ''
  }

  get pictureUrl() {
    return this.identity?.pictureUrl
  }

  get pictureSymbol() {
    return this.identity?.pictureSymbol
  }

  get phones() {
    return this.identity?.phones || []
  }

  get emailAddresses() {
    return this.identity?.emailAddresses || []
  }

  get isAnonymous() {
    return !!this.identity?.isAnonymous
  }

  get status() {
    return this.root.voice.isOldOperator ? 'active' : this.roomParticipant.status
  }

  get invitation() {
    return this.roomParticipant.invitation
  }

  get addedAt(): Date | null {
    return this.roomParticipant.addedAt ? new Date(this.roomParticipant.addedAt) : null
  }

  get role() {
    return this.roomParticipant.role
  }

  /**
   * The associated Member object of the participant identifier.
   *
   * The member identity is looked up in the following order:
   *   1. By member ID
   *   2. By direct number
   */
  private getMember(): Member | null {
    return (
      (this.roomParticipant.userId
        ? this.root.member.collection.get(this.roomParticipant.userId)
        : null) ??
      this.root.member.collection.list.find(
        (m) => m.directNumber?.number === this.roomParticipant.identifier,
      ) ??
      null
    )
  }
}
