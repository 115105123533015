import { StylesProvider } from '@material-ui/core/styles'
import { DialogBus } from '@openphone/desktop-client'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'

import LazyConfetti from '@src/component/LazyConfetti'
import useMetaThemeColor from '@src/lib/hooks/useMetaThemeColor'
import useWindowSize from '@src/lib/hooks/useWindowSize'
import { ServiceContext } from '@src/service/context'
import { useAppTheme } from '@src/theme'
import DesignSystemProvider from '@ui/DesignSystemProvider'

import AlertDialog from './AlertDialog'
import AppMediaViewer from './AppMediaViewer'
import type AppStore from './AppStore'
import { useAppStore } from './context'
import DialogBusContext from './dialogs/DialogBusContext'
import { ErrorBoundary } from './error'
import { GlobalStyles } from './global-styles'
import * as styles from './index.css'
import Router from './router/Router'
import { Toast } from './toast'
import UpdateProvider from './update'
import WindowsTitleBar from './windows-title-bar'

declare global {
  interface Window {
    app: AppStore
    dataLayer: any[]
    Attribution: any
  }
}

const App = function () {
  const { width, height } = useWindowSize()
  const store = useAppStore()
  const theme = useAppTheme(store.themeKey)
  useMetaThemeColor(theme)

  const dialogBus = useMemo(() => {
    return store.isElectron ? new DialogBus() : null
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [])

  return (
    <StylesProvider injectFirst>
      <DesignSystemProvider themeKey={theme.palette.type}>
        <UpdateProvider app={store}>
          <DialogBusContext.Provider value={dialogBus}>
            <div className={styles.root}>
              {store.is('windows') && <WindowsTitleBar app={store} />}
              <div className={styles.body}>
                <GlobalStyles />
                <ServiceContext.Provider value={store.service}>
                  <ErrorBoundary>
                    <AlertDialog />
                    <Toast />
                    <Router />
                    <AppMediaViewer />
                    {store.confetti && (
                      <LazyConfetti
                        recycle={false}
                        width={width}
                        height={height}
                        numberOfPieces={500}
                        tweenDuration={7000}
                      />
                    )}
                  </ErrorBoundary>
                </ServiceContext.Provider>
              </div>
            </div>
          </DialogBusContext.Provider>
        </UpdateProvider>
      </DesignSystemProvider>
    </StylesProvider>
  )
}

export default observer(App)
