import { createContext } from 'react'

export interface RecaptchaContextProps {
  isReady: boolean
  generateToken: (action: string) => PromiseLike<string>
}

const RecaptchaContext = createContext<RecaptchaContextProps | null>(null)

export default RecaptchaContext
