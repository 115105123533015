import { makeAutoObservable } from 'mobx'

import type { Model } from '.'

export interface CodableCapability {
  description: string | null
  type: CapabilityType | null
  enabled: boolean
  included: number | null
  includedDuration: number | null
  includedType: string | null
  meta: Record<string, unknown>
}

export type CapabilityType =
  | 'reports'
  | 'voicemailTranscription'
  | 'autoCallRecording'
  | 'snippets'
  | 'googleContacts'
  | 'auditLog'
  | 'roundRobinCalling'
  | 'autoReplies'
  | 'callTransfer'
  | 'maxPhoneNumberUsers'
  | 'internationalMessaging'
  | 'callRecording'
  | 'conferenceCalling'
  | 'emailIntegration'
  | 'callerId'
  | 'apiMessaging'
  | 'hubspot'
  | 'calling'
  | 'ivr'
  | 'messaging'
  | 'slackIntegration'
  | 'internationalCalling'
  | 'showOnCallStatus'
  | 'groupMessaging'

class Capability implements Model {
  description: string | null = null
  type: CapabilityType | null = null
  enabled = false
  included: number | null = null
  includedDuration: number | null = null
  includedType: string | null = null
  meta: Record<string, unknown> = {}

  constructor(attrs: CodableCapability) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }

  get id() {
    return this.type as string
  }

  deserialize(json: CodableCapability): this {
    Object.assign(this, json)

    return this
  }

  serialize(): CodableCapability {
    return {
      description: this.description ?? '',
      type: this.type,
      enabled: this.enabled,
      included: this.included,
      includedDuration: this.includedDuration,
      includedType: this.includedType,
      meta: this.meta,
    }
  }
}

export default Capability
