import React from 'react'

import { CircularProgress } from '@src/component/progress'
import useTransition from '@src/lib/hooks/useTransition'

import * as styles from './SpinnerScreen.css'

export default function SpinnerScreen() {
  const state = useTransition(true, { enterDelay: 1000 })
  const hidden = state !== 'entered'

  return <div className={styles.root}>{hidden ? null : <CircularProgress />}</div>
}
