import type { TwilioError } from '@twilio/voice-sdk'
import { Call, Device } from '@twilio/voice-sdk'
import { action, makeAutoObservable, when, observable, reaction } from 'mobx'

import { isDialog } from '@src/config'
import { DisposeBag } from '@src/lib/dispose'
import { logError } from '@src/lib/log'
import type Service from '@src/service'
import Collection from '@src/service/collections/Collection'
import { isDirectNumber } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'
import type {
  AddParticipantsParams,
  UpdateParticipantsParams,
  TransferParams,
  UpdateRoomParams,
} from '@src/service/transport/voice'
import type {
  RecordingStatusMessage,
  RoomParticipantUpdateMessage,
  RoomParticipantSpeakingMessage,
  RoomParticipantOnHoldMessage,
  RoomParticipantMutedMessage,
  RoomParticipantStatusMessage,
} from '@src/service/transport/websocket'

import type { ActiveCallFromType, ActiveCallToType } from './ActiveCall'
import ActiveCall from './ActiveCall'
import type { LegacyTransferParams } from './convertSelectionToVoiceTarget'

export interface IVoiceSession {
  token: string
  expiry: number
}

export default class VoiceStore {
  readonly calls = new Collection<ActiveCall>({ bindElements: true })

  audioDevices = new Map<string, MediaDeviceInfo>()
  inputDevices = new Map<string, MediaDeviceInfo>()
  device: Device | null = null
  defaultAudioInputDeviceId: MediaDeviceInfo['deviceId'] | null = null
  defaultAudioOutputDeviceId: MediaDeviceInfo['deviceId'] | null = null
  defaultAudioRingtoneDeviceId: MediaDeviceInfo['deviceId'] | null = null
  ready = false
  focused = false
  // FIXME: this should be removed once we complete the migration to the new operator
  isOldOperator = true
  error: TwilioError.TwilioError | null = null

  protected session: IVoiceSession | null = null

  private readonly disposeBag = new DisposeBag()

  onRefreshRejected?: () => void

  constructor(private root: Service) {
    makeAutoObservable(this, {
      device: false,
      defaultAudioInputDeviceId: observable.ref,
      defaultAudioOutputDeviceId: observable.ref,
      defaultAudioRingtoneDeviceId: observable.ref,
    })
    this.handleWebsocket()

    when(
      () => root.auth.hasSession,
      () => {
        if (root.capabilities.features.callingEnabled) {
          this.startDevice()
        }
        this.disposeBag.add(
          reaction(
            () => root.capabilities.features.callingEnabled,
            (callingEnabled) => {
              if (callingEnabled && !this.ready) {
                this.startDevice()
              } else if (!callingEnabled && this.ready) {
                this.stopDevice()
              }
            },
            { fireImmediately: true },
          ),
        )
      },
    )

    makePersistable(this, 'VoiceStore', {
      defaultAudioInputDeviceId: root.storage.sync(),
      defaultAudioOutputDeviceId: root.storage.sync(),
      defaultAudioRingtoneDeviceId: root.storage.sync(),
    })
  }

  get hasOngoingCall() {
    return this.ongoingCalls.length
  }

  get hasActiveCalls() {
    return this.calls.length > 0
  }

  get incomingCalls() {
    return this.calls.list.filter((c) => c.status === 'incoming')
  }

  get ongoingCalls() {
    return this.calls.list.filter(
      (c) =>
        c.status === 'connecting' ||
        c.status === 'reconnecting' ||
        c.status === 'connected' ||
        c.status === 'ringing',
    )
  }

  startCall = async (from: ActiveCallFromType, to: ActiveCallToType) => {
    if (!this.ready) return
    const callFrom =
      // FIXME: this is hack due to the current nerf of direct dialing
      false && to.every((o) => isDirectNumber(o.number))
        ? this.root.user.current?.directNumber
        : from
    if (this.device && callFrom) {
      const call = await ActiveCall.connect(this.root, this.device, callFrom, to)
      if (call) {
        this.calls.put(call)
        this.root.analytics.callStarted({
          direction: 'outgoing',
          participants: [...to.map((o) => o.number), from.id],
        })
      }
    }
  }

  recordings = {
    start: (roomId: string, participantId: string) => {
      return this.root.transport.voice.recordings.put(roomId, participantId, {
        action: 'start',
      })
    },
    resume: (roomId: string, participantId: string) => {
      return this.root.transport.voice.recordings.put(roomId, participantId, {
        action: 'resume',
      })
    },
    pause: (roomId: string, participantId: string) => {
      return this.root.transport.voice.recordings.put(roomId, participantId, {
        action: 'pause',
      })
    },
    bulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.root.transport.voice.recordings.bulkDelete(activityId, recordingIds)
    },
    // FIXME: this should be removed once we complete the migration to the new operator
    legacyBulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.root.transport.voice.recordings.legacyBulkDelete(
        activityId,
        recordingIds,
      )
    },
    // FIXME: this should be removed once we complete the migration to the new operator
    legacyRecord: (
      callSid: string,
      phoneNumberId: string,
      action: 'start' | 'pause' | 'resume' | 'stop',
    ) => {
      return this.root.transport.voice.recordings.legacyRecord(
        callSid,
        phoneNumberId,
        action,
      )
    },
  }

  room = {
    get: (roomId: string) => {
      return this.root.transport.voice.room.get(roomId)
    },

    transfer: (roomId: string, params: TransferParams) => {
      return this.root.transport.voice.room.transfer(roomId, params)
    },

    // FIXME: this should be removed once we complete the migration to the new operator
    legacyTransfer: (callSid: string, params: LegacyTransferParams) => {
      return this.root.transport.voice.room.legacyTransfer(callSid, params)
    },

    update: (roomId: string, params: UpdateRoomParams) => {
      return this.root.transport.voice.room.update(roomId, params)
    },
  }

  addParticipants(roomId: string, params: AddParticipantsParams) {
    return this.root.transport.voice.participants.add(roomId, params)
  }

  retryAddingParticipant(roomId: string, participantId: string) {
    return this.root.transport.voice.participants.addAgain(roomId, participantId)
  }

  updateParticipant(
    roomId: string,
    participantId: string,
    params: UpdateParticipantsParams,
  ) {
    return this.root.transport.voice.participants.update(roomId, participantId, params)
  }

  removeParticipant(roomId: string, participantId: string) {
    return this.root.transport.voice.participants.remove(roomId, participantId)
  }

  setAudioInputDevice(id: MediaDeviceInfo['deviceId']): Promise<void> {
    this.defaultAudioInputDeviceId = id
    return this.device?.audio?.setInputDevice(id) ?? Promise.resolve()
  }

  setAudioOutputDevice(id: MediaDeviceInfo['deviceId']): Promise<void> {
    this.defaultAudioOutputDeviceId = id
    return this.device?.audio?.speakerDevices.set(id) ?? Promise.resolve()
  }

  setAudioRingtoneDevice(id: MediaDeviceInfo['deviceId']): Promise<void> {
    this.defaultAudioRingtoneDeviceId = id
    return this.device?.audio?.ringtoneDevices.set(id) ?? Promise.resolve()
  }

  private refreshToken(): Promise<IVoiceSession> {
    return this.root.transport.voice
      .token()
      .then(
        action((res) => {
          // FIXME: this should be removed once we complete the migration to the new operator
          this.isOldOperator = res.version < 4
          this.session = {
            token: res.token,
            expiry: Date.parse(res.expiry),
          }
          return this.session
        }),
      )
      .catch((err) => {
        this.onRefreshRejected?.()
        throw err
      })
  }

  private startDevice() {
    if (!isDialog) {
      this.refreshToken().then(
        action((session) => {
          this.stopDevice()
          this.device = new Device(session.token, {
            allowIncomingWhileBusy: true,
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
            maxAverageBitrate: 24000,
            sounds: {
              disconnect: this.root.sound.url('callEnded'),
              outgoing: this.root.sound.url('callStarted'),
              incoming: this.root.sound.url('primaryRingtone'),
            },
          })
          this.device.register()
          this.device.on('registered', this.handleRegistered)
          this.device.on('error', this.handleError)
          this.device.on('unregistered', this.handleUnregistered)
          this.device.on('incoming', this.handleIncoming)
          this.device.on('tokenWillExpire', this.handleTokenWillExpire)
          this.device.audio?.on('deviceChange', this.handleAudioDeviceChange)
        }),
      )
    }
  }

  private handleRegistered = () => {
    this.ready = true
    this.setDefaultAudioInputDevice()
    this.setDefaultAudioOutputDevice()
    this.setDefaultAudioRingtoneDevice()
  }

  private handleError = (error: TwilioError.TwilioError) => {
    this.ready = false
    this.error = error

    // Invalid JWT token
    if (error.code === 31204 || error.code === 31205 || error.code === 9221) {
      this.startDevice()
    } else {
      logError(error)
    }
  }

  private handleUnregistered = () => {
    this.ready = false
  }

  private handleIncoming = async (call: Call) => {
    const activeCall = await ActiveCall.fromIncomingCall(this.root, call)
    if (activeCall) {
      this.calls.put(activeCall)
    }
  }

  private handleTokenWillExpire = () => {
    when(
      // Refreshing the token with an ongoing call will cause it to drop
      () => !this.hasActiveCalls,
      () => {
        // Calling `device.updateToken` is pretty buggy, restarting the device is more reliable
        this.startDevice()
      },
    )
  }

  private handleAudioDeviceChange = () => {
    this.audioDevices =
      this.device?.audio?.availableOutputDevices ?? new Map<string, MediaDeviceInfo>()
    this.inputDevices =
      this.device?.audio?.availableInputDevices ?? new Map<string, MediaDeviceInfo>()
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'recording-status':
          return this.handleRecordingStatus(msg)
        case 'room-participant-update':
          return this.handleRoomParticipantUpdate(msg)
        case 'room-participant-speaking':
        case 'room-participant-on-hold':
        case 'room-participant-muted':
        case 'room-participant-status':
          return this.handleRoomParticipantPartialUpdate(msg)
      }
    })
  }

  private handleRecordingStatus(msg: RecordingStatusMessage) {
    const call = this.calls.find((call) => call.sid === msg.recording.callSid)

    if (call) {
      call.handleRecordingStatusUpdate(msg.recording.recordingStatus)
    }
  }

  private handleRoomParticipantUpdate(msg: RoomParticipantUpdateMessage) {
    const call = this.calls.find((call) => call.roomId === msg.participant.roomId)

    if (call) {
      call.upsertRoomParticipant(msg.participant.id, msg.participant)
    }
  }

  private handleRoomParticipantPartialUpdate(
    msg:
      | RoomParticipantSpeakingMessage
      | RoomParticipantOnHoldMessage
      | RoomParticipantMutedMessage
      | RoomParticipantStatusMessage,
  ) {
    const call = this.calls.find((call) => call.roomId === msg.participant.roomId)

    if (call) {
      call.updateRoomParticipant(msg.participant.id, msg.participant)
    }
  }

  private stopDevice = () => {
    if (!this.device) return
    this.device.off('registered', this.handleRegistered)
    this.device.off('error', this.handleError)
    this.device.off('unregistered', this.handleUnregistered)
    this.device.off('incoming', this.handleIncoming)
    this.device.audio?.off('deviceChange', this.handleAudioDeviceChange)
    this.device.destroy()
    this.device = null
  }

  private setDefaultAudioInputDevice() {
    if (!this.defaultAudioInputDeviceId) return

    this.device?.audio?.setInputDevice(this.defaultAudioInputDeviceId).catch((error) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
      if (error.message.includes('Device not found')) {
        this.defaultAudioInputDeviceId = null
      } else {
        logError(error)
      }
    })
  }

  private setDefaultAudioOutputDevice() {
    if (!this.defaultAudioOutputDeviceId) return

    this.device?.audio?.speakerDevices
      .set(this.defaultAudioOutputDeviceId)
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        if (error.message.includes('Devices not found')) {
          this.defaultAudioOutputDeviceId = null
        } else {
          logError(error)
        }
      })
  }

  private setDefaultAudioRingtoneDevice() {
    if (!this.defaultAudioRingtoneDeviceId) return

    this.device?.audio?.ringtoneDevices
      .set(this.defaultAudioRingtoneDeviceId)
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        if (error.message.includes('Devices not found')) {
          this.defaultAudioRingtoneDeviceId = null
        } else {
          logError(error)
        }
      })
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
