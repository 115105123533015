import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import React from 'react'
import type { AriaProgressBarProps } from 'react-aria'
import { useProgressBar } from 'react-aria'

import VisuallyHidden from '@ui/VisuallyHidden'

import * as styles from './ProgressBar.css'

export interface ProgressBarProps extends AriaProgressBarProps {
  value: number
  status?: 'active' | 'error'
  className?: string
  label: string
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    status = 'active',
    value,
    label,
    isIndeterminate,
    minValue = 0,
    maxValue = 100,
    className,
    ...restOfProps
  } = props
  const { progressBarProps, labelProps } = useProgressBar(props)

  const percentage = Math.round(((value - minValue) / (maxValue - minValue)) * 100)

  return (
    <div>
      <VisuallyHidden>
        <span {...labelProps}>{label}</span>
      </VisuallyHidden>
      <div
        {...restOfProps}
        {...progressBarProps}
        className={cx(
          styles.root,
          isIndeterminate ? styles.indeterminate : undefined,
          status === 'error' ? styles.error : undefined,
          className,
        )}
        style={assignInlineVars({
          [styles.percentageVar]: `${isIndeterminate ? 25 : percentage}`,
        })}
      />
    </div>
  )
}

export default ProgressBar
