export function isMacDesktop(): boolean {
  return navigator.userAgent.includes('Macintosh')
}

export function isFirefox(): boolean {
  return navigator.userAgent.includes('Firefox/')
}

export function isChrome(): boolean {
  return hasUABrand('Google Chrome')
}

export function isEdge(): boolean {
  return hasUABrand('Microsoft Edge')
}

export function isBrave(): boolean {
  return !!navigator?.brave
}

export function isChromium(): boolean {
  return !isChrome() && !isEdge() && !isBrave() && hasUABrand('Chromium')
}

export function isSafari(): boolean {
  return 'safari' in window
}

export function getBrowserName(): string | null {
  if (isFirefox()) return 'Firefox'
  if (isChrome()) return 'Google Chrome'
  if (isBrave()) return 'Brave'
  if (isEdge()) return 'Microsoft Edge'
  if (isSafari()) return 'Safari'
  if (isChromium()) return 'Chromium'
  return null
}

function hasUABrand(brand: string): boolean {
  if (!('userAgentData' in navigator)) return false
  return !!navigator.userAgentData?.brands.find((b) => b.brand === brand)
}
