/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import type Service from '@src/service'
import type { Model } from '@src/service/model/base'
import type { IntegrationEvent } from '@src/service/transport/integration'

export class SlackIntegration implements Model {
  id = ''
  // eslint-disable-next-line @typescript-eslint/prefer-as-const -- FIXME: Fix this ESLint violation!
  type: 'slack' = 'slack'
  events: Partial<IntegrationEvent> = {}
  phoneNumberId: string | null = null
  createdAt: number | null = null
  configureUrl: string | null = null
  channel: string | null = null

  constructor(private service: Service) {
    makeAutoObservable(this, {})
  }

  update(attrs: Partial<this>) {
    Object.assign(this, attrs)
    this.service.integration.updateLegacy(this)
  }

  delete() {
    this.service.integration.deleteLegacy(this)
  }

  deserialize = (json) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      events: toJS(this.events),
      phoneNumberId: this.phoneNumberId,
      createdAt: this.createdAt,
      configureUrl: this.configureUrl,
      channel: this.channel,
    }
  }
}
