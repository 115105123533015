import type { Theme } from '@material-ui/core/styles'
import { makeStyles, alpha } from '@material-ui/core/styles'
import cx from 'classnames'
import React from 'react'

import type { AlertAction } from '@src/app/AppStore'
import IconButton from '@src/component/IconButton'
import Typography from '@ui/Typography'
import { XIcon } from '@ui/icons/tint/20/general'

import Button from './ButtonV2'

type CalloutColor = 'gray' | 'purple' | 'yellow'

interface CalloutProps extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'title'> {
  title?: React.ReactNode
  body: React.ReactNode
  icon: React.ReactNode
  color?: CalloutColor
  elevated?: boolean
  size?: 'small' | 'large'
  onDismiss?: () => void
  actions?: AlertAction[]
}

const Callout = function ({
  icon,
  title,
  body,
  color = 'gray',
  size = 'small',
  elevated,
  className,
  actions,
  onDismiss,
  ...props
}: CalloutProps) {
  const styles = useStyles({ elevated, color, size, onDismiss })
  return (
    <div {...props} className={cx(styles.root, className)}>
      {onDismiss && (
        <IconButton
          className={styles.close}
          size={22}
          icon={<XIcon />}
          onClick={onDismiss}
        />
      )}
      <div className={styles.emoji}>{icon}</div>
      <div className={styles.wrapper}>
        {title && (
          <Typography
            fontWeight="medium"
            variant={size === 'large' ? 'title3' : 'callout'}
            className={styles.title}
          >
            {title}
          </Typography>
        )}
        <Typography
          variant={size === 'large' ? 'body' : 'footnote'}
          className={styles.body}
        >
          {body}
        </Typography>
        {actions && actions?.length > 0 && (
          <div className={styles.actions}>
            {actions
              .slice()
              .reverse()
              .map((action) =>
                action.type === 'destructive' ? (
                  <Button
                    autoFocus
                    key={action.title}
                    height={36}
                    color="danger"
                    variant="contained"
                    onClick={action.onClick}
                    style={{ marginRight: 10 }}
                  >
                    {action.title}
                  </Button>
                ) : (
                  <Button
                    key={action.title}
                    height={36}
                    autoFocus={action.type === 'primary'}
                    variant={action.type === 'primary' ? 'contained' : 'text'}
                    color={action.type === 'primary' ? 'primary' : 'textSecondary'}
                    onClick={action.onClick}
                    style={{ marginRight: 10 }}
                  >
                    {action.title}
                  </Button>
                ),
              )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Callout

const getBgColor = (theme: Theme, { color, elevated }: Partial<CalloutProps>) => {
  if (elevated) {
    return theme.palette.op.background.popover
  }
  switch (color) {
    case 'gray':
      return theme.palette.op.hover.primary
    case 'purple':
      return theme.palette.op.tag.purple.bg
    case 'yellow':
      return theme.palette.op.note.bg
  }
}

const getTitleColor = (theme: Theme, { color, size }: Partial<CalloutProps>) => {
  if (size === 'large') {
    return theme.palette.op.text.primary
  }
  switch (color) {
    case 'gray':
      return theme.palette.op.text.secondary
    case 'purple':
      return theme.palette.op.tag.purple.text
    case 'yellow':
      return theme.palette.op.note.text
  }
}

const getBodyColor = (theme: Theme, color: CalloutColor = 'gray') => {
  switch (color) {
    case 'gray':
      return theme.palette.op.text.tertiary
    case 'purple':
      return alpha(theme.palette.op.tag.purple.text, 0.7)
    case 'yellow':
      return alpha(theme.palette.op.note.text, 0.7)
  }
}

const useStyles = makeStyles<Theme, Partial<CalloutProps>>((theme) => ({
  root: (props) => ({
    background: getBgColor(theme, props),
    borderRadius: 8,
    position: 'relative',
    display: 'flex',
    padding: props.size === 'large' ? 24 : '14px 16px',
    boxShadow: props.elevated
      ? `0 4px 24px 0 rgba(0, 0, 0, 0.2), 0 0 0 1.5px ${theme.palette.op.border.common}`
      : 'none',
  }),
  wrapper: {
    flex: 1,
    marginLeft: 14,
    alignSelf: 'center',
  },
  title: (props) => ({
    marginBottom: props.size === 'large' ? 6 : 2,
    color: getTitleColor(theme, props),
    paddingRight: props.onDismiss ? 40 : 0,
  }),
  body: ({ color }) => ({
    color: getBodyColor(theme, color),
    '& > *': {
      color: 'inherit',
    },
  }),
  emoji: {
    fontSize: 15,
    textAlign: 'center',
    minWidth: 20,
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  actions: {
    display: 'flex',
    marginTop: 14,
  },
}))
