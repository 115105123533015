import { makeAutoObservable, toJS } from 'mobx'

import { getInitials } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import ObjectID from '@src/lib/object-id'
import uuid from '@src/lib/uuid'

import type Service from '..'

import type { Identity, IdentityPhone, Model } from './base'
import type { Member } from './member'

export interface GroupMembership {
  groupId: string
  userId: string
  role: string
}

export interface CodableGroupModel {
  id: string
  name: string
  type: string | null
  symbol: string | null
  description: string | null
  orgId: string | null

  createdBy: string | null
  deletedAt: string | null
  createdAt: string | null
  updatedAt: string | null
  members: GroupMembership[]
}

export class GroupModel implements Model, Identity {
  id: string = ObjectID()
  name = ''
  type: string | null = null
  symbol = ''
  description = ''
  orgId: string | null = null

  createdBy: string | null = null
  deletedAt: string | null = null
  createdAt: string | null = null
  updatedAt: string | null = null
  members: GroupMembership[] = []

  constructor(private root: Service, attrs: Partial<GroupModel>) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get membersIdentities(): Member[] {
    return this.members
      .map((member) => this.root.member.collection.get(member.userId))
      .filter(isNonNull)
  }

  set membersIdentities(members: Member[]) {
    this.members = members.map((member) => ({
      userId: member.id,
      groupId: this.id,
      role: member.role,
    }))
  }

  get shortName() {
    return this.name
  }

  get initials() {
    return getInitials(this.name)
  }

  get pictureUrl() {
    return ''
  }

  get isAnonymous() {
    return false
  }

  get phones(): IdentityPhone[] {
    return this.membersIdentities.reduce(
      (acc, member) => [...acc, ...member.phoneNumbers],
      [] as IdentityPhone[],
    )
  }

  get emailAddresses() {
    return this.membersIdentities.map((member) => member.email)
  }

  save() {
    if (!this.name) return
    if (!this.id) {
      this.id = `GR${uuid()}`.replace(/-/g, '')
      return this.root.workspace.createGroup({
        id: this.id,
        description: this.description,
        type: this.type,
        name: this.name,
        symbol: this.symbol,
        members: this.members,
      })
    }

    return this.root.workspace.updateGroup({
      id: this.id,
      description: this.description,
      type: this.type,
      name: this.name,
      symbol: this.symbol,
    })
  }

  delete() {
    if (this.id) {
      this.deletedAt = Date.now().toString()
      return this.root.workspace.deleteGroup(this.id)
    }
  }

  deserialize(json: Partial<GroupModel>) {
    Object.assign(this, json)

    return this
  }

  serialize(): CodableGroupModel {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      symbol: this.symbol,
      description: this.description,
      orgId: this.orgId,
      createdBy: this.createdBy,
      deletedAt: this.deletedAt,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      members: toJS(this.members),
    }
  }
}
