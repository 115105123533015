import cx from 'classnames'
import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import { useStorePartnerStackKey } from '@src/app/onboarding/partnerStack'
import { RecaptchaProvider } from '@src/app/recaptcha'
import { Redirect } from '@src/app/router'
import { isMac } from '@src/config'
import useStore from '@src/lib/hooks/useStore'

import EmailCode from './EmailCode'
import ForgotPassword from './ForgotPassword'
import * as styles from './Login.css'
import LoginOptions from './LoginOptions'
import Password from './Password'
import { LoginUiStore } from './store'

const LoginStoreContext = React.createContext<LoginUiStore | null>(null)

function Login() {
  const app = useAppStore()
  const store = useStore(() => new LoginUiStore(app), [])

  useStorePartnerStackKey()

  return (
    <LoginStoreContext.Provider value={store}>
      <RecaptchaProvider>
        <div className={cx(styles.root, { [styles.rootMac]: isMac })}>
          <Routes>
            <Route path="coupon/*" element={<LoginOptions />} />
            <Route path="signup/*">
              <Route path="code" element={<EmailCode />} />
              <Route path="*" element={<LoginOptions />} />
            </Route>
            <Route path="login/*">
              <Route path="code" element={<EmailCode />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route path="password" element={<Password />} />
              <Route path="*" element={<LoginOptions />} />
            </Route>
            <Route path="*" element={<Redirect to="login" />} />
          </Routes>
        </div>
      </RecaptchaProvider>
    </LoginStoreContext.Provider>
  )
}

export default Login

export function useLoginStore() {
  const store = useContext(LoginStoreContext)
  if (!store) {
    throw new Error('Login Store is not defined')
  }
  return store
}
