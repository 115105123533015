/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, flow, makeAutoObservable, remove } from 'mobx'
import type { Subscription } from 'rxjs'

import { NotFoundError } from '@src/lib/api/errorHandler'
import isNonNull from '@src/lib/isNonNull'
import log from '@src/lib/log'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import makePersistable from '@src/service/storage/makePersistable'
import type { ConversationParticipantStatus } from '@src/service/transport/communication'

import type Service from '.'
import type { IActivity, IConversation } from './model'
import { ActivityModel, Conversation, Participant } from './model'
import type { ActivitySearchParams } from './transport/communication'
import type { PageInfo } from './transport/lib/Paginated'
import type {
  ActivityUpdateMessage,
  ConversationUpdateMessage,
  ParticipantStatusMessage,
} from './transport/websocket'
import type MainWorker from './worker/main'
import type { ConversationRepository } from './worker/repository/conversation'

export type ConversationQuery = {
  phoneNumberId?: string
  directNumberId?: string
  phoneNumber: string
  canBeNew?: boolean
}

export type ConversationsQuery = {
  phoneNumberId?: string
  directNumberId?: string
  snoozed?: boolean
  read?: boolean
  last?: number
}

export default class ConversationStore {
  readonly collection: PersistedCollection<Conversation, ConversationRepository>

  private lastFetchedAt: { [key: string]: number } = {}
  private pageInfos: { [key: string]: PageInfo } = {}

  unreadCounts: Record<string, number> = {}
  private unreadCountSubscription: Subscription | null = null

  private updateTimeout: number | null = null
  private batchedConversationUpdates: Conversation[] = []
  private batchedConversationDeletes: string[] = []
  private batchedActivityUpdates: ActivityModel[] = []

  presence: {
    [key: string]: {
      [key: string]: { status: ConversationParticipantStatus; timestamp: number }
    }
  } = {}

  constructor(private root: Service, private worker: MainWorker) {
    this.collection = new PersistedCollection({
      table: root.storage.table('conversation'),
      classConstructor: () => new Conversation(root),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'lastFetchedAt' | 'pageInfos'>(this, 'ConversationStore', {
      lastFetchedAt: root.storage.sync(),
      pageInfos: root.storage.async(),
    })

    this.handleWebsocket()

    setInterval(this.cleanUpLastPresenceTimes, 10000)
  }

  fetchRecent = (params: ConversationsQuery) => {
    // fetch the first 200 convos
    const shouldFetchTheMostRecentBatchOfConversations =
      this.root.flags.getFlag('numOfConversationsToFetchWhenFiltering') !== 0
    const self = this
    const key = this.keyForQuery(params)
    const lastFetchedAt = shouldFetchTheMostRecentBatchOfConversations
      ? 0
      : this.lastFetchedAt[key]
    const since = lastFetchedAt ? new Date(lastFetchedAt) : undefined
    const filters = since
      ? { directNumberId: params.directNumberId, phoneNumberId: params.phoneNumberId }
      : params
    return this.root.transport.communication.conversations
      .list({
        ...filters,
        since,
        includeDeleted: Boolean(since),
      })
      .then(
        flow(function* (response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          const convos = yield self.load(response.result)
          self.lastFetchedAt[key] = Math.max(
            lastFetchedAt || 0,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            ...convos.map((c) => c.updatedAt),
          )
          if (!since) {
            self.pageInfos[key] = response.pageInfo
          }
        }),
      )
  }

  hasMore = (params: ConversationsQuery): boolean => {
    const key = this.keyForQuery(params)
    const pageInfo = this.pageInfos[key]
    return !pageInfo || pageInfo?.hasPreviousPage == true
  }

  /**
   * Tries to fetch a conversation from the api given its id, if the conversation is found
   * it will store it and return it back.
   */
  fetchConversation = async (conversationId: string): Promise<Conversation | null> => {
    const { conversation, result: activities } =
      await this.root.transport.communication.activities.list({
        id: conversationId,
      })

    if (!conversation || !activities) {
      return null
    }

    const [storedConversation] = await this.load([conversation], activities)

    return storedConversation
  }

  /**
   * Tries to get a conversation from memory first, then the underlying storage and then
   * if not found, from the API.
   * @param id
   */
  getById = async (id: string): Promise<Conversation | null> => {
    const conversation: Conversation | null =
      this.collection.get(id, { skipStorage: true }) ??
      (await this.collection.performQuery((repo) => repo.get(id))) ??
      (await this.fetchConversation(id))

    return conversation
  }

  findAll = async (query: ConversationQuery): Promise<Conversation[]> => {
    query.phoneNumber = query.phoneNumber.split(',').sort().join(',')
    this.collection.performQuery((table) => table.find(query))
    return this.root.transport.communication.conversations
      .list(query)
      .then((res) => this.load(res.result))
  }

  findOne = async (query: ConversationQuery): Promise<Conversation> => {
    let conv: Conversation | null | undefined
    query.phoneNumber = query.phoneNumber.split(',').sort().join(',')

    const filter = (c: IConversation): boolean => {
      return (
        (query.canBeNew ? true : !c.isNew) &&
        !c.deletedAt &&
        c.phoneNumber === query.phoneNumber &&
        (!query.directNumberId || query.directNumberId === c.directNumberId) &&
        (!query.phoneNumberId || query.phoneNumberId === c.phoneNumberId)
      )
    }

    /**
     * Check memory
     */
    if ((conv = this.collection.list.find(filter))) {
      return conv
    }

    /**
     * Check database
     */
    if (
      (conv = await this.collection
        .performQuery((repo) => repo.find(query))
        .then((a) => a[0]))
    ) {
      return conv
    }

    /**
     * Ask the API
     */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return (
      this.root.transport.communication.activities
        .list(query)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        .then((res) => this.load([res.conversation], res.result)[0])
        .catch((e) => {
          if (e instanceof NotFoundError) {
            return null
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          log.error(e)
        })
    )
  }

  findList = (params: ConversationsQuery) => {
    return this.collection.performQuery((repo) =>
      repo
        .find(params)
        .then((conversations) =>
          Promise.all([
            this.root.activity.loadByIds(
              conversations.map((c) => c.lastActivityId ?? ''),
            ),
            this.root.contact.loadByNumbers(
              conversations.flatMap((c) => (c.phoneNumber ?? '').split(',')),
            ),
          ]).then(() => conversations),
        ),
    )
  }

  fetchMore = (params: ConversationsQuery) => {
    const key = this.keyForQuery(params)
    const pageInfo = this.pageInfos[key]
    if (!this.hasMore(params)) return Promise.resolve()
    return this.root.transport.communication.conversations
      .list({ ...params, before: pageInfo?.startId, includeDeleted: false })
      .then(
        action((response) => {
          this.load(response.result)
          this.pageInfos[key] = response.pageInfo
        }),
      )
  }

  search = (params: ActivitySearchParams) => {
    return this.root.transport.communication.conversations.search(params)
  }

  snooze = (id: string, duration = 525949200) => {
    return this.root.transport.communication.conversations.snooze(id, duration)
  }

  unsnooze = (id: string) => {
    return this.root.transport.communication.conversations.unsnooze(id)
  }

  markAsRead = (id: string) => {
    return this.root.transport.communication.conversations.markAsRead(id)
  }

  markAsUnread = (id: string) => {
    return this.root.transport.communication.conversations.markAsUnread(id)
  }

  archive = async (id: string) => {
    const conversation = this.collection.get(id)
    if (!conversation) {
      return
    }

    if (!conversation.isNew) {
      await this.root.transport.communication.conversations.archive(id)
    }

    this.collection.delete(conversation)
  }

  create(conversation: Conversation) {
    if (conversation.phoneNumberId) {
      return this.root.transport.communication.conversations.create({
        phoneNumberId: conversation.phoneNumberId,
        to: conversation.participants.map((p) => p.phoneNumber).join(','),
        conversationId: conversation.id,
      })
    }
  }

  updateName(conversation: Conversation) {
    if (!conversation.name) return
    return this.root.transport.communication.conversations
      .updateName({
        name: conversation.name,
        conversationId: conversation.id,
      })
      .then(() => {
        this.root.analytics.conversationRenamed()
      })
  }

  delete(conversation: Conversation) {
    return this.root.transport.communication.conversations.delete({
      id: conversation.id,
    })
  }

  participantStatus = async (
    conversationId: string,
    status: ConversationParticipantStatus,
  ) => {
    const userId = this.root.user.current?.id
    if (!userId) {
      return
    }
    this.presence[conversationId] ??= {}
    if (status === 'exited') {
      remove(this.presence[conversationId], userId)
    } else {
      this.presence[conversationId][userId] = { timestamp: Date.now(), status }
    }
    return this.root.transport.communication.conversations.participantStatus(
      conversationId,
      status,
    )
  }

  upload(
    file: File,
    onProgress?: (progress: number, total: number) => void,
  ): Promise<string> {
    return this.root.transport.communication.upload(file, onProgress)
  }

  loadUnreadCounts() {
    this.unreadCountSubscription?.unsubscribe()
    this.unreadCountSubscription = this.worker.onEvent.subscribe((event) => {
      if (event.type === 'unread changed') {
        this.setUnreadCounts(event.unreadCounts)
      }
    })
  }

  private keyForQuery(params: ConversationsQuery) {
    return Object.keys(params)
      .sort()
      .map((k) => `${k}=${params[k]}`)
      .join('&')
  }

  async load(
    conversations: IConversation[],
    activities: IActivity[] = [],
  ): Promise<Conversation[]> {
    const convos = await this.collection.load(conversations)
    await this.root.activity.collection.load([
      ...conversations.map((c) => c.lastActivity).filter(isNonNull),
      ...activities,
    ])
    return convos
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'activity-update':
        case 'conversation-update':
          return this.handleConversationUpdate(msg)
        case 'participant-status':
          return this.handleParticipantStatus(msg)
      }
    })
  }

  private handleParticipantStatus(message: ParticipantStatusMessage) {
    if (message.status.effectiveAt && message.user.id) {
      this.presence[message.conversationId] ??= {}
      const conversation = this.presence[message.conversationId]
      if (message.status.name === 'exited') {
        remove(conversation, message.user.id)
        return
      }
      const lastStatus = conversation[message.user.id]
      const timestamp = Date.parse(message.status.effectiveAt)
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain -- FIXME: Fix this ESLint violation!
      if (lastStatus && lastStatus.timestamp && lastStatus.timestamp > timestamp) {
        return
      } else {
        this.presence[message.conversationId][message.user.id] = {
          status: message.status.name,
          timestamp,
        }
      }
    }
  }

  private cleanUpLastPresenceTimes = () => {
    const threshold = Date.now() - 90000
    for (const conversationId in this.presence) {
      for (const userId in this.presence[conversationId]) {
        if (this.presence[conversationId][userId].timestamp < threshold) {
          remove(this.presence[conversationId], userId)
        }
      }
    }
  }

  private handleConversationUpdate(
    msg: ActivityUpdateMessage | ConversationUpdateMessage,
  ) {
    if (this.updateTimeout) {
      window.clearTimeout(this.updateTimeout)
    }
    if (msg.conversation?.deletedAt) {
      if (!this.batchedConversationDeletes.includes(msg.conversation.id)) {
        this.batchedConversationDeletes.push(msg.conversation.id)
      }
    } else {
      const alreadyBatchedUpdate = this.batchedConversationUpdates.findIndex(
        (c) => c.id === msg.conversation.id,
      )
      if (alreadyBatchedUpdate === -1) {
        this.batchedConversationUpdates.push(msg.conversation)
      } else {
        this.batchedConversationUpdates[alreadyBatchedUpdate] = {
          ...this.batchedConversationUpdates[alreadyBatchedUpdate],
          ...msg.conversation,
        } as Conversation
      }
      if (msg.activity) {
        const alreadyBatchedActivity = this.batchedActivityUpdates.findIndex(
          (a) => a.id === msg.activity?.id,
        )
        if (alreadyBatchedActivity === -1) {
          this.batchedActivityUpdates.push(msg.activity)
        } else {
          this.batchedActivityUpdates[alreadyBatchedActivity] = {
            ...this.batchedActivityUpdates[alreadyBatchedActivity],
            ...msg.activity,
          } as ActivityModel
        }
        if (
          msg.activity.type === 'message' &&
          msg.activity.createdAt === msg.activity.updatedAt &&
          msg.activity.direction === 'incoming'
        ) {
          this.root.analytics.messageReceived()
        }
      }
    }
    this.updateTimeout = window.setTimeout(async () => {
      this.collection.deleteBulk(this.batchedConversationDeletes)
      await this.collection.load(this.batchedConversationUpdates)
      await this.root.activity.collection.load(this.batchedActivityUpdates)
      this.batchedConversationDeletes = []
      this.batchedConversationUpdates = []
      this.batchedActivityUpdates = []
    }, 500)
  }

  private setUnreadCounts(unreadCounts: Record<string, number>) {
    this.unreadCounts = unreadCounts
  }
}

export { Conversation, ActivityModel, Participant }
