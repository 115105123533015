import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppStore } from '@src/app/context'

const RouterAnalytics = () => {
  const location = useLocation()
  const appStore = useAppStore()

  useEffect(() => {
    appStore.service.analytics.page(location.pathname)
  }, [location.pathname, appStore.service.analytics])

  return null
}

export default RouterAnalytics
