import type { Method } from 'axios'
import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { toQueryString } from '@src/lib'
import { ConnectionError } from '@src/lib/api/errorHandler'
import ObjectID from '@src/lib/object-id'
import type { Model } from '@src/service/model'

import type ApiClient from './network/ApiClient'
import type WebSocket from './network/websocket'

type PromiseFunc<T> = { resolve: (res: T) => void; reject: (error: Error) => void }

export abstract class Transaction<T = any> implements Model {
  abstract id: string
  type: string | null = null
  status: 'queued' | 'in-progress' | 'completed' = 'queued'
  queue?: string = ''
  response: T | null = null
  error: Error | null = null
  retry?: boolean = false
  retryAttempts?: number = 0
  createdAt = Date.now()

  private promises: PromiseFunc<T>[] = []

  constructor() {
    makeObservable(this, {
      status: observable,
      response: observable,
      error: observable,
      isPending: computed,
      fulfill: action,
      reject: action,
    })
  }

  get isPending() {
    return this.status === 'queued'
  }

  promise(): Promise<T> {
    if (this.status === 'completed' && this.error) {
      return Promise.reject(this.error)
    } else if (this.status === 'completed' && this.response) {
      return Promise.resolve(this.response)
    } else {
      return new Promise<T>((resolve, reject) => {
        this.promises.push({ resolve, reject })
      })
    }
  }

  fulfill(response: T): T {
    this.status = 'completed'
    this.response = response
    this.promises.forEach((p) => p.resolve(response))
    this.promises = []
    return response
  }

  reject(error: Error): Error {
    this.status = 'completed'
    this.error = error
    this.promises.forEach((p) => p.reject(error))
    this.promises = []
    return this.error
  }

  abstract serialize()

  abstract deserialize(json: any): this
}

export class HttpTransaction<R = any, Q = any, T = any> extends Transaction<R> {
  override id = ObjectID()
  override type = 'http'
  url: string | null = null
  method?: Method = 'GET'
  query?: Q | null = null
  body?: T | null = null
  headers?: Record<string, string>

  constructor(params: Partial<HttpTransaction>) {
    super()
    Object.assign(this, params)

    makeObservable(this, {
      run: action,
    })
  }

  run(client: ApiClient): Promise<R> {
    this.status = 'in-progress'
    return client
      .perform<R, any>({
        url: `${this.url}${toQueryString(
          typeof this.query === 'object' ? this.query : null,
        )}`,
        method: this.method,
        data: this.body,
        headers: { 'x-op-requestid': this.id, ...this.headers },
      })
      .then(this.fulfill.bind(this))
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      status: this.status,
      queue: this.queue,
      response: toJS(this.response),
      error: toJS(this.error),
      retry: this.retry,
      retryAttempts: this.retryAttempts,
      url: this.url,
      method: this.method,
      query: toJS(this.query),
      body: toJS(this.body),
    }
  }
}

export class WebsocketTransaction<R = any, T = any> extends Transaction<R> {
  override id = ObjectID()
  override type = 'websocket'
  _class: 'Query' | 'Command' | null = null
  path: string | null = null
  method = 'GET'
  params?: any = {}
  body?: T | null = null

  constructor(params: Partial<WebsocketTransaction>) {
    super()
    Object.assign(this, params)

    makeObservable(this, {
      run: action,
    })
  }

  run(client: WebSocket): Promise<R> {
    this.status = 'in-progress'
    return new Promise((resolve, reject) => {
      const result = client.send({
        id: this.id,
        _class: this._class,
        path: this.path,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        params: this.params,
        body: this.body,
      })

      if (result === false) {
        const error = new ConnectionError('Failed to deliver websocket message')
        this.reject(error)
        return reject(error)
      }

      const handleResponse = (data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        if (data.id === this.id) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          this.fulfill(data.body)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          resolve(data.body)
          client.off('message', handleResponse)
        }
      }

      client.on('message', handleResponse)
    })
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      status: this.status,
      queue: this.queue,
      response: toJS(this.response),
      error: toJS(this.error),
      retry: this.retry,
      retryAttempts: this.retryAttempts,
      _class: this._class,
      path: this.path,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      params: toJS(this.params),
      body: toJS(this.body),
    }
  }
}
