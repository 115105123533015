import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import shuffle from 'lodash/fp/shuffle'
import React, { useEffect, useRef, useState } from 'react'

import useTransition from '@src/lib/hooks/useTransition'
import Typography from '@ui/Typography'

import * as styles from './RotatingTips.css'

const tips = shuffle([
  <>
    Use{' '}
    <Typography variant="caption3" color="textPrimary" className={styles.shortcuts}>
      <kbd>⌘</kbd>
      <kbd>K</kbd>
    </Typography>
    at any time to bring up the Command Menu
  </>,
  <>
    Use{' '}
    <Typography variant="caption3" color="textPrimary" className={styles.shortcuts}>
      <kbd>⌘</kbd>
      <kbd>/</kbd>
    </Typography>
    at any time to bring up the search window
  </>,
  <>
    When writing a message, press{' '}
    <Typography variant="caption3" color="textPrimary" className={styles.shortcuts}>
      <kbd>/</kbd>
    </Typography>{' '}
    to bring up commands
  </>,
  <>Use snippets to save and send frequently written messages</>,
  <>Head to phone number settings to manage your call flow</>,
  <>You can schedule any message to be sent at a later date</>,
  <>Connect to HubSpot or Salesforce to sync contact and activity data</>,
  <>Create webhooks to send real-time notifications of events to your apps</>,
  <>Invite a teammate to your workspace to start sharing phone numbers</>,
  <>Use auto-replies to automatically respond to missed calls</>,
  <>Connect your number to Slack to receive text notifications</>,
  <>Configure business hours to conditionally send calls to voicemail</>,
])

interface RotatingTipsProps {
  height?: number
  wait?: number
  animationDuration?: number
  initiallyHidden?: boolean
}

export const RotatingTips = ({
  height = 22,
  wait = 5000,
  animationDuration = 700,
  initiallyHidden = true,
  ...props
}: RotatingTipsProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const state = useTransition(true, { enterDelay: 3000 })
  const hidden = initiallyHidden ? state !== 'entered' : false
  const [index, setIndex] = useState(0)

  const currentTip = tips[index % tips.length]
  const nextTip = tips[(index + 1) % tips.length]

  useEffect(() => {
    if (hidden || !wrapperRef.current || tips.length === 1) return

    const animation = wrapperRef.current.animate(
      [{ transform: `none` }, { transform: `translateY(${-height}px)` }],
      {
        duration: animationDuration,
        easing: 'cubic-bezier(0.79,0.14,0.15,0.86)',
        delay: wait,
      },
    )

    const onAnimationFinish = () => {
      setIndex((prev) => prev + 1)
      animation.play()
    }

    animation.addEventListener('finish', onAnimationFinish)

    return () => {
      animation.removeEventListener('finish', onAnimationFinish)
    }
  }, [height, animationDuration, wait, hidden])

  return (
    <div
      {...props}
      className={cx(styles.root, hidden && styles.hidden)}
      style={assignInlineVars({ [styles.heightVar]: `${height}px` })}
    >
      <div ref={wrapperRef} className={styles.wrapper}>
        <Typography variant="callout" color="textSecondary" className={styles.tip}>
          {currentTip}
        </Typography>
        <Typography variant="callout" color="textSecondary" className={styles.tip}>
          {nextTip}
        </Typography>
      </div>
    </div>
  )
}

export default RotatingTips
