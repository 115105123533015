/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */

import { action, flow, makeAutoObservable, remove } from 'mobx'

import type ById from '@src/lib/ById'
import { chunk, insertAtIndex, removeAtIndex } from '@src/lib/collections'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type { ContactNoteCodableReaction } from '@src/service/model/reactions/ContactNoteReaction'
import ContactNoteReaction, {
  isContactNoteReaction,
} from '@src/service/model/reactions/ContactNoteReaction'
import makePersistable from '@src/service/storage/makePersistable'
import type { SharedContactSettingsRepository } from '@src/service/worker/repository/SharedContactSettingsRepository'

import type Service from '.'
import type { GroupMembership, IContact, ISharedContactSettings, Note } from './model'
import {
  buffer,
  Contact,
  GoogleContactSettings,
  SharedContactSettings,
  Member,
} from './model'
import { ContactTemplateItem } from './model/contact-template-item'
import type { PageInfo } from './transport/lib/Paginated'
import type { GooglePeopleSyncProgressNotification } from './transport/websocket'
import type MainWorker from './worker/main'
import type {
  ContactRepository,
  ContactGoogleSettingsRepository,
  ContactTemplateItemRepository,
} from './worker/repository'

interface CsvImport {
  name: string
  userId: string
}
export default class ContactStore {
  readonly collection: PersistedCollection<Contact, ContactRepository>
  readonly template: PersistedCollection<
    ContactTemplateItem,
    ContactTemplateItemRepository
  >
  readonly googleContactSettings: PersistedCollection<
    GoogleContactSettings,
    ContactGoogleSettingsRepository
  >
  settings: PersistedCollection<SharedContactSettings, SharedContactSettingsRepository>
  csvImports: CsvImport[] = []

  private byNumber: ById<Set<Contact>> = {}
  private contactNumbers: ById<string[]> = {}

  private pageInfo: PageInfo | null = null
  private lastFetchedAt: number | null = null
  private _loaded = false

  constructor(private root: Service, private worker: MainWorker) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('contact'),
      classConstructor: () => new Contact(root),
    })
    this.template = new PersistedCollection({
      table: this.root.storage.table('contactTemplateItem'),
      classConstructor: () => new ContactTemplateItem(root),
    })
    this.googleContactSettings = new PersistedCollection({
      table: this.root.storage.table('contactGoogleSettings'),
      classConstructor: () => new GoogleContactSettings(root),
    })

    this.settings = new PersistedCollection({
      table: this.root.storage.table('contactSettings'),
      classConstructor: () => new SharedContactSettings(root),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'pageInfo' | 'lastFetchedAt'>(this, 'ContactStore', {
      pageInfo: root.storage.sync(),
      lastFetchedAt: root.storage.sync(),
    })

    this.handleWebsocket()
    this.handleIndexByPhoneNumber()
    this.loadCsvImports()
  }

  get sharedContactSettings(): SharedContactSettings | null {
    return this.settings.list[0]
  }

  set sharedContactSettings(value: Partial<SharedContactSettings> | null) {
    const settings = this.settings.list[0]
    const defaultSharingIds = value?.defaultSharingIds

    if (settings && defaultSharingIds) {
      settings.defaultSharingIds = defaultSharingIds
    }
  }

  get defaultSharingIds(): readonly string[] {
    return this.sharedContactSettings?.defaultSharingIds ?? []
  }

  get sortedTemplates() {
    return this.template.list.sort((i1, i2) => {
      if (i1.order == null) return 1
      if (i2.order == null) return -1
      return i1.order - i2.order
    })
  }

  get(id: string) {
    return this.collection.get(id)
  }

  getByNumber(number: string) {
    this.loadByNumber(number)
    return this.byNumber[number] ? Array.from(this.byNumber[number]) : []
  }

  loadAll = () => {
    return this.collection.performQuery((repo) => repo.all())
  }

  loadAllIfNecessary = () => {
    if (this._loaded) return
    this._loaded = true
    return this.loadAll()
  }

  loadByNumbers = (numbers: string[]) => {
    const load = numbers.filter((number) => !this.byNumber[number])
    return this.collection.performQuery((repo) => repo.getByPhoneNumbers(load))
  }

  loadByNumber = buffer(this.loadByNumbers)

  googleSync = (token: string, redirectUri = 'postmessage'): Promise<any> => {
    return this.root.transport.contacts.googleSync(token, redirectUri)
  }

  fetchMissing = async (): Promise<any> => {
    const self = this
    if (this.pageInfo?.hasNextPage === false && this.lastFetchedAt) {
      return this.root.transport.contacts
        .fetch({ since: new Date(this.lastFetchedAt), includeDeleted: true })
        .then(
          flow(function* (resp) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            const contacts = yield self.load(resp)
            self.lastFetchedAt = Math.max(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
              ...contacts.map((c) => c.updatedAt),
              Number(self.lastFetchedAt) + 1,
            )
          }),
        )
    } else if (this.pageInfo?.hasNextPage !== false) {
      return this.root.transport.contacts
        .fetch({ limit: 200, lastId: this.pageInfo?.endId })
        .then(
          flow(function* (resp) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            const contacts = yield self.load(resp.result)
            self.pageInfo = resp.pageInfo
            self.lastFetchedAt = Math.max(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
              ...contacts.map((c) => c.updatedAt),
              Number(self.lastFetchedAt) + 1,
            )
          }),
        )
        .then(() => this.fetchMissing())
    }
  }

  async createBulk(contacts: Contact[]) {
    await this.collection.load(contacts)
    return Promise.all(
      chunk(contacts, 100).map((contacts) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        this.root.transport.contacts.createBulk(contacts.map((c) => c.toJSON())),
      ),
    )
  }

  update = (contact: Contact) => {
    contact.local = false
    this.collection.put(contact)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    return this.root.transport.contacts.update(contact.toJSON())
  }

  merge = (contact: Contact, withContacts: Contact[]) => {
    // TODO: offline support
    return this.root.transport.contacts.merge(
      contact.id,
      withContacts.map((c) => c.id),
    )
  }

  delete = async (contact: Contact) => {
    this.collection.delete(contact)
    if (!contact.local) {
      return this.root.transport.contacts.delete(contact.id)
    }
  }

  deleteBySource = async (source: string, sourceName: string) => {
    await this.loadAllIfNecessary()
    const ids = this.collection.list
      .filter((c) => c.source === source && c.sourceName === sourceName)
      .map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteAll = () => {
    const ids = this.collection.list.map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteBulk = (ids: string[]) => {
    if (!ids || ids.length === 0) return Promise.resolve()
    this.collection.deleteBulk(ids)
    return Promise.all(
      chunk(ids, 500).map((ids) => this.root.transport.contacts.deleteBulk(ids)),
    )
  }

  shareBulk = async (
    contactIds: string[],
    shareIds: string[],
    progress: (progress: number) => void,
  ) => {
    progress(0)
    const batches = chunk(contactIds, 250)
    for (let i = 0; i < batches.length; i++) {
      const ids = batches[i]
      await this.root.transport.contacts.shareBulk(ids, shareIds)
      progress((i + 1) / (batches.length - 1))
    }
  }

  putNote = (note: Note) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.put(note.contact.id, note.toJSON())
  }

  deleteNote = (note: Note) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.deleteNote(note.contact.id, note.id)
  }

  fetchTemplates = () => {
    this.template.performQuery((repo) => repo.all())
    return this.root.transport.contacts.template
      .fetch()
      .then(action((res) => this.template.load(res, { deleteOthers: true })))
  }

  updateTemplate = (template: ContactTemplateItem) => {
    template.local = false
    this.template.put(template)
    return this.root.transport.contacts.template
      .put(template.serialize())
      .then(this.template.load)
  }

  deleteTemplate = async (template: ContactTemplateItem) => {
    this.template.delete(template)
    if (!template.local) {
      return this.root.transport.contacts.template.delete(template.serialize())
    }
  }

  reorderTemplates = (from: number, to: number) => {
    const sorted = [...this.sortedTemplates]
    const item = sorted[from]
    insertAtIndex(removeAtIndex(sorted, from), item, to).map((t, order) => {
      t.update({ order })
    })
  }

  fetchGoogleContactSettings = () => {
    this.googleContactSettings.performQuery((repo) => repo.all())
    return this.root.transport.contacts.settings
      .fetchGoogleSettings()
      .then((res) => this.googleContactSettings.load(res, { deleteOthers: true }))
  }

  fetchSettings = () => {
    this.settings.performQuery((repo) => repo.all())
    return this.root.transport.contacts.settings.fetch().then((res) => {
      this.settings.load(res, { deleteOthers: true })
    })
  }
  loadCsvImports() {
    this.worker.service.contact.getUniqueSources('csv').then(
      action((data) => {
        this.csvImports = data.map((item) => ({
          name: item.sourceName,
          userId: item.userId,
        }))
      }),
    )
  }

  updateSettings = (settings: Pick<ISharedContactSettings, 'defaultSharingIds'>) => {
    return this.root.transport.contacts.settings.put(settings)
  }

  deleteSettings = (id: string) => {
    this.googleContactSettings.delete(id)
    return this.root.transport.contacts.settings.delete(id)
  }
  addNoteReaction(
    contactId: string,
    noteId: string,
    reaction: ContactNoteCodableReaction,
  ) {
    return this.root.transport.contacts.note.addReaction(contactId, noteId, reaction)
  }

  deleteNoteReaction(
    contactId: string,
    noteId: string,
    reaction: ContactNoteCodableReaction,
  ) {
    return this.root.transport.contacts.note.deleteReaction(
      contactId,
      noteId,
      reaction.id,
    )
  }

  /**
   * Calculates the total number of unique members that are contained
   * in the list of entity ids.
   *
   * @param entityIds string[] - List of user, organization, and group ids
   */
  getUniqueMemberCountInEntities(entityIds: string[]) {
    const userMembersIds = entityIds.filter((id) => id.startsWith('US'))
    const groupAndPhoneNumberMembersIds = entityIds
      .filter((id) => id.startsWith('GR'))
      .reduce((result, groupId) => {
        const phoneNumberOrGroup =
          this.root.workspace.groups.get(groupId) ??
          this.root.phoneNumber.collection.list.find((ph) => ph.groupId === groupId)

        const membersIds: string[] =
          phoneNumberOrGroup?.members.map((member: Member | GroupMembership) => {
            if (member instanceof Member) {
              return member.id
            }

            return member.userId
          }) ?? []
        return [...result, ...membersIds]
      }, [] as string[])
    return [...new Set([...userMembersIds, ...groupAndPhoneNumberMembersIds])].length
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'reaction-update':
          if (isContactNoteReaction(data.reaction)) {
            return this.handleContactNoteReactionUpdate(data.reaction)
          }
          break
        case 'reaction-delete':
          if (isContactNoteReaction(data.reaction)) {
            return this.handleContactNoteReactionDelete(data.reaction)
          }
          break
        case 'contact-update':
        case 'contact-note-update':
        case 'contact-note-delete':
          this.updateCsvImportSourcesIfNeeded(data.contact)
          return this.collection.load(data.contact)

        case 'contact-delete':
          return this.collection.delete(data.contact.id)

        case 'bulk-operation-complete': {
          if (data.collection === 'contacts') {
            this.fetchMissing()
          }
          return
        }

        case 'template-update':
          return this.template.load(data.template)
        case 'template-delete':
          return this.template.delete(data.template.id)
        case 'google-people-sync-progress':
          return this.handleGooglePeopleSyncProgressNotification(data)

        case 'contact-settings-update':
          return this.handleContactSettingsUpdate(data.settings)
        case 'contact-settings-delete':
          return this.handleContactSettingsDelete(data.settings)
      }
    })
  }

  private handleContactNoteReactionUpdate(value: ContactNoteReaction) {
    const contact = this.collection.get(value.contactId)
    const note = contact?.notes.find((note) => note.id === value.noteId)

    if (note) {
      const reaction = note.reactions.find((reaction) => reaction.id === value.id)

      if (reaction) {
        reaction.deserialize(value)
      } else {
        note.reactions.push(new ContactNoteReaction(this.root, note, value))
      }
    }
  }

  private handleContactNoteReactionDelete(value: ContactNoteReaction) {
    const contact = this.collection.get(value.contactId)
    const note = contact?.notes.find((note) => note.id === value.noteId)

    if (!note) {
      return
    }

    const index = note.reactions.findIndex((reaction) => reaction.id === value.id)

    if (index >= 0) {
      note.reactions = removeAtIndex(note.reactions, index)
    }
  }

  private updateCsvImportSourcesIfNeeded(contact: IContact) {
    if (
      contact.source === 'csv' &&
      contact.sourceName &&
      contact.userId &&
      !this.csvImports.some((source) => source.name === contact.sourceName)
    ) {
      this.csvImports.push({
        name: contact.sourceName,
        userId: contact.userId,
      })
    }
  }

  private handleContactSettingsUpdate(settings: ISharedContactSettings) {
    const item = this.settings.find((item) => item.id === settings.id)
    if (item) {
      item.deserialize(settings)
      this.settings.put(item)
    }
  }

  private handleContactSettingsDelete(settings: ISharedContactSettings) {
    this.settings.delete(settings.id)
  }

  private handleGooglePeopleSyncProgressNotification(
    msg: GooglePeopleSyncProgressNotification,
  ) {
    const settings = this.googleContactSettings.list.find(
      (s) => s.source === msg.status.source,
    )
    if (!settings) return
    settings.resyncStatus = msg.status.state
  }

  /**
   * As contacts are added/updated/deleted from the collection, this
   * function keeps map of phone numbers to contacts for fast lookup
   */
  private handleIndexByPhoneNumber() {
    this.collection.observe(
      action((event) => {
        if (event.type === 'put') {
          event.objects.forEach((contact) => {
            this.contactNumbers[contact.id] ??= []
            const numbers = this.contactNumbers[contact.id]
            numbers.forEach((number) => {
              this.byNumber[number]?.delete(contact)
            })
            contact.phoneNumbers.forEach((item) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
              const number = item.value
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              this.byNumber[number] ??= new Set()
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              if (!this.byNumber[number].has(contact)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
                this.byNumber[number].add(contact)
              }
            })
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            this.contactNumbers[contact.id] = contact.phoneNumbers.map((i) => i.value)
          })
        } else if (event.type == 'delete') {
          event.objects.forEach((object) => {
            const numbers = this.contactNumbers[object.id]
            numbers?.forEach((number) => {
              if (!this.byNumber[number]) return
              const existing = Array.from(this.byNumber[number]).find(
                (c) => c.id === object.id,
              )

              if (!existing) {
                return
              }

              this.byNumber[number].delete(existing)
            })
            remove(this.contactNumbers, object.id)
          })
        }
      }),
    )
  }

  load(contacts: any) {
    if (!contacts) return
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    this.collection.deleteBulk(contacts.filter((c) => c.deletedAt))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    return this.collection.load(contacts.filter((c) => !c.deletedAt))
  }
}
