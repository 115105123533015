/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable } from 'mobx'

import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import { Snippet } from './model'
import type { SnippetRepository } from './worker/repository'

export default class SnippetsService {
  collection: PersistedCollection<Snippet, SnippetRepository>

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('snippet'),
      classConstructor: () => new Snippet(root),
    })

    makeAutoObservable(this, {})

    this.subscribeToWebSocket()
  }

  fetch = () => {
    this.collection.performQuery((repo) => repo.all())
    return this.root.transport.snippets
      .list()
      .then(action((res) => this.collection.load(res, { deleteOthers: true })))
  }

  save = (snippet: Snippet) => {
    this.root.snippet.collection.put(snippet)
    return this.root.transport.snippets.put(snippet.serialize())
  }

  delete = (snippet: Snippet) => {
    this.collection.delete(snippet)
    return this.root.transport.snippets.delete(snippet)
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'canned-message-update':
          return this.collection.load(data.message)
        case 'canned-message-delete':
          return this.collection.delete(data.message.id)
      }
    })
  }
}
