import { useTooltipTrigger, useTooltip } from '@react-aria/tooltip'
import { mergeProps, mergeRefs } from '@react-aria/utils'
import { useTooltipTriggerState } from '@react-stately/tooltip'
import type { TooltipTriggerProps } from '@react-types/tooltip'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { CSSProperties, RefObject } from 'react'
import React from 'react'
import type { TransitionStatus } from 'react-transition-group'
import { Transition } from 'react-transition-group'

import Popover, { type PopoverProps } from '@ui/Popover'
import { useSequentialHoverTooltip } from '@ui/TooltipProvider'
import { useTheme, themeClassNames, ThemeProvider, type ThemeKey } from '@ui/theme'

import * as styles from './Tooltip.css'
import TooltipContent from './TooltipContent'

type PopoverPlacement = NonNullable<PopoverProps['placement']>
export type TooltipPlacement = Extract<
  PopoverPlacement,
  | 'top'
  | 'top left'
  | 'top right'
  | 'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'left'
  | 'right'
>

export interface TooltipProps {
  /**
   * A single child that will act as the trigger for the tooltip.
   *
   * @example
   * ```jsx
   * <Tooltip title="This is a tooltip">
   *   <button>Trigger</button>
   * </Tooltip>
   * ```
   */
  children: React.ReactChild

  /**
   * The text to display in the tooltip.
   */
  title: string

  /**
   * Secondary text to display in the tooltip.
   */
  body?: string

  /**
   * Icon to display next to the title in the tooltip.
   */
  icon?: React.ReactNode

  /**
   * Property to prevent the tooltip from appearing.
   */
  disabled?: boolean

  /**
   * Key combination to display in the tooltip.
   *
   * @example
   * 'Meta+Shift+X'
   */
  shortcut?: string

  /**
   * Classname to apply to the tooltip.
   */
  className?: string

  /**
   * Tooltip placement.
   *
   * @default 'bottom'
   */
  placement?: TooltipPlacement

  /**
   * The delay time for the tooltip to show up. [See guidelines](https://spectrum.adobe.com/page/tooltip/#Immediate-or-delayed-appearance).
   * @default 400
   */
  delay?: number

  /**
   * By default, opens for both focus and hover. Can be made to open only for focus.
   *
   * @see https://react-spectrum.adobe.com/react-aria/useTooltipTrigger.html#api
   */
  trigger?: 'focus'

  /**
   * Whether the overlay is open by default (controlled).
   */
  open?: boolean

  /**
   * Whether the overlay is open by default (uncontrolled).
   */
  defaultOpen?: boolean

  /**
   * Handler that is called when the overlay's open state changes.
   */
  onOpenChange?: (open: boolean) => void

  /**
   * Enforce themeKey
   */
  themeKey?: ThemeKey

  /**
   * The additional offset applied along the main axis between the element and its
   * anchor element. Use sparingly.
   *
   * @default 6
   */
  offset?: number

  /**
   * The additional offset applied along the cross axis between the element and its
   * anchor element. Use sparingly.
   *
   * @default 0
   */
  crossOffset?: number
}

/**
 * The animation duration for the first time a tooltip shows
 */
const INITIAL_ANIMATION_DURATION = 200

/**
 * The animation duration for sequential hovers among tooltiped elements
 */
const SEQUENTIAL_ANIMATION_DURATION = 100

/**
 * Time it takes for the tooltip to start transitioning in.
 * This means that until this 400ms have passed the tooltip
 * will not be mounted.
 */
const INITIAL_DELAY_DURATION = 400

const transitionStyles: Record<TransitionStatus, CSSProperties> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: {},
}

export const Tooltip = ({
  children,
  title,
  body,
  icon,
  className,
  disabled,
  shortcut,
  delay = INITIAL_DELAY_DURATION,
  trigger,
  placement = 'bottom',
  open,
  defaultOpen,
  onOpenChange,
  themeKey,
  offset = 6,
  crossOffset,
}: TooltipProps) => {
  const enforceTheme = !!themeKey
  const theme = useTheme(themeKey)
  const child = React.Children.only(children)

  const triggerRef = React.useRef<HTMLElement>(null)
  const tooltipRef = React.useRef<HTMLDivElement>(null)

  const tooltipTriggerProps: TooltipTriggerProps = {
    delay,
    isDisabled: disabled,
    trigger,
    isOpen: open,
    defaultOpen,
    onOpenChange,
  }
  const state = useTooltipTriggerState(tooltipTriggerProps)
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    tooltipTriggerProps,
    { ...state, close: () => state.close(true) },
    triggerRef,
  )
  delete triggerProps.onClick

  let triggerElement: React.ReactElement

  if (isReactText(child)) {
    triggerElement = (
      <span {...triggerProps} ref={triggerRef}>
        {child}
      </span>
    )
  } else {
    const mergedRef = hasRef(child) ? mergeRefs(child.ref, triggerRef) : triggerRef
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const mergedProps = mergeProps(triggerProps, child.props, { ref: mergedRef })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    triggerElement = React.cloneElement(child, mergedProps)
  }

  const tooltip = useTooltip({}, state)
  const { isSequentialHover, setCurrentlyOpenedTooltip, unsetCurrentlyOpenedTooltip } =
    useSequentialHoverTooltip(state.isOpen)

  if (disabled) {
    return <>{children}</>
  }

  return (
    <>
      {triggerElement}
      <Transition
        in={state.isOpen}
        mountOnEnter
        unmountOnExit
        timeout={isSequentialHover ? SEQUENTIAL_ANIMATION_DURATION : delay}
        onEntering={setCurrentlyOpenedTooltip}
        onExit={unsetCurrentlyOpenedTooltip}
      >
        {(state) => (
          <ThemeProvider
            themeKey={
              enforceTheme
                ? theme.themeKey
                : theme.match({
                    light: 'dark',
                    dark: 'light',
                  })
            }
          >
            <Popover
              targetRef={triggerRef}
              className={
                enforceTheme
                  ? theme.themeClassName
                  : theme.match({
                      light: themeClassNames.dark,
                      dark: themeClassNames.light,
                    })
              }
              placement={placement}
              offset={offset}
              crossOffset={crossOffset}
            >
              <div
                className={cx(styles.tooltip({ isSequentialHover }), className)}
                {...mergeProps({}, tooltipProps, tooltip.tooltipProps)}
                ref={tooltipRef}
                style={{
                  ...transitionStyles[state],
                  ...assignInlineVars({
                    [styles.initialAnimationDurationVar]: `${INITIAL_ANIMATION_DURATION}ms`,
                    [styles.sequentialAnimationDurationVar]: `${SEQUENTIAL_ANIMATION_DURATION}ms`,
                  }),
                }}
              >
                <TooltipContent
                  title={title}
                  body={body}
                  icon={icon}
                  shortcut={shortcut}
                  placement={placement}
                />
              </div>
            </Popover>
          </ThemeProvider>
        )}
      </Transition>
    </>
  )
}

export default Tooltip

function isReactText(node: React.ReactChild): node is React.ReactText {
  return typeof node === 'string' || typeof node === 'number'
}

function hasRef(
  element: React.ReactElement,
): element is React.ReactElement & { ref: RefObject<unknown> } {
  return !!element['ref']
}
