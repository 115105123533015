import Snackbar from '@material-ui/core/Snackbar'
import type { SnackbarProps } from '@material-ui/core/Snackbar'
import type { SnackbarContentProps as MuiSnackbarContentProps } from '@material-ui/core/SnackbarContent'
import MuiSnackbarContent from '@material-ui/core/SnackbarContent'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import type { CSSProperties } from '@material-ui/styles'
import React from 'react'

interface SnackbarContentProps extends MuiSnackbarContentProps {
  type: 'info' | 'error' | 'loading' | 'special'
}

const SnackbarContent = function ({ type, ...props }: SnackbarContentProps) {
  const classes = useSnackbarContentStyles({ type })
  return <MuiSnackbarContent classes={classes} {...props} />
}

const useSnackbarContentStyles = makeStyles<Theme, SnackbarContentProps>((theme) => ({
  root: (props) => ({
    color: theme.palette.op.text.primary,
    boxShadow: theme.shadows[6],
    minWidth: 'auto',
    ...properties(theme, props),
  }),
  message: {
    padding: 0,
  },
  action: ({ type }) => ({
    color: type === 'error' ? theme.palette.op.secondary.red2 : theme.palette.op.gray[1],
    marginLeft: 15,
  }),
}))

const properties = (theme: Theme, props: SnackbarContentProps): CSSProperties => {
  switch (props.type) {
    case 'error':
      return {
        background: theme.palette.op.background.popover,
        borderLeft: `4px solid ${theme.palette.op.secondary.red2}`,
        borderRadius: '4px 6px 6px 4px',
      }
    case 'loading':
    case 'info':
      return {
        background: theme.palette.op.background.popover, // TODO: In Zeplin, this color appears as gray 7 for Dark mode and pure white for Light mode. We don't have a Gray 7 defined in our theme. Should we use popover? @lightMode
        borderLeft: `4px solid ${theme.palette.op.primary[2]}`,
        borderRadius: '4px 6px 6px 4px',
      }
    case 'special':
      return {
        background: theme.palette.op.primary[1],
        color: theme.palette.op.white,
        borderRadius: 8,
        padding: '20px 24px',
      }
  }
}

export { Snackbar, SnackbarContent, SnackbarContentProps, SnackbarProps }
