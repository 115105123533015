import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'

import type { MessageMedia } from '@src/service/model'

import * as styles from './DocViewer.css'
import useViewerStyles from './useViewerStyles'

interface DocViewerProps {
  media: MessageMedia
}

const DocViewer = function ({ media }: DocViewerProps) {
  const viewerStyles = useViewerStyles()
  const rootRef = useRef<HTMLDivElement>(null)

  if (media.file) {
    return null
  }

  return (
    <div ref={rootRef} className={viewerStyles.root}>
      <iframe
        frameBorder={0}
        className={styles.iframe}
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${media.url}`}
      />
    </div>
  )
}

export default observer(DocViewer)
