/// <reference types="@openphone/desktop-client" />

import createConfig, { ENV_OVERRIDE_KEY } from './createConfig'

export { ENV_OVERRIDE_KEY }

export const platform = typeof window !== 'undefined' ? window._e?.platform : undefined
export const isWeb = !platform
export const isMac = platform === 'darwin'
export const isWindows = platform === 'win32'
export const isDialog =
  typeof window !== 'undefined' ? window.location.pathname.includes('/_/dialog') : false

const config = createConfig(import.meta.env)

export default config
