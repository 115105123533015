import { action, makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import type Nullable from '@src/lib/Nullable'
import Collection from '@src/service/collections/Collection'

import type Service from '..'

import type { Invite, Member, Model, PhoneNumber } from './'
import { DirectNumber, UserSettings } from './'

export interface UserAnalytics {
  referrer: string
  /**
   * Enrichment data from Clearbit
   *
   * @see https://dashboard.clearbit.com/docs#enrichment-api-person-api
   */
  enrichment?: {
    employment: {
      role: string
      subRole: string
    }
    emailProvider?: boolean
  }
  [key: string]: any
}

export interface IUser {
  id: string
  blocked: boolean
  email: string
  firstName: string
  lastName: string
  pictureUrl: string
  createdAt: number
  updatedAt: number
  verifiedPhoneNumber: string
  analytics: UserAnalytics
  settings: UserSettings
}

export class User implements Nullable<IUser>, Model {
  id = ''
  blocked: boolean | null = null
  email: string | null = null
  firstName: string | null = null
  lastName: string | null = null
  pictureUrl: string | null = null
  createdAt: number | null = null
  updatedAt: number | null = null
  verifiedPhoneNumber: string | null = null
  analytics: UserAnalytics | null = null
  settings: UserSettings

  // Relations
  private directNumbers = new Collection<DirectNumber>({ bindElements: true })
  phoneNumbers = new Collection<PhoneNumber>({ bindElements: true })
  invites: Invite[] = []

  constructor(private root: Service) {
    this.settings = new UserSettings(this.root, {})

    makeAutoObservable(this, {})
  }

  get asMember(): Member | null {
    return this.root.member.collection.get(this.id)
  }

  get isOpenPhoneOrganizationMember() {
    const [, emailDomain] = (this.email ?? '').split('@')
    return emailDomain === 'openphone.co'
  }

  /**
   * Only require a phone verification if user is using a free email provider
   */
  get needsPhoneVerification() {
    return this.analytics?.enrichment?.emailProvider !== false
  }

  /**
   * Returns a direct number that associated with the current organization.
   * Potentially, a user can be associated with multiple organizations which would result in multiple direct numbers.
   */
  get directNumber(): DirectNumber | null {
    return (
      this.directNumbers.find(
        (number) => number.orgId === this.root.organization.current?.id,
      ) ?? null
    )
  }

  fetch = () => {
    this.root.transport.account.get().then(
      action((response) => {
        this.deserialize(response)
      }),
    )
  }

  update = (user: Partial<this>) => {
    Object.assign(this, user)
    this.root.user.update(this)
  }

  deserialize = ({ directNumbers, settings, ...json }: any) => {
    Object.assign(this, json)

    if (directNumbers) {
      this.directNumbers.clear()
      this.directNumbers.putBulk(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        directNumbers.map((dn) => new DirectNumber(this.root).deserialize(dn)),
      )
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)

    this.settings.tearDown()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    this.settings = new UserSettings(this.root, settings)
    return this
  }

  serialize = (): any => {
    return {
      id: this.id,
      blocked: this.blocked,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      pictureUrl: this.pictureUrl,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      verifiedPhoneNumber: this.verifiedPhoneNumber,
      analytics: toJS(this.analytics),
      directNumbers: this.directNumbers.list?.map((dn) => dn.serialize()),
      settings: this.settings.serialize(),
    }
  }
}
