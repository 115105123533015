import { createCallbackState } from '@src/app/Callback'
import config from '@src/config'

const googleAuth = new Promise<any>((resolve) => {
  const init = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    const gapi = (window as any).gapi
    if (!gapi) {
      setTimeout(init, 200)
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    gapi.load('auth2', () => resolve(gapi.auth2))
  }
  init()
})

export const googleLogin = (): Promise<{ accessToken: string; code: string }> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
  return googleAuth
    .then(
      (auth) =>
        new Promise<any>((resolve, reject) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
          auth.authorize(
            {
              client_id: config.GOOGLE_CLIENT_ID,
              scope: 'profile https://www.googleapis.com/auth/userinfo.email ',
              response_type: 'code permission',
              prompt: 'select_account consent',
            },
            function (response) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              if (response.error) {
                // An error happened!
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
                reject(response.error)
                return
              }
              // The user authorized the application for the scopes requested.
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              const accessToken = response.access_token
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
              const code = response.code
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
              resolve({ accessToken, code })
              // You can also now use gapi.client to perform authenticated requests.
            },
          ),
        ),
    )
    .catch((e) => {
      if (e !== 'popup_closed_by_user') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        throw new Error(e)
      }
    })
}

export const redirectGoogleLogin = () => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint)

  // Parameters to pass to OAuth 2.0 endpoint.
  const params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}/native/login`,
    response_type: 'token',
    scope: 'email profile',
    prompt: 'select_account',
    include_granted_scopes: 'false',
  }

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}

export const redirectGoogleContactLogin = (isElectron = false) => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint)

  if (isElectron) {
    form.setAttribute('target', '_blank')
  }

  // Parameters to pass to OAuth 2.0 endpoint.
  const params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}/callback/google-contacts`,
    response_type: 'code',
    scope:
      'profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly',
    prompt: 'select_account consent',
    include_granted_scopes: 'false',
    access_type: 'offline',
    state: createCallbackState({
      desktop: isElectron,
      redirectTo: `/contacts`,
    }),
  }

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}
