import { useEffect, useState } from 'react'

import config from '@src/config'
import useAsyncScript from '@src/lib/hooks/useAsyncScript'

const src = `https://www.google.com/recaptcha/api.js?render=${config.GOOGLE_RECAPTCHA_SITE_KEY}`

function createGoogleReCaptchaScript() {
  const script = document.createElement('script')
  script.async = true
  script.src = src
  return script
}

const isGoogleReCaptchaAvailable = () => 'grecaptcha' in window && !!grecaptcha

export default function useGoogleReCaptcha() {
  const [isReady, setReady] = useState(false)

  const { loaded } = useAsyncScript({
    scriptCreator: createGoogleReCaptchaScript,
    getInitialState: isGoogleReCaptchaAvailable,
  })

  useEffect(() => {
    if (loaded) {
      grecaptcha.ready(() => setReady(true))
    }
  }, [isReady, loaded])

  return { loaded, isReady }
}
