import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { useBillingSubscription } from '@src/app/billing/subscription'
import { useAppStore } from '@src/app/context'
import { Card, Disclaimer } from '@src/app/onboarding/common'
import Illustration from '@src/component/illustrations'
import AccountFlagged from '@src/component/illustrations/AccountFlagged'

import VerificationLayout from './VerificationLayout'

const Flagged = () => {
  const { history, needsOnboarding } = useAppStore()
  const { isReviewRejected, isReviewApproved, isActive } = useBillingSubscription()

  useEffect(() => {
    if (isReviewApproved) {
      if (needsOnboarding) {
        history.push('/onboarding')
      } else if (isActive) {
        history.push('/')
      }
    }
  }, [history, isReviewApproved, needsOnboarding, isActive])

  if (isReviewApproved) {
    return null
  }

  return (
    <VerificationLayout>
      <Card
        emoji={<Illustration illustration={AccountFlagged} style={{ width: 295 }} />}
        title={isReviewRejected ? 'Account blocked...' : 'Pending review...'}
        description={
          isReviewRejected
            ? 'Your account was rejected after a manual review. If you think this decision is incorrect, contact support@openphone.co.'
            : 'Our team will email you within 48 hours with an update. Your 7-day trial has not started and you will not be charged.'
        }
      >
        {!isReviewRejected && (
          <Disclaimer
            emoji="⚠️"
            text="Attempts to bypass this process using another email address will fail and may impact the status of your pending review."
          />
        )}
      </Card>
    </VerificationLayout>
  )
}

export default observer(Flagged)
