import { assignInlineVars } from '@vanilla-extract/dynamic'
import type { AriaPositionProps, PositionAria } from 'react-aria'
import { mergeProps, useOverlayPosition } from 'react-aria'

import { setBaseZIndex } from '@ui/z-index/zIndex'

import { useLayer } from './LayerProvider'

export default function useLayeredOverlayPosition(
  props: AriaPositionProps,
): PositionAria {
  const { overlayProps: overlayPositionProps, ...rest } = useOverlayPosition(props)

  delete overlayPositionProps.style?.zIndex

  const layer = useLayer()

  const mergedProps = mergeProps(overlayPositionProps, {
    style: {
      ...assignInlineVars({ ...setBaseZIndex(layer) }),
      ...overlayPositionProps.style,
    },
  })

  return {
    overlayProps: mergedProps,
    ...rest,
  }
}
