/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import cx from 'classnames'
import React from 'react'

import Typography from '@ui/Typography'

import * as styles from './Card.css'

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  emoji: React.ReactNode
  title: string
  description: React.ReactNode
  headerClassName?: string
  contentClassName?: string
}

export const Card = function ({
  emoji,
  title,
  description,
  children,
  className,
  headerClassName,
  contentClassName,
  ...props
}: CardProps) {
  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={cx(styles.header, headerClassName)}>
        {emoji && (
          <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
            {emoji}
          </Typography>
        )}
        {title && (
          <Typography variant="largeTitle" style={{ marginBottom: 10 }}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
      <div className={cx(styles.content, contentClassName)}>{children}</div>
    </div>
  )
}
