/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable, when } from 'mobx'

import Collection from '@src/service/collections/Collection'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type { PhoneNumber } from './model'
import { Invite, User } from './model'
import type { AccountConnectionType } from './transport/account'

export default class UserStore {
  /**
   * Prefer using `UserStore.getCurrentUser()` instead if you expect the current
   * user to be ready.
   */
  current: User | null = null
  currentUserPromise: Promise<User>

  connections: AccountConnectionType[] = []

  readonly invites = new Collection<Invite>({ bindElements: true })
  readonly phoneNumbers = new Collection<PhoneNumber>({
    filter: this.isCurrentUserMemberOfPhoneNumber.bind(this),
  })

  constructor(private root: Service) {
    makeAutoObservable(this, {})
    makePersistable(this, 'UserStore', {
      current: root.storage.async((data) => new User(root).deserialize(data)),
      connections: root.storage.async(),
    })

    this.currentUserPromise = when(
      () => this.current !== null && !!this.current?.asMember,
    ).then(() => this.current as User)

    this.bindCollections()
    this.subscribeToWebSocket()
  }

  /**
   * Returns the current user if it's ready, otherwise throws an error.
   *
   * @returns The current user
   * @throws If the current user is not ready yet
   */
  getCurrentUser() {
    const currentUser = this.current

    if (!currentUser) {
      throw new Error(
        'UserStore.getCurrentUser() called before the current user was ready',
      )
    }

    return currentUser
  }

  fetch() {
    return this.root.transport.account.get().then(
      action((user) => {
        if (this.current) {
          this.current.deserialize(user)
        } else {
          this.current = new User(this.root).deserialize(user)
        }
      }),
    )
  }

  update(user: User) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    return this.root.transport.account.update(user.serialize()).then((user) => {
      if (this.current) {
        this.current.deserialize(user)
      } else {
        const current = new User(this.root)
        current.deserialize(user)
        this.current = current
      }
    })
  }

  fetchInvites = () => {
    return this.root.transport.account.invites.list().then((invites) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
      this.invites.putBulk(invites.map((json) => new Invite().deserialize(json)))
    })
  }

  fetchInviteByToken(token: string): Promise<any> {
    return this.root.transport.account.invites.get(token)
  }

  fetchConnections = () => {
    return this.root.transport.account.connections.list().then(
      action((result) => {
        this.connections = result.connections
      }),
    )
  }

  acceptInvite(invite: Invite | string): Promise<any> {
    const token = typeof invite === 'string' ? invite : invite.token
    return this.root.transport.account.invites
      .accept(token)
      .then(() => this.root.auth.refreshToken())
      .then(() => this.root.reset())
  }

  rejectInvite(invite: Invite): Promise<any> {
    this.invites.delete(invite)
    return this.root.transport.account.invites.reject(invite.token)
  }

  setPassword = (password: string): Promise<any> => {
    return this.root.transport.auth.setPassword(password)
  }

  changePassword = (oldPassword: string, password: string): Promise<any> => {
    return this.root.transport.auth.changePassword(oldPassword, password)
  }

  protected bindCollections() {
    this.phoneNumbers.bind(this.root.phoneNumber.collection)
  }

  private isCurrentUserMemberOfPhoneNumber(phoneNumber: PhoneNumber): boolean {
    return (
      phoneNumber.role === 'member' ||
      phoneNumber.role === 'admin' ||
      phoneNumber.role === 'owner'
    )
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      if (data.type === 'user-update') {
        this.current ??= new User(this.root)
        this.current.deserialize(data.user)
      }

      if (data.type === 'user-auth-update') {
        this.connections = data.connections
      }
    })
  }
}
