import { action, makeAutoObservable, makeObservable, observable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import ObjectID from '@src/lib/object-id'
import type { GooglePeopleSyncProgressState } from '@src/service/transport/websocket'

import type Service from '..'

import type { Model } from './base'

interface BaseContactSettings {
  id: string
  userId: string | null
  createdAt: number | null
  updatedAt: number | null
  meta: { hasRefreshToken: boolean } | null
}

export interface IGoogleContactSettings extends BaseContactSettings {
  hasRefreshToken: boolean | null
  source: string | null
  sourceName: string | null
  type: string | null
  resyncStatus: GooglePeopleSyncProgressState | null
}

export interface ISharedContactSettings extends BaseContactSettings {
  deletedAt: number | null
  deletedBy: string | null
  orgId: string | null
  defaultSharingIds: string[]
}

export class SharedContactSettings implements ISharedContactSettings, Model {
  createdAt: number | null = null
  id: string = ObjectID()
  meta: { hasRefreshToken: boolean } | null = null
  updatedAt: number | null = null
  userId: string | null = null
  defaultSharingIds: string[] = []
  deletedAt: number | null = null
  deletedBy: string | null = null
  orgId: string | null = null

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  deserialize = (json: any) => {
    if (json) {
      Object.assign(this, json)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.updatedAt = parseDate(json.updatedAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.createdAt = parseDate(json.createdAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.deletedAt = parseDate(json.deletedAt)
    }
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      meta: toJS(this.meta),
      updatedAt: this.updatedAt,
      userId: this.userId,
      defaultSharingIds: toJS(this.defaultSharingIds),
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      orgId: this.orgId,
    }
  }
}

export class GoogleContactSettings implements IGoogleContactSettings, Model {
  createdAt: number | null = null
  id: string = ObjectID()
  meta: { hasRefreshToken: boolean } | null = null
  hasRefreshToken: boolean | null = null
  source: string | null = null
  sourceName: string | null = null
  type: string | null = null
  updatedAt: number | null = null
  userId: string | null = null
  resyncStatus: GooglePeopleSyncProgressState | null = null

  constructor(private root: Service) {
    makeObservable(this, {
      resyncStatus: observable.ref,
      resync: action,
    })
  }

  resync() {
    this.resyncStatus = 'started'

    return this.root.transport.contacts.settings.resync(this).catch(
      action((error) => {
        // TODO: this request can fail, but the job doesn't
        this.resyncStatus = null
        throw error
      }),
    )
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.updatedAt = parseDate(json.updatedAt)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.createdAt = parseDate(json.createdAt)
    return this
  }

  serialize = () => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      meta: toJS(this.meta),
      hasRefreshToken: this.hasRefreshToken,
      source: this.source,
      sourceName: this.sourceName,
      type: this.type,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}
