import type { Theme } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import React from 'react'

import type AppStore from '@src/app/AppStore'
import Callout from '@src/component/Callout'
import useReaction from '@src/lib/hooks/useReaction'
import Typography from '@ui/Typography'

import * as styles from './index.css'

interface UpdateProviderProps {
  app: AppStore
  children: React.ReactNode
}

const UpdateProvider = function ({ app, children }: UpdateProviderProps) {
  useReaction(
    () => app.storesLoaded,
    (loaded) => {
      if (!loaded) return
      app.update.startChecking()
    },
    { fireImmediately: true },
  )

  return (
    <>
      {children}
      {app.update.showPrompt && (
        <Callout
          elevated
          className={styles.root}
          size="large"
          icon={<Typography variant="title1">🎁</Typography>}
          title="Updates available"
          body={`A new and better version of the app is installed and ready. ${
            app.update.strategy === 'electron'
              ? 'Relaunch the app to get all the changes.'
              : 'Reload the page to get all the changes.'
          }`}
          onDismiss={app.update.handleDismiss}
          actions={[
            {
              title: 'Maybe later',
              type: 'secondary',
              onClick: app.update.handleDefer,
            },
            {
              title: app.update.strategy === 'electron' ? 'Relaunch' : 'Reload',
              type: 'primary',
              onClick: app.update.installAndRestart,
            },
          ]}
        />
      )}
    </>
  )
}

export default observer(UpdateProvider)
