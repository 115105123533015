import type { SoundURLs } from '@src/service/SoundStore'

import callEnded from './call-ended.mp3'
import callStarted from './call-started.mp3'
import error from './error.mp3'
import missedCall from './missed-call.mp3'
import newMessage from './new-message.mp3'
import primaryRingtone from './primary-ringtone.mp3'
import reaction from './reaction.mp3'
import secondaryNotification from './secondary-notification.mp3'
import secondaryRingtone from './secondary-ringtone.mp3'
import textReceived from './text-received.mp3'

export const sounds: SoundURLs = {
  callEnded,
  callStarted,
  error,
  missedCall,
  newMessage,
  primaryRingtone,
  reaction,
  secondaryNotification,
  secondaryRingtone,
  textReceived,
}
