import type { PhoneNumberSelection } from '@src/component/phone-number-selector/Controller'
import type { Contact, Member, PhoneNumber } from '@src/service/model'
import { isDirectNumber } from '@src/service/model/direct-number'
import type { VoiceTarget } from '@src/service/transport/voice'

export default function convertSelectionToVoiceTarget(
  selection: PhoneNumberSelection,
): VoiceTarget {
  switch (selection.type) {
    case 'inbox':
      return { phoneNumber: selection.to.number }
    case 'inbox-member':
    case 'member':
      return {
        userId: selection.to.id,
        ...(isDirectNumber(selection.via)
          ? { directNumber: selection.via }
          : { phoneNumber: selection.via }),
      }
    case 'contact':
      return { phoneNumber: selection.via }
    case 'add-number':
    case 'number':
      return { phoneNumber: selection.to }
  }
}

// FIXME: this should be removed once we complete the migration to the new operator
export type LegacyTransferParams =
  | { phoneNumber: string }
  | { phoneNumberId: string }
  | { userId: string }

// FIXME: this should be removed once we complete the migration to the new operator
type TransfereeDescriptor =
  | { type: 'inbox'; to: PhoneNumber }
  | { type: 'inbox-member'; to: Member; via: string }
  | { type: 'member'; to: Member; via: string }
  | { type: 'contact'; to: Contact; via: string }
  | { type: 'add-number'; to: string }
  | { type: 'number'; to: string }

// FIXME: this should be removed once we complete the migration to the new operator
export function getLegacyTransferParams(
  transferee: TransfereeDescriptor,
): LegacyTransferParams {
  switch (transferee.type) {
    case 'inbox':
      return { phoneNumberId: transferee.to.id }
    case 'member':
    case 'inbox-member':
      return { userId: transferee.to.id, phoneNumberId: transferee.via }
    case 'contact':
      return { phoneNumber: transferee.via }
    case 'add-number':
    case 'number':
      return { phoneNumber: transferee.to }
  }
}
