import { makeAutoObservable, toJS } from 'mobx'

import { removeAtIndex } from '@src/lib/collections'
import { parseDate } from '@src/lib/date'
import { stripSkinVariation } from '@src/lib/emoji'
import uuid from '@src/lib/uuid'
import { ActivityReaction } from '@src/service/model/reactions'
import type { ActivityCodableReaction } from '@src/service/model/reactions'

import type Service from '..'

import type { ActivityModel, Enrichment } from './ActivityModel'
import type { Model } from './base'
import type { CodableMessageMedia } from './media'
import { MessageMedia } from './media'

export interface CodableComment {
  id: string
  body: string | null
  enrichment: Enrichment | null
  media: CodableMessageMedia[]
  reactions: ActivityCodableReaction[]
  createdAt: number | null
  updatedAt: number | null
  userId: string | null
}

export interface EncodableComment {
  id: string
  activityId: string
  body: string | null
  media?: CodableMessageMedia[]
}

export interface DecodableComment extends CodableComment {
  activityId?: string
  conversationId?: string
}

export class Comment implements Model, CodableComment {
  readonly type = 'message'

  id: string = `CM${uuid()}`.replace(/-/g, '')
  body: string | null = null
  enrichment: Enrichment | null = null
  media: MessageMedia[] = []
  createdAt: number | null = Date.now()
  updatedAt: number | null = Date.now()
  userId: string | null = null

  // Relations
  reactions: ActivityReaction[] = []

  constructor(
    private root: Service,
    readonly activity: ActivityModel,
    attrs: Partial<CodableComment> | Partial<DecodableComment> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  addReaction(reaction: ActivityReaction) {
    this.reactions.push(reaction)
    return this.root.activity.addReaction(reaction)
  }

  deleteReaction(reaction: ActivityReaction) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.reactions = removeAtIndex(this.reactions, reactionIndex)
    return this.root.activity.deleteReaction(reaction)
  }

  toggleReaction(emoji: string) {
    const body = this.root.emoji.getEmojiWithSkinTone(emoji)
    const reaction = this.reactions.find(
      (r) =>
        r.body &&
        stripSkinVariation(r.body) === stripSkinVariation(body) &&
        r.userId === this.root.user.current?.id,
    )

    if (reaction) {
      return this.deleteReaction(reaction)
    } else {
      return this.addReaction(
        new ActivityReaction(this.root, this, {
          body,
          userId: this.root.user.current?.id,
        }),
      )
    }
  }

  deserialize(attrs?: Partial<CodableComment> | Partial<DecodableComment>) {
    if (attrs) {
      const { media, reactions, ...json } = attrs
      Object.assign(this, json)
      this.media = media?.map((m) => new MessageMedia(this.root).deserialize(m)) ?? []
      this.reactions =
        reactions?.map?.((r) => new ActivityReaction(this.root, this, r)) ?? []
      const createdAt = json.createdAt || this.createdAt
      this.createdAt = createdAt ? parseDate(createdAt) : null
      const updatedAt = json.updatedAt || this.updatedAt
      this.updatedAt = updatedAt ? parseDate(updatedAt) : null
    }
    return this
  }

  serialize(): CodableComment {
    return {
      id: this.id,
      body: this.body,
      media: this.media?.map((m) => m.serialize()),
      reactions: this.reactions?.map((r) => r.serialize()),
      enrichment: toJS(this.enrichment),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  toJSON(): EncodableComment {
    return {
      id: this.id,
      activityId: this.activity.id,
      body: this.body,
      media: this.media?.map((m) => m.serialize()),
    }
  }
}
