import { action, makeObservable, observable } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type { CodableTrustRegistration, TrustRegistrationObject } from './model'
import { TrustRegistration } from './model'

export default class TrustRegistrationStore {
  private readonly disposeBag = new DisposeBag()
  registration: TrustRegistration | null = null

  constructor(private readonly root: Service) {
    this.subscribeToWebSocket()
    makeObservable<this, 'setRegistration'>(this, {
      registration: observable,
      setRegistration: action.bound,
      update: action.bound,
    })
    makePersistable(this, 'TrustRegistrationStore', {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      registration: root.storage.async((d) => new TrustRegistration(d)),
    })
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  async fetch() {
    const json = await this.root.transport.trust.registration.fetch()
    return this.setRegistration(json)
  }

  async create(props: Partial<TrustRegistrationObject>) {
    const json = await this.root.transport.trust.registration.create(props)
    return this.setRegistration(json)
  }

  async update(props: Partial<TrustRegistrationObject>) {
    if (this.registration) {
      const json = await this.root.transport.trust.registration.update(
        this.registration.id,
        this.registration.etag,
        props,
      )
      return this.registration.localUpdate(json)
    }
    return null
  }

  async delete() {
    if (this.registration) {
      await this.root.transport.trust.registration.delete(
        this.registration.id,
        this.registration.etag,
      )
      this.setRegistration(null)
    }
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'reg-update':
          return this.setRegistration(data.registration)
        case 'reg-delete':
          return this.setRegistration(null)
      }
    })
  }

  private setRegistration(
    json: CodableTrustRegistration | null,
  ): TrustRegistration | null {
    if (json === null || !json.id) {
      return (this.registration = null)
    } else if (this.registration) {
      return this.registration.deserialize(json)
    } else {
      return (this.registration = new TrustRegistration(json))
    }
  }
}
