import type { ReactNode } from 'react'
import React, { useContext, createContext } from 'react'

import type { Layer } from '@ui/z-index/zIndex'

const LayerContext = createContext<Layer>('base')

export const useLayer: () => Layer = () => useContext(LayerContext) ?? 'base'

export default function LayerProvider({
  layer,
  children,
}: {
  layer: Layer
  children: ReactNode
}) {
  return <LayerContext.Provider value={layer}>{children}</LayerContext.Provider>
}
