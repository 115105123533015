import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import Backoff from 'backoff'
import { makeObservable, observable } from 'mobx'

import config, { platform } from '@src/config'
import errorHandler, {
  AuthenticationError,
  ForbiddenError,
  TooManyRequestsError,
} from '@src/lib/api/errorHandler'
import log from '@src/lib/log'
import uuid from '@src/lib/uuid'
import type Service from '@src/service'
import type { Session } from '@src/service/model'
import makePersistable from '@src/service/storage/makePersistable'

const deviceId = (() => {
  let id = localStorage.getItem('deviceId')
  if (id) return id
  else {
    id = uuid()
    localStorage.setItem('deviceId', id)
    return id
  }
})()

const tabId = Math.floor(Math.random() * Math.floor(100))

export type ClientState = 'authenticated' | 'reauthenticating' | 'unauthenticated'

class ApiClient {
  private readonly backoff = Backoff.exponential({
    factor: 2.0,
    initialDelay: 100,
    maxDelay: 60000,
    randomisationFactor: 0.4,
  })
  private isAlreadyFetchingAccessToken = false
  private requests: ((error?: any) => void)[] = []
  private request = axios.create({
    timeout: 30000,
  })
  private state: ClientState
  private visitorId: string | null = null
  private visitorIdUpdatedAt: number | null = null

  idToken: string | null = null
  onRefreshRequired: (() => Promise<Session>) | null = null
  onRefreshRejected: (() => void) | null = null

  constructor(private root: Service) {
    makeObservable<this, 'visitorId' | 'visitorIdUpdatedAt'>(this, {
      visitorId: observable.ref,
      visitorIdUpdatedAt: observable.ref,
    })

    makePersistable<this, 'visitorId' | 'visitorIdUpdatedAt'>(this, 'ApiClient', {
      visitorId: this.root.storage.sync(),
      visitorIdUpdatedAt: this.root.storage.sync(),
    })

    this.state = 'unauthenticated'

    // Called when a backoff timer is started.
    this.backoff.on('backoff', (_: any, delay: number) => {
      if (this.state === 'reauthenticating') {
        log.info(`Will attempt to refresh the token in ${delay}ms`)
      }
    })

    // Called when a backoff timer ends. We want to try to refresh the token
    // at this point
    this.backoff.on('ready', (attempt: number) => {
      if (this.state === 'reauthenticating') {
        log.info(`Refreshing the login token. Attempt #${attempt}.`)
        this.refreshSession()
      }
    })

    /**
     * Add the authorization header to every request if the url is an
     * OpenPhone service.
     */
    this.request.interceptors.request.use(async (requestConfig) => {
      if (requestConfig.headers) {
        const idToken = this.idToken
        const requestUrl = new URL(requestConfig.url ?? '')
        const isOpenPhoneService =
          !!requestUrl.host.match(/openphone|localhost/) &&
          !requestUrl.host.match(/auth0/)
        if (idToken && isOpenPhoneService) {
          requestConfig.headers.Authorization = idToken
        }
        if (isOpenPhoneService) {
          requestConfig.headers['x-op-device'] = platform ?? 'browser'
          requestConfig.headers['x-op-device-id'] = `${deviceId}:${tabId}`
          requestConfig.headers['x-op-app'] = 'web'
          requestConfig.headers['x-op-version'] = config.VERSION
        } else {
          delete requestConfig.headers['x-op-requestid']
        }
      }
      return requestConfig
    })

    /**
     * Refresh accessToken if a request fails due to an
     * expired JWT token.
     */
    this.request.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (
          this.idToken &&
          error.response &&
          error.response.status === 401 &&
          error.response.config.url?.startsWith(config.AUTH_SERVICE_URL) === false
        ) {
          /**
           * Create a Promise that will be returned by this interceptor
           * that will add the retry request to the request queue once it's
           * executed. These callbacks will eventually be called once the
           * call to refresh the JWT token completes.
           */
          const config = error.response.config
          const retryCallback = new Promise((resolve, reject) => {
            this.requests.push((err) => {
              if (err) {
                return reject(err)
              }
              this.request(config).then(resolve).catch(reject)
            })
          })

          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshSession()
              .then(() => {
                this.requests.forEach((r) => r())
                this.requests = []
              })
              .catch(() => {
                this.requests.forEach((r) => r(error))
                this.requests = []
              })
              .finally(() => {
                this.isAlreadyFetchingAccessToken = false
              })
          }
          return retryCallback
        }
        return Promise.reject(error)
      },
    )
  }

  refreshSession(): Promise<void> {
    this.state = 'reauthenticating'

    if (!this.onRefreshRequired) {
      return Promise.resolve()
    }
    return this.onRefreshRequired()
      .then((response) => this.setSession(response))
      .catch((error: { response?: AxiosResponse; status?: any }) => {
        const jsonError = log.toJsonString(error)
        log.debug(`refresh token called failed: ${jsonError}`)
        log.debug(`resposne status: ${error.response?.status}`)
        log.debug(`status: ${error.status}`)
        if (
          error instanceof AuthenticationError ||
          error instanceof ForbiddenError ||
          error instanceof TooManyRequestsError
        ) {
          this.resetSession(error)
          this.onRefreshRejected?.()
        } else {
          this.backoff.backoff()
        }
        throw error
      })
  }

  private setSession(session: Session) {
    this.idToken = session.idToken
    this.state = 'authenticated'
    this.backoff.reset()
  }

  private resetSession(_error: any) {
    this.idToken = null
    this.state = 'unauthenticated'
    this.backoff.reset()
  }

  perform<T, D>(config: AxiosRequestConfig<D>): Promise<T> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.request(config)
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        return response.data
      })
      .catch(errorHandler)
  }

  get<T = any, D = any>(url: string, options?: AxiosRequestConfig<D>): Promise<T> {
    return this.perform({
      url,
      method: 'GET',
      ...options,
    })
  }

  post<T = any, Payload = any>(
    url: string,
    data?: Payload,
    options?: AxiosRequestConfig,
  ): Promise<T> {
    return this.perform({
      url,
      method: 'POST',
      data,
      ...options,
    })
  }

  put<T = any, D = any>(
    url: string,
    data?: D,
    options?: AxiosRequestConfig<D>,
  ): Promise<T> {
    return this.perform({
      url,
      method: 'PUT',
      data,
      ...options,
    })
  }

  patch<T = any, D = any>(
    url: string,
    data?: D,
    options?: AxiosRequestConfig<D>,
  ): Promise<T> {
    return this.perform({
      url,
      method: 'PATCH',
      data,
      ...options,
    })
  }

  delete<T = any, D = any>(url: string, options?: AxiosRequestConfig<D>): Promise<T> {
    return this.perform({
      url,
      method: 'DELETE',
      ...options,
    })
  }
}

export default ApiClient
