import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'

import { useBillingSubscription } from '@src/app/billing/subscription'
import { useAppStore } from '@src/app/context'
import { Card, Disclaimer } from '@src/app/onboarding/common'
import Button from '@src/component/ButtonV2'
import { CircularProgress } from '@src/component/progress'
import config from '@src/config'
import useStatefulPromise from '@src/lib/hooks/useStatefulPromise'
import { logError } from '@src/lib/log'
import Typography from '@ui/Typography'
import { PadlockShieldIcon } from '@ui/icons/tint/20/general'

import VerificationLayout from './VerificationLayout'

const Identity = () => {
  const { isElectron, signOut, service, history } = useAppStore()
  const {
    isFlagged,
    isKycProcessing,
    identityVerificationSecret,
    isReviewApproved,
    orgId,
  } = useBillingSubscription()
  const [stripe, runStripe] = useStatefulPromise(() =>
    loadStripe(config.STRIPE_PUBLISHABLE_KEY),
  )
  const [submitted, setSubmitted] = useState(false)
  const isStripeProcessing = isKycProcessing || submitted

  useEffect(() => {
    runStripe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isFlagged) {
      history.push('/verification/flagged')
    } else if (isReviewApproved) {
      history.push('/onboarding')
    }
  }, [history, isFlagged, isReviewApproved])

  const openStripeModal = async () => {
    if (stripe.error) {
      logError(stripe.error)
      return
    }

    if (stripe.data && identityVerificationSecret) {
      stripe.data
        .verifyIdentity(identityVerificationSecret)
        .then((res) => {
          if (res.error) {
            logError(res.error)
            if (orgId) {
              service.billing.refreshClientSecret(orgId).catch(logError)
            }
          } else {
            setSubmitted(true)
          }
        })
        .catch(logError)
    } else {
      logError(new Error('no valid stripe object and/or identityVerificationSecret'))
    }
  }

  const handleVerifyManual = () => {
    window.open('https://openphone.typeform.com/to/C7yREtZR', '_blank')
    service.billing.declineKyc().catch(logError)
  }

  const handleSignOut = () => {
    window.open('https://my.openphone.com/login', '_blank')
    signOut()
  }

  if (isFlagged || isReviewApproved) {
    return null
  }

  return (
    <VerificationLayout>
      <Card
        emoji={
          isStripeProcessing ? (
            <CircularProgress style={{ height: 33, width: 33 }} />
          ) : (
            <span style={{ fontSize: 35 }}>🪪</span>
          )
        }
        title={
          isStripeProcessing
            ? 'Verifying identity...'
            : 'We need to verify a few extra details'
        }
        description={
          isStripeProcessing
            ? `Sit tight, we're almost done. This process usually takes less than 3 minutes.`
            : `We're doing our part to reduce phone fraud by asking new users to confirm their identity with our trusted partner - Stripe`
        }
      >
        {!isStripeProcessing ? (
          <>
            {isElectron ? (
              <>
                <Button onClick={handleSignOut} fullWidth>
                  Take me to the web app
                </Button>
                <Disclaimer
                  emoji="🔒"
                  text="We don't support this process on the desktop app. Please navigate to the web app to proceed."
                />
              </>
            ) : (
              <>
                <Button
                  onClick={openStripeModal}
                  startIcon={<PadlockShieldIcon />}
                  fullWidth
                >
                  Verify with Stripe (Instant)
                </Button>
                <Button
                  onClick={handleVerifyManual}
                  color="neutral"
                  style={{ marginTop: '10px' }}
                  fullWidth
                >
                  Verify manually (up to 2 business days)
                </Button>
                <Disclaimer
                  emoji="🔒"
                  text="Your information will be encrypted and stored safely in compliance with our strict security standards."
                />
              </>
            )}
          </>
        ) : (
          <Typography
            variant="body"
            color="textSecondary"
            style={{ textAlign: 'center' }}
          >
            Having trouble? Reach out to us at{' '}
            <a href="mailto:support@openphone.com">support@openphone.com</a>
          </Typography>
        )}
      </Card>
    </VerificationLayout>
  )
}

export default observer(Identity)
