import React from 'react'
import { createRoot } from 'react-dom/client'

import { CircularProgress } from '@src/component/progress'
import { useAppTheme } from '@src/theme'
import DesignSystemProvider from '@ui/DesignSystemProvider'
import Typography from '@ui/Typography'
import { LogomarkIcon } from '@ui/icons/tint/misc'

function LoadingScreen() {
  const theme = useAppTheme('system')

  return (
    <DesignSystemProvider themeKey={theme.palette.type}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: theme.palette.op.background.primary,
        }}
      >
        <LogomarkIcon style={{ position: 'absolute', top: 32, left: 32 }} />

        <CircularProgress size={48} />

        <Typography variant="title2" color="textPrimary" style={{ marginTop: 32 }}>
          Update in progress...
        </Typography>
      </div>
    </DesignSystemProvider>
  )
}

export default function mountLoadingScreen() {
  const element = document.getElementById('root')

  if (!element) {
    throw new Error('Could not find root element')
  }

  const root = createRoot(element)

  root.render(<LoadingScreen />)
}
