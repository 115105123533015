/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable, reaction } from 'mobx'

import isNonNull from '@src/lib/isNonNull'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import { Subscription } from './model'
import type {
  Subscription as ISubscription,
  Coupon,
  CreditCard,
  Invoice,
  UpgradeParams,
  CancelParams,
} from './transport/billing'

export default class BillingStore {
  /**
   * Prefer using `Billing.getCurrentSubscription()` instead if you expect the current
   * subscription to be ready.
   */
  subscription: Subscription | null = null
  coupon: Coupon | null = null
  invoices: Invoice[] = []
  upcomingInvoice: Invoice | null = null
  card: CreditCard | null = null
  showPremiumBanner = true

  /**
   * - Trigger UI changes when a KYC (Know Your Customer) check fails
   * - The client is notified about the KYC failure via WebSocket subscription update
   * - We can determine a failed attempt by comparing the `identityFailedCount` property
   *   between the new and old subscription objects
   */
  hasKycFailed = false

  constructor(private root: Service) {
    this.subscribeToWebSocket()
    makeAutoObservable(this, {})
    makePersistable(this, 'BillingStore', {
      subscription: root.storage.async((d) => new Subscription(root).deserialize(d)),
      coupon: root.storage.async(),
      showPremiumBanner: root.storage.sync(),
    })

    reaction(
      () => this.subscription?.identityFailedCount,
      (count, prevCount) => {
        if (isNonNull(count) && isNonNull(prevCount) && count > prevCount) {
          this.hasKycFailed = true
        }
      },
    )
  }

  /**
   * Returns the current subscription if it's ready, otherwise throws an error.
   *
   * @returns The current subscription
   * @throws If the current subscription is not ready yet
   */
  getCurrentSubscription() {
    const currentSubscription = this.subscription

    if (!currentSubscription) {
      throw new Error(
        'BillingStore.getCurrentSubscription() called before the current subscription was ready',
      )
    }

    return currentSubscription
  }

  fetchSubscription() {
    return this.root.transport.billing.subscription().then(
      action((json) => {
        this.subscription ??= new Subscription(this.root)
        this.subscription.deserialize(json)
      }),
    )
  }

  fetchIdentityVerificationSecret() {
    return this.root.transport.billing.identityVerificationSecret()
  }

  fetchInvoices() {
    return this.root.transport.billing.invoices().then(
      action((res) => {
        this.invoices = res
      }),
    )
  }

  fetchUpcomingInvoice() {
    return this.root.transport.billing.upcomingInvoice().then(
      action((res) => {
        this.upcomingInvoice = res
      }),
    )
  }

  fetchCards() {
    return this.root.transport.billing.creditCard().then(
      action((res) => {
        this.card = res
      }),
    )
  }

  upgrade(params: UpgradeParams) {
    return this.root.transport.billing.upgrade(params).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  getSubscriptionIntent() {
    return this.root.transport.billing.getSubscriptionIntent()
  }

  convertCredits(amount: number) {
    return this.root.transport.billing.convertCredits(amount).then(
      action((res) => {
        this.upsertSubscription(res.subscription)
      }),
    )
  }

  addCredits(amount: number) {
    return this.root.transport.billing.addCredits(amount).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  autoCharge(amount: number) {
    return this.root.transport.billing.autoCharge(amount).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  restart() {
    return this.root.transport.billing.restart().then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  endTrial() {
    return this.root.transport.billing.endTrial().then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  // TODO(christopherbot) make params required when WRK-1023 is done
  cancel(params?: CancelParams) {
    return this.root.transport.billing.cancel(params).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  upsertCreditCard(paymentMethodId: string) {
    return this.root.transport.billing.upsertCreditCard(paymentMethodId).then(
      action((res) => {
        this.card = res
      }),
    )
  }

  upsertSubscription(subscription: ISubscription) {
    if (this.subscription) {
      this.subscription.deserialize(subscription)
    } else {
      const newSubscription = new Subscription(this.root)
      newSubscription.deserialize(subscription)
      this.subscription = newSubscription
    }
  }

  fetchCoupon(code: string) {
    return this.root.transport.billing
      .coupon(code)
      .then(action((res) => (this.coupon = res)))
  }

  /**
   * Refreshes the Stripe client secret for KYC flow
   *
   * @param orgId
   * @returns updated supscription with new identityVerificationSecret
   */
  refreshClientSecret(orgId: string) {
    return this.root.transport.billing.refreshClientSecret(orgId).then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  /**
   * Bypasses the KYC flow
   *
   * @param orgId
   * @returns updated supscription by setting identityVerificationStatus to `failed` & reviewStatus to `needs review`
   */
  declineKyc() {
    return this.root.transport.billing.declineKyc().then(
      action((res) => {
        this.upsertSubscription(res)
      }),
    )
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'subscription-update':
          return this.upsertSubscription(data.subscription)
      }
    })
  }
}
