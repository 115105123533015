import fuzzysort from 'fuzzysort'

export function randomId(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

export function convertToAction(str: string): string {
  return str.toUpperCase().replace(/-/, '_')
}

export function capitalize(s: string | null): string | null {
  if (!s) return s
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function hash(string: string): number {
  if (!string) return 0
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = (hash << 5) - hash + string.charCodeAt(i)
    hash |= 0
  }
  return hash
}

export function stripSpecials(str: string): string {
  return str.replace(/[*#+()\-\s]/g, '')
}

export function highlight(str: string, query?: string): string {
  if (!query) return str
  const result = fuzzysort.single(stripSpecials(query), str)
  if (!result) return str
  return fuzzysort.highlight(result, '<em>', '</em>') || str
}

export function hashCode(str: string): number {
  let hash = 0
  if (str.length == 0) {
    return hash
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

export function compareString(
  a: string,
  b: string,
  opts: { caseInsensitive?: boolean } = {},
): -1 | 0 | 1 {
  if (opts.caseInsensitive) {
    a = a?.toLocaleLowerCase()
    b = b?.toLocaleLowerCase()
  }
  if (a === b) return 0
  if (a < b) return -1
  return 1
}

export function getInitials(text?: string | null): string {
  return (
    text
      ?.match(/(^\S\S?|\b\S)?/g)
      ?.join('')
      .match(/(^\S|\S$)?/g)
      ?.join('')
      .toUpperCase() ?? ''
  )
}

export function isTruthy(value: any): boolean {
  if (!value) return false
  if (typeof value === 'string') {
    value = value.toLowerCase()
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
  return ['false', 'off', null, 0, '0', 'no'].indexOf(value) === -1
}

/**
 * @param amount in cents
 * @param options Optional formatting options
 * @param options.hideCentsIfZero If true, displays $x instead of $x.00
 */
export const toFormattedDollars = (
  amount: number,
  options?: {
    hideCentsIfZero?: boolean
  },
): string => {
  const dollarAmount = amount / 100
  return dollarAmount.toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    ...(options?.hideCentsIfZero &&
      dollarAmount % 1 === 0 && {
        maximumFractionDigits: 0,
      }),
  })
}

export const joinPretty = (names: string[], join = 'and', oxford = true) => {
  switch (names.length) {
    case 0: {
      return names
    }
    case 1: {
      return names[0]
    }
    case 2: {
      return `${names[0]} and ${names[1]}`
    }
    default: {
      let returnString = names[0]
      for (let i = 1; i < names.length; i++) {
        if (i < names.length - 1) {
          returnString += `, ${names[i]}`
        } else {
          returnString += oxford ? ',' : ''
          returnString += ` ${join} ${names[i]}`
        }
      }
      return returnString
    }
  }
}
