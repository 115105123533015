import type NonNullableRangeModifier from '@src/app/analytics/NonNullableRangeModifier'

export type ReportGranularity = 'day' | 'week'

export const getAnalyticsQuery = ({
  phoneNumberId,
  range,
  granularity = 'day',
  usersCount,
}: {
  phoneNumberId: string[]
  range: NonNullableRangeModifier
  granularity: ReportGranularity
  usersCount: number
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    body: {
      query: {
        bool: {
          filter: [
            {
              range: {
                createdAt: {
                  time_zone: timezone,
                  gte: range.from,
                  lte: range.to,
                },
              },
            },
            {
              terms: {
                phoneNumberId,
              },
            },
          ],
        },
      },
      aggs: {
        uniquePeople: {
          date_histogram: {
            field: 'createdAt',
            calendar_interval: granularity,
            min_doc_count: 0,
            time_zone: timezone,
            extended_bounds: {
              min: range.from.getTime(),
              max: range.to.getTime(),
            },
          },
          aggs: {
            unique: {
              cardinality: { field: 'phoneNumber' },
            },
          },
        },
        incoming: {
          filter: {
            bool: {
              must: {
                term: {
                  direction: 'incoming',
                },
              },
              must_not: {
                term: {
                  type: 'voicemail',
                },
              },
            },
          },
          aggs: {
            statuses: {
              terms: { field: 'status' },
              aggs: {
                histogram: {
                  date_histogram: {
                    field: 'createdAt',
                    calendar_interval: granularity,
                    min_doc_count: 0,
                    time_zone: timezone,
                    extended_bounds: {
                      min: range.from.getTime(),
                      max: range.to.getTime(),
                    },
                  },
                },
              },
            },
          },
        },
        voicemails: {
          filter: {
            term: { type: 'voicemail' },
          },
          aggs: {
            histogram: {
              date_histogram: {
                field: 'createdAt',
                calendar_interval: granularity,
                min_doc_count: 0,
                time_zone: timezone,
                extended_bounds: {
                  min: range.from.getTime(),
                  max: range.to.getTime(),
                },
              },
            },
          },
        },
        activitiesByDay: {
          terms: {
            script: {
              source: `LocalDateTime.ofInstant(Instant.ofEpochMilli(doc['createdAt'].value.millis),ZoneId.of('${timezone}')).getDayOfWeek()`,
              lang: 'painless',
            },
          },
          aggs: {
            hours: {
              histogram: {
                script: `LocalDateTime.ofInstant(Instant.ofEpochMilli(doc['createdAt'].value.millis),ZoneId.of('${timezone}')).getHour()`,
                interval: 1,
                min_doc_count: 0,
                extended_bounds: {
                  min: 0,
                  max: 23,
                },
              },
            },
          },
        },
        belongsTo: {
          filter: {
            exists: {
              field: 'type',
            },
          },
          aggs: {
            activities: {
              terms: {
                field: 'type',
              },
              aggs: {
                direction: {
                  terms: {
                    field: 'direction',
                  },
                  aggs: {
                    histogram: {
                      date_histogram: {
                        field: 'createdAt',
                        time_zone: timezone,
                        calendar_interval: granularity,
                        min_doc_count: 0,
                        extended_bounds: {
                          min: range.from.getTime(),
                          max: range.to.getTime(),
                        },
                      },
                    },
                  },
                },
                duration: {
                  date_histogram: {
                    field: 'createdAt',
                    time_zone: timezone,
                    calendar_interval: granularity,
                    min_doc_count: 0,
                    extended_bounds: {
                      min: range.from.getTime(),
                      max: range.to.getTime(),
                    },
                  },
                  aggs: {
                    duration: {
                      sum: {
                        field: 'duration',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        wtf: {
          terms: {
            field: 'belongsTo',
            size: phoneNumberId.length,
          },
          aggs: {
            activities: {
              terms: {
                field: 'type',
              },
              aggs: {
                direction: {
                  terms: {
                    field: 'direction',
                  },
                  aggs: {
                    histogram: {
                      date_histogram: {
                        field: 'createdAt',
                        time_zone: 'America/Vancouver',
                        calendar_interval: 'day',
                        min_doc_count: 0,
                        extended_bounds: {
                          min: range.from.getTime(),
                          max: range.to.getTime(),
                        },
                      },
                    },
                  },
                },
                duration: {
                  date_histogram: {
                    field: 'createdAt',
                    time_zone: 'America/Vancouver',
                    calendar_interval: 'day',
                    min_doc_count: 0,
                    extended_bounds: {
                      min: range.from.getTime(),
                      max: range.to.getTime(),
                    },
                  },
                  aggs: {
                    duration: {
                      sum: {
                        field: 'duration',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        users: {
          terms: {
            field: 'belongsTo',
            size: usersCount,
          },
          aggs: {
            histogram: {
              date_histogram: {
                field: 'createdAt',
                time_zone: timezone,
                calendar_interval: granularity,
                min_doc_count: 0,
                extended_bounds: {
                  min: range.from.getTime(),
                  max: range.to.getTime(),
                },
              },
            },
          },
        },
      },
    },
  }
}

export const getAnalyticsQueryV2 = ({
  phoneNumberId,
  range,
  granularity = 'day',
}: {
  phoneNumberId: string[]
  range: NonNullableRangeModifier
  granularity: ReportGranularity
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    body: {
      query: {
        bool: {
          filter: [
            {
              range: {
                createdAt: {
                  time_zone: timezone,
                  gte: range.from,
                  lte: range.to,
                },
              },
            },
            {
              terms: {
                phoneNumberId,
              },
            },
          ],
        },
      },
      aggs: {
        uniquePeople: {
          date_histogram: {
            field: 'createdAt',
            calendar_interval: granularity,
            min_doc_count: 0,
            time_zone: timezone,
            extended_bounds: {
              min: range.from.getTime(),
              max: range.to.getTime(),
            },
          },
          aggs: {
            unique: {
              cardinality: { field: 'phoneNumber' },
            },
          },
        },
        incoming: {
          filter: {
            bool: {
              must: {
                term: {
                  direction: 'incoming',
                },
              },
              must_not: {
                term: {
                  type: 'voicemail',
                },
              },
            },
          },
          aggs: {
            statuses: {
              terms: { field: 'status' },
              aggs: {
                histogram: {
                  date_histogram: {
                    field: 'createdAt',
                    calendar_interval: granularity,
                    min_doc_count: 0,
                    time_zone: timezone,
                    extended_bounds: {
                      min: range.from.getTime(),
                      max: range.to.getTime(),
                    },
                  },
                },
              },
            },
          },
        },
        belongsTo: {
          filter: {
            exists: {
              field: 'type',
            },
          },
          aggs: {
            activities: {
              terms: {
                field: 'type',
              },
              aggs: {
                direction: {
                  terms: {
                    field: 'direction',
                  },
                  aggs: {
                    histogram: {
                      date_histogram: {
                        field: 'createdAt',
                        time_zone: timezone,
                        calendar_interval: granularity,
                        min_doc_count: 0,
                        extended_bounds: {
                          min: range.from.getTime(),
                          max: range.to.getTime(),
                        },
                      },
                    },
                  },
                },
                duration: {
                  date_histogram: {
                    field: 'createdAt',
                    time_zone: timezone,
                    calendar_interval: granularity,
                    min_doc_count: 0,
                    extended_bounds: {
                      min: range.from.getTime(),
                      max: range.to.getTime(),
                    },
                  },
                  aggs: {
                    duration: {
                      sum: {
                        field: 'duration',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  }
}

export const getAnalyticsUsersActivities = ({
  userIds,
  range,
  prevRange,
}: {
  userIds: string[]
  range: NonNullableRangeModifier
  prevRange: NonNullableRangeModifier
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    body: {
      query: {
        bool: {
          filter: [
            {
              range: {
                createdAt: {
                  time_zone: timezone,
                  gte: prevRange.from,
                  lte: range.to,
                },
              },
            },
            {
              terms: {
                userId: userIds,
              },
            },
          ],
        },
      },
      aggs: {
        users: {
          terms: {
            field: 'belongsTo',
            size: userIds.length,
          },
          aggs: {
            period: {
              date_range: {
                field: 'createdAt',
                time_zone: timezone,
                ranges: [
                  {
                    key: 'previous',
                    from: prevRange.from,
                    to: prevRange.to,
                  },
                  {
                    key: 'current',
                    from: range.from,
                    to: range.to,
                  },
                ],
              },
              aggs: {
                calls: {
                  filter: {
                    term: {
                      type: 'call',
                    },
                  },
                  aggs: {
                    duration: { sum: { field: 'duration' } },
                  },
                },
                messages_sent: {
                  filter: {
                    bool: {
                      must: [
                        {
                          term: {
                            type: 'message',
                          },
                        },
                        {
                          term: {
                            direction: 'outgoing',
                          },
                        },
                      ],
                    },
                  },
                },
                calls_outgoing: {
                  filter: {
                    bool: {
                      must: [
                        {
                          term: {
                            type: 'call',
                          },
                        },
                        {
                          term: {
                            direction: 'outgoing',
                          },
                        },
                      ],
                    },
                  },
                },
                calls_incoming: {
                  filter: {
                    bool: {
                      must: [
                        {
                          term: {
                            type: 'call',
                          },
                        },
                        {
                          term: {
                            direction: 'incoming',
                          },
                        },
                      ],
                    },
                  },
                },
                direction: {
                  terms: {
                    field: 'direction',
                  },
                  aggs: {
                    status: {
                      terms: {
                        field: 'status',
                      },
                      aggs: {
                        histogram: {
                          date_histogram: {
                            field: 'createdAt',
                            time_zone: 'America/Vancouver',
                            calendar_interval: 'day',
                            min_doc_count: 0,
                            extended_bounds: {
                              min: prevRange.from.getTime(),
                              max: range.to.getTime(),
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  }
}

export const getDailyActivities = ({
  range,
  phoneNumberIds,
}: {
  range: NonNullableRangeModifier
  phoneNumberIds: string[]
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return {
    body: {
      query: {
        bool: {
          filter: [
            {
              range: {
                createdAt: {
                  time_zone: timezone,
                  gte: range.from,
                  lte: range.to,
                },
              },
            },
            {
              terms: {
                phoneNumberId: phoneNumberIds,
              },
            },
          ],
        },
      },
      aggs: {
        activitiesByDay: {
          terms: {
            script: {
              source: `LocalDateTime.ofInstant(Instant.ofEpochMilli(doc['createdAt'].value.millis),ZoneId.of('${timezone}')).getDayOfWeek()`,
              lang: 'painless',
            },
          },
          aggs: {
            hours: {
              histogram: {
                script: `LocalDateTime.ofInstant(Instant.ofEpochMilli(doc['createdAt'].value.millis),ZoneId.of('${timezone}')).getHour()`,
                interval: 1,
                min_doc_count: 0,
                extended_bounds: {
                  min: 0,
                  max: 23,
                },
              },
            },
          },
        },
      },
    },
  }
}
