import { createContext } from 'react'

import type { Subscription } from '@src/service/model'

export interface BillingSubscriptionContextType {
  subscription: Subscription
}

const BillingSubscriptionContext = createContext<BillingSubscriptionContextType | null>(
  null,
)

export default BillingSubscriptionContext
