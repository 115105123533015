import type { IReactionGroup } from '@src/component/reactions'
import { stripSkinVariation } from '@src/lib/emoji'
import type BaseReaction from '@src/service/model/reactions/BaseReaction'

const getGroupReactions = (
  reactions: readonly BaseReaction[],
  currentUserId: string,
): IReactionGroup[] => {
  const groups = reactions.reduce((acc, value) => {
    const emoji = stripSkinVariation(value.body ?? '')
    const group = acc.get(emoji) ?? { variations: new Set<string>(), reactions: [] }
    acc.set(emoji, {
      variations: new Set([...group.variations, value.body ?? '']),
      reactions: [...group.reactions, value],
    })
    return acc
  }, new Map<string, { variations: Set<string>; reactions: BaseReaction[] }>())

  return [...groups.entries()].map(([emoji, group]) => ({
    emoji,
    variations: [...group.variations],
    count: group.reactions.length,
    currentUserReacted: Boolean(group.reactions.find((r) => r.userId === currentUserId)),
  }))
}

export default getGroupReactions
