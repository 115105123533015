/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const CircularProgress = withStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.op.primary[2],
  },
}))(MuiCircularProgress)

export { CircularProgress }
