export const borderRadius = {
  tooltip: 5,
  dropdown: 8,
  popup: 8,
  modal: 10,
  comments: {
    closed: 10,
    open: 15,
  },
  card: 15,
}

export const contactTags = {
  green1: '#00CD6B',
  red1: '#FD4B57',
  blue1: '#009FFE',
  orange1: '#FF9D00',
  cherokee1: '#E4B677',
  turquoise1: '#00D3C3',
  mauve1: '#CDA9FD',
  purple1: '#A04EF8',
  monaLisa1: '#FF9990',
  gray1: '#A6B4C9',
}

export const legacy = {
  dateEditor: {
    weekDayGray: '#8B9898',
    todayButton: '#4A90E2',
    weekNumberBorder: '#EAECEC',
    disabledText: '#DCE0E0',
  },
  chart: {
    green1: '#4AFF5E',
    green2: '#8BFFA6',
    blue1: '#4AB4FF',
    blue2: '#87CCFF',
    yellow1: '#FFCB49',
    red1: '#FF7777',
  },
  heatmap: {
    blue1: '#4040FE',
  },
  commandItem: {
    purple1: '#604496',
  },
  draftGreen: '#39D07E',
  ongoingCall: {
    active: '#EB3E3E',
    record: '#EB3E3E',
  },
  buttonOutlined: '#FF8C8C',
}

export const callModal = {
  background: '#0E0E15',
  backgroundBlurred: '#12121B',
  highlight: '#2C2C3D',
}
