type SPEC = {
  readonly radix: number
  readonly unit: string[]
}

export type FileType =
  | 'audio'
  | 'blank'
  | 'contact'
  | 'csv'
  | 'doc'
  | 'image'
  | 'pdf'
  | 'presentation'
  | 'spreadsheet'
  | 'text'
  | 'video'
  | 'zip'

const si = { radix: 1e3, unit: ['b', 'kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'] }

const iec = {
  radix: 1024,
  unit: ['b', 'Kib', 'Mib', 'Gib', 'Tib', 'Pib', 'Eib', 'Zib', 'Yib'],
}

const jedec = { radix: 1024, unit: ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'] }

type MemStandard = 'si' | 'iec' | 'jedec'

export const SPECS: Record<MemStandard, SPEC> = {
  si,
  iec,
  jedec,
}

export function fileSize(bytes: number, fixed = 1, spec?: MemStandard): string {
  bytes = Math.abs(bytes)

  const { radix, unit } = SPECS[spec ?? 'jedec']

  let loop = 0

  // calculate
  while (bytes >= radix) {
    bytes /= radix
    ++loop
  }
  return `${bytes.toFixed(fixed)} ${unit[loop]}`
}

export function fileType(
  contentType: string | undefined | null,
  path: string | undefined | null,
): FileType {
  if (!contentType) return 'blank'

  const type = contentType.split(';')[0]
  const ext = path?.split('.').pop()

  switch (type) {
    case 'application/x-pdf':
    case 'application/pdf':
      return 'pdf'

    case 'text/csv':
      return 'csv'

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'spreadsheet'

    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'presentation'

    case 'application/msword':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      return 'doc'

    case 'application/zip':
      return 'zip'

    case 'text/vcard':
    case 'text/x-vcard':
      return 'contact'

    case 'text/directory':
      if (ext === 'vcf') {
        return 'contact'
      }
  }

  if (contentType.startsWith('audio/')) {
    return 'audio'
  } else if (contentType.startsWith('image/')) {
    return 'image'
  } else if (contentType.startsWith('video/')) {
    return 'video'
  }

  return 'blank'
}

export function fileTypeFromExtension(extension: string): FileType {
  if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) return 'image'
  if (['mp3', 'aac', 'ogg', 'mpga'].includes(extension)) return 'audio'
  if (['webm', 'mp4'].includes(extension)) return 'video'

  switch (extension) {
    case 'doc':
    case 'docx':
      return 'doc'
    case 'pdf':
      return 'pdf'
    case 'csv':
      return 'csv'
    case 'xls':
    case 'xlsx':
      return 'spreadsheet'
    case 'ppt':
    case 'pptx':
      return 'presentation'
    case 'zip':
      return 'zip'
    case 'txt':
      return 'text'
  }

  return 'blank'
}

export function isValidFileType(type: string) {
  const invalidFileTypes = ['text/html']
  return !invalidFileTypes.includes(type)
}

/**
 * Returns the hex representation of the first 32 bytes of the given file.
 * Here's a documentation site that contains the list of file signatures:
 * https://www.garykessler.net/library/file_sigs.html
 *
 * @param blob
 * @returns An hex string of the 32 first bytes of the blob
 */
export async function getMagicBytes(blob: Blob): Promise<string> {
  const buffer = await readFileAsArrayBuffer(blob.slice(0, 32))
  const uint8 = new Uint8Array(buffer)

  let hexBytes = ''
  for (const byte of uint8) {
    hexBytes += byte.toString(16).padStart(2, '0')
  }

  hexBytes = hexBytes.toUpperCase()

  return hexBytes
}

async function readFileAsArrayBuffer(file: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}
