import type { FlagMap } from './FlagsTypes'

const defaultFlags: FlagMap = {
  cnam: false,
  salesforce: {
    enabled: false,
    messageLogging: false,
  },
  standardBrandRegistration: false,
  contactNoteReactions: false,
  callFlowSettings: {
    layoutRedesign: false,
    roundRobin: false,
    callGreeting: false,
  },
  conversationsBulkActions: false,
  analyticsUiRefresh: false,
  onboardingSubscriptionFraudTexts: false,
  inboxFilters: false,
  sentry: false,
  sentryTracesSampleRate: 0,
  pricingV5: false,
  analyticsUiRefreshUsersSpentOnCallsColumn: false,
  cohere: false,
  pendo: false,
  posthog: false,
  messagingPolicyWarning: false,
  numOfConversationsToFetchWhenFiltering: 0,
  captureCancelSubscriptionReason: false,
  panelResizing: 'disabled',
  groupCallingConference: false,
  groupCallingWarmTransfer: false,
  groupCallingHold: false,
  groupCallingListener: false,
  groupCallingWhispering: false,
  groupCallingSelfParticipant: false,
  groupCallingAddMultipleParticipants: false,
  profilePageReferralCallout: false,
  contactFilters: false,
  minAutoChargeAmountCents: 0,
  callTranscripts: false,
  ringOrderSettings: false,
  pinnedConversations: false,
  maxPinnableConversations: 0,
}

export default defaultFlags
