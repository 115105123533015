/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable } from 'mobx'

import type ById from '@src/lib/ById'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import { PhoneNumber } from './model'
import type { PhoneNumberRepository } from './worker/repository'

export default class PhoneNumberStore {
  readonly collection: PersistedCollection<PhoneNumber, PhoneNumberRepository>

  readonly byGroup: ById<PhoneNumber | null> = {}
  readonly byNumber: ById<PhoneNumber | null> = {}

  loaded = false

  constructor(private root: Service) {
    this.collection = new PersistedCollection<PhoneNumber, PhoneNumberRepository>({
      table: this.root.storage.table('phoneNumber'),
      classConstructor: () => new PhoneNumber(this.root),
    })

    makeAutoObservable(this, {})
    this.subscribeToWebSocket()
    this.createIndexes()
  }

  fetch() {
    this.collection
      .performQuery((repo) => repo.all())
      .then(
        action((data) => {
          // TODO: See if this can be a characteristic of the PersistedCollection
          if (data.length > 0) this.loaded = true
        }),
      )

    return this.root.transport.account.workspace.phoneNumbers
      .list()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
      .then(action(() => (this.loaded = true)))
  }

  buy(phoneNumber: string, temporary: boolean): Promise<PhoneNumber> {
    return this.root.transport.account.phoneNumbers
      .buy(phoneNumber, temporary)
      .then(this.collection.load)
      .then((phoneNumbers) => {
        this.root.analytics.numberCreated('member')
        return phoneNumbers[0]
      })
  }

  verify(phoneNumber: string, recaptcha_token?: string): Promise<void> {
    return this.root.transport.account.verifyPhoneNumber(phoneNumber, recaptcha_token)
  }

  checkCode(phoneNumber: string, code: string): Promise<void> {
    return this.root.transport.account
      .checkPhoneNumberCode(phoneNumber, code)
      .then((user) => {
        this.root.user.current?.deserialize(user)
      })
  }

  getByPortRequestId(id: string): PhoneNumber | undefined {
    return this.collection.list.find((item) => item.portRequestId === id)
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'phone-number-update':
          return this.collection.load(data.phoneNumber)
        case 'phone-number-delete':
          return this.collection.delete(data.phoneNumberId)
      }
    })
  }

  protected createIndexes() {
    this.collection.observe(
      action((event) => {
        if (event.type === 'put') {
          event.objects.forEach((phoneNumber) => {
            if (phoneNumber.groupId) {
              this.byGroup[phoneNumber.groupId] = phoneNumber
            }
            this.byNumber[phoneNumber.number] = phoneNumber
          })
        } else if (event.type === 'delete') {
          event.objects.forEach((phoneNumber) => {
            if (phoneNumber.groupId) {
              this.byGroup[phoneNumber.groupId] = null
            }
            this.byNumber[phoneNumber.number] = null
          })
        }
      }),
    )
  }
}
