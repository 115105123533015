export default {
  default: [
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  emoji: ['Apple Color Emoji', '-apple-system', 'sans-serif'].join(','),
  mono: ['JetBrains Mono', 'zeitung-mono', 'monospace'].join(','),
  header: [
    'GT-Standard',
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(','),
  signature: ['Caveat', 'sans-serif'].join(', '),
}
