import React, { useCallback, type ReactNode } from 'react'

import RecaptchaContext from '@src/app/recaptcha/RecaptchaContext'
import config from '@src/config'
import useGoogleReCaptcha from '@src/lib/hooks/useGoogleReCaptcha'

const RecaptchaProvider = (props: { children: ReactNode }) => {
  const { isReady } = useGoogleReCaptcha()

  const generateToken = useCallback(
    (action: string) =>
      grecaptcha.execute(config.GOOGLE_RECAPTCHA_SITE_KEY, {
        action,
      }),
    [],
  )

  return (
    <RecaptchaContext.Provider value={{ generateToken, isReady }}>
      {props.children}
    </RecaptchaContext.Provider>
  )
}

export default RecaptchaProvider
