import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { object, string, type SchemaOf } from 'yup'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import TextField from '@ui/TextField'

function isValidDeploymentUrl(url: string) {
  const validDeploymentUrls = [
    /^https:\/\/[\w-]+-openphone.vercel.app\/?$/,
    /\w+\.openphone\.com?$/,
  ]
  return validDeploymentUrls.some((validDeploymentUrl) => validDeploymentUrl.test(url))
}

interface FormFields {
  url: string
}

const schema: SchemaOf<FormFields> = object({
  url: string()
    .required()
    .url()
    .test(
      'is-valid-deployment-url',
      '${path} is not a valid deployment url',
      (value) => !!value && isValidDeploymentUrl(value),
    ),
})

export default function DeployPreview() {
  const styles = useStyles()
  const { electron } = useAppStore()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      url: '',
    },
  })

  const handleDeployPreview = handleSubmit(({ url }) => {
    electron?.send('start-preview', { url })
    window.close()
  })

  const handleCancel = () => {
    window.close()
  }

  return (
    <div className={styles.root}>
      <form onSubmit={handleDeployPreview} className={styles.form}>
        <TextField
          id="url"
          type="url"
          label="URL"
          className={styles.input}
          errorText={errors.url?.message}
          {...register('url')}
        />
        <Button
          type="button"
          onClick={handleCancel}
          className={styles.cancel}
          variant={'text'}
        >
          Close
        </Button>
        <Button type="submit" className={styles.accept}>
          Preview
        </Button>
      </form>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "input input"
      "cancel accept"
    `,
    padding: '1rem 2rem 2rem',
    gridTemplateRows: 'min-content',
    rowGap: '1rem',
    columnGap: '2rem',
  },
  input: {
    gridArea: 'input',
  },
  cancel: {
    gridArea: 'cancel',
  },
  accept: {
    gridArea: 'accept',
  },
})
