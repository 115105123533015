import cx from 'classnames'
import React from 'react'

import * as styles from './Bone.css'

export interface BoneProps {
  className?: string
}

export const Bone = ({ className }: BoneProps) => {
  return <div className={cx(styles.root, className)} />
}

export default Bone
