import { makeAutoObservable, toJS } from 'mobx'

import { parseDate, removeAtIndex } from '@src/lib'
import ObjectID from '@src/lib/object-id'
import { ContactNoteReaction, getGroupReactions } from '@src/service/model/reactions'

import type Service from '..'

import type { Enrichment } from './ActivityModel'
import type { Model } from './base'
import type { Contact } from './contact'

export interface INote {
  createdAt: number | null
  deletedAt: number | null
  id: string
  private: boolean | null
  text: string | null
  updatedAt: number | null
  userId: string | null
}

export class Note implements INote, Model {
  createdAt: number | null = Date.now()
  deletedAt: number | null = null
  id: string = ObjectID()
  private: boolean | null = null
  text: string | null = null
  updatedAt: number | null = Date.now()
  userId: string | null = null
  enrichment: Enrichment | null = null
  reactions: ContactNoteReaction[] = []

  constructor(
    private service: Service,
    public contact: Contact,
    attrs: Partial<Note> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get canEdit() {
    return this.userId === this.service.user.current?.id
  }

  get user() {
    return this.service.member.collection.get(this.userId)
  }

  get groupReactions() {
    return getGroupReactions(this.reactions, this.service.user.getCurrentUser().id)
  }

  update(text: string) {
    this.text = text
    return this.service.contact.putNote(this)
  }

  delete() {
    this.contact.notes = this.contact.notes.filter((n) => n !== this)
    return this.service.contact.deleteNote(this)
  }

  toggleReaction(value: string) {
    let reaction = this.service.emoji.getReaction(
      value,
      this.service.emoji,
      this.reactions,
      this.service.user.getCurrentUser().id,
    )

    if (reaction) {
      this.deleteReaction(reaction)
    } else {
      reaction = new ContactNoteReaction(this.service, this, {
        body: this.service.emoji.getEmojiWithSkinTone(value),
        userId: this.service.user.getCurrentUser().id,
      })
      this.addReaction(reaction)
    }
  }

  deleteReaction(reaction: ContactNoteReaction) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.reactions = removeAtIndex(this.reactions, reactionIndex)
    return this.service.contact.deleteNoteReaction(this.contact.id, this.id, reaction)
  }

  addReaction(reaction: ContactNoteReaction) {
    this.reactions.push(reaction)
    return this.service.contact.addNoteReaction(
      this.contact.id,
      this.id,
      reaction.toJSON(),
    )
  }

  deserialize(json: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const { reactions, updatedAt, createdAt, deletedAt, ...attrs } = json
    if (attrs) {
      Object.assign(this, attrs)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.updatedAt = parseDate(updatedAt || this.updatedAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.createdAt = parseDate(createdAt || this.createdAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.deletedAt = parseDate(deletedAt || this.deletedAt)
    }

    if (reactions) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
      this.reactions = reactions.map((reaction) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        const existing = this.reactions.find((r) => r.id === reaction.id)
        if (existing) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          return existing.deserialize(reaction)
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          return new ContactNoteReaction(this.service, this, reaction)
        }
      })
    }
    return this
  }

  serialize() {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      enrichment: toJS(this.enrichment),
      updatedAt: this.updatedAt,
      userId: this.userId,
      reactions: this.reactions.map((r) => r.serialize()),
    }
  }

  toJSON(): INote {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      private: this.private,
      text: this.text,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}
