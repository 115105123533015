import type { DialogBus } from '@openphone/desktop-client'
import { createContext, useContext } from 'react'

const DialogBusContext = createContext<DialogBus | null>(null)

export function useDialogBus(): DialogBus | null {
  return useContext(DialogBusContext)
}

export default DialogBusContext
