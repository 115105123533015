import React, { lazy, Suspense } from 'react'
import type { Props as ConfettiProps } from 'react-confetti'

const Confetti = lazy(() => import('react-confetti'))

export default function LazyConfetti(props: ConfettiProps) {
  return (
    <Suspense fallback={null}>
      <Confetti {...props} />
    </Suspense>
  )
}
