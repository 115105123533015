import { createContext } from 'react'

import type { ThemeMatch } from './createThemeMatch'
import createThemeMatch from './createThemeMatch'
import type { ThemeKey } from './themes/common'
import { themeClassNames } from './themes/common'

export interface ThemeContextType {
  themeKey: ThemeKey
  themeClassName: string
  match: ThemeMatch
}

const ThemeContext = createContext(createThemeContextValue('light'))

export default ThemeContext

export function createThemeContextValue(themeKey: ThemeKey) {
  return {
    themeKey,
    themeClassName: themeClassNames[themeKey],
    match: createThemeMatch(themeKey),
  }
}
